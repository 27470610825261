// import { URLs } from '@common/api';
import { getData, URLs } from '@common/api';
import * as StoreTestimonials from '@common/models/StoreTestimonials';
import { DEFAULT_TESTIMONIALS_LAYOUT_SELECTION } from '@theme/utils/constants';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchStoreTestimonialsFailure, fetchStoreTestimonialsRequest, fetchStoreTestimonialsSuccess } from './slice';

export function* fetchStoreTestimonialsSaga({ payload }: Record<string, string>): Generator<unknown> {
  try {
    const data = yield call(() => getData({ url: URLs.FETCH_TESTIMONIAL.replace('{userSectionId}', payload) }));
    const layoutName = data?.data?.layout?.name || DEFAULT_TESTIMONIALS_LAYOUT_SELECTION || '';
    const result = StoreTestimonials.processResponse(data.data, layoutName);
    const { id } = data?.data;
    yield put(fetchStoreTestimonialsSuccess({ id: id, result }));
  } catch (e) {
    yield put(fetchStoreTestimonialsFailure('Request failed with: ' + e));
  }
}

export default function* storeTestimonialsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchStoreTestimonialsRequest.type, fetchStoreTestimonialsSaga);
}
