interface Promotion {
  id: number;
  name: string;
  tagline: string;
  promoCode: string;
  isActive: boolean;
  isValid: boolean;
  validFrom: string;
  validUntil: string;
  minEligibleAmount: number;
  useFrequency: number;
  frequencyAllowed: number;
  eligibleProducts: number[];
  eligibleCategories: number[];
  discountPercentage: number;
  maxDiscountAmount: number;
  flatDiscount: number;
  includesShippingCharges: boolean;
  showOnCheckout: boolean;
  oncePerUser: boolean;
  isAutoDiscount: boolean;
}

export const processResponse = (promotion: Record<string, never>): Promotion => {
  return {
    id: promotion.id,
    name: promotion.name,
    tagline: promotion.tagline,
    promoCode: promotion.promo_code,
    isActive: promotion.is_active,
    isValid: promotion.is_valid,
    validFrom: promotion.valid_from,
    validUntil: promotion.valid_until,
    minEligibleAmount: promotion.min_eligible_amount,
    useFrequency: promotion.use_frequency,
    frequencyAllowed: promotion.frequency_allowed,
    eligibleProducts: promotion.eligible_products,
    eligibleCategories: promotion.eligible_categories,
    discountPercentage: +promotion.discount_percentage,
    maxDiscountAmount: +promotion.max_discount_amount,
    flatDiscount: +promotion.flat_discount,
    includesShippingCharges: promotion.includes_shipping_charges,
    showOnCheckout: promotion.show_on_checkout,
    oncePerUser: promotion.once_per_user,
    isAutoDiscount: promotion.is_auto_discount,
  };
};

export default Promotion;
