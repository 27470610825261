import { PayloadAction } from '@reduxjs/toolkit';
import { delay, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { addToast, removeToast, ToastWithKeyType } from './slice';

function* autoDismissSaga({ payload: toast }: PayloadAction<ToastWithKeyType>) {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  if (toast.dismissAfterMillis) {
    yield delay(toast.dismissAfterMillis);
    yield put(removeToast(toast.key));
  }
}

export default function* toastsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(addToast.type, autoDismissSaga);
}
