interface ProductsListType {
  id: number;
  title: string;
  image: string;
  slug: string;
}
export interface CategoriesProductsListResults {
  categoryId: number;
  name: string;
  productsList: Array<ProductsListType>;
}

export interface CategoriesProductsList {
  results: CategoriesProductsListResults[];
}

export const processResponse = (categories: Record<string, never>): CategoriesProductsList => {
  const results = (categories.results as Record<string, never>[])?.map((category) => ({
    categoryId: category.id,
    name: category.name,
    productsList: category.products,
  }));
  return { results };
};
