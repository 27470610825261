import { getData, URLs } from '@common/api';
import * as OnboardStatus from '@common/models/OnboardStatus';
import { getMerchantJWTToken } from '@common/utils/token';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchOnboardStatusFailure, fetchOnboardStatusRequest, fetchOnboardStatusSuccess } from './slice';

function* fetchOnboardStatusSaga() {
  try {
    const merchantToken = getMerchantJWTToken();
    const { data } = yield call(() =>
      getData({
        url: URLs.GET_ONBOARD_STATUS,
        ...(merchantToken && { headers: { Authorization: `Bearer ${merchantToken}` } }),
      }),
    );
    data.data = OnboardStatus.processResponse(data.data);
    yield put(fetchOnboardStatusSuccess(data.data));
  } catch (e) {
    yield put(fetchOnboardStatusFailure('Request failed with: ' + e));
  }
}

export default function* onboardStatusSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchOnboardStatusRequest.type, fetchOnboardStatusSaga);
}
