import { ProductQueryParamsType } from '@common/store/products/slice';
import queryString from 'query-string';

import history from './history';

export const getQueryParamsFromURL = (search?: string): unknown => {
  return queryString.parse(search ?? history.location.search) as unknown;
};

export const getProductsQueryParamsFromURL = (search?: string): ProductQueryParamsType => {
  const { final_price__gte, final_price__lte, facets, ordering, product_filter, category } = getQueryParamsFromURL(
    search,
  ) as ProductQueryParamsType;
  return {
    final_price__gte,
    final_price__lte,
    facets,
    product_filter,
    category,
    ordering: ordering ?? 'sort_order',
  } as ProductQueryParamsType;
};

export const appendProductsQueryParamsToURL = (queryParams: ProductQueryParamsType): void => {
  const { final_price__gte, final_price__lte, facets, ordering, product_filter, category } = queryParams;
  const queryParamsString = queryString.stringify({
    ...(getQueryParamsFromURL() as Record<string, string>),
    final_price__gte,
    final_price__lte,
    facets,
    ordering,
    product_filter,
    category,
  });

  history.push(history.location.pathname + '?' + queryParamsString);
};
