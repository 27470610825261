import '@common/styles/layouts.css';

import useDimensions from '@common/hooks/useDimensions';
import useSubscribe from '@common/hooks/useSubscribe';
import { ReactComponent as ArchiveFile } from '@common/icons/ArchiveFile.svg';
import { ReactComponent as TWarning } from '@common/icons/TWarning.svg';
import Cta from '@components/Cta';
import classnames from 'classnames';
import React, { ChangeEvent, FocusEvent, useState } from 'react';

export interface FormInputProps {
  name: string;
  label: string;
  type: string;
  multiple?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  value: string;
  className?: string;
  hint?: React.ReactNode;
  error?: React.ReactNode;
  enableCopyToClipboard?: boolean;
  enableVisbilityToggle?: boolean;
  inputClassName?: string;
}

const FormInput: React.FC<FormInputProps> = ({
  name,
  error,
  label,
  type,
  multiple,
  onChange,
  onBlur,
  value,
  className = '',
  inputClassName = '',
  hint,
}) => {
  const [ref, dimensions] = useDimensions();
  const [isFocused, setIsFocused] = useState(false);
  return (
    <div className={className}>
      <div
        ref={ref}
        className={classnames(
          'form-input-wrapper text-15 relative border rounded-full border-input-border focus-within:border-theme focus-within:border-2',
          {
            'form-input-wrapper--error border-input-error': error,
            'h-44': type !== 'textarea',
            'h-200': type === 'textarea',
          },
        )}
      >
        <input
          className={classnames(
            'block w-full appearance-none focus:outline-none bg-transparent h-full rounded-full px-16',
            inputClassName,
          )}
          type={type}
          id={name}
          autoComplete="new-password"
          name={name}
          multiple={multiple}
          placeholder=" "
          value={value}
          onFocus={() => setIsFocused(true)}
          onChange={(e) => onChange(e)}
          onBlur={(e) => {
            setIsFocused(false);
            onBlur(e);
          }}
        />
        <label
          className="block top-0 px-16 mt-12 absolute pointer-events-none text-input-label opacity-75 overflow-auto"
          htmlFor={name}
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            // eslint-disable-next-line
            // @ts-ignore
            maxWidth: `${dimensions?.width - (isFocused || value ? 28 : 0)}px`,
          }}
        >
          {label}
        </label>

        {error ? (
          <div
            className={classnames(
              'text-visibility-off absolute select-none top-0 right-0 rounded-full h-full bg-white flex items-center px-12 cursor-pointer',
            )}
          >
            <TWarning className="fill-current" />
          </div>
        ) : null}
      </div>
      <div className="px-2 text-input-hint opacity-70 text-12 mt-4">
        {error ? <span className="text-input-error opacity-100">{error}</span> : null}
        {hint && !error ? <span>{hint}</span> : null}
      </div>
    </div>
  );
};

export interface StoreUpdateProps {
  id: string;
}

const StoreUpdate: React.FC<StoreUpdateProps> = (id) => {
  const {
    currentState,
    firstStepHandler,
    secondStepHandler,
    SUBSCRIBE_BUTTON_STATES,
    storeInfo,
    PRIVACY_URL,
    doItLater,
    PRIVACY_POLICY_WARNING,
    form: { values, handleChange, handleBlur, errors, submissions, touched, asyncErrors, handleSubmit },
    storeUpdate,
  } = useSubscribe(id?.id);

  let inner = null;
  if (currentState === SUBSCRIBE_BUTTON_STATES.INITIAL) {
    inner = (
      <div className="storeUpdates-container border-divider py-32 container mx-auto flex flex-wrap justify-between items-center text-16">
        <div className="mb-12 md:mb-0">
          <div className="text-sectionHeading font-semibold text-24">{storeUpdate?.title}</div>
          <div className="text-contentColor">{storeUpdate?.subTitle}</div>
        </div>
        <Cta
          link={'#'}
          font-customisation="para-text"
          button-customisation="buyNow-button"
          customClass={'px-32 py-10 bg-theme text-white font-medium rounded-full w-full md:w-auto subscribe-button'}
          onClick={(e) => {
            e.preventDefault();
            firstStepHandler();
          }}
          text={storeUpdate?.ctaText}
        />
      </div>
    );
  }

  if (currentState === SUBSCRIBE_BUTTON_STATES.FORM) {
    inner = (
      <form onSubmit={handleSubmit(secondStepHandler)} className="py-32 container mx-auto">
        <div className="flex flex-wrap items-start text-16 mb-10">
          <FormInput
            name="email"
            label="Enter your email address"
            type="text"
            inputClassName="bg-white"
            className="md:mr-12 w-full md:max-w-320 mb-8 md:mb-0"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(submissions || touched.email) && (errors.email || asyncErrors.email)}
          />
          <button
            className="md:mr-12 md:px-32 disabled:bg-disabled disabled:cursor-not-allowed py-10 bg-theme text-white font-medium rounded-full flex-1 md:flex-none subscribe-button"
            type="submit"
            disabled={!values.accept || errors.email}
          >
            {storeUpdate?.ctaText}
          </button>
          <div
            onClick={doItLater}
            className="text-theme py-10 font-medium text-14 cursor-pointer flex-1 md:flex-none text-center"
          >
            I’ll do it later
          </div>
        </div>
        <div className="flex items-center">
          <label htmlFor="accept" className="cursor-pointer mr-8 block">
            <input
              type="checkbox"
              id="accept"
              name="accept"
              value="true"
              checked={values.accept}
              onChange={handleChange}
              className="mr-8 w-20 block h-20 checked:bg-theme block subscribe-checkbox"
            />
          </label>
          <div className="text-12 text-subscribe-privacy">
            By signing up for alerts, you agree to receive emails from {storeInfo?.storeInfo?.storename}. To know more
            about how we keep your data safe, refer to our{' '}
            <a href={PRIVACY_URL} target="_blank" className="text-theme cursor-pointer" rel="noreferrer">
              Privacy Policy.
            </a>
          </div>
        </div>
        {!errors.email && !values.accept && (
          <div className="text-12 text-input-error opacity-70 mt-4 ml-36">{PRIVACY_POLICY_WARNING}</div>
        )}
      </form>
    );
  }

  if (currentState === SUBSCRIBE_BUTTON_STATES.FINAL) {
    inner = (
      <div className="border-divider py-32 container mx-auto flex flex-wrap items-center text-16">
        <ArchiveFile width="65px" height="65px" className="mr-28 subscribe-file-icon text-theme fill-current" />
        <div className="md:mb-0">
          <div className="text-sectionHeading font-semibold text-24">Thanks for subscribing!</div>
          <div className="text-contentColor">
            We will keep you posted via emails on <span className="text-subscribe-text">{values.email}</span>
          </div>
        </div>
      </div>
    );
  }

  if (currentState === SUBSCRIBE_BUTTON_STATES.HIDE) {
    return null;
  }

  return (
    <div className="border-subscribe-divider border-t storeUpdateBg" id={id?.id}>
      {inner}
    </div>
  );
};

export default StoreUpdate;
