import { getData, URLs } from '@common/api';
import * as Section from '@common/models/sections';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchContactFailure, fetchContactRequest, fetchContactSuccess } from './slice';

function* fetchContactSaga() {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_CONTACT }));
    data.sections = Section.processResponse(data.sections);
    yield put(fetchContactSuccess(data.sections));
  } catch (e) {
    yield put(fetchContactFailure('Request failed with: ' + e));
  }
}

export default function* contactSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchContactRequest.type, fetchContactSaga);
}
