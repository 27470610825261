import Promotion from '@common/models/Promotion';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PromotionsResponseType {
  count: number;
  next: string;
  previous: string;
  results: Promotion[];
}

export interface PromotionsSliceType {
  promotions?: PromotionsResponseType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState: <PromotionsSliceType>{
    promotions: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchPromotionsRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.promotions = null;
      state.error = '';
    },
    fetchPromotionsSuccess(state, { payload }: PayloadAction<PromotionsResponseType>) {
      state.promotions = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchPromotionsFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const { fetchPromotionsSuccess, fetchPromotionsRequest, fetchPromotionsFailure } = promotionsSlice.actions;

export default promotionsSlice.reducer;
