import { getLambdaLink } from '@common/utils';

import { getBuyerJWTToken } from '../utils/token';

interface PageHeader {
  id: number;
  logo: string;
  searchText: string;
  ctaText: string;
  name: string;
  layout: Layout;
  navbarLinks: FilteredLinkResponseType[];
}

interface Layout {
  id: number;
  name: string;
  image: string;
}

interface Navbar {
  id: number;
  type: number;
  title: string;
  link: string;
  target: string;
  isActive: boolean;
  customPage: CustomPage;
}

interface CustomPage {
  id: number;
  urlPath: string;
  name: string;
  loginRequired: boolean;
  useThemeBase: boolean;
  isExternalUrl: boolean;
  pageTitle: string;
  content: string;
}

interface FilteredLinkResponseType {
  id: number;
  name: string;
  label: string;
  storeLink: string;
  loginRequired: boolean;
  isExternalLink: boolean;
  customPage: CustomPage;
}

const processIndividualResponse = (link: Navbar): FilteredLinkResponseType => {
  return {
    id: link.id,
    name: link.title,
    label: link.title,
    storeLink:
      link?.customPage?.loginRequired && !getBuyerJWTToken() ? `/account/login?redirect_url=${link.link}` : link.link,
    isExternalLink: link.link?.match(/(http(s?)):\/\//i) ? true : false,
    customPage: link.customPage,
    loginRequired: link?.customPage?.loginRequired || false,
  };
};

export const processResponse = (pageHeader: Record<string, never>): PageHeader => {
  const navbar = (pageHeader.navigation as Record<string, never>[])?.map((navbar) => ({
    id: navbar.id,
    type: navbar.type,
    title: navbar.title,
    link: navbar.link,
    target: navbar.target,
    isActive: navbar.is_active,
    customPage: {
      id: (navbar.custompage as Record<string, never>)?.id,
      urlPath: (navbar.custompage as Record<string, never>)?.url_path,
      name: (navbar.custompage as Record<string, never>)?.name,
      loginRequired: (navbar.custompage as Record<string, never>)?.login_required,
      useThemeBase: (navbar.custompage as Record<string, never>)?.use_theme_base,
      isExternalUrl: (navbar.custompage as Record<string, never>)?.is_external_url,
      pageTitle: (navbar.custompage as Record<string, never>)?.page_title,
      content: (navbar.custompage as Record<string, never>)?.content,
    },
  }));
  const navbarLinks = navbar.filter((link) => link.isActive).map((link) => processIndividualResponse(link));
  return {
    id: pageHeader.id,
    logo: getLambdaLink({ link: pageHeader.logo as string, width: 0, height: 0 }),
    searchText: pageHeader.search_text,
    ctaText: pageHeader.cta_text,
    name: pageHeader.name,
    layout: {
      id: (pageHeader.layout as Record<string, never>)?.id,
      name: (pageHeader.layout as Record<string, never>)?.name,
      image: (pageHeader.layout as Record<string, never>)?.image,
    },
    navbarLinks,
  };
};

export default PageHeader;
