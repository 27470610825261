import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CustomPageType {
  id: number;
  name: string;
  url: string;
  title: string;
  protected: boolean;
  themed: boolean;
}

export interface CustomPagesResponseType {
  count: number;
  next?: string;
  previous?: string;
  results: CustomPageType[];
}

export interface CustomPagesSliceType {
  pages?: CustomPagesResponseType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const customPagesSlice = createSlice({
  name: 'customPages',
  initialState: <CustomPagesSliceType>{
    pages: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchCustomPagesRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.pages = null;
      state.error = '';
    },
    fetchCustomPagesSuccess(state, { payload }: PayloadAction<CustomPagesResponseType>) {
      state.pages = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchCustomPagesFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const { fetchCustomPagesSuccess, fetchCustomPagesRequest, fetchCustomPagesFailure } = customPagesSlice.actions;

export default customPagesSlice.reducer;
