import ProductBundle from '@common/models/ProductBundle';
import { CART_HASH_LOCAL_STORAGE_KEY } from '@common/utils/constants';
import { getBuyerJWTToken, getLocalStorageItem } from '@common/utils/token';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FormCustomField } from '../../models/CustomField';
import { FormProductOption } from '../../models/Option';
import Product from '../../models/Product';
import Variant from '../../models/Variant';
import { computeProductHash } from '../../utils';

export interface CartQuantity {
  cartQuantity: number;
}

export type CartProductType = (Product | Variant) & {
  cartQuantity: number;
  formProductOptions?: FormProductOption[];
  formCustomFields?: FormCustomField[];
  reseller?: string;
};
export interface PromoCodeType {
  code: string;
  discount: number;
  totalOrder: number;
  discountedTotalOrder: number;
}
export interface WholesaleDiscountType {
  discountedPrice: number;
  finalDiscountedPrice: number;
  totalDiscount: number;
  wsBreakup: Record<string, unknown>;
}

export interface CartSliceType {
  promoCode?: PromoCodeType;
  wholesaleDiscount?: WholesaleDiscountType;
  isPromoCodeFetching: boolean;
  products: Record<number, CartProductType>;
  quantityByProductId: Record<number, number>;
  groupToResellerMapping: Record<number, string>;
  oneTimePromoError?: boolean;
  isInvalidCode?: boolean;
  showApplied: boolean;
  deleteUserCart: boolean;
  cartId: number;
  bundles: {
    [key: string]: {
      productHashes: string[];
    };
  };
  isDeleteBundleProductModal: boolean;
}

export interface AddToCartParams {
  isBuyNowFlow?: boolean;
  discountLinkPromoCode?: string;
}

export interface AddProductToCartPayload extends AddToCartParams {
  product: Product | Variant;
  quantity?: number;
  disableSuccessToasts?: boolean;
  mixpanelProperties?: Record<string, unknown>;
  cartId?: number;
}

export interface SubtractProductToCartPayload {
  product: Product | Variant;
  mixpanelProperties?: Record<string, unknown>;
}

export interface SetProductQuantityInCartPayload {
  product: Product | Variant;
  quantity: number;
  mixpanelProperties?: Record<string, unknown>;
}
export interface GroupResellerType {
  groupId: number;
  reseller: string;
}

export const getCartInitialState = (): CartSliceType =>
  <CartSliceType>{
    products: {},
    quantityByProductId: {},
    promoCode: null,
    wholesaleDiscount: null,
    isPromoCodeFetching: false,
    groupToResellerMapping: {},
    bundles: {},
    isDeleteBundleProductModal: false,
  };

const cartSlice = createSlice({
  name: 'cart',
  initialState: getCartInitialState(),
  reducers: {
    resetCart(state, { payload: preserveReseller }: PayloadAction<boolean>) {
      state.products = {};
      state.quantityByProductId = {};
      state.promoCode = null;
      state.cartId = null;
      state.wholesaleDiscount = null;
      state.isPromoCodeFetching = false;
      state.oneTimePromoError = false;
      state.isInvalidCode = false;
      state.showApplied = false;
      if (!preserveReseller) {
        state.groupToResellerMapping = {};
      }
    },
    replaceCart(state, { payload: cart }: PayloadAction<CartSliceType>) {
      const cartHash = getLocalStorageItem(CART_HASH_LOCAL_STORAGE_KEY);

      state.products = cart.products;
      state.quantityByProductId = cart.quantityByProductId;
      state.promoCode = cart.promoCode;
      state.wholesaleDiscount = cart.wholesaleDiscount;
      state.isPromoCodeFetching = cart.isPromoCodeFetching;
      state.groupToResellerMapping = cart.groupToResellerMapping ?? {};
      state.bundles = cart.bundles;

      if ((cart.cartId && getBuyerJWTToken()) || cartHash) {
        state.cartId = cart.cartId;
      }
    },
    /* eslint-disable-next-line */
    addProductToCart(state, { payload }: PayloadAction<AddProductToCartPayload>) {},
    addCartId(state, { payload }) {
      state.cartId = payload;
    },
    addProductToCartSuccess(state, { payload: { product, quantity = 1 } }: PayloadAction<AddProductToCartPayload>) {
      const productHash = product.hash ?? computeProductHash(product);
      if (state.products[productHash]) {
        state.products[productHash].cartQuantity += quantity;
      } else {
        state.products[productHash] = {
          ...product,
          hash: productHash,
          cartQuantity: Math.max(product.minQuantity, quantity),
        };
      }
      state.promoCode = null;
      state.wholesaleDiscount = null;
      state.deleteUserCart = true;
    },
    subtractProductFromCart(state, { payload: { product } }: PayloadAction<SubtractProductToCartPayload>) {
      const productHash = computeProductHash(product);

      if (state.products[productHash]) {
        const minQuantity = state.products[productHash].minQuantity;
        if (state.products[productHash].cartQuantity === minQuantity) {
          delete state.products[productHash];
        } else {
          state.products[productHash].cartQuantity -= 1;
        }
      }
      state.promoCode = null;
      state.wholesaleDiscount = null;
    },
    setProductQuantityInCart(
      state,
      { payload: { product, quantity } }: PayloadAction<SetProductQuantityInCartPayload>,
    ) {
      const productHash = computeProductHash(product);
      state.products[productHash].cartQuantity = quantity;
      state.promoCode = null;
      state.wholesaleDiscount = null;
    },
    removeProductFromCart(state, { payload: { product } }: PayloadAction<SubtractProductToCartPayload>) {
      if (state?.bundles && state?.bundles[product.bundleId]) {
        const productHashes = state?.bundles[product.bundleId].productHashes;
        //Checking if the product is a bundle product
        if (productHashes.includes(product.hash)) {
          const isBundleIdMatched = Object.values(state.products).some((prod) => product.bundleId == prod.bundleId);
          for (const key in state.products) {
            //Converting the bundle products to normal products
            if (key != product.hash && state.products[key]?.bundleId && isBundleIdMatched) {
              const hash = computeProductHash({
                ...state.products[key],
                finalPrice: state.products[key]?.discountedPrice ?? state.products[key]?.price,
                bundleId: null,
              });
              //Increasing the product quantity when converted product(hash) is already exist in the cart
              if (state.products[hash]) {
                state.products[hash].cartQuantity = state.products[hash].cartQuantity + 1;
              } else {
                state.products[hash] = {
                  ...state.products[key],
                  bundleId: null,
                  bundleDiscountAmount: null,
                  finalPrice: state.products[key]?.discountedPrice ?? state.products[key]?.price,
                  hash,
                };
              }
            }
          }
          productHashes.map((productHash) => {
            delete state.products[productHash];
          });
        }
        delete state.bundles[product.bundleId];
      }
      if (state.products[product.hash]) {
        delete state.products[product.hash];
      }
      state.promoCode = null;
      state.wholesaleDiscount = null;
    },
    setQuantityByProductId(state, { payload }: PayloadAction<Record<number, number>>) {
      state.quantityByProductId = payload;
    },
    addPromoCodeToCart(state) {
      state.isPromoCodeFetching = true;
      state.showApplied = false;
      state.oneTimePromoError = false;
      state.isInvalidCode = false;
    },
    addPromoCodeToCartSuccess(state, { payload: promoCode }: PayloadAction<PromoCodeType>) {
      state.promoCode = promoCode;
      state.showApplied = true;
      state.isPromoCodeFetching = false;
      state.wholesaleDiscount = null;
    },
    addPromoCodeToCartFailure(state) {
      state.oneTimePromoError = true;
    },
    addPromoInvalid(state) {
      state.isInvalidCode = true;
    },
    updateWholeSaleDiscount(state, { payload: wholesaleDiscount }: PayloadAction<WholesaleDiscountType>) {
      state.wholesaleDiscount = wholesaleDiscount;
    },
    addResellerToCart(state, { payload: { groupId, reseller } }: PayloadAction<GroupResellerType>) {
      state.groupToResellerMapping = state.groupToResellerMapping ?? {};
      state.groupToResellerMapping[groupId] = reseller;
    },
    computeResellerForProducts(state) {
      let doesAnyProductHaveReseller = false;
      for (const key in state.products) {
        const reseller = state.groupToResellerMapping[state.products[key].groupId];
        if (reseller) {
          state.products[key].reseller = reseller;
          doesAnyProductHaveReseller = true;
        }
      }
      if (doesAnyProductHaveReseller) {
        state.promoCode = null;
      }
    },
    setCartId(state, { payload: cartId }: PayloadAction<number>) {
      state.cartId = cartId;
    },
    addBundleProductToCartSuccess(state, { payload: bundleProduct }: PayloadAction<ProductBundle>) {
      const bundlesKeys = Object.keys(state.bundles);
      //Check if bundle is already persist in localstorage
      if (!bundlesKeys.length) {
        state.bundles[bundleProduct.bundleId] = {
          productHashes: bundleProduct.products.map((bunProduct) => {
            for (const key in state.products) {
              const product = state.products[key];
              //Checking Bundle Product ID is equals to the existing product ID in the cart
              //Reducing the product from the cart.
              if (product.id == bunProduct.id && !product.bundleId) {
                if (product.cartQuantity == 1) {
                  delete state.products[key];
                  delete state.quantityByProductId[product.id];
                } else {
                  product.cartQuantity -= 1;
                  state.quantityByProductId[product.id] -= 1;
                }
              }
            }
            return computeProductHash({ ...bunProduct, bundleId: bundleProduct.bundleId });
          }),
        };
      }
    },
  },
});

export const {
  resetCart,
  replaceCart,
  addProductToCart,
  addProductToCartSuccess,
  subtractProductFromCart,
  removeProductFromCart,
  addPromoCodeToCart,
  addPromoCodeToCartSuccess,
  addPromoCodeToCartFailure,
  addPromoInvalid,
  setQuantityByProductId,
  updateWholeSaleDiscount,
  addResellerToCart,
  computeResellerForProducts,
  setCartId,
  addCartId,
  addBundleProductToCartSuccess,
  setProductQuantityInCart,
} = cartSlice.actions;

export default cartSlice.reducer;
