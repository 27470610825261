// import Mixpanel from 'mixpanel-browser';

import { RootState, store } from '../store';
import { isBrowser } from '.';
import { context, COOKIE_KEYS } from './constants';
import { getCookie } from './token';

let mixpanel = null;

interface MixpanelOptions {
  send_immediately?: boolean;
  transport?: 'xhr' | 'sendBeacon';
}

const getCommonEventProperties = () => {
  const state: RootState = store.getState();
  return {
    template: getCookie(COOKIE_KEYS.THEME_COOKIE) || state.storeInfo.storeInfo?.theme?.name || '',
  };
};

export const trackEvent = (
  eventName: string,
  eventProperties: Record<string, unknown> | undefined,
  options?: MixpanelOptions,
  callback?: () => void,
): void => {
  if (!isBrowser()) {
    return;
  }
  try {
    const finalEventProperties = { ...eventProperties, ...getCommonEventProperties() };
    if (!mixpanel) {
      import('mixpanel-browser').then((Mixpanel) => {
        mixpanel = Mixpanel;
        mixpanel?.default?.init(context.MIXPANEL_TOKEN);
        mixpanel?.default?.track(eventName, finalEventProperties, options, callback);
      });
    } else {
      mixpanel?.default?.track(eventName, finalEventProperties, options, callback);
    }
  } catch (e) {
    console.error('[Failed] Analytics:', e);
  }
};
