import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Product from '../../models/Product';

export interface SearchResponseType {
  total: number;
  meta: {
    totalHits: number;
    timeTakenInMs: number;
  };
  results: Product[];
}

export interface SearchResponsePayloadType {
  response: SearchResponseType;
  queryParams: SearchQueryParams;
}

export interface SearchQueryParams {
  format: 'json';
  time: number;
  active: true;
  offset: number;
  loc_currency: 'INR';
  limit: number;
  suggest: string;
  oos: boolean;
  bponly: boolean;
}

export const getSearchQueryParamsInitialState = (search: string): SearchQueryParams => ({
  format: 'json',
  time: Math.floor(Date.now() / 1000),
  active: true,
  offset: 0,
  loc_currency: 'INR',
  limit: 24,
  suggest: search,
  bponly: true,
  oos: undefined,
});

export interface SearchSliceType {
  pagination: {
    currentPage: number;
    lastPage: number;
    multiplier: number;
  };
  currentQueryParams?: SearchQueryParams;
  search?: SearchResponseType;
  fetchedProducts?: Product[];
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const searchInitialState = {
  pagination: {
    currentPage: 1,
    lastPage: 1,
    multiplier: 1,
  },
  search: null,
  fetchedProducts: [],
  isFetching: false,
  isFetched: false,
  error: '',
};

export interface FetchSearchRequestPayloadType {
  storeKey: string;
  queryParams: SearchQueryParams;
}

interface FetchSearchSuccessPayloadType extends FetchSearchRequestPayloadType {
  search: SearchResponseType;
}

interface FetchSearchFailurePayloadType {
  storeKey: string;
  error: string;
}

const searchSlice = createSlice({
  name: 'search',
  initialState: {} as Record<string, SearchSliceType>,
  reducers: {
    resetSearchStoreNew(state, { payload: storeKey }: PayloadAction<string>) {
      state[storeKey] = searchInitialState;
    },
    fetchSearchRequestNew(state, { payload: { storeKey, queryParams } }: PayloadAction<FetchSearchRequestPayloadType>) {
      state[storeKey] = {
        ...state[storeKey],
        isFetching: true,
        isFetched: false,
        search: null,
        currentQueryParams: queryParams,
        error: '',
      };
    },
    fetchSearchSuccessNew(
      state,
      { payload: { storeKey, search, queryParams } }: PayloadAction<FetchSearchSuccessPayloadType>,
    ) {
      const currentPage = queryParams.offset / queryParams.limit + 1;
      state[storeKey].pagination = {
        currentPage,
        lastPage: parseInt(`${(search.meta.totalHits - 1) / queryParams.limit + 1}`),
        multiplier: queryParams.limit,
      };
      if (currentPage === 1) {
        state[storeKey].fetchedProducts?.splice(0, state[storeKey].fetchedProducts?.length);
        state[storeKey].fetchedProducts?.push(...search?.results);
      } else {
        state[storeKey].fetchedProducts?.push(...search?.results);
      }
      state[storeKey].search = search;
      state[storeKey].isFetching = false;
      state[storeKey].isFetched = true;
    },
    fetchSearchFailureNew(state, { payload: { storeKey, error } }: PayloadAction<FetchSearchFailurePayloadType>) {
      state[storeKey].error = error;
      state[storeKey].isFetching = false;
      state[storeKey].isFetched = true;
      state[storeKey].search = null;
    },
  },
});

export const {
  resetSearchStoreNew,
  fetchSearchSuccessNew,
  fetchSearchRequestNew,
  fetchSearchFailureNew,
} = searchSlice.actions;

export default searchSlice.reducer;
