import React from 'react';

interface ErrorProps {
  message: string;
}

const Error: React.FC<ErrorProps> = ({ message }) => (
  <div className="container mx-auto my-42 p-32 text-center font-semibold text-16 bg-white rounded shadow text-notFound-label">
    {message}
  </div>
);

export default Error;
