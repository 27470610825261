interface Group {
  id: number;
  name: string;
  sortOrder: number;
  keys: {
    id: number;
    name: string;
    sortOrder: number;
    filterable: boolean;
    filterType: string;
    values: Array<string>;
  }[];
}

export const processResponse = (group: Record<string, never>): Group => {
  const keys = (group.keys as Record<string, never>[]).map((key) => ({
    id: key.id,
    name: key.name,
    sortOrder: key.sort_order,
    filterable: key.filterable,
    filterType: key.filter_type,
    values: key.values,
  }));
  return {
    id: group.id,
    name: group.name,
    sortOrder: group.sort_order,
    keys: keys,
  };
};

export default Group;
