const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

interface FormatTimestampOptions {
  hideDay?: boolean;
  useShortMonths?: boolean;
}

export const formatTimestamp = (
  timestamp: string,
  { hideDay, useShortMonths }: FormatTimestampOptions = {},
): [string, string] => {
  const date = new Date(timestamp);

  let formattedDate = `${date.getDate()} ${
    useShortMonths ? shortMonthNames[date.getMonth()] : monthNames[date.getMonth()]
  } ${date.getFullYear()}`;
  if (!hideDay) {
    formattedDate += `, ${dayNames[date.getDay()]}`;
  }
  let hour = date.getHours();
  hour = hour !== 0 ? hour % 12 || 12 : hour;
  const formattedTime = `${hour}:${date.getMinutes()} ${parseInt(`${date.getHours() / 12}`) ? 'PM' : 'AM'}`;
  return [formattedDate, formattedTime];
};

export const formatTimestamp2 = (timestamp: string): [string, string] => {
  const date = new Date(timestamp);
  let hour = date.getHours();
  hour = hour !== 0 ? hour % 12 || 12 : hour;
  const formattedDate = `${shortMonthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  const formattedTime = `${hour}:${date.getMinutes().toString().padStart(2, '0')} ${
    parseInt(`${date.getHours() / 12}`) ? 'PM' : 'AM'
  }`;
  return [formattedDate, formattedTime];
};

export const formatDate = (timestamp: string): string => {
  const date = new Date(timestamp);

  return `${date.getDate()} ${monthNames[date.getMonth()]}, ${date.getFullYear()}`;
};

export const isExpired = (lastDateString: string | boolean): boolean => {
  if (lastDateString) {
    const currentDate = new Date();
    const lastDate = new Date(lastDateString as string);
    return currentDate > lastDate;
  }
  return false;
};
