export interface Policy {
  privacy: boolean;
  termsAndConditions: boolean;
  cancelAndRefund: boolean;
  shippingAndDelivery: boolean;
}

export const policyProcessResponse = (policy: Record<string, { hidden: boolean }>): Policy => {
  return {
    privacy: policy.privacy.hidden || false,
    termsAndConditions: policy['terms-and-conditions'].hidden || false,
    cancelAndRefund: policy['cancel-and-refund'].hidden || false,
    shippingAndDelivery: policy['shipping-and-delivery'].hidden || false,
  };
};

export default Policy;
