import PageHeader from '@common/models/PageHeader';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PageHeaderSliceType {
  sections?: Record<number, PageHeaderTextResponseType>;
}

export interface PageHeaderTextResponseType {
  id: number;
  layout: string;
  result: PageHeader;
}

const pageHeaderSlice = createSlice({
  name: 'pageHeader',
  initialState: <PageHeaderSliceType>{
    sections: {},
  },
  reducers: {
    fetchPageHeaderRequest(state, { payload }) {
      const pageHeaderObject = {
        isFetching: true,
        isFetched: false,
        pageHeader: null,
        layout: '',
        error: '',
      };
      state[payload.id] = pageHeaderObject;
    },
    fetchPageHeaderSuccess(state, { payload }: PayloadAction<PageHeaderTextResponseType>) {
      const pageHeaderObject = {
        isFetching: false,
        isFetched: true,
        pageHeader: payload.result,
        layout: payload.layout,
        error: '',
      };
      state[payload.id] = pageHeaderObject;
    },
    fetchPageHeaderFailure() {
      // const pageHeaderObject = {
      //   isFetching: false,
      //   isFetched: true,
      //   pageHeader: null,
      //   error: payload.result,
      // };
      // state[payload.id] = pageHeaderObject;
    },
    editPageHeader(state, { payload }: unknown) {
      const pageHeaderObject = {
        isFetching: false,
        isFetched: true,
        pageHeader: payload.result,
        layout: payload.layout,
        error: '',
      };
      state[payload.id] = pageHeaderObject;
    },
  },
});

export const {
  fetchPageHeaderSuccess,
  fetchPageHeaderRequest,
  fetchPageHeaderFailure,
  editPageHeader,
} = pageHeaderSlice.actions;

export default pageHeaderSlice.reducer;
