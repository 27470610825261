import { getData, URLs } from '@common/api';
import * as Slider from '@common/models/LegacySlider';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchLegacySliderFailure, fetchLegacySliderRequest, fetchLegacySliderSuccess } from './slice';

function* fetchLegacySliderSaga() {
  try {
    const data = yield call(() =>
      getData({
        url: URLs.GET_SLIDER,
      }),
    );
    const results = data.data.results?.map((product) => Slider.processResponse(product));
    yield put(fetchLegacySliderSuccess(results));
  } catch (e) {
    yield put(fetchLegacySliderFailure('Request failed with: ' + e));
  }
}

export default function* sliderSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchLegacySliderRequest.type, fetchLegacySliderSaga);
}
