import history from './history';

export const computeRedirectUrl = (): string => {
  const searchParams = new URLSearchParams(history.location.search);
  let redirectUrl = encodeURIComponent(searchParams.get('redirect_url') || '');
  const pathname = history.location.pathname;
  const excludedUrls = ['/account/login', '/account/register', '/account/forgot-password'];
  if (!redirectUrl && !excludedUrls.reduce((acc, curr) => acc || curr.indexOf(pathname) !== -1, false)) {
    redirectUrl = encodeURIComponent(pathname + history.location.search);
  }
  if (!redirectUrl) {
    redirectUrl = '/';
  }
  return redirectUrl;
};
