import { SectionEnum } from './SectionEnum';

export interface Testimonial {
  type: SectionEnum.TESTIMONIAL;
  name: string;
  heading: string;
}

export const testimonialProcessResponse = (testimonial: Record<string, never>): Testimonial => {
  return {
    type: testimonial.type,
    name: testimonial.name,
    heading: testimonial.heading,
  };
};
