import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import SocialProfile from '../../models/SocialProfile';

interface SocialProfilesResponseType {
  count: number;
  previous: string | null;
  next: string | null;
  results: SocialProfile[];
}

export interface SocialProfilesSliceType {
  socialProfiles?: SocialProfilesResponseType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const socialProfilesSlice = createSlice({
  name: 'socialProfiles',
  initialState: <SocialProfilesSliceType>{
    socialProfiles: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchSocialProfilesRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.socialProfiles = null;
      state.error = '';
    },
    fetchSocialProfilesSuccess(state, { payload }: PayloadAction<SocialProfilesResponseType>) {
      state.socialProfiles = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchSocialProfilesFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const {
  fetchSocialProfilesSuccess,
  fetchSocialProfilesRequest,
  fetchSocialProfilesFailure,
} = socialProfilesSlice.actions;

export default socialProfilesSlice.reducer;
