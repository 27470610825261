import '@common/styles/layouts.css';

import { STORE_TYPE } from '@common/models/StoreInfo';
import { RootState } from '@common/store';
import { FeaturesSliceType } from '@common/store/features/slice';
import { fetchProductListRequest } from '@common/store/productList/slice';
import FetchProductsSuccessPayloadType from '@common/store/productList/slice.ts';
import loadable from '@loadable/component';
import { DEFAULT_PRODUCTLIST_LAYOUT_SELECTION } from '@theme/utils/constants';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { fetchProductsRatingRequest } from '../../common/store/productRating/slice';
import { ADD_NEW_SECTION_ID } from '../../common/utils/constants';
import Fallback from '../common/Fallback';

export interface ProductListProps {
  layoutName: string;
  id: number;
  fetchProductListRequest: fetchProductListRequest.type;
  productList: FetchProductsSuccessPayloadType;
  previewQueryLayout: string;
  features: FeaturesSliceType;
}

const AllProducts = loadable(() => import(/* webpackChunkName: "AllProducts" */ './AllProducts')); //All products
const ProductList1 = loadable(() => import(/* webpackChunkName: "PS1" */ './ProductList1')); //Comfort
const ProductList2 = loadable(() => import(/* webpackChunkName: "PS2" */ './ProductList2')); //Bright
const ProductList3 = loadable(() => import(/* webpackChunkName: "PS3" */ './ProductList3')); //Sakra
const ProductList4 = loadable(() => import(/* webpackChunkName: "PS4" */ './ProductList4')); //Grace
const ProductList5 = loadable(() => import(/* webpackChunkName: "PS5" */ './ProductList5')); //Flow
const ProductList6 = loadable(() => import(/* webpackChunkName: "PS6" */ './ProductList6')); //Essense
const ProductList7 = loadable(() => import(/* webpackChunkName: "PS7" */ './ProductList7')); //Runway
const ProductList8 = loadable(() => import(/* webpackChunkName: "PS8" */ './ProductList8')); //Spring
const ProductList9 = loadable(() => import(/* webpackChunkName: "PS9" */ './ProductList9')); //Cipher
const ProductList10 = loadable(() => import(/* webpackChunkName: "PS10" */ './ProductList10')); //Compass
const ProductList11 = loadable(() => import(/* webpackChunkName: "PS11" */ './ProductList11'));
const ProductList12 = loadable(() => import(/* webpackChunkName: "PS12" */ './ProductList12'));
const ProductList13 = loadable(() => import(/* webpackChunkName: "PS13" */ './ProductList13'));
const ProductList14 = loadable(() => import(/* webpackChunkName: "PS14" */ './ProductList14'));
const ProductList15 = loadable(() => import(/* webpackChunkName: "PS15" */ './ProductList15'));
const ProductList16 = loadable(() => import(/* webpackChunkName: "PS16" */ './ProductList16'));

class ProductList extends PureComponent<ProductListProps> {
  constructor(props) {
    super(props);
    const { fetchProductListRequest, id, previewQueryLayout, productList } = this.props;
    if (!productList[id]?.isFetched && !productList[id]?.isFetching && id.toString() !== ADD_NEW_SECTION_ID) {
      fetchProductListRequest({ sectionId: id, previewQueryLayout: previewQueryLayout });
    }
  }

  fetchProductRatings = () => {
    const { id, productList, previewQueryLayout, productsRating, fetchProductsRatingRequest } = this.props;
    const layoutName = previewQueryLayout || productList[id]?.layout?.name || DEFAULT_PRODUCTLIST_LAYOUT_SELECTION;
    const productIds = productList[id]?.list?.results?.map((product) => product.id);

    if (
      productIds?.length > 0 &&
      !productsRating[id]?.isFetched &&
      !productsRating[id]?.isFetching &&
      (layoutName === 'PS15' || layoutName === 'PS16')
    ) {
      fetchProductsRatingRequest({ id, productIds });
    }
  };

  componentDidMount(): void {
    this.fetchProductRatings();
  }

  componentDidUpdate(): void {
    this.fetchProductRatings();
  }

  render(): React.ReactElement {
    const { id, productList, previewQueryLayout, features, productsRating, storeInfo } = this.props;
    const storeType = storeInfo.storeInfo?.storeType;
    const showAddToCartButton = storeType != STORE_TYPE.DISABLED_CART;
    const productListRatings = productsRating[id]?.results || [];
    const layoutName = previewQueryLayout || productList[id]?.layout?.name || DEFAULT_PRODUCTLIST_LAYOUT_SELECTION;
    return (
      <>
        {(productList[id]?.list?.results?.length > 0 && layoutName) || productList[id]?.productDetails ? (
          <div id={id.toString()}>
            {productList[id]?.showcaseType !== 4 ? (
              <>
                {layoutName === 'PS1' ? (
                  <ProductList1
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    {...this.props}
                  />
                ) : null}
                {layoutName === 'PS2' ? (
                  <ProductList2
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    {...this.props}
                  />
                ) : null}
                {layoutName === 'PS3' ? (
                  <ProductList3
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    {...this.props}
                  />
                ) : null}
                {layoutName === 'PS4' ? (
                  <ProductList4
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    {...this.props}
                  />
                ) : null}
                {layoutName === 'PS5' ? (
                  <ProductList5
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    {...this.props}
                  />
                ) : null}
                {layoutName === 'PS6' ? (
                  <ProductList6
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    {...this.props}
                  />
                ) : null}
                {layoutName === 'PS7' ? (
                  <ProductList7
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    {...this.props}
                  />
                ) : null}
                {layoutName === 'PS8' ? (
                  <ProductList8
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    showAddToCartButton={showAddToCartButton}
                    {...this.props}
                  />
                ) : null}
                {layoutName === 'PS9' ? (
                  <ProductList9
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    {...this.props}
                  />
                ) : null}
                {layoutName === 'PS10' ? (
                  <ProductList10
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    showAddToCartButton={showAddToCartButton}
                    {...this.props}
                  />
                ) : null}
                {layoutName === 'PS11' ? (
                  <ProductList11
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    {...this.props}
                  />
                ) : null}
                {layoutName === 'PS12' ? (
                  <ProductList12
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    {...this.props}
                  />
                ) : null}
                {layoutName === 'PS13' ? (
                  <ProductList13
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    {...this.props}
                    features={features?.features}
                  />
                ) : null}
                {layoutName === 'PS14' ? (
                  <ProductList14
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    {...this.props}
                  />
                ) : null}
                {layoutName === 'PS15' ? (
                  <ProductList15
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    productListRatings={productListRatings}
                    {...this.props}
                  />
                ) : null}
                {layoutName === 'PS16' ? (
                  <ProductList16
                    fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                    products={productList[id]}
                    {...this.props}
                    productListRatings={productListRatings}
                  />
                ) : null}
              </>
            ) : (
              <AllProducts
                fallback={<Fallback id={id} sectionType="ProductShowcase" />}
                title={productList[id].title}
                {...this.props}
              />
            )}
          </div>
        ) : null}
      </>
    );
  }
}

// const ProductListFallback = () => {
//   return <div></div>;
// };

export default connect(
  ({ productsRating, productList, features }: RootState) => ({
    productList,
    features,
    productsRating,
  }),
  {
    fetchProductListRequest,
    fetchProductsRatingRequest,
  },
)(ProductList);
