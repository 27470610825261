import NudgeList, { Nudge } from '@common/models/NudgeList';
import { RootState } from '@common/store';
import { NudgesSliceType } from '@common/store/nudges/slice';
import { trackEvent } from '@common/utils/analytics';
import { getMerchantJWTToken } from '@common/utils/token';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export interface UseNudgeReturnType {
  nudges: NudgesSliceType;
  nudgeList: NudgeList;
  showNudges: boolean;
  nudge?: Nudge;
  handleModalOpen: () => void;
}

export const nudgeInfo = {
  'upgrade-store-url-paid-plan-desktop': {
    featureName: 'purchase_domain',
  },
  'upgrade-store-url-free-plan-desktop': {
    featureName: 'premium_store',
  },
  'upgrade-store-url-free-plan-mobile-web': {
    featureName: 'premium_store',
  },
  'upgrade-store-url-paid-plan-mobile-web': {
    featureName: 'purchase_domain',
  },
  'enable-whatsapp-widget-desktop': {
    featureName: 'premium_store',
  },
  'upgrade-to-premium-themes-desktop': {
    featureName: 'premium_store',
  },
  'remove-instamojo-branding-desktop': {
    featureName: 'premium_store',
  },
  'enable-whatsapp-widget-mobile-web': {
    featureName: 'premium_store',
  },
  'upgrade-to-premium-themes-mobile-web': {
    featureName: 'premium_store',
  },
  'remove-instamojo-branding-mobile-web': {
    featureName: 'premium_store',
  },
};

export default (slug: string): UseNudgeReturnType => {
  const nudges = useSelector((state: RootState) => state.nudges);
  const nudgeList = nudges.results;

  const showNudges = Boolean(nudgeList?.showNudges && nudgeList?.nudges.length > 0 && getMerchantJWTToken());
  const nudge = showNudges ? nudgeList?.nudges?.find((nudge) => nudge?.slug === slug) : undefined;
  const { handleModalOpen } = useHandleModalOpen({ ctaUrl: nudge?.ctaUrl, slug: nudge?.slug, name: nudge?.name });

  useEffect(() => {
    if (nudge) {
      trackEvent('Viewed Offer Ads', {
        slot: nudge.name,
        features: nudgeInfo[nudge.slug].featureName,
        source: 'storefront',
      });
    }
  }, [nudge]);
  return {
    nudges,
    nudgeList,
    showNudges,
    nudge,
    handleModalOpen,
  };
};

function inIframe() {
  return typeof window !== undefined && window?.self !== window?.top;
}

const useHandleModalOpen = ({
  ctaUrl,
  slug,
  name,
}: {
  ctaUrl: string;
  slug: string;
  name: string;
}): { handleModalOpen: () => void } => {
  const handleModalOpen = () => {
    if (ctaUrl) {
      const refParam = ctaUrl.includes('subscription') ? 'source' : 'ref';
      window.open(`${ctaUrl}?${refParam}=${name}`, '_blank', 'noopener,noreferrer');
    } else {
      if (inIframe()) {
        window.top.postMessage({ type: 'nudge', slug }, '*');
      } else {
        window.postMessage({ type: 'nudge', slug }, '*');
      }
      trackEvent('Clicked Offer Ad', {
        slot: name,
        feature_name: nudgeInfo[slug].featureName,
        source: 'storefront',
      });
    }
  };

  return { handleModalOpen };
};
