import { getBuyerJWTToken } from '../utils/token';

interface LinkItemProps {
  title: string;
  value: string;
  type: string;
  target: string;
  id: number;
  children: Record<string, string>[];
  login_required: boolean;
  custompage?: {
    login_required: boolean;
  };
  isExternalLink: boolean;
}
interface FilterBarResponse {
  title: string;
  value: string;
  type: string;
  target: string;
  id: number;
  is_active: boolean;
  children: Record<string, string>[];
  login_required: boolean;
  isExternalLink: boolean;
}

export interface FilterLinkResponseType {
  id: number;
  name: string;
  label: string;
  storeLink: string;
  childCategories: Record<string, string>[];
  loginRequired: boolean;
  isExternalLink: boolean;
}

export const processResponse = (links: FilterBarResponse[]): FilterLinkResponseType[] => {
  links = links.filter((link) => link.is_active);
  return links.map((link) => processIndividualResponse(link));
};

const processIndividualResponse = (link: LinkItemProps): FilterLinkResponseType => {
  return {
    id: link.id,
    name: link.title,
    label: link.title,
    storeLink:
      link?.custompage?.login_required && !getBuyerJWTToken()
        ? `/account/login?redirect_url=${link.value}`
        : link.value,
    isExternalLink: link.value?.match(/(http(s?)):\/\//i) ? true : false,
    childCategories: link.children,
    loginRequired: link?.custompage?.login_required || false,
  };
};
