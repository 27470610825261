import queryString from 'query-string';

import { isBrowser } from '.';
import { context } from './constants';
import history from './history';

export const getLocalStorageItem = (tokenName: string): string | undefined => {
  try {
    return isBrowser() && localStorage.getItem(tokenName);
  } catch {}
};

export const setLocalStorageItem = (tokenName: string, tokenValue: string): void => {
  try {
    return isBrowser() && localStorage.setItem(tokenName, tokenValue);
  } catch {}
};

export const removeLocalStorageItem = (tokenName: string): void => {
  try {
    return isBrowser() && localStorage.removeItem(tokenName);
  } catch {}
};

export const setCookie = (name: string, value: string): void => {
  if (isBrowser()) {
    document.cookie = name + '=' + value + '; Path=/;';
  }
};

export const setCookieWithExpiry = (name: string, value: string, expiry_in_hours: number): void => {
  const now = new Date();
  let time = now.getTime();
  time += 3600 * 1000 * expiry_in_hours;
  now.setTime(time);
  document.cookie = name + '=' + value + '; Expires=' + now.toUTCString() + '; Path=/';
};

export const removeCookie = (name: string): void => {
  if (isBrowser()) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
};

export const getCookie = (name: string): string | undefined => {
  if (isBrowser()) {
    const cookieObject = document.cookie?.split('; ').reduce((prev, current) => {
      const [name, value] = current.split('=');
      prev[name] = value;
      return prev;
    }, {});
    return cookieObject[name];
  }
};

export const getBuyerJWTToken = (): string | undefined =>
  isBrowser() ? getCookie(context.BUYER_TOKEN_NAME) : context.BUYER_TOKEN;
export const setBuyerJWTToken = (value: string): void => setCookie(context.BUYER_TOKEN_NAME, value);
export const removeBuyerJWTToken = (): void => removeCookie(context.BUYER_TOKEN_NAME);

export const getMerchantJWTToken = (): string | undefined => {
  const previewQuery = queryString.parse(history.location.search)['theme'] || context.PREVIEW_THEME;

  if (previewQuery) {
    return undefined;
  }
  return isBrowser() ? getCookie(context.MERCHANT_TOKEN_NAME) : context.MERCHANT_TOKEN;
};
export const setMerchantJWTToken = (value: string): void => setCookie(context.MERCHANT_TOKEN_NAME, value);
export const removeMerchantJWTToken = (): void => removeCookie(context.MERCHANT_TOKEN_NAME);

export const isMerchantView = (): boolean => {
  function inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
  return getMerchantJWTToken() && !inIframe();
};
