// import { URLs } from '@common/api';
import { getData, URLs } from '@common/api';
import * as Slider from '@common/models/Slider';
import { Context as ContextInterface } from '@common/utils/constants';
import { DEFAULT_SLIDER_LAYOUT_SELECTION } from '@theme/utils/constants';
import { call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects';

import { fetchSliderFailure, fetchSliderRequest, fetchSliderSuccess } from './slice';

export function* fetchSliderSaga({ payload }: Record<string, string>): Generator<unknown> {
  try {
    const context: ContextInterface = yield select((store) => {
      return store.context.context;
    });
    const merchantID = context.MERCHANT_ACCOUNT_ID;
    const data = yield call(() =>
      getData({ url: URLs.GET_HERO_BANNER_LAYOUT.replace('{id}', payload).replace('{userId}', merchantID.toString()) }),
    );
    const { id, layout } = data?.data;
    const layoutName = layout?.name || DEFAULT_SLIDER_LAYOUT_SELECTION;
    const results = data.data?.banners?.map((slide) => Slider.processResponse(slide, layoutName));
    yield put(fetchSliderSuccess({ id: id, layout: layout, results }));
  } catch (e) {
    // console.log('ERROR', e);
    yield put(fetchSliderFailure('Request failed with: ' + e));
  }
}

export default function* heroBannerSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchSliderRequest.type, fetchSliderSaga);
}
