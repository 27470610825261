import Testimonial from '@common/models/Testimonial';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TestimonialsResponseType {
  count: number;
  next: string;
  previous: string;
  results: Testimonial[];
}

export interface TestimonialsSliceType {
  testimonials?: TestimonialsResponseType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const testimonialsSlice = createSlice({
  name: 'testimonials',
  initialState: <TestimonialsSliceType>{
    testimonials: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchTestimonialsRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.testimonials = null;
      state.error = '';
    },
    fetchTestimonialsSuccess(state, { payload }: PayloadAction<TestimonialsResponseType>) {
      state.testimonials = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchTestimonialsFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const {
  fetchTestimonialsSuccess,
  fetchTestimonialsRequest,
  fetchTestimonialsFailure,
} = testimonialsSlice.actions;

export default testimonialsSlice.reducer;
