import { getData, URLs } from '@common/api';
import * as ThemeCustomisation from '@common/models/ThemeCustomisation';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import {
  fetchThemeCustomisationFailure,
  fetchThemeCustomisationRequest,
  fetchThemeCustomisationSuccess,
} from './slice';

function* fetchThemeCustomisationSaga() {
  try {
    let data = yield call(() =>
      getData({
        url: URLs.GET_CUSTOM_THEME,
      }),
    );
    data = ThemeCustomisation.processResponse(data?.data);
    yield put(fetchThemeCustomisationSuccess(data));
  } catch (e) {
    yield put(fetchThemeCustomisationFailure('Request failed with:' + e));
  }
}
export default function* themeCustomisationSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchThemeCustomisationRequest.type, fetchThemeCustomisationSaga);
}
