import { getCustomDomainUrl, getSubscriptionUrl } from '@common/utils/constants';

export interface Nudge {
  componentName: string;
  ctaText: string;
  ctaUrl: string;
  modalTitle?: string;
  modalText: string;
  name: string;
  nudgeText: string;
  pages: string[];
  slug: string;
  type: string;
  planAvailability?: string;
  modalCtaText: string;
  modalCtaUrl: string;
}

export interface NudgeList {
  nudges: Nudge[];
  showNudges: boolean;
  isUserOnPremiumPlan: boolean;
}

export const processResponse = (nudge: Record<string, never>): Nudge => {
  return {
    componentName: nudge.component_name,
    ctaText: nudge.cta_text,
    ctaUrl: nudge.cta_url,
    modalTitle: null,
    modalText: nudge.modal_text,
    name: nudge.name,
    nudgeText: nudge.nudge_text,
    pages: nudge.pages,
    slug: nudge.slug,
    type: nudge.type,
    planAvailability: null,
    modalCtaText: nudge.modal_cta_text,
    modalCtaUrl: nudge.modal_cta_url,
  };
};

export const mapResponse = (nudge: Nudge): Nudge => {
  switch (nudge.slug) {
    case 'upgrade-store-url-free-plan-desktop':
    case 'upgrade-store-url-free-plan-mobile-web': {
      return {
        ...nudge,
        modalCtaText: 'Upgrade Plan',
        ctaText: 'Upgrade Plan',
        modalTitle: 'Link custom domain to store',
        modalText:
          '<p style="text-align: center;">Change your store&apos;s web address to a URL of your choice by linking it to a custom domain that you own.</p>',
        planAvailability: 'Available on Starter and Growth Plan',
      };
    }
    case 'upgrade-store-url-paid-plan-desktop':
    case 'upgrade-store-url-paid-plan-mobile-web': {
      return {
        ...nudge,
        modalCtaText: 'Link a domain',
        ctaText: 'Link a domain',
        modalTitle: 'Got a website url? Link it here',
        modalText:
          '<p style="text-align: center;">Link an existing domain name to your store and get discovered easily on search engines and social media.</p>',
        planAvailability: null,
        ctaUrl: getCustomDomainUrl(),
      };
    }
    case 'enable-whatsapp-widget-desktop':
    case 'enable-whatsapp-widget-mobile-web': {
      return {
        ...nudge,
        modalCtaText: 'Upgrade Plan',
        modalTitle: 'Put Whatsapp Button on Store',
        modalText: '<p style="text-align: center;">Allow visitors to reach out to you directly via Whatsapp.</p>',
        planAvailability: 'Available on Growth Plan',
      };
    }
    case 'upgrade-to-premium-themes-desktop':
    case 'upgrade-to-premium-themes-mobile-web': {
      return {
        ...nudge,
        modalCtaText: 'Upgrade Plan',
        modalTitle: 'Premium Themes',
        modalText:
          '<p style="text-align: center;">Pick your store theme from our library of professionally designed beautiful themes</p>',
        planAvailability: 'Available on Starter and Growth Plan',
      };
    }
    case 'remove-instamojo-branding-desktop':
    case 'remove-instamojo-branding-mobile-web': {
      return {
        ...nudge,
        modalCtaText: 'Upgrade Plan',
        modalTitle: 'Remove Instamojo Branding',
        modalText:
          '<p style="text-align: center;">There will be no mention of Instamojo branding or logo on your store.</p>',
        planAvailability: 'Available on Starter and Growth Plan',
        name: 'Remove Instamojo branding',
        nudgeText: 'Remove Instamojo branding from your online store',
        modalCtaUrl: getSubscriptionUrl(),
      };
    }
    default: {
      return nudge;
    }
  }
};

export default NudgeList;
