import { getData, URLs } from '@common/api';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { processResponse } from './../../models/FBEPixel';
import { fetchFBEPixelFailure, fetchFBEPixelRequest, fetchFBEPixelSuccess } from './slice';

function* fetchFBEPixel() {
  try {
    const data = yield call(() =>
      getData({
        url: URLs.GET_FBE_PIXEL,
      }),
    );
    const pixel = processResponse(data.data);
    yield put(fetchFBEPixelSuccess(pixel));
  } catch (e) {
    yield put(fetchFBEPixelFailure('Request failed with: ' + e));
  }
}

export default function* fbePixelSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchFBEPixelRequest.type, fetchFBEPixel);
}
