import { getData, URLs } from '@common/api';
import * as SocialProfile from '@common/models/SocialProfile';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchSocialProfilesFailure, fetchSocialProfilesRequest, fetchSocialProfilesSuccess } from './slice';

function* fetchSocialProfilesSaga() {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_SOCIAL_PROFILES }));
    data.results = data.results?.map((result) => SocialProfile.processResponse(result));
    yield put(fetchSocialProfilesSuccess(data));
  } catch (e) {
    yield put(fetchSocialProfilesFailure('Request failed with: ' + e));
  }
}

export default function* socialProfilesSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchSocialProfilesRequest.type, fetchSocialProfilesSaga);
}
