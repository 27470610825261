import ProductBundle from '@common/models/ProductBundle';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Product from '../../models/Product';

export interface ProductDetailsSliceType {
  productDetails?: Product;
  productSlug?: number;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
  reviews: [];
  reviewsCount: number;
  nextReviewsUrl: string | null;
  bundle?: ProductBundle;
  isNextReviewsLoading: boolean;
  isNextReviewsLoadingFailed: boolean;
}

export interface ProductBundlePayload {
  productSlug: string;
  location: string;
}

const productDetailsSlice = createSlice({
  name: 'productDetails',
  initialState: <ProductDetailsSliceType>{
    productSlug: null,
    productDetails: null,
    isFetching: false,
    isFetched: false,
    error: '',
    reviews: [],
    bundle: null,
    reviewsCount: null,
    nextReviewsUrl: '',
    isNextReviewsLoading: false,
    isNextReviewsLoadingFailed: false,
  },
  reducers: {
    fetchProductDetailsRequest(state, { payload: productSlug }: PayloadAction<number>) {
      state.productSlug = productSlug;
      state.isFetching = true;
      state.isFetched = false;
      state.productDetails = null;
      state.reviews = [];
      state.error = '';
    },
    fetchProductDetailsSuccess(state, { payload }: PayloadAction<Product>) {
      state.productDetails = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchProductDetailsFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchProductReviewsRequest(state, {}) {
      state.reviews = [];
      state.reviewsCount = 0;
      state.nextReviewsUrl = '';
    },
    fetchProductReviewsSuccess(state, { payload }) {
      state.reviews = payload.data;
      state.reviewsCount = payload.count;
      state.nextReviewsUrl = payload.next;
    },
    fetchNextPageProductReviewsRequest(state, {}) {
      state.reviews = [...state.reviews];
      state.nextReviewsUrl = '';
      state.isNextReviewsLoading = true;
      state.isNextReviewsLoadingFailed = false;
    },
    fetchNextPageProductReviewsSuccess(state, { payload }) {
      state.reviews = [...state.reviews, ...payload.data];
      state.nextReviewsUrl = payload.next;
      state.isNextReviewsLoading = false;
      state.isNextReviewsLoadingFailed = false;
    },
    fetchNextPageProductReviewsFailed(state) {
      state.isNextReviewsLoading = false;
      state.isNextReviewsLoadingFailed = true;
    },
    /* eslint-disable-next-line */
    fetchProductBundleRequest(state, { payload }) {
      state.bundle = null;
    },
    fetchProductBundleSuccess(state, { payload }: PayloadAction<ProductBundle>) {
      state.bundle = payload;
    },
  },
});

export const {
  fetchProductDetailsSuccess,
  fetchProductDetailsRequest,
  fetchProductDetailsFailure,
  fetchNextPageProductReviewsRequest,
  fetchNextPageProductReviewsSuccess,
  fetchProductReviewsRequest,
  fetchProductReviewsSuccess,
  fetchProductBundleRequest,
  fetchProductBundleSuccess,
  fetchNextPageProductReviewsFailed,
} = productDetailsSlice.actions;

export default productDetailsSlice.reducer;
