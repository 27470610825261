export interface CategoriesProductCountResults {
  categoryId: number;
  name: string;
  productCount: number;
}

export interface CategoriesProductCount {
  results: CategoriesProductCountResults[];
}
export const processResponse = (categories: Record<string, never>): CategoriesProductCount => {
  const results = (categories.results as Record<string, never>[])?.map((category) => ({
    categoryId: category.id,
    name: category.name,
    productCount: category.product_count,
  }));
  return { results };
};
