export default interface ThemeCustomisation {
  colorFooterBG: string;
  colorFooterHeading: string;
  // colorFooterBody: string;
  colorFooterLink: string;
  colorFooterText: string;
  colorHeaderBG: string;
  colorHeaderLinks: string;
  colorPageBG: string;
  colorPageBodyText: string;
  colorPageButtonBG: string;
  colorPageButtonText: string;
  colorPageButtonBorder: string;
  colorPageHeadings: string;
  colorBannerHeading: string;
  colorBannerSubHeading: string;
  colorBannerButtonBG: string;
  colorBannerButtonText: string;
  colorBannerButtonBorder: string;
  colorCartButtonAddToCartBG: string;
  colorCartButtonAddToCartText: string;
  colorCartButtonAddToCartBorder: string;
  colorCartButtonBuyNowBG: string;
  colorCartButtonBuyNowText: string;
  colorCartButtonBuyNowBorder: string;
  colorCartButtonWhishlistBG: string;
  colorCartButtonWhishlistText: string;
  colorCartButtonWhishlistBorder: string;

  fontPragraphText: {
    id: number;
    name: string;
    fontURL: string;
    imageURL: string;
    isActive: boolean;
    variant: string;
  };
  fontSectionHeading: {
    id: number;
    name: string;
    fontURL: string;
    imageURL: string;
    isActive: boolean;
    variant: string;
  };
  id?: number;
}

interface FontCustomisation {
  id?: number;
  name: string;
  details: [
    {
      font_relative_url: string;
      id?: number;
      image_relative_url: string;
      isActive: boolean;
      variant: string;
    },
  ];
}

export const processResponse = (themeCustomisation: Record<string, string>): ThemeCustomisation => {
  // const fontPragraphText = themeCustomisation.font_paragraph_text;
  const fontPragraphText = {
    id: themeCustomisation.font_paragraph_text?.id,
    name: themeCustomisation.font_paragraph_text?.name?.name,
    fontURL: themeCustomisation.font_paragraph_text?.font_relative_url,
    imageURL: themeCustomisation.font_paragraph_text?.image_relative_url,
    isActive: themeCustomisation.font_paragraph_text?.is_active,
    variant: themeCustomisation.font_paragraph_text?.variant,
  };
  const fontSectionHeading = {
    id: themeCustomisation.font_section_heading?.id,
    name: themeCustomisation.font_section_heading?.name?.name,
    fontURL: themeCustomisation.font_section_heading?.font_relative_url,
    imageURL: themeCustomisation.font_section_heading?.image_relative_url,
    isActive: themeCustomisation.font_section_heading?.is_active,
    variant: themeCustomisation.font_section_heading?.variant,
  };
  return {
    colorFooterBG: themeCustomisation.color_footer_background,
    colorFooterHeading: themeCustomisation.color_footer_heading,
    // colorFooterBody: themeCustomisation.color_footer_body,
    colorFooterLink: themeCustomisation.color_footer_link,
    colorFooterText: themeCustomisation.color_footer_text,
    colorHeaderBG: themeCustomisation.color_header_background,
    colorHeaderLinks: themeCustomisation.color_header_links,
    colorPageBG: themeCustomisation.color_page_background,
    colorPageBodyText: themeCustomisation.color_page_body_text,
    colorPageButtonBG: themeCustomisation.color_page_button_background,
    colorPageButtonText: themeCustomisation.color_page_button_text,
    colorPageButtonBorder: themeCustomisation.color_page_button_border,
    colorPageHeadings: themeCustomisation.color_page_headings,
    colorBannerHeading: themeCustomisation.color_header_banner_heading,
    colorBannerSubHeading: themeCustomisation.color_header_banner_subheading,
    colorBannerButtonBG: themeCustomisation.color_header_banner_button_background,
    colorBannerButtonText: themeCustomisation.color_header_banner_button_text,
    colorBannerButtonBorder: themeCustomisation.color_header_banner_button_border,
    colorCartButtonAddToCartBG: themeCustomisation.color_cart_addtocart_background,
    colorCartButtonAddToCartText: themeCustomisation.color_cart_addtocart_text,
    colorCartButtonAddToCartBorder: themeCustomisation.color_cart_addtocart_border,
    colorCartButtonBuyNowBG: themeCustomisation.color_cart_buynow_background,
    colorCartButtonBuyNowText: themeCustomisation.color_cart_buynow_text,
    colorCartButtonBuyNowBorder: themeCustomisation.color_cart_buynow_border,
    colorCartButtonWhishlistBG: themeCustomisation.color_cart_whishlist_background,
    colorCartButtonWhishlistText: themeCustomisation.color_cart_whishlist_text,
    colorCartButtonWhishlistBorder: themeCustomisation.color_cart_whishlist_border,
    fontPragraphText,
    fontSectionHeading,
  };
};

export interface FetchThemeCustomisationType {
  data: ThemeCustomisation;
}

export interface FetchFontCustomisationType {
  data: {
    pages: Array<FontCustomisation>;
  };
}

export interface ThemeCustomisationResponseType {
  mutate: ThemeCustomisation | unknown;
}
