import Gift from '@common/icons/Gift.svg';
import { RouteMap } from '@common/RouteMap';
import { ReferralSliceType } from '@common/store/referral/slice';
import { formatDate } from '@common/utils/date';
import Link from '@lib/Link';
import React from 'react';

interface RewardProps {
  referral: Record<string, ReferralSliceType>;
}

const Reward: React.FunctionComponent<RewardProps> = ({ referral }) => {
  return (
    <>
      {referral.referralCodeError ? (
        <div className="flex h-200">
          <div className="m-auto">
            <div className="text-24 font-semibold text-referral-darkGray">
              Sorry, this referral discount is no longer available.
            </div>
          </div>
        </div>
      ) : (
        <div className="sm:w-6/12 w-full mx-auto flex flex-col text-center items-center my-72">
          <img className="h-32 w-32" src={Gift} alt={'Gift'} />
          <div className="mt-32 text-24 font-semibold text-referral-darkGray">
            Here is a gift from {referral.referrals?.referrerName}!
          </div>
          <div className="mt-24 text-20 text-referral-gray">
            Claim an instant discount of <strong>₹{referral.referrals?.currentProgram?.refereeDiscount}</strong> on your
            first purchase when you signup.
          </div>
          {referral.referrals?.currentProgram?.expiryDate && (
            <div className="mt-16 text-12 text-referral-lightGray">
              *Discount valid until {formatDate(referral.referrals?.currentProgram?.expiryDate)}
            </div>
          )}
          <Link
            className="bg-theme rounded text-white mt-40 py-10 px-40 text-center sm:w-5/12 w-full"
            to={`${RouteMap.ACCOUNT_REGISTER}/?referrer_code=${referral.referrals?.referralCode}`}
          >
            Signup Now
          </Link>
        </div>
      )}
    </>
  );
};

export default Reward;
