interface MetaTags {
  title: string;
  keywords: string;
  description: string;
  contentType: string;
}

export const processResponse = (metaTags: Record<string, never>): MetaTags => {
  return {
    title: metaTags.title,
    keywords: metaTags.keywords,
    description: metaTags.description,
    contentType: metaTags.content_type,
  };
};

export default MetaTags;
