import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import ReferralProgram from '../../models/ReferralProgram';
import Referrals from '../../models/Referrals';

export interface ReferralSliceType {
  referrals?: Referrals;
  referralProgram?: ReferralProgram;
  isReferralsFetching: boolean;
  isReferralsFetched: boolean;
  isReferralProgramFetching: boolean;
  isReferralProgramFetched: boolean;
  isReferralCodeFetching: boolean;
  isReferralCodeFetched: boolean;
  referralsError: string;
  referralProgramError: string;
  referralCodeError: string;
}

const referralSlice = createSlice({
  name: 'referral',
  initialState: <ReferralSliceType>{
    referrals: null,
    isReferralsFetching: false,
    isReferralsFetched: false,
    isReferralProgramFetching: false,
    isReferralProgramFetched: false,
    isReferralCodeFetching: false,
    isReferralCodeFetched: false,
    referralsError: '',
    referralProgramError: '',
    referralCodeError: '',
  },
  reducers: {
    fetchReferralsRequest(state) {
      state.isReferralsFetching = true;
      state.isReferralsFetched = false;
      state.referrals = null;
      state.referralsError = '';
    },
    fetchReferralsSuccess(state, { payload }: PayloadAction<Referrals>) {
      state.referrals = payload;
      state.isReferralsFetching = false;
      state.isReferralsFetched = true;
    },
    fetchReferralsFailure(state, { payload }: PayloadAction<string>) {
      state.referralsError = payload;
      state.isReferralsFetching = false;
      state.isReferralsFetched = true;
    },
    fetchReferralProgramRequest(state) {
      state.isReferralProgramFetching = true;
      state.isReferralProgramFetched = false;
      state.referralProgram = null;
      state.referralProgramError = '';
    },
    fetchReferralProgramSuccess(state, { payload }: PayloadAction<ReferralProgram>) {
      state.referralProgram = payload;
      state.isReferralProgramFetching = false;
      state.isReferralProgramFetched = true;
    },
    fetchReferralProgramFailure(state, { payload }: PayloadAction<string>) {
      state.referralProgramError = payload;
      state.isReferralProgramFetching = false;
      state.isReferralProgramFetched = true;
    },
    fetchReferralCodeDetailsRequest(state) {
      state.isReferralCodeFetching = true;
      state.isReferralCodeFetched = false;
      state.referrals = null;
      state.referralCodeError = '';
    },
    fetchReferralCodeDetailsSuccess(state, { payload }: PayloadAction<Referrals>) {
      state.referrals = payload;
      state.isReferralCodeFetching = false;
      state.isReferralCodeFetched = true;
    },
    fetchReferralCodeDetailsFailure(state, { payload }: PayloadAction<string>) {
      state.referralCodeError = payload;
      state.isReferralCodeFetching = false;
      state.isReferralCodeFetched = true;
    },
    resetReferralCodeDetails(state) {
      state.isReferralCodeFetching = false;
      state.isReferralCodeFetched = false;
      state.referrals = null;
      state.referralCodeError = '';
    },
  },
});

export const {
  resetReferralCodeDetails,
  fetchReferralsRequest,
  fetchReferralsSuccess,
  fetchReferralsFailure,
  fetchReferralProgramRequest,
  fetchReferralProgramSuccess,
  fetchReferralProgramFailure,
  fetchReferralCodeDetailsRequest,
  fetchReferralCodeDetailsSuccess,
  fetchReferralCodeDetailsFailure,
} = referralSlice.actions;

export default referralSlice.reducer;
