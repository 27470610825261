import ProductBundle from '@common/models/ProductBundle';
import { PayloadAction } from '@reduxjs/toolkit';
import { COMMON_TOAST_MESSAGE } from '@theme/utils/constants';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { RootState, store } from '..';
import { ToastVariant } from '../../../lib-components/Toast';
import { getCartDetailApi } from '../../../themes/Checkout/apis/users';
import { deleteData, postCart, postData, URLs } from '../../api';
import { processCartRequest } from '../../models/Cart';
import Product, { PRODUCT_TYPE } from '../../models/Product';
import Variant from '../../models/Variant';
import {
  computeCartProperties,
  computeProductHash,
  isBrowser,
  loadFromLocalStorage,
  saveToLocalStorage,
} from '../../utils';
import { trackEvent } from '../../utils/analytics';
import { performCheckout } from '../../utils/checkout';
import {
  CART_HASH_LOCAL_STORAGE_KEY,
  CART_LOCAL_STORAGE_BACKUP_KEY,
  CART_LOCAL_STORAGE_KEY,
  COOKIE_KEYS,
} from '../../utils/constants';
import { isFormError } from '../../utils/error';
import {
  getBuyerJWTToken,
  getLocalStorageItem,
  getMerchantJWTToken,
  removeLocalStorageItem,
  setCookie,
} from '../../utils/token';
import { fetchFeaturesSuccess } from '../features/slice';
import { addToast } from '../toasts/slice';
import { fetchUserProfileSuccess } from '../userProfile/slice';
import {
  addBundleProductToCartSuccess,
  addCartId,
  addProductToCart,
  AddProductToCartPayload,
  addProductToCartSuccess,
  addPromoCodeToCart,
  addPromoCodeToCartFailure,
  addPromoCodeToCartSuccess,
  addPromoInvalid,
  addResellerToCart,
  CartSliceType,
  removeProductFromCart,
  replaceCart,
  resetCart,
  setCartId,
  setProductQuantityInCart,
  setQuantityByProductId,
  subtractProductFromCart,
  SubtractProductToCartPayload,
  updateWholeSaleDiscount,
} from './slice';

const getToastContent = COMMON_TOAST_MESSAGE?.getToastContent || null;

const trackCartAction = (
  product: Product | Variant,
  eventName = 'Added Product to Cart',
  additionalProperties: Record<string, unknown> = {},
) => {
  const state: RootState = store.getState();
  const totalAmount = Object.values(state.cart.products).reduce(
    (sum, product) => sum + (product.cartQuantity ?? 1) * (product.finalPrice ?? 0),
    0,
  );
  const productType =
    product.type === 'physical-product' || product.type === 'digital-product'
      ? `${product.type.split('-')[0]}_good`
      : product.type === 'event-tickets'
      ? product.type.split('-').join('_').slice(0, -1)
      : product.type;
  trackEvent(eventName, {
    buyer_id: state.userProfile.profile?.id,
    offer_id: product.id,
    order_id: '',
    amount: product.finalPrice ?? 0,
    cart_amount: totalAmount ?? 0,
    currency: 'INR',
    transaction_category: '',
    parent_ref_store_flag: 1,
    ga_client_id: '',
    remote_checkout_flag: false,
    intent: state.storeInfo.storeInfo?.paymentsAllowed ? 'payment' : 'store',
    embed: false,
    self_flag: !!getMerchantJWTToken(),
    seller_username: state.storeInfo.storeInfo?.username,
    seller_account_id: state.storeInfo.storeInfo?.accountId,
    is_mojocommerce_cart: true,
    source: 'theme-cart',
    property: 'product_category',
    property_value: productType,
    ...additionalProperties,
  });
};

function* sanityCheckingAddingAndUpdatingProductDetailsInCartSaga({
  payload: { product, quantity = 1, disableSuccessToasts, isBuyNowFlow, discountLinkPromoCode, mixpanelProperties },
}: PayloadAction<AddProductToCartPayload>) {
  product = {
    ...product,
    hash: computeProductHash(product),
  };

  function* discountLinkFlow() {
    yield addPromoCodeToCartSaga({ payload: discountLinkPromoCode, type: '' });
    const state = store.getState() as RootState;
    if (state.cart.promoCode?.code) {
      goToCheckout();
    } else {
      const backupCart = (loadFromLocalStorage(CART_LOCAL_STORAGE_BACKUP_KEY) as unknown) as CartSliceType;
      removeLocalStorageItem(CART_LOCAL_STORAGE_BACKUP_KEY);
      yield put(replaceCart(backupCart));
    }
  }

  if (isBuyNowFlow) {
    const state: RootState = store.getState();
    const cart = state.cart;

    if (product?.type === PRODUCT_TYPE.DIGITAL || discountLinkPromoCode) {
      saveToLocalStorage(CART_LOCAL_STORAGE_BACKUP_KEY, (cart as unknown) as Record<string, unknown>);
      store.dispatch(resetCart(true));
    } else if (cart.products[product.hash]) {
      goToCheckout();
      return;
    }
  }
  if (!product?.id) {
    yield put(
      addToast({
        content: `This product does not exist`,
        variant: ToastVariant.error,
        dismissAfterMillis: 5000,
      }),
    );
  } else {
    // Any other type of product.
    const state: RootState = store.getState();
    const typeSet = new Set(Object.values(state.cart.products)?.map((product) => product.type));
    const cartProductType =
      [...typeSet.values()][0] === 'other' ? 'other products' : [...typeSet.values()][0]?.split('-').join(' ');

    if (typeSet.size && !typeSet.has(product.type)) {
      // Cart must contain products of same type.
      const productType = product.type.split('-').join(' ');
      yield put(
        addToast({
          content: `You cannot add ${productType} ${
            productType === 'other' ? 'products' : ''
          } to a cart that already has ${cartProductType}. Please clear the cart first before adding ${productType}.`,
          variant: ToastVariant.error,
          dismissAfterMillis: 10000,
        }),
      );
    } else {
      const cartQuantity = state.cart.quantityByProductId[product.id] || 0;
      const minQuantity = product.minQuantity;
      const maxQuantity = product.maxQuantity;
      const stock = product.stock;

      if (cartQuantity === 0) {
        // Check if quantity exceeds maxOrderQuantity or stock when cartQuantity is 0.
        const quantityToAdd = Math.max(minQuantity, quantity);
        const stockLeft = stock - quantityToAdd;
        const slotsLeft = maxQuantity - quantityToAdd;
        if (stockLeft < 0) {
          yield put(
            addToast({
              content: `Exceeded present stock. Can only add ${stock} of this product.`,
              variant: ToastVariant.error,
              dismissAfterMillis: 10000,
            }),
          );
        } else if (slotsLeft < 0) {
          yield put(
            addToast({
              content: `You can only add a maximum of ${maxQuantity} unit of this product per order.`,
              variant: ToastVariant.error,
              dismissAfterMillis: 10000,
            }),
          );
        } else {
          yield put(addProductToCartSuccess({ product, quantity: quantityToAdd }));
          trackCartAction(product, undefined, mixpanelProperties);
          if (isBuyNowFlow && discountLinkPromoCode) {
            yield discountLinkFlow();
          } else if (isBuyNowFlow) {
            goToCheckout();
          } else if (!disableSuccessToasts) {
            if (!product.bundleId) {
              yield put(
                addToast({
                  content: getToastContent
                    ? getToastContent(product.title, quantityToAdd)
                    : `Added ${quantityToAdd} items of ${product.title} to cart.`,
                  variant: ToastVariant.success,
                  type: 'addedToCart',
                  dismissAfterMillis: 10000,
                  productImage: (product.images || [])[0],
                }),
              );
            }
          }
        }
      } else {
        // Check if quantity exceeds maxOrderQuantity or stock when cartQuantity exists.
        const toBeQuantity = quantity + cartQuantity;
        const stockLeft = stock - toBeQuantity;
        const slotsLeft = maxQuantity - toBeQuantity;
        if (stockLeft < 0) {
          yield put(
            addToast({
              content: `Exceeded present stock. Can only add ${stock} of this product.`,
              variant: ToastVariant.error,
              dismissAfterMillis: 10000,
            }),
          );
        } else if (slotsLeft < 0) {
          yield put(
            addToast({
              content: `You can only add a maximum of ${maxQuantity} unit of this product per order.`,
              variant: ToastVariant.error,
              dismissAfterMillis: 10000,
            }),
          );
        } else {
          yield put(addProductToCartSuccess({ product, quantity }));
          trackCartAction(product, undefined, mixpanelProperties);
          if (isBuyNowFlow && discountLinkPromoCode) {
            yield discountLinkFlow();
          } else if (isBuyNowFlow) {
            goToCheckout();
          } else if (!disableSuccessToasts) {
            if (!product.bundleId) {
              yield put(
                addToast({
                  content: getToastContent
                    ? getToastContent(product.title, quantity)
                    : `Added ${quantity} items of ${product.title} to cart.`,
                  type: 'addedToCart',
                  variant: ToastVariant.success,
                  dismissAfterMillis: 10000,
                }),
              );
            }
          }
        }
      }
    }
  }
}

function* addPromoCodeToCartSaga({ payload: promoCode }: PayloadAction<string>) {
  try {
    const state: RootState = store.getState();
    const profile = state.userProfile.profile;
    const products = Object.values(state.cart.products);
    const totalProducts = products.reduce((sum, product) => sum + product.cartQuantity, 0);
    const totalAmount = products.reduce((sum, product) => sum + product.cartQuantity * product.finalPrice, 0);
    const processedCart = processCartRequest({
      promoCode: null,
      totalItems: totalProducts,
      totalPrice: totalAmount,
      items: products,
    });
    const formData = new FormData();
    formData.set('code', promoCode);
    formData.set(
      'cart',
      JSON.stringify({
        itemlist: processedCart.items,
      }),
    );
    if (profile) {
      formData.set('email', profile.email);
      formData.set('contact', profile.contact);
    }
    const response = yield call(() =>
      postData({
        url: URLs.POST_PROMOTION,
        data: formData,
        headers: {
          'Content-type': 'multipart/form-data',
        },
      }),
    );
    const promoCodeObject = {
      code: response.data.promo_code,
      discount: response.data.discount,
      discountedTotalOrder: response.data.discounted_total_order,
      totalOrder: response.data.total_order,
      promoCategory: response.data.promo_category,
    };
    yield put(addPromoCodeToCartSuccess(promoCodeObject));
    const cart = store.getState().cart;
    saveToLocalStorage(CART_LOCAL_STORAGE_KEY, cart);
    trackEvent('Applied Buyer Discount Coupon', {
      order_id: '',
      cart_amount: promoCodeObject.totalOrder,
      discount_amount: promoCodeObject.discount,
      coupon_code: promoCodeObject.code,
      coupon_type: promoCodeObject.promoCategory,
      seller_username: state.storeInfo.storeInfo?.username,
      seller_account_id: state.storeInfo.storeInfo?.accountId,
      source: 'theme-cart',
    });
  } catch (e) {
    if (isFormError(e)) {
      if (e?.response?.data?.non_field_errors) {
        yield put(addPromoCodeToCartFailure());
      } else {
        if (e?.response?.data?.error === 'Invalid coupon code') {
          yield put(addPromoInvalid());
        }
        yield put(
          addToast({
            content: e?.response?.data?.error,
            variant: ToastVariant.error,
            type: e?.response?.data?.error === 'Invalid coupon code' ? 'invalidCouponCode' : '',
            dismissAfterMillis: 10000,
          }),
        );
      }
    } else {
      yield put(
        addToast({
          content: `Error appling promocode.`,
          variant: ToastVariant.error,
          dismissAfterMillis: 10000,
        }),
      );
    }
    yield put(addPromoCodeToCartSuccess(null));
  }
}

function* updateQuantityByProductId() {
  let state: RootState = store.getState();
  const quantityByProductId = Object.values(state.cart.products).reduce((acc, curr) => {
    if (acc[curr.id]) {
      acc[curr.id] += curr.cartQuantity;
    } else {
      acc[curr.id] = curr.cartQuantity;
    }
    return acc;
  }, {});
  yield put(setQuantityByProductId(quantityByProductId));
  state = store.getState();
  saveToLocalStorage(CART_LOCAL_STORAGE_KEY, (state.cart as unknown) as Record<string, unknown>);

  try {
    if (state.features?.features?.wholesalePricing) {
      const { promoCode, totalProducts, totalAmount, processedProducts } = computeCartProperties(state.cart);
      const { data } = yield call(() =>
        postData({
          url: URLs.POST_WHOLESALE_DISCOUNT,
          data: {
            cart: {
              itemlist: processedProducts,
              promo: {
                promo_code: promoCode?.code,
                discount: promoCode?.discount,
                total_order: promoCode?.totalOrder,
                discounted_total_order: promoCode?.discountedTotalOrder,
              },
              total_items: totalProducts,
              total_price: totalAmount,
            },
          },
        }),
      );

      if (data?.ws_breakup) {
        yield put(
          updateWholeSaleDiscount({
            discountedPrice: data?.ws_breakup?.discounted_price,
            totalDiscount: data?.ws_breakup?.total_discount,
            wsBreakup: data?.ws_breakup,
            finalDiscountedPrice: data?.final_discounted_price,
          }),
        );
      } else {
        yield put(updateWholeSaleDiscount(null));
      }
    } else {
      yield put(updateWholeSaleDiscount(null));
    }
  } catch (e) {
    yield put(updateWholeSaleDiscount(null));
    // console.error(e);
  }

  try {
    const state = store.getState() as RootState;
    const cartHash = getLocalStorageItem(CART_HASH_LOCAL_STORAGE_KEY);
    const { cartId, products } = state.cart;
    if (getBuyerJWTToken() && Object.keys(products).length === 0) {
      try {
        if (cartId) {
          yield put(setCartId(cartId));
          return;
        }
      } catch (e) {
        if (e.response?.status == 400) {
          yield postCartApiCall(state, products);
        }
      }
    } else {
      if (getBuyerJWTToken() || cartHash) {
        yield postCartApiCall(state, products);
      }
    }
  } catch (e) {
    console.error(e);
  }
}

function* getCart() {
  const cartHash = getLocalStorageItem(CART_HASH_LOCAL_STORAGE_KEY);
  try {
    const data = yield call(() => getCartDetailApi(cartHash));
    if (data.cart_json.original_cart) {
      yield put(replaceCart(JSON.parse(data.cart_json.original_cart)));
    }
  } catch (e) {}
}

function* postCartApiCall(state, products) {
  const { promoCode, wholesaleDiscount, processedProducts, totalAmountAfterDiscount } = computeCartProperties(
    state.cart,
  );
  Object.keys(products).length === 0 && state.cart.cartId ? yield put(setCartId(state.cart.cartId)) : '';

  const { firstname, lastname, contact, email } = state.userProfile.profile;
  const { data } = yield call(() =>
    postCart({
      firstname,
      lastname,
      contact,
      email,
      promoCode: promoCode?.code,
      items: processedProducts,
      promoDiscountedTotal: promoCode?.discountedTotalOrder,
      promoDiscount: promoCode?.discount,
      wsBreakup: wholesaleDiscount?.wsBreakup,
      finalDiscountedPrice: totalAmountAfterDiscount,
    }),
  );
  if (data?.id) {
    yield put(addCartId(data?.id));
  }
}

function* deleteCart({ payload: cartId }: PayloadAction<string>) {
  const cartHash = getLocalStorageItem(CART_HASH_LOCAL_STORAGE_KEY);
  try {
    yield call(() =>
      deleteData({
        url: cartHash
          ? `${URLs.DELETE_CART.replace('{id}', `${cartId}`)}?cart_hash=${cartHash}`
          : URLs.DELETE_CART.replace('{id}', `${cartId}`),
        headers: {
          'Content-type': 'multipart/form-data',
        },
      }),
    );
    getLocalStorageItem(CART_HASH_LOCAL_STORAGE_KEY) && removeLocalStorageItem(CART_HASH_LOCAL_STORAGE_KEY);
  } catch (e) {
    getLocalStorageItem(CART_HASH_LOCAL_STORAGE_KEY) && removeLocalStorageItem(CART_HASH_LOCAL_STORAGE_KEY);
  }
}

function* removalOfProductFromCartSuccess({
  payload: { product, mixpanelProperties },
}: PayloadAction<SubtractProductToCartPayload>) {
  if (product) {
    trackCartAction(product, 'Removed Product from Cart', mixpanelProperties);
  }
}

function* sanityBundleProductCheck({ payload: bundleProduct }: PayloadAction<ProductBundle>) {
  const state: RootState = store.getState();
  const { quantityByProductId } = state.cart;
  const products = Object.values(state.cart.products);
  const checkThemeName = state.storeInfo.storeInfo?.theme?.name;

  const cart = loadFromLocalStorage(CART_LOCAL_STORAGE_KEY);
  if (cart?.bundles && Object.keys(cart?.bundles).length != 0) {
    yield put(
      addToast({
        content: `Deal already added`,
        variant: ToastVariant.error,
        dismissAfterMillis: 5000,
      }),
    );
    return;
  } else {
    yield put(
      addToast({
        content: `Deal added in the cart`,
        variant: ToastVariant.success,
        dismissAfterMillis: 5000,
      }),
    );
  }

  for (const key in products) {
    const { maxQuantity, stock, id } = products[key];
    if (quantityByProductId[id] > maxQuantity || quantityByProductId[id] > stock) {
      yield put(
        addToast({
          content: `Exceeded present stock.`,
          variant: ToastVariant.error,
          dismissAfterMillis: 10000,
        }),
      );
      return;
    }
  }

  if (window.location.pathname.includes('confirmation')) {
    if (checkThemeName == 'Cipher' || checkThemeName == 'Rugged') {
      window.location.href = '/';
    } else {
      window.location.href = '/cart';
    }
  }

  const calcPercentage = Math.round(
    ((bundleProduct.bundlePriceWithoutDiscount - bundleProduct.bundleDiscountedPrice) /
      bundleProduct.bundlePriceWithoutDiscount) *
      100,
  );
  const discountType = bundleProduct.discountType == 0 ? 'Percentage discount' : 'Fixed discount';
  trackEvent('Added Product Bundle to Cart', {
    bundle_id: bundleProduct.bundleId,
    title: bundleProduct.title,
    description: bundleProduct.description,
    location: bundleProduct.location,
    num_products: bundleProduct.products.length,
    original_amount: bundleProduct.bundlePriceWithoutDiscount,
    discount_type: discountType,
    discount_percentage: calcPercentage,
    discounted_amount: bundleProduct.bundleDiscountedPrice,
    seller_account_id: state.storeInfo.storeInfo?.accountId,
    seller_username: state.storeInfo.storeInfo?.username,
  });

  bundleProduct.products.map((product) => {
    const modifiedProduct = {
      ...product,
      bundleId: bundleProduct.bundleId,
      bundleDiscountAmount: product.bundleDiscountAmount,
    };
    store.dispatch(addProductToCart({ product: modifiedProduct }));
  });
}

export default function* cartSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(addProductToCart.type, sanityCheckingAddingAndUpdatingProductDetailsInCartSaga);
  yield takeEvery(addPromoCodeToCart.type, addPromoCodeToCartSaga);

  yield takeEvery(addProductToCartSuccess.type, updateQuantityByProductId);
  yield takeEvery(subtractProductFromCart.type, updateQuantityByProductId);
  yield takeEvery(setProductQuantityInCart.type, updateQuantityByProductId);
  yield takeEvery(removeProductFromCart.type, updateQuantityByProductId);
  yield takeEvery(resetCart.type, updateQuantityByProductId);
  yield takeEvery(replaceCart.type, updateQuantityByProductId);
  yield takeEvery(addPromoCodeToCartSuccess.type, updateQuantityByProductId);
  yield takeEvery(addResellerToCart.type, updateQuantityByProductId);
  if (isBrowser() && process.env.THEME != 'Checkout') {
    yield takeEvery(fetchFeaturesSuccess.type, updateQuantityByProductId);
    yield takeEvery(fetchUserProfileSuccess.type, getCart);
    yield takeEvery(fetchUserProfileSuccess.type, updateQuantityByProductId);
  }

  yield takeEvery(subtractProductFromCart.type, removalOfProductFromCartSuccess);
  yield takeEvery(removeProductFromCart.type, removalOfProductFromCartSuccess);
  yield takeEvery(setCartId.type, deleteCart);
  yield takeEvery(addBundleProductToCartSuccess.type, sanityBundleProductCheck);
}

const goToCheckout = () => {
  const cart = (store.getState() as RootState).cart;
  const products = Object.values(cart.products);
  const totalProducts = products.reduce((sum, product) => sum + product.cartQuantity, 0);
  const totalAmount = products.reduce((sum, product) => sum + product.cartQuantity * product.finalPrice, 0);
  // eslint-disable-next-line
  saveToLocalStorage(CART_LOCAL_STORAGE_KEY, cart as any);
  setCookie(COOKIE_KEYS.CART_PRODUCT_TYPE, products?.[0]?.type);
  performCheckout({
    promoCode: null,
    totalItems: totalProducts,
    totalPrice: totalAmount,
    items: products,
  });
};
