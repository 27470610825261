import CartProduct, { processProductRequest } from '@common/models/CartProduct';
import { CategoriesTreeProps, CategoriesTreeType, SpecificCategory } from '@common/models/CategoryShowCase';
import { CustomPagesListType, StorePageNameTypes } from '@common/models/StorePage';
import { RouteMap } from '@common/RouteMap';
import { CartProductType, CartSliceType, PromoCodeType, WholesaleDiscountType } from '@common/store/cart/slice';
import { FilterBarSliceType } from '@common/store/filterBar/slice';
import { getQueryParams, LAYOUT_IMAGE_HEIGHT, LAYOUT_IMAGE_WIDTH } from '@common/utils/constants';
import objectHash from 'object-hash';
import { matchPath } from 'react-router-dom';

import {
  errorFromAPIToast,
  formErrorMessageToast,
  somethingWentWrongFailureMessageToast,
} from '../../Admin/common/components/ToastContainers';
import { defaultLayouts } from '../../Admin/common/constants/defaultLayout';
import { ToastsType } from '../../Admin/ContextProvider';
import { FormProductOption } from '../models/Option';
import { FormCustomField } from './../models/CustomField';
import {
  context,
  getAboutUsPageMetaTags,
  getCancelAndRefundMetaTags,
  getCategoryMetaTags,
  getContactUsMetaTags,
  getFaqsMetaTags,
  getPrivacyPolicyMetaTags,
  getShippingAndDeliveryMetaTags,
  getTermsAndConditionsMetaTags,
  HOME,
} from './constants';

interface builderErrorToastMessagesProps {
  statusCode: number;
  errors?: string;
  addToast: (values: ToastsType) => void;
}
interface PageType {
  customPageId: string;
}

export const isBrowser = (): boolean => {
  return !(typeof window === 'undefined');
};

export const isServer = (): boolean => {
  return typeof window === 'undefined';
};

export const isIOSDevice = (): boolean => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator?.userAgent?.includes('Mac') && 'ontouchend' in document)
  );
};

export const JSONStrigifyAndParse = (obj: unknown): string => {
  return JSON.parse(JSON.stringify(obj));
};

export const getTrailingSlashUrl = (url: string): string => {
  return url.endsWith('/') ? url : `${url}/`;
};

export const saveToLocalStorage = (key: string, value: Record<string, unknown> | Record<string, unknown>[]): void => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (e) {
    console.log('Saving to localStorage failed with: ', e);
  }
};

export const loadFromLocalStorage = (key: string): Record<string, unknown> | undefined => {
  try {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue === null) return undefined;
    return JSON.parse(serializedValue);
  } catch (e) {
    console.log('Loading from localStorage failed with: ', e);
    return undefined;
  }
};

export const computeObjectHash = (object: Record<string, unknown>): string => {
  return JSON.stringify(object);
};

export const addMinutes = (d: Date, minutes: number): Date => {
  return new Date(d.getTime() + minutes * 60000);
};

export const isMerchant = (): boolean => true;

export const isPremiumUser = (): boolean => true;

export const copyTextToClipboard = (text: string): void => {
  const textArea = document.createElement('textarea');
  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '0';

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em';
  textArea.style.height = '2em';

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = '0';

  // Clean up any borders.
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = 'transparent';

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.log('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
};

export const smoothScroll = (y: number, duration: number): void => {
  if (!isBrowser()) {
    return;
  }
  if (duration) {
    const initialY = document.documentElement.scrollTop || document.body.scrollTop;
    const baseY = (initialY + y) * 0.5;
    const difference = initialY - baseY;
    const startTime = performance.now();
    function step() {
      let normalizedTime = (performance.now() - startTime) / duration;
      if (normalizedTime > 1) normalizedTime = 1;
      window.scrollTo(0, baseY + difference * Math.cos(normalizedTime * Math.PI));
      if (normalizedTime < 1) window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
  } else {
    // eslint-disable-next-line
    // @ts-ignore
    window.scrollTo({ left: 0, top: y, behavior: 'instant' });
  }
};

export const computeProductHash = (product: {
  id: number;
  finalPrice: number;
  formProductOptions?: FormProductOption[];
  formCustomFields?: FormCustomField[];
  bundleId?: number | null;
}): string => {
  return objectHash({
    id: product.id,
    finalPrice: product.finalPrice,
    formProductOptions: [...(product.formProductOptions || [])]?.sort((x, y) => x?.option?.id - y?.option?.id),
    formCustomFields: [...(product.formCustomFields || [])]?.sort((x, y) => x?.id - y?.id),
    bundleId: product.bundleId,
  });
};

export const computeProductHashForOldSchema = ({
  id,
  final_price,
  options,
  custom_fields,
}: {
  id: number;
  final_price: number;
  options?: FormProductOption[];
  custom_fields?: FormCustomField[];
}): string => {
  return computeProductHash({
    id: id,
    finalPrice: final_price,
    formProductOptions: options,
    formCustomFields: custom_fields,
  });
};

export const shouldUseCustomNavigation = (): boolean => {
  // const state: RootState = store.getState();
  return false;
};

const testMobileRegex = (userAgent: string): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
};
export const isMobile = (): boolean => {
  return isBrowser() ? testMobileRegex(navigator.userAgent) : testMobileRegex(context.USER_AGENT);
};

export interface ComputedCartProperties {
  products: CartProductType[];
  promoCode: PromoCodeType;
  wholesaleDiscount: WholesaleDiscountType;
  totalProducts: number;
  totalAmount: number;
  totalDiscount: number;
  totalAmountAfterDiscount: number;
  cartHasOptionProduct: boolean;
  cartHasCustomFieldProduct: boolean;
  cartHasVariant: boolean;
  processedProducts: CartProduct[];
  discountBreakup: {
    promo?: number;
    wholesale?: number;
  };
  cartHasResellerProduct: boolean;
  hasSomeDiscount: boolean;
  subTotal?: number;
  totalDiscountedAmount?: number;
}

export const computeCartProperties = (cart: CartSliceType): ComputedCartProperties => {
  const products = Object.values(cart.products);
  const promoCode = cart.promoCode;
  const wholesaleDiscount = cart.wholesaleDiscount;
  const totalProducts = products.reduce((sum, product) => sum + product.cartQuantity, 0);
  const totalAmount = products.reduce((sum, product) => sum + product.cartQuantity * product.finalPrice, 0) as number;
  const cartHasOptionProduct = products
    .map((product) => !!product.formProductOptions?.length)
    .reduce((acc, curr) => acc || curr, false);
  const cartHasCustomFieldProduct = products
    .map((product) => !!product.formCustomFields?.length)
    .reduce((acc, curr) => acc || curr, false);
  const cartHasVariant = products
    .map((product) => !!product.optionValues?.length)
    .reduce((acc, curr) => acc || curr, false);
  const cartHasResellerProduct = products
    .map((product) => !!cart?.groupToResellerMapping?.[product.groupId])
    .reduce((acc, curr) => acc || curr, false);

  const totalAmountAfterDiscount =
    wholesaleDiscount?.finalDiscountedPrice ?? promoCode?.discountedTotalOrder ?? totalAmount;
  const totalDiscount = totalAmount - totalAmountAfterDiscount;
  const processedProducts = products.map((product) => processProductRequest(product));

  const discountBreakup = {
    promo: promoCode?.discount,
    wholesale: wholesaleDiscount?.totalDiscount,
  };
  const hasSomeDiscount = totalAmountAfterDiscount != totalAmount;

  const totalDiscountedAmount = products.reduce(
    (sum, product) =>
      product?.hasDiscount ? sum + product.cartQuantity * (product.price - product.discountedPrice) : sum,
    0,
  );

  const subTotal = totalDiscountedAmount + totalAmount;

  return {
    products,
    promoCode,
    wholesaleDiscount,
    totalProducts,
    totalAmount,
    cartHasOptionProduct,
    cartHasCustomFieldProduct,
    cartHasVariant,
    totalDiscount,
    processedProducts,
    totalAmountAfterDiscount,
    discountBreakup,
    cartHasResellerProduct,
    hasSomeDiscount,
    subTotal,
    totalDiscountedAmount,
  };
};

export const generateFacetsString = (values: Record<string, unknown>): string => {
  const facets =
    Object.keys(values)
      .filter((key) => key !== 'category' && key !== 'ordering')
      .map((key) => {
        let value = values[key];
        if (typeof value === 'object') {
          value = Object.keys(value)
            .filter((x) => value[x])
            .join(';');
        }
        return value ? `${key}:${value}` : '';
      })
      .filter((x) => x)
      .join('|') || undefined;

  return facets;
};

interface GetLambdaLinkArgs {
  link: string;
  width: number;
  height: number;
}

export const getLambdaLink = ({ link, width, height }: GetLambdaLinkArgs): string => {
  const params = getQueryParams();
  if (link === null) return null;
  if (params?.imgOptz === '0') {
    return decodeURIComponent(link);
  }

  // TODO: Skip optimization for images with special chars; needs a proper fix.
  const hasSpecialChars = /[!@#$^&*+]/.test(link);
  if (hasSpecialChars) {
    return decodeURIComponent(link);
  }

  const lambdaLink = link?.replace(/\.([a-zA-Z0-9]+)$/, (extension) => {
    if (extension?.length <= 6) {
      return `_${width}x${height}${context.WEBP_SUPPORT ? '_webp' : ''}${extension}`;
    } else {
      return extension;
    }
  });
  return decodeURIComponent(lambdaLink);
};

export const shortenString = (str: string, characterCount: number): string => {
  if (str.length < characterCount) {
    return str;
  }
  return str.slice(0, characterCount);
};

export const convertToMyInstamojoDomain = (url: string): string => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = `https://${url}`;
  }
  return url.replace('.stores.', '.').replace('.instamojo.com', '.myinstamojo.com').replace('http://', 'https://');
};

export const getActiveNavbar = (label: string, navbarResponse: FilterBarSliceType): unknown => {
  if (navbarResponse?.links) {
    return navbarResponse?.links.find((link) => link.label === label);
  }
};

export const getPixelScript = (pixelId: string): string => {
  return `<script>
  !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
  n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
  document,'script','https://connect.facebook.net/en_US/fbevents.js');

  fbq('init', '${pixelId}');
  fbq('track', "PageView");
  </script>
  <noscript><img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"
  /></noscript>`;
};

const routeToParamsMapping = (() => {
  const nullMapping = {};
  for (const route in RouteMap) {
    nullMapping[RouteMap[route]] = { type: -1 };
  }
  const mapping = {
    ...nullMapping,
    ...{
      [RouteMap.ROOT]: { type: 4, others: 'home' },
      [RouteMap.HOME]: { type: 4, others: 'home' },
      [RouteMap.SHOP_ALL]: { type: 4, others: 'shop' },
      [RouteMap.ABOUT]: { type: 4, others: 'aboutus' },
      [RouteMap.CONTACT]: { type: 4, others: 'contactus' },
      [RouteMap.FAQ]: { type: 4, others: 'faq' },
      [RouteMap.PRODUCT]: { type: 1 },

      [RouteMap.SEO_POLICY_PRIVACY]: { type: 4, others: 'privacy_policy' },
      [RouteMap.SEO_POLICY_CANCELLATION_AND_REFUND]: { type: 4, others: 'cnrpolicy' },
      [RouteMap.SEO_POLICY_TERMS_AND_CONDITIONS]: { type: 4, others: 'tnc' },
      [RouteMap.SEO_POLICY_SHIPPING_AND_DELIVERY]: { type: 4, others: 'sndpolicy' },

      '/policy/privacy': { type: 4, others: 'privacy_policy' },
      '/policy/cancellation': { type: 4, others: 'cnrpolicy' },
      '/policy/tnc': { type: 4, others: 'tnc' },
      '/policy/shipping': { type: 4, others: 'sndpolicy' },

      [RouteMap.CATEGORY_1]: { type: 2 },
      [RouteMap.CATEGORY_2]: { type: 2 },
    },
  };
  return mapping;
})();

export const getCustomMetaTagsParams = (pathname: string): Record<string, string> => {
  let customTagsParams = null;
  const parametersInPathnames = pathname.split('/');
  const categoryOrProductId =
    parametersInPathnames[parametersInPathnames.length - 1] === ''
      ? parametersInPathnames[parametersInPathnames.length - 2]
      : parametersInPathnames[parametersInPathnames.length - 1];
  for (const key in routeToParamsMapping) {
    const isMatch = matchPath(pathname, { path: key, exact: true });
    if (isMatch) {
      customTagsParams = routeToParamsMapping[key];
    }
  }

  if (customTagsParams && customTagsParams.type !== -1) {
    if (customTagsParams.type === 2) {
      customTagsParams.category = categoryOrProductId;
    } else if (customTagsParams.type === 1) {
      customTagsParams.product__slug = categoryOrProductId;
    }
  }

  // custom-page
  if (!customTagsParams) {
    customTagsParams = { type: 3, custompage: pathname.replace('/p/', '/') };
  }
  return customTagsParams;
};

export const getYoutubeVideoThumbnail = (url: string): string => {
  if (url !== null && url !== undefined) {
    const splitedUrl = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    // eslint-disable-next-line
    return `https://i.ytimg.com/vi/${
      splitedUrl[2] ? splitedUrl[2].split(/[^0-9a-z_\-]/i)[0] : splitedUrl[0]
    }/hqdefault.jpg`;
  } else {
    return '';
  }
};

export const matchYoutubeUrlRegex = (url: string): boolean => {
  const regex = new RegExp(/^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$/);
  return regex.test(url);
};

export const getProductCategoryTree = (categories: SpecificCategory, level = 0): [CategoriesTreeType] => {
  const categoryTree = [];
  if (!categories || categories.length === 0) {
    return [];
  }
  for (let i = 0; i < categories.length; i++) {
    const formatedCategoryObject = {
      value: categories[i].id || categories[i].value,
      label: categories[i].name || categories[i].label,
      image: categories[i].image || null,
      order: categories[i].order,
      level: level,
      children: getProductCategoryTree(categories[i].child_categories, level + 1),
      depth: level,
    };
    if (formatedCategoryObject && formatedCategoryObject?.children.length === 0) {
      delete formatedCategoryObject.children;
    }
    categoryTree.push(formatedCategoryObject);
  }

  return categoryTree;
};

export const mappingMultiViewProductCategory = (
  toMapProductCategory: Array<CategoriesTreeType>,
  categoriesList: Array<CategoriesTreeType>,
): CategoriesTreeType => {
  let mappedCateogryList = [];
  toMapProductCategory.map((category) => {
    mappedCateogryList = categoriesList.map((categoryList) => {
      if (category.value === categoryList.value) {
        categoryList.isSelected = true;
      } else {
        if (categoryList.children && categoryList.children.length) {
          (function handleSelectedCategories(categoryList) {
            categoryList.map((child) => {
              if (child.value === category.value) child.isSelected = true;
              else if (child.children && child.children.length) {
                handleSelectedCategories(child.children);
              }
            });
          })(categoryList.children);
        }
      }

      return categoryList;
    });
  });

  return mappedCateogryList;
};
export const defaultFeatureRoutes = ['/contactus', '/account/wishlist'];

export const getPageMetaTags = ({
  location,
  storeName,
  categoryName,
}: {
  storeName: string;
  location: Location;
  categoryName?: string;
}): Array<{ content: string; name: string }> => {
  switch (true) {
    case location.pathname == RouteMap.ABOUT:
      return getAboutUsPageMetaTags(storeName);
    case location.pathname === RouteMap.CONTACT:
      return getContactUsMetaTags(storeName);
    case location.pathname === RouteMap.FAQ:
      return getFaqsMetaTags(storeName);
    case location.pathname === '/policy/privacy':
      return getPrivacyPolicyMetaTags(storeName);
    case location.pathname === '/policy/tnc':
      return getTermsAndConditionsMetaTags(storeName);
    case location.pathname === '/policy/cancellation':
      return getCancelAndRefundMetaTags(storeName);
    case location.pathname === '/policy/shipping':
      return getShippingAndDeliveryMetaTags(storeName);
    case location.pathname.includes('category'):
      return getCategoryMetaTags(storeName, categoryName);
    default:
      break;
  }
};
export const getStorePagetitle = ({
  location,
  storeName,
  customMetaTags,
}: {
  location: Location;
  storeName: string;
  customMetaTags: Array;
}): string => {
  const metaTitle = customMetaTags?.customMetaTags?.results?.find((customMetaTag) => customMetaTag.name === 'title')
    ?.content;

  switch (location.pathname) {
    case RouteMap.ABOUT:
    case '/aboutus/':
      return metaTitle || `About Us | ${storeName}`;
    case RouteMap.CONTACT:
      return metaTitle || `Contact Us | ${storeName}`;
    case RouteMap.FAQ:
    case '/faqs/':
      return metaTitle || `Faq | ${storeName}`;
    case '/policy/privacy':
      return metaTitle || `Privacy Policy | ${storeName}`;
    case '/policy/tnc':
      return metaTitle || `Terms & Conditions | ${storeName}`;
    case '/policy/cancellation':
      return metaTitle || `Cancellation Policy | ${storeName}`;
    case '/policy/shipping':
      return metaTitle || `Shipping Policy | ${storeName}`;
    default:
      break;
  }
};

export const handleDefaultLayout = (
  themeName: string,
  section: string,
  sectionLayout: Array<Record<string, unknown>>,
  type: string,
): string => {
  const layoutName = defaultLayouts?.[themeName]?.[section];
  const layouts = sectionLayout?.filter((layout) => layout.section_type === type)[0]?.layouts;
  const defaultLayout = layouts?.filter((layout) => layout?.name === layoutName)[0];
  return defaultLayout;
};

export const hasNoTextContent = (html: HTMLElement): boolean => {
  const tempElem = document.createElement('div');
  tempElem.innerHTML = html;
  const hasNoTextContent = tempElem.textContent.trim().length === 0;
  tempElem.remove();
  return hasNoTextContent;
};

const isChidrentExist = (category) => {
  return category.children && category.children.length > 0;
};

export const handleParentLavelCategory = (
  categoriesTree: Array<CategoriesTreeProps>,
  selectedCounts = [],
): CategoriesTreeProps => {
  categoriesTree?.length &&
    categoriesTree?.map((category: CategoriesTreeProps) => {
      selectedCounts.push(category);
      if (isChidrentExist(category)) {
        handleParentLavelCategory(category.children, selectedCounts);
      }
    });
  return selectedCounts;
};

export const handleResizeImage = (image: string): string => {
  const layoutImage = getLambdaLink({
    link: image as string,
    width: LAYOUT_IMAGE_WIDTH,
    height: LAYOUT_IMAGE_HEIGHT,
  });
  return layoutImage;
};

export const getMSClarityScript = (projectId: () => string): string => {
  return `<script type="text/javascript">
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${projectId}");
  </script>`;
};

export const builderErrorToastMessages = ({
  statusCode,
  errors = '',
  addToast,
}: builderErrorToastMessagesProps): void => {
  if (statusCode === 403 || statusCode === 402) {
    addToast({
      message: errorFromAPIToast(errors),
    });
  } else if (statusCode === 400) {
    addToast({
      message: formErrorMessageToast(errors),
    });
  } else {
    addToast({
      message: somethingWentWrongFailureMessageToast(),
    });
  }
};

export const maskEmail = (email: string): string => {
  const maskedEmail = email.replace(/([^@\.])/g, '*').split('');
  let previous = '';
  for (let i = 0; i < maskedEmail.length; i++) {
    if (i <= 1 || previous == '.' || previous == '@') {
      maskedEmail[i] = email[i];
    }
    previous = email[i];
  }
  localStorage.setItem(maskedEmail.join(''), email);
  return maskedEmail.join('');
};

export const unMaskEmail = (maskedEmail: string): string => {
  return localStorage.getItem(maskedEmail);
};

export const getIsHomePageURL = (customPageName: string): boolean => {
  return customPageName === HOME;
};

export const getCurrentSelectedPage = (customPages: CustomPagesListType, customPageId: string): StorePageNameTypes => {
  return customPages?.customPagesList.find((item) =>
    getIsHomePageURL(customPageId) ? item.value === HOME : item.customPageId === Number(customPageId),
  );
};

export const getCustomPageURLName = ({ customPageId }: PageType): string => {
  return getIsHomePageURL(customPageId) ? 'home' : `custom-page/${customPageId}`;
};

export const getCustomPageName = ({ customPageId }: PageType): string => {
  return getIsHomePageURL(customPageId) ? null : customPageId;
};

export const getPageType = (customPageName: string): string => {
  return customPageName === 'Home' || customPageName === 'About Us' || customPageName === 'FAQ'
    ? customPageName
    : 'Custom Page';
};
export const getCustomPageSectionURL = (currentSelectedPage: StorePageNameTypes): string => {
  const { value, customPageId } = currentSelectedPage;
  return RouteMap.BUILDER_CUSTOM_PAGE_SECTION.replace(':customPageId', getIsHomePageURL(value) ? value : customPageId);
};

export const getAddNewSectionURL = ({ customPageId }: PageType): string => {
  return RouteMap.BUILDER_ADD_NEW.replace(':customPageId', customPageId);
};

export const isNewCardFlow = (): string => {
  if (isBrowser()) {
    return new URLSearchParams(window.location.search).get('newFlow');
  }
};
