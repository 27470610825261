import { getData, URLs } from '@common/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import * as PageScript from '../../models/PageScript';
import {
  fetchPageScriptsFailure,
  fetchPageScriptsRequest,
  fetchPageScriptsStorePagesSuccess,
  fetchPageScriptsSuccess,
  PageScriptsRequestPayloadType,
} from './slice';

function* fetchPageScriptsSaga({ payload: { queryParams } }: PayloadAction<PageScriptsRequestPayloadType>) {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_PAGE_SCRIPTS, params: queryParams }));
    data.results = data.results?.map((pageScript) => PageScript.processResponse(pageScript));
    yield put(fetchPageScriptsSuccess({ response: data, queryParams }));
  } catch (e) {
    yield put(fetchPageScriptsFailure('Request failed with: ' + e));
  }
}

function* fetchPageScriptsStorePagesSaga() {
  try {
    let { data } = yield call(() => getData({ url: URLs.GET_PAGE_SCRIPTS_STORE_PAGES }));
    data = PageScript.processStorePagesResponse(data);
    yield put(fetchPageScriptsStorePagesSuccess(data));
  } catch (e) {
    // yield put(fetchPageScriptsStorePagesFailure('Request failed with: ' + e));
  }
}

export default function* pageScriptsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchPageScriptsRequest.type, fetchPageScriptsSaga);
  yield takeEvery(fetchPageScriptsRequest.type, fetchPageScriptsStorePagesSaga);
}
