import React, { useEffect, useRef, useState } from 'react';

export interface LoadMoreProps {
  sectionId: number;
}

const ScrollRef: React.FC<LoadMoreProps> = ({ sectionId }) => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const target = ref.current;
    const options = {
      threshold: 1.0,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(target);
        }
      });
    }, options);

    observer.observe(target);
  }, []);
  return (
    <div
      ref={ref}
      id={`${sectionId}-scroll-animation`}
      className={`section-scroll-animation${isVisible ? '-visible' : ''}`}
    ></div>
  );
};

export default ScrollRef;
