import InnerHTML from 'dangerously-set-html-content';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from '../../common/store';
import { MDPAppScriptsSliceType } from '../../common/store/mdpAppScripts/slice';

interface MDPInjectScriptProps {
  mdpAppScripts: MDPAppScriptsSliceType;
  pageType: number;
}

const MDPInjectScript: React.FC<MDPInjectScriptProps> = ({ mdpAppScripts, pageType }) => {
  const [value, setValue] = useState(0);
  const html = mdpAppScripts.mdpAppScripts?.scripts
    ?.filter((appScript) => appScript.pageType === pageType)
    .map((appscript) => appscript.script)
    .join('\n');

  const comment = `<!-- ${value} -->`;
  const location = useLocation();
  useEffect(() => {
    setValue(value + 1);
  }, [location?.pathname, location?.search]);

  return html ? <InnerHTML html={comment + html} /> : null;
};

export default connect(({ mdpAppScripts }: RootState) => ({ mdpAppScripts }))(MDPInjectScript);
