import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as MDPAppScript from '../../models/MDPAppScript';

export interface MDPAppScriptsResponseType {
  scripts: MDPAppScript.default[];
}

export interface MDPAppScriptsResponsePayloadType {
  response: MDPAppScriptsResponseType;
}

export interface MDPAppScriptsSliceType {
  mdpAppScripts?: MDPAppScriptsResponseType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const MDPAppScriptsSlice = createSlice({
  name: 'mdpAppScripts',
  initialState: <MDPAppScriptsSliceType>{
    mdpAppScripts: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchMDPAppScriptsRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.mdpAppScripts = null;
      state.error = '';
    },
    fetchMDPAppScriptsSuccess(
      state,
      { payload: { response: appScripts } }: PayloadAction<MDPAppScriptsResponsePayloadType>,
    ) {
      state.mdpAppScripts = appScripts;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchMDPAppScriptsFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
      state.mdpAppScripts = null;
    },
  },
});

export const {
  fetchMDPAppScriptsSuccess,
  fetchMDPAppScriptsRequest,
  fetchMDPAppScriptsFailure,
} = MDPAppScriptsSlice.actions;

export default MDPAppScriptsSlice.reducer;
