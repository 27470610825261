// import { useConstructor } from '@common/hooks/useConstructor';
// import { RouteMap } from '@common/RouteMap';
import { RootState } from '@common/store';
import { CustomMetaTagsSliceType } from '@common/store/seo/customMetaTags/slice';
import { fetchCustomMetaTagsRequest } from '@common/store/seo/customMetaTags/slice';
import { getCustomMetaTagsParams, getPageMetaTags, getStorePagetitle, getTrailingSlashUrl } from '@common/utils';
import { getStoreUrl } from '@common/utils/constants';
// import { getCustomMetaTagsParams } from '@common/utils/seo';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
// import { matchPath } from 'react-router-dom';
// import { matchPath, useLocation, useParams } from 'react-router-dom';

// import { matchPath, useLocation } from 'react-router-dom';

export interface CustomMetaTagsProps {
  customMetaTags: CustomMetaTagsSliceType;
  fetchCustomMetaTagsRequest: typeof fetchCustomMetaTagsRequest;
}

// const routeToParamsMapping = (() => {
//   const nullMapping = {};
//   for (const route in RouteMap) {
//     nullMapping[RouteMap[route]] = { type: -1 };
//   }
//   const mapping = {
//     ...nullMapping,
//     ...{
//       [RouteMap.ROOT]: { type: 4, others: 'home' },
//       [RouteMap.HOME]: { type: 4, others: 'home' },
//       [RouteMap.SHOP_ALL]: { type: 4, others: 'shop' },
//       [RouteMap.ABOUT]: { type: 4, others: 'aboutus' },
//       [RouteMap.CONTACT]: { type: 4, others: 'contactus' },
//       [RouteMap.FAQ]: { type: 4, others: 'faq' },

//       [RouteMap.SEO_POLICY_PRIVACY]: { type: 4, others: 'privacy_policy' },
//       [RouteMap.SEO_POLICY_CANCELLATION_AND_REFUND]: { type: 4, others: 'cnrpolicy' },
//       [RouteMap.SEO_POLICY_TERMS_AND_CONDITIONS]: { type: 4, others: 'tnc' },
//       [RouteMap.SEO_POLICY_SHIPPING_AND_DELIVERY]: { type: 4, others: 'sndpolicy' },

//       '/policy/privacy': { type: 4, others: 'privacy_policy' },
//       '/policy/cancellation': { type: 4, others: 'cnrpolicy' },
//       '/policy/tnc': { type: 4, others: 'tnc' },
//       '/policy/shipping': { type: 4, others: 'sndpolicy' },

//       [RouteMap.CATEGORY_1]: { type: 2 },
//       [RouteMap.CATEGORY_2]: { type: 2 },
//     },
//   };
//   return mapping;
// })();

// const SEO: React.FunctionComponent<CustomMetaTagsProps> = ({ fetchCustomMetaTagsRequest, customMetaTags }) => {
//   const location = useLocation();
//   let customTagsParams = null;
//   const { categoryId } = useParams();
//   for (const key in routeToParamsMapping) {
//     const isMatch = matchPath(location.pathname, { path: key, exact: true });
//     if (isMatch) {
//       customTagsParams = routeToParamsMapping[key];
//     }
//   }
//   useConstructor(() => {
//     if (customTagsParams && customTagsParams.type !== -1) {
//       if (customTagsParams.type === 2) {
//         customTagsParams.category = categoryId;
//       }
//       fetchCustomMetaTagsRequest(customTagsParams);
//     }
//     if (!customTagsParams) {
//       fetchCustomMetaTagsRequest({ type: 3, custompage: location.pathname.replace('/p/', '/') });
//     }
//   }, []);
//   return (
//     <Helmet>
//       <link rel="canonical" href={`${getStoreUrl().slice(0, -1)}${getTrailingSlashUrl(location.pathname)}`} />
//       {customMetaTags.customMetaTags?.results?.map((result, index) => (
//         <meta key={index} name={result.name} content={result.content} />
//       ))}
//     </Helmet>
//   );
// };

class SEO extends React.Component<CustomMetaTagsProps> {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { location, fetchCustomMetaTagsRequest } = this.props;
    const customTagsParams = getCustomMetaTagsParams(location.pathname);
    fetchCustomMetaTagsRequest(customTagsParams);
  }
  render() {
    const { customMetaTags, location, storeInfo } = this.props;
    const pageMetaTags = getPageMetaTags({ location, storeName: storeInfo?.storeInfo?.storename });
    const storePageTitle = customMetaTags?.isFetched
      ? getStorePagetitle({
          location,
          storeName: storeInfo?.storeInfo?.storename,
          customMetaTags,
        })
      : null;
    return (
      <Helmet>
        {storePageTitle ? <title>{storePageTitle}</title> : null}
        <link rel="canonical" href={`${getStoreUrl().slice(0, -1)}${getTrailingSlashUrl(location.pathname)}`} />
        {customMetaTags.customMetaTags?.results?.map((result, index) => (
          <meta key={index} name={result.name} content={result.content} />
        ))}
        {pageMetaTags?.map((result, index) => (
          <meta key={index} name={result.name} content={result.content} />
        ))}
      </Helmet>
    );
  }
}

export default withRouter(
  connect(
    ({ storeInfo, seo: { customMetaTags } }: RootState) => ({
      customMetaTags,
      storeInfo,
    }),
    {
      fetchCustomMetaTagsRequest,
    },
  )(SEO),
);
