import ThemeCustomisation from '@common/models/ThemeCustomisation';
import { isBrowser } from '@common/utils';
// import { ThemeCustomisationSliceType } from '@common/store/themeCustomisation/slice';
// import InnerHTML from 'dangerously-set-html-content';
import React from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';

// import InjectScript from '../../../lib-components/InjectScript';

interface ThemeCustomisationProps {
  customTheme: ThemeCustomisation;
}

const ThemeCustomisationComp: React.FC<ThemeCustomisationProps> = ({ customTheme }) => {
  const FontConfig = [
    {
      key: 'fontSectionHeading',
      attributeName: 'section-heading',
    },
    {
      key: 'fontPragraphText',
      attributeName: 'para-text',
    },
  ];
  const ColorConfig = [
    {
      key: 'colorFooterText',
      attributeName: 'footer-text',
      style: 'color',
    },
    {
      key: 'colorFooterLink',
      attributeName: 'footer-links',
      style: 'color',
    },
    {
      key: 'colorFooterHeading',
      attributeName: 'footer-heading',
      style: 'color',
    },
    // {
    //   key: 'colorFooterBody',
    //   attributeName: 'footer-body',
    //   style: 'color',
    // },

    {
      key: 'colorFooterBG',
      attributeName: 'footer-bg',
      style: 'background-color',
    },
    {
      key: 'colorFooterBG',
      attributeName: 'footer-bg',
      style: 'background',
    },
    {
      key: 'colorHeaderBG',
      attributeName: 'header-bg',
      style: 'background-color',
    },
    {
      key: 'colorHeaderBG',
      attributeName: 'header-bg',
      style: 'background',
    },

    {
      key: 'colorHeaderLinks',
      attributeName: 'header-links',
      style: 'color',
    },
    {
      key: 'colorPageBG',
      attributeName: 'page-bg',
      style: 'background-color',
    },
    {
      key: 'colorPageHeadings',
      attributeName: 'page-heading',
      style: 'color',
    },
    {
      key: 'colorPageBodyText',
      attributeName: 'page-body-text',
      style: 'color',
    },

    {
      key: 'colorBannerHeading',
      attributeName: 'banner-heading',
      style: 'color',
    },
    {
      key: 'colorBannerSubHeading',
      attributeName: 'banner-subHeading',
      style: 'color',
    },
  ];
  //test code start

  const ButtonConfig = [
    //Banner Button
    {
      key: 'colorBannerButtonBG',
      attributeName: 'banner-button',
      style: 'background-color',
    },
    {
      key: 'colorBannerButtonText',
      attributeName: 'banner-button',
      style: 'color',
    },
    {
      key: 'colorBannerButtonBorder',
      attributeName: 'banner-button',
      style: 'border-color',
    },
    //wishlist button
    {
      key: 'colorCartButtonWhishlistBG',
      attributeName: 'wishlist-button',
      style: 'background-color',
    },
    {
      key: 'colorCartButtonWhishlistText',
      attributeName: 'wishlist-button',
      style: 'color',
    },
    {
      key: 'colorCartButtonWhishlistBorder',
      attributeName: 'wishlist-button',
      style: 'border-color',
    },
    //Buy now Button
    {
      key: 'colorCartButtonBuyNowBG',
      attributeName: 'buyNow-button',
      style: 'background-color',
    },
    {
      key: 'colorCartButtonBuyNowText',
      attributeName: 'buyNow-button',
      style: 'color',
    },
    {
      key: 'colorCartButtonBuyNowBorder',
      attributeName: 'buyNow-button',
      style: 'border-color',
    },
    //Add to cart Button
    {
      key: 'colorCartButtonAddToCartBG',
      attributeName: 'addToCart-button',
      style: 'background-color',
    },
    {
      key: 'colorCartButtonAddToCartText',
      attributeName: 'addToCart-button',
      style: 'color',
    },
    {
      key: 'colorCartButtonAddToCartBorder',
      attributeName: 'addToCart-button',
      style: 'border-color',
    },
    //Page Button
    {
      key: 'colorPageButtonBG',
      attributeName: 'page-button',
      style: 'background-color',
    },
    {
      key: 'colorPageButtonText',
      attributeName: 'page-button',
      style: 'color',
    },
    {
      key: 'colorPageButtonBorder',
      attributeName: 'page-button',
      style: 'border-color',
    },
  ];
  const customButtons = ButtonConfig.map((config) => {
    if (customTheme[config.key]) {
      return `[Button-customisation = ${config.attributeName}]{
        ${config.style}:${customTheme[config.key]} !important;
      }`;
    }
  }).join('');

  //test code end

  const customColors = ColorConfig.map((config) => {
    if (customTheme[config.key]) {
      return `[color-customisation = ${config.attributeName}]{
        ${config.style}:${customTheme[config.key]} !important;
      }`;
    }
  }).join('');

  const customFonts = FontConfig.map((fontConfig) => {
    if (customTheme[fontConfig.key] && customTheme[fontConfig.key].fontURL) {
      if (isBrowser()) {
        const font = new FontFace(fontConfig.key, `url('${customTheme[fontConfig.key].fontURL}')`);
        font
          .load()
          .then((loaded_face) => {
            document.fonts.add(loaded_face);
          })
          .catch((error) => {
            console.log('ERROR', error);
          });
      }
      return `
      @font-face {
        font-family: ${fontConfig.key} !important;
        src: url('${customTheme[fontConfig.key].fontURL}') format("truetype");
        font-display: swap;
      }
      [font-customisation=${fontConfig.attributeName}]{
        font-family : ${fontConfig.key} !important;
      }`;
    }
  }).join('');
  return (
    <>
      <Helmet>
        {/* <style
          dangerouslySetInnerHTML={{
            __html: `<style>${customColors || ''}${customFonts || ''} ${customButtons || ''}</style>`,
          }}
        /> */}
        {ReactHtmlParser(`<style>${customColors || ''}${customFonts || ''} ${customButtons || ''}</style>`)}
        {/* <InnerHTML html={`<style>${customColors || ''}${customFonts || ''} ${customButtons || ''}</style>`} /> */}
      </Helmet>
      {/* <InnerHTML html={`<style>${customColors || ''}${customFonts || ''} ${customButtons || ''}</style>`} /> */}
    </>
  );
};

export default ThemeCustomisationComp;
