import { getData, URLs } from '@common/api';
import * as Subdivision from '@common/models/geo/Subdivision';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import {
  fetchSubdivisionsFailure,
  fetchSubdivisionsRequest,
  FetchSubdivisionsRequestPayloadType,
  fetchSubdivisionsSuccess,
} from './slice';

function* fetchSubdivisionsSaga({
  payload: { storeKey, queryParams },
}: PayloadAction<FetchSubdivisionsRequestPayloadType>) {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_SUBDIVISIONS, params: queryParams }));
    data.results = data.results?.map((result) => Subdivision.processResponse(result));
    yield put(fetchSubdivisionsSuccess({ storeKey, queryParams, subdivisions: data }));
  } catch (e) {
    yield put(fetchSubdivisionsFailure({ storeKey, error: 'Request failed with: ' + e }));
  }
}

export default function* subdivisionsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchSubdivisionsRequest.type, fetchSubdivisionsSaga);
}
