export default interface Review {
  id: number;
  created: string;
  customerId: number;
  customerName: string;
  rating: number;
  review: string;
}

export const processResponse = (review: Record<string, never>): Review => {
  return {
    id: review.id,
    created: new Date(review.created).toDateString(),
    customerId: review.customer_id,
    customerName: review.customer_name,
    rating: review.rating,
    review: review.review,
  };
};

export const processPageNo = (next: string): number | string => {
  if (next) {
    const pageIndex = next.indexOf('page=');
    let pageNoLastIndex = pageIndex + 6;

    while (Number(next.slice(pageIndex + 5, pageNoLastIndex))) {
      pageNoLastIndex++;
    }
    return Number(next.slice(pageIndex + 5, pageNoLastIndex - 1));
  } else {
    return next;
  }
};
