import { getData, URLs } from '@common/api';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchCustomPagesFailure, fetchCustomPagesRequest, fetchCustomPagesSuccess } from './slice';

function* fetchCustomPagesSaga() {
  try {
    const data = yield call(() =>
      getData({
        url: URLs.GET_CUSTOM_PAGES,
      }),
    );
    yield put(fetchCustomPagesSuccess(data.data));
  } catch (e) {
    yield put(fetchCustomPagesFailure('Request failed with: ' + e));
  }
}

export default function* customPagesSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchCustomPagesRequest.type, fetchCustomPagesSaga);
}
