import { getData, URLs } from '@common/api';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchCountriesFailure, fetchCountriesRequest, fetchCountriesSuccess } from './slice';

function* fetchCountriesSaga() {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_COUNTRIES }));
    yield put(fetchCountriesSuccess(data.results));
  } catch (e) {
    yield put(fetchCountriesFailure('Request failed with: ' + e));
  }
}

export default function* countriesSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchCountriesRequest.type, fetchCountriesSaga);
}
