import { SectionEnum } from './SectionEnum';

export interface Products {
  type: SectionEnum.PRODUCTS;
  name: 'latest_product' | 'featured_product';
  heading: string;
}

export const productsProcessResponse = (products: Record<string, never>): Products => {
  return {
    type: products.type,
    name: products.name,
    heading: products.heading,
  };
};
