import { getData, URLs } from '@common/api';
import * as StoreInfo from '@common/models/StoreInfo';
import { isBrowser } from '@common/utils';
import { trackEvent } from '@common/utils/analytics';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchStoreInfoFailure, fetchStoreInfoRequest, fetchStoreInfoSuccess } from './slice';

const handleViewedOnlineStoreEvent = (data) => {
  setTimeout(() => {
    const queryDict = new URLSearchParams(window.location.search);
    const viewMode = queryDict.get('view');
    const perfEntries = performance.getEntriesByType('navigation');
    const [p] = perfEntries;
    if (!location.pathname.startsWith('/product/') && !/^\/[\w-]+\/p\d+/.test(location.pathname)) {
      trackEvent('Viewed Online Store', {
        self_flag: queryDict.get('merchant') === 'true',
        store_username: data?.username,
        seller_account_id: data?.accountId,
        store_account_id: data?.accountId,
        store_ref: '',
        num_products: '',
        is_mojocommerce_store: true,
        ...(viewMode === 'mobile' && { view_mode: 'mobile' }),
        ...(p && p.toJSON()),
      });
    }
  }, 500);
};

function* fetchStoreInfoSaga() {
  try {
    let { data } = yield call(() => getData({ url: URLs.GET_STORE_INFO }));
    data = StoreInfo.processResponse(data);
    yield put(fetchStoreInfoSuccess(data));

    if (isBrowser()) {
      if (document.readyState === 'complete') {
        handleViewedOnlineStoreEvent(data);
      } else {
        document.onreadystatechange = function () {
          if (document.readyState === 'complete') {
            handleViewedOnlineStoreEvent(data);
          }
        };
      }
    }
  } catch (e) {
    yield put(fetchStoreInfoFailure('Request failed with: ' + e));
  }
}

export default function* sectionsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchStoreInfoRequest.type, fetchStoreInfoSaga);
}
