import { getData, URLs } from '@common/api';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchAutoFillAddressesFailure, fetchAutoFillAddressesRequest, fetchAutoFillAddressesSuccess } from './slice';

function* fetchAutoFillAddressesSaga() {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_AUTO_FILL_ADDRESSES }));
    yield put(fetchAutoFillAddressesSuccess(data));
  } catch (e) {
    yield put(fetchAutoFillAddressesFailure('Request failed with: ' + e));
  }
}

export default function* autoFillAddressesSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchAutoFillAddressesRequest.type, fetchAutoFillAddressesSaga);
}
