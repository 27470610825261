import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as ShopAttribute from '../../models/ShopAttribute';

export interface ShopAttributesResponseType {
  count: number;
  next: string | null;
  previous: string | null;
  results: ShopAttribute.default[];
}

export interface ShopAttributesResponsePayloadType {
  response: ShopAttributesResponseType;
  queryParams: ShopAttributesQueryParams;
}

export interface ShopAttributesQueryParams {
  limit: number;
  page: number;
}
export interface ShopAttributesRequestPayloadType {
  queryParams: ShopAttributesQueryParams;
}

export const getShopAttributesQueryParamsInitialState = (): ShopAttributesQueryParams => ({
  limit: 500,
  page: 1,
});

export interface ShopAttributesSliceType {
  pagination: {
    currentPage: number;
    lastPage: number;
    multiplier: number;
  };
  currentQueryParams?: ShopAttributesQueryParams;
  shopAttributes?: ShopAttributesResponseType;
  categoryId: number;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const shopAttributesSlice = createSlice({
  name: 'shopAttributes',
  initialState: <ShopAttributesSliceType>{
    pagination: {
      currentPage: 1,
      lastPage: 1,
      multiplier: 1,
    },
    shopAttributes: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchShopAttributesRequest(state, { payload: { queryParams } }: PayloadAction<ShopAttributesRequestPayloadType>) {
      state.isFetching = true;
      state.isFetched = false;
      state.shopAttributes = null;
      state.currentQueryParams = queryParams;
      state.error = '';
    },
    fetchShopAttributesSuccess(
      state,
      { payload: { response: shopAttributes, queryParams } }: PayloadAction<ShopAttributesResponsePayloadType>,
    ) {
      state.shopAttributes = shopAttributes;
      state.isFetching = false;
      state.isFetched = true;
      state.pagination = {
        currentPage: queryParams.page,
        lastPage: parseInt(`${(shopAttributes.count - 1) / queryParams.limit + 1}`),
        multiplier: queryParams.limit,
      };
    },
    fetchShopAttributesFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
      state.shopAttributes = null;
    },
  },
});

export const {
  fetchShopAttributesSuccess,
  fetchShopAttributesRequest,
  fetchShopAttributesFailure,
} = shopAttributesSlice.actions;

export default shopAttributesSlice.reducer;
