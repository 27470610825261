import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Product from '../../models/Product';
import { fetchProductDetailsSuccess } from '../productDetails/slice';

export interface RecentlyViewedTime {
  recentlyViewedTime: string;
}

export interface RecentlyViewedSliceType {
  products: Record<number, Product & RecentlyViewedTime>;
}

const addProductToRecentlyViewedFn = (state, { payload: product }: PayloadAction<Product>) => {
  delete state.products[product.id];
  state.products[product.id] = {
    ...product,
    recentlyViewedTime: new Date().toISOString(),
  };
};

const recentlyViewedSlice = createSlice({
  name: 'recentlyViewed',
  initialState: <RecentlyViewedSliceType>{
    products: {},
  },
  reducers: {
    addProductToRecentlyViewed: addProductToRecentlyViewedFn,
  },
  extraReducers: {
    [fetchProductDetailsSuccess.type]: addProductToRecentlyViewedFn,
  },
});

export const { addProductToRecentlyViewed } = recentlyViewedSlice.actions;

export default recentlyViewedSlice.reducer;
