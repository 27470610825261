import Reward from '@common/components/Reward';
import { RootState } from '@common/store';
import {
  addProductToCart,
  CartSliceType,
  removeProductFromCart,
  subtractProductFromCart,
} from '@common/store/cart/slice';
import { FeaturesSliceType } from '@common/store/features/slice';
import { fetchHomeRequest, HomeSliceType } from '@common/store/home/slice';
import {
  fetchProductsRequest,
  getProductQueryParamsInitialState,
  ProductsSliceType,
  resetProductsStore,
} from '@common/store/products/slice';
import { RecentlyViewedSliceType } from '@common/store/recentlyViewed/slice';
import {
  fetchReferralCodeDetailsRequest,
  ReferralSliceType,
  resetReferralCodeDetails,
} from '@common/store/referral/slice';
import { MetaTagsSliceType } from '@common/store/seo/metaTags/slice';
import { SliderSliceType } from '@common/store/slider/slice';
import { StoreInfoSliceType } from '@common/store/storeInfo/slice';
import { TestimonialsSliceType } from '@common/store/testimonials/slice';
import { addToast } from '@common/store/toasts/slice';
import {
  fetchMoreUserSectionsRequest,
  fetchUserSectionsRequest,
  UserSectionsSliceType,
} from '@common/store/userSections/slice';
import { PRODUCTS_KEY } from '@common/utils/constants';
import PageLayout from '@Core/components/PageLayout';
import Error from '@lib/Error';
import InjectScript from '@lib/InjectScript';
import Loading from '@lib/Loading';
import React, { PureComponent, RefObject } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { MDPPageType } from '../../../../common/models/MDPAppScript';
import { ERROR_TEXT } from '../../../../common/utils/errorContent';
import { getQueryParamsFromURL } from '../../../../common/utils/params';
import MDPInjectScript from '../../../../lib-components/MDPInjectScript';
import UserSection from '../UserSection';

interface HomeProps {
  products: Record<string, ProductsSliceType>;
  cart: CartSliceType;
  recentlyViewed: RecentlyViewedSliceType;
  home: HomeSliceType;
  userSections: UserSectionsSliceType;
  slider: SliderSliceType;
  storeInfo: StoreInfoSliceType;
  fetchProductsRequest: typeof fetchProductsRequest;
  fetchHomeRequest: typeof fetchHomeRequest;
  fetchUserSectionsRequest: typeof fetchUserSectionsRequest;
  fetchMoreUserSectionsRequest: typeof fetchMoreUserSectionsRequest;
  resetProductsStore: typeof resetProductsStore;
  addProductToCart: typeof addProductToCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  removeProductFromCart: typeof removeProductFromCart;
  addToast: typeof addToast;
  fetchReferralCodeDetailsRequest: typeof fetchReferralCodeDetailsRequest;
  resetReferralCodeDetails: typeof resetReferralCodeDetails;
  features: FeaturesSliceType;
  testimonials: TestimonialsSliceType;
  referral: ReferralSliceType;
  metaTags: MetaTagsSliceType;
}

class Home extends PureComponent<HomeProps> {
  private allProductsRef: RefObject<HTMLDivElement>;
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const queryParam = getQueryParamsFromURL();
    if (queryParam?.referrer_code) {
      this.props.fetchReferralCodeDetailsRequest(queryParam?.referrer_code);
    } else {
      this.props.resetReferralCodeDetails();
    }
    if (!this.props.products[PRODUCTS_KEY.ALL]?.list?.results.length) {
      this.props.resetProductsStore(PRODUCTS_KEY.ALL);
      this.props.fetchProductsRequest({
        storeKey: PRODUCTS_KEY.ALL,
        queryParams: {
          ...getProductQueryParamsInitialState(),
        },
      });
    }
  }

  fetchMoreUserSections = () => {
    const { fetchMoreUserSectionsRequest, userSections } = this.props;
    if (!userSections.isFetching) {
      fetchMoreUserSectionsRequest();
    }
  };

  render() {
    const { userSections, metaTags } = this.props;
    // const testimonialLayout = this.props.home.sections?.find((section) => section.type === SectionEnum.TESTIMONIAL);

    return (
      <PageLayout>
        {metaTags?.isFetched && !metaTags?.error ? (
          <Helmet>
            <meta name="description" content={metaTags?.metaTags?.description} />
            <meta property="og:description" content={metaTags?.metaTags?.description} />
            <meta name="keywords" content={metaTags?.metaTags?.keywords} />
          </Helmet>
        ) : null}
        <MDPInjectScript pageType={MDPPageType.STORE_PAGE} />
        <InjectScript others="home" />
        {this.props.referral.isReferralCodeFetched && <Reward referral={this.props.referral} />}
        {!userSections.isFetched && <Loading />}
        {userSections.isFetched && userSections.error && <Error message={ERROR_TEXT.HOME} />}
        <UserSection
          userSections={userSections}
          addToast={this.props.addToast}
          cart={this.props.cart}
          storeInfo={this.props.storeInfo}
          addProductToCart={this.props.addProductToCart}
          subtractProductFromCart={this.props.subtractProductFromCart}
          fetchMoreUserSectionsRequest={this.fetchMoreUserSections}
        />
      </PageLayout>
    );
  }
}

export default connect(
  ({
    products,
    features,
    cart,
    recentlyViewed,
    home,
    userSections,
    slider,
    storeInfo,
    testimonials,
    referral,
    seo: { metaTags },
  }: RootState) => ({
    storeInfo,
    products,
    features,
    cart,
    recentlyViewed,
    home,
    userSections,
    slider,
    testimonials,
    referral,
    metaTags,
  }),
  {
    fetchProductsRequest,
    fetchHomeRequest,
    fetchUserSectionsRequest,
    fetchMoreUserSectionsRequest,
    resetProductsStore,
    addProductToCart,
    subtractProductFromCart,
    removeProductFromCart,
    addToast,
    fetchReferralCodeDetailsRequest,
    resetReferralCodeDetails,
  },
)(Home);
