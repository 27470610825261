import { getLambdaLink, isMobile } from '@common/utils';

import DEFAULT_CATEGORY_IMAGE from '../images/DefaultCategoryImage.png';
import { shouldUseCustomNavigation } from '../utils';

interface Category {
  id: number;
  name: string;
  slug: string;
  storeLink: string;
  homePageLambdaImage: string | null;
  image: string | null;
  additionalImage: string | null;
  label: string | null;
  isFeatured: boolean;
  parentCategory?: Category | null;
  order: number;
  childCategories?: Category[];
  description: string;
  pageTitle: string;
  created: string;
  modified: string;
}

interface Dimensions {
  desktop: {
    width: number;
    height: number;
  };
  mobile: {
    width: number;
    height: number;
  };
}

const getCS3DesktopDimentions = ({
  index,
  length,
}: {
  index: number;
  length: number;
}): { width: number; height: number } => {
  if (length <= 2) {
    switch (index) {
      case 0:
        return { width: 698, height: 300 };
      case 1:
        return { width: 488, height: 300 };
    }
  }
  if (length === 3) {
    switch (index) {
      case 0:
        return { width: 698, height: 564 };
      case 1:
      case 2:
        return { width: 488, height: 270 };
    }
  }
  if (length === 4) {
    switch (index) {
      case 0:
      case 2:
        return { width: 698, height: 270 };
      case 1:
      case 3:
        return { width: 488, height: 270 };
    }
  }
  if (length === 5) {
    switch (index) {
      case 0:
      case 2:
        return { width: 698, height: 360 };
      case 1:
      case 3:
      case 4:
        return { width: 488, height: 230 };
    }
  }
  return { width: 0, height: 0 };
};
const getCS4DesktopDimentions = ({
  index,
  length,
}: {
  index: number;
  length: number;
}): { width: number; height: number } => {
  if (length <= 2) {
    switch (index) {
      case 0:
        return { width: 756, height: 300 };
      case 1:
        return { width: 444, height: 300 };
    }
  }
  if (length === 3) {
    switch (index) {
      case 0:
        return { width: 756, height: 554 };
      case 1:
      case 2:
        return { width: 444, height: 270 };
    }
  }
  if (length === 4) {
    switch (index) {
      case 0:
      case 2:
        return { width: 756, height: 270 };
      case 1:
      case 3:
        return { width: 444, height: 270 };
    }
  }
  if (length >= 5) {
    switch (index) {
      case 0:
      case 2:
        return { width: 756, height: 362 };
      case 1:
      case 3:
      case 4:
        return { width: 444, height: 236 };
      default:
        return { width: 0, height: 0 };
    }
  }
};
const getCS25DesktopDimentions = ({
  index,
  length,
}: {
  index: number;
  length: number;
}): { width: number; height: number } => {
  if (length <= 2) {
    return { width: 600, height: 600 };
  }
  if (length === 3) {
    switch (index) {
      case 0:
        return { width: 600, height: 600 };
      case 1:
      case 2:
        return { width: 286, height: 596 };
    }
  }
  if (length > 3) {
    if (index === 0) return { width: 600, height: 600 };
    return { width: 300, height: 300 };
  }
};
const getCS19DesktopDimentions = ({
  index,
}: // length,
{
  index: number;
  length: number;
}): { width: number; height: number } => {
  switch (index) {
    case 0:
    case 1:
      return { width: 600, height: 443 };
    case 2:
      return { width: 600, height: 903 };
    default:
      break;
  }
};

export const categoryLayoutDimensions = {
  CS1: {
    mobile: { width: 536, height: 336 },
    desktop: { width: 588, height: 300 },
  },
  CS2: {
    mobile: { width: 557, height: 350 },
    desktop: { width: 588, height: 250 },
  },
  CS3: {
    mobile: { width: 501, height: 322 },
    desktop: getCS3DesktopDimentions,
  },
  CS4: {
    mobile: { width: 597, height: 420 },
    desktop: getCS4DesktopDimentions,
  },
  CS5: {
    mobile: { width: 220, height: 200 },
    desktop: { width: 280, height: 250 },
  },
  CS6: {
    mobile: { width: 278, height: 245 },
    desktop: { width: 278, height: 245 },
  },
  CS7: {
    mobile: { width: 220, height: 124 },
    desktop: { width: 280, height: 200 },
  },
  CS8: {
    mobile: { width: 220, height: 220 },
    desktop: { width: 260, height: 260 },
  },
  CS9: {
    mobile: { width: 220, height: 124 },
    desktop: { width: 280, height: 200 },
  },
  CS10: {
    mobile: { width: 208, height: 118 },
    desktop: { width: 384, height: 216 },
  },
  CS11: {
    mobile: { width: 320, height: 216 },
    desktop: { width: 352, height: 252 },
  },
  CS12: {
    mobile: { width: 283, height: 232 },
    desktop: { width: 509, height: 418 },
  },
  CS13: {
    mobile: { width: 250, height: 250 },
    desktop: { width: 280, height: 280 },
  },
  CS14: {
    mobile: { width: 512, height: 346 },
    desktop: { width: 509, height: 431 },
  },
  CS15: {
    mobile: { width: 400, height: 405 },
    desktop: { width: 413, height: 493 },
  },
  CS16: {
    mobile: { width: 333, height: 165 },
    desktop: { width: 420, height: 207 },
  },
  CS17: {
    mobile: { width: 78, height: 78 },
    desktop: { width: 180, height: 180 },
  },
  CS18: {
    mobile: { width: 512, height: 400 },
    desktop: { width: 600, height: 478 },
  },
  CS19: {
    mobile: { width: 280, height: 230 },
    desktop: getCS19DesktopDimentions,
  },
  CS20: {
    mobile: { width: 78, height: 78 },
    desktop: { width: 180, height: 180 },
  },
  CS21: {
    mobile: { width: 573, height: 955 },
    desktop: { width: 598, height: 997 },
  },
  CS22: {
    mobile: { width: 576, height: 960 },
    desktop: { width: 640, height: 1066 },
  },
  CS23: {
    mobile: { width: 130, height: 130 },
    desktop: { width: 270, height: 270 },
  },
  CS24: {
    mobile: { width: 0, height: 0 },
    desktop: { width: 0, height: 0 },
  },
  CS25: {
    mobile: { width: 500, height: 500 },
    desktop: getCS25DesktopDimentions,
  },
};

export const getCategoryDimenstions = ({
  layoutName,
  index,
  length,
}: {
  layoutName: string;
  index: number;
  length: number;
}): Dimensions => {
  const dimenstions = categoryLayoutDimensions[layoutName];
  return {
    desktop: (typeof dimenstions?.desktop === 'function'
      ? dimenstions?.desktop({ index, length })
      : dimenstions?.desktop) || { width: 0, height: 0 },
    mobile:
      typeof dimenstions?.mobile === 'function'
        ? dimenstions?.mobile({ index, length })
        : dimenstions?.mobile || { width: 0, height: 0 },
  };
};

export const getOptimsedCategoryImage = ({
  link,
  layoutName,
  index,
  length,
}: {
  link: string;
  layoutName: string;
  index: number;
  length: number;
}): string => {
  const { desktop, mobile } = getCategoryDimenstions({ layoutName, index, length });
  return getLambdaLink({ link, ...(isMobile() ? mobile : desktop) });
};

export const getShopAllCategory = (childCategories: Category[]): Category => {
  const shopAllCategories = {
    id: 0,
    name: 'Shop All',
    storeLink: '/',
    homePageLambdaImage: null,
    slug: null,
    image: null,
    additionalImage: null,
    label: 'Shop All',
    isFeatured: false,
    parentCategory: null,
    order: 0,
    description: 'Shop All Description',
    pageTitle: 'ShopAll',
    created: '',
    modified: '',
  };
  return {
    ...shopAllCategories,
    childCategories: [
      ...childCategories,
      {
        ...shopAllCategories,
        childCategories: [],
        slug: 'shop',
        storeLink: '/shop?ordering=oos,final_price',
      },
    ],
  };
};

export const getFeaturedCategories = (category: Category, featuredCategories: Category[] = []): Category[] => {
  if (category.isFeatured) {
    featuredCategories.push(category);
  }
  category.childCategories.forEach((childCategory) => {
    getFeaturedCategories(childCategory, featuredCategories);
  });

  return featuredCategories;
};

export const processResponse = (category: Record<string, never>): Category => {
  if (category == null) {
    return null;
  }
  const parentCategory = processResponse(category.parent_category);
  const childCategories = (category.child_categories as Record<string, never>[])?.map((category) => {
    return processResponse(category);
  });

  let slug = (category.name as string)
    ?.replace(/[\W_]+/g, ' ')
    .toLowerCase()
    .replace(/  +/g, ' ')
    .replace(/%/g, '')
    .trim()
    .replace(/ /g, '-');
  if (!slug) {
    slug = (category.name as string)
      ?.replace(/\s+/g, ' ')
      .toLowerCase()
      .replace(/  +/g, ' ')
      .replace(/%/g, '')
      .trim()
      .replace(/ /g, '-');
  }
  const storeLink = shouldUseCustomNavigation() ? `/${slug}/c${category.id}` : `/category/${category.id}/${slug}`;

  const homePageLambdaImage = category.image ? category.image : '';
  return {
    id: category.id || category.value,
    name: category?.name || category.label,
    homePageLambdaImage,
    image: category.image || DEFAULT_CATEGORY_IMAGE,
    storeLink,
    slug,
    additionalImage: category.additional_image,
    label: category.label,
    isFeatured: category.is_featured,
    parentCategory,
    order: category.order,
    childCategories,
    description: category.description,
    pageTitle: category.page_title,
    created: category.created,
    modified: category.modified,
  };
};
export default Category;
