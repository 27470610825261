import UserAddress from '@common/models/UserAddress';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AutoFillAddressesResponseType {
  count: number;
  next: string | null;
  previous: string | null;
  results: UserAddress[];
}

export interface AutoFillAddressesSliceType {
  addresses?: AutoFillAddressesResponseType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const autoFillAddressSlice = createSlice({
  name: 'autoFillAddresses',
  initialState: <AutoFillAddressesSliceType>{
    addresses: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchAutoFillAddressesRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.addresses = null;
      state.error = '';
    },
    fetchAutoFillAddressesSuccess(state, { payload }: PayloadAction<AutoFillAddressesResponseType>) {
      state.addresses = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchAutoFillAddressesFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const {
  fetchAutoFillAddressesSuccess,
  fetchAutoFillAddressesRequest,
  fetchAutoFillAddressesFailure,
} = autoFillAddressSlice.actions;

export default autoFillAddressSlice.reducer;
