import InnerHTML from 'dangerously-set-html-content';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../common/store';
import { PageScriptsSliceType } from '../../common/store/pageScripts/slice';

interface InjectScriptProps {
  product?: number;
  category?: number;
  custompage?: number;
  others?: string;
  pageTypeVerbose?: string;
  pageScripts: PageScriptsSliceType;
}

const htmlDecode = (input) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

const InjectScript: React.FC<InjectScriptProps> = ({
  pageScripts,
  product,
  category,
  custompage,
  pageTypeVerbose,
  others,
}) => {
  const [headElements, setHeadElements] = useState([]);

  const pageScriptsToBeRendered = pageScripts.pageScripts?.results?.filter(
    (pageScript) =>
      (product && product === pageScript.product) ||
      (category && category === pageScript.category) ||
      (custompage && custompage === pageScript.custompage) ||
      (pageTypeVerbose && pageTypeVerbose === pageScript.pageTypeVerbose) ||
      (others && others === pageScript.others && pageScript.pageTypeVerbose !== 'Store Wide'),
  );
  const headScripts = pageScriptsToBeRendered
    ?.map((pageScript) => {
      return pageScript.section === 1 ? pageScript.script : '';
    })
    .join('');
  const bodyScripts = pageScriptsToBeRendered
    ?.map((pageScript) => {
      return pageScript.section === 2 ? pageScript.script : '';
    })
    .join('\n');
  useEffect(() => {
    if (headScripts) {
      const doc = new DOMParser().parseFromString(headScripts, 'text/html');
      const elementsInHead = Array.from(doc.head.children).map((childNode: Element) => {
        const element = document.createElement(childNode.tagName);
        Array.from(childNode.attributes || []).map((attr) => {
          element.setAttribute(attr.nodeName, attr.nodeValue);
        });
        element.innerHTML = htmlDecode(childNode.innerHTML);
        document.head.appendChild(element);
        return element;
      });
      setHeadElements(elementsInHead);
    }
    return () => {
      headElements.forEach((element) => {
        document.head.removeChild(element);
      });
    };
  }, [pageScripts.pageScripts?.results]);
  return bodyScripts ? <InnerHTML html={bodyScripts} /> : <></>;
};

export default connect(({ pageScripts }: RootState) => ({ pageScripts }))(InjectScript);
