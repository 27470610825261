import { ReactComponent as ToastCloseIcon } from '@common/icons/ToastClose.svg';
import React, { Fragment } from 'react';
interface ToastProps {
  content: string | React.ReactNode;
  onClose: () => void;
  variant: ToastVariant;
  textColor?: string;
  isIframe?: boolean;
}

export enum ToastVariant {
  error = 'bg-toast-error',
  success = 'bg-toast-success',
  iframeSuccessText = 'bg-toast-iframeSuccessText',
  iframeSuccessBg = 'bg-toast-iframeSuccessBg',
}

const Toast: React.FC<ToastProps> = ({ content, variant, onClose, textColor, isIframe }) => {
  return (
    <Fragment>
      {isIframe ? (
        <div id="toast" className={`shadow-lg fixed top-0 ${variant}`} style={{ zIndex: 51, width: window.innerWidth }}>
          <div className={`container mx-auto items-center py-16 text-15 text-center ${variant} ${textColor}`}>
            <div dangerouslySetInnerHTML={{ __html: String(content) }}></div>
          </div>
        </div>
      ) : (
        <div id="toast" className={`shadow-lg fixed top-0 ${variant}`} style={{ zIndex: 60, width: window.innerWidth }}>
          <div
            className={`container mx-auto flex items-center py-16 text-15 text-white justify-between`}
            font-customisation="para-text"
          >
            <div dangerouslySetInnerHTML={{ __html: String(content) }}></div>
            <ToastCloseIcon className="cursor-pointer" onClick={() => onClose()} />
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default Toast;
