import { getData, URLs } from '@common/api';
import * as Product from '@common/models/Product';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import {
  fetchSearchFailureNew,
  fetchSearchRequestNew,
  FetchSearchRequestPayloadType,
  fetchSearchSuccessNew,
} from './slice';

function* fetchSearchSaga({ payload: { queryParams, storeKey } }: PayloadAction<FetchSearchRequestPayloadType>) {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_SEARCH_NEW, params: queryParams }));
    data.results = data.results?.map((process) => Product.processResponse(process));
    data.meta = {
      totalHits: data?.count,
    };
    yield put(fetchSearchSuccessNew({ search: data, queryParams, storeKey }));
  } catch (e) {
    yield put(fetchSearchFailureNew({ storeKey, error: 'Request failed with: ' + e }));
  }
}

export default function* searchSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchSearchRequestNew.type, fetchSearchSaga);
}
