export interface productRating {
  id: number;
  ratingScore: string;
  ratingVotes: number;
}

export interface productsRating {
  results: Array<productRating>;
}
export const processResponse = (products: Array): Array<productRating> => {
  const results = products?.map((product) => ({
    id: product.id,
    ratingScore: product.rating_score,
    ratingVotes: product.rating_votes,
  }));
  return results;
};
