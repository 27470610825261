import { all, AllEffect, ForkEffect } from 'redux-saga/effects';

import aboutSaga from './about/sagas';
import autoFillAddressesSagas from './autoFillAddresses/sagas';
import cartSaga from './cart/sagas';
import categoriesSaga from './categories/sagas';
import categoriesProductCountSagas from './categoriesProductCount/sagas';
import categoriesProductsListSagas from './categoriesProductsList/sagas';
import categorySaga from './category/sagas';
import categoryAttributesSaga from './categoryAttributes/sagas';
import categoryList from './categoryList/sagas';
import contactSaga from './contact/sagas';
import customCssSaga from './customCss/sagas';
import customPageDetailSaga from './customPageDetail/sagas';
import customPagesSaga from './customPages/sagas';
import customPageUserSectionSagas from './customPageUserSections/sagas';
import faqSaga from './faq/sagas';
import fbePixelSagas from './fbePixel/sagas';
import featuresSaga from './features/sagas';
import navigationDetailsSaga from './filterBar/sagas';
import geoSaga from './geo/sagas';
import googleOauthSagas from './googleOauth/sagas';
import heroBannerSaga from './heroBanner/sagas';
import homeSaga from './home/sagas';
import htmlBlockSaga from './htmlBlock/sagas';
import imageAndTextSaga from './imageAndText/sagas';
import legacySliderSagas from './legacySlider/sagas';
import mdpAppScriptSaga from './mdpAppScripts/sagas';
import newSearchSaga from './newSearch/sagas';
import nudgesSaga from './nudges/sagas';
import onboardStatusSaga from './onboardStatus/sagas';
import pageHeaderSagas from './pageHeader/sagas';
import pageScriptsSaga from './pageScripts/sagas';
import policiesSaga from './policies/sagas';
import policiesTosSaga from './policiesTos/sagas';
import priceLimitsSaga from './priceLimits/sagas';
import productDetailsSaga from './productDetails/sagas';
import productList from './productList/sagas';
import productsRatingSagas from './productRating/sagas';
import productsSaga from './products/sagas';
import promotionsSaga from './promotions/sagas';
import referralsSagas from './referral/sagas';
import reportAbuseSagas from './reportAbuse/sagas';
import richTextEditorSaga from './richTextEditor/sagas';
import searchSaga from './search/sagas';
import seoSaga from './seo/sagas';
import shopAttributesSaga from './shopAttributes/sagas';
import sliderSaga from './slider/sagas';
import socialProfilesSaga from './socialProfiles/sagas';
import storeInfoSaga from './storeInfo/sagas';
import storeTestimonialsSaga from './storeTestimonials/sagas';
import testimonialsSaga from './testimonials/sagas';
import textSaga from './text/sagas';
import themeCustomisationSaga from './themeCustomisation/sagas';
import toastsSaga from './toasts/sagas';
import userAddressSaga from './userAddress/sagas';
import userAddressesSaga from './userAddresses/sagas';
import userOrderSaga from './userOrder/sagas';
import userOrdersSaga from './userOrders/sagas';
import userProfileSaga from './userProfile/sagas';
import userSectionsSaga from './userSections/sagas';
import whatsappSaga from './whatsapp/sagas';
import wishListSagas from './wishList/sagas';

export default function* rootSaga(): Generator<AllEffect<Generator<ForkEffect<never>, void, unknown>>, void, unknown> {
  yield all([
    homeSaga(),
    heroBannerSaga(),
    textSaga(),
    imageAndTextSaga(),
    richTextEditorSaga(),
    userSectionsSaga(),
    faqSaga(),
    contactSaga(),
    aboutSaga(),
    productDetailsSaga(),
    categoriesSaga(),
    navigationDetailsSaga(),
    categorySaga(),
    productsSaga(),
    userProfileSaga(),
    cartSaga(),
    toastsSaga(),
    storeInfoSaga(),
    sliderSaga(),
    policiesSaga(),
    customPagesSaga(),
    customPageDetailSaga(),
    promotionsSaga(),
    ...seoSaga(),
    ...geoSaga(),
    userAddressesSaga(),
    userAddressSaga(),
    userOrderSaga(),
    userOrdersSaga(),
    testimonialsSaga(),
    customCssSaga(),
    themeCustomisationSaga(),
    socialProfilesSaga(),
    searchSaga(),
    featuresSaga(),
    categoryAttributesSaga(),
    pageScriptsSaga(),
    newSearchSaga(),
    onboardStatusSaga(),
    wishListSagas(),
    nudgesSaga(),
    mdpAppScriptSaga(),
    priceLimitsSaga(),
    reportAbuseSagas(),
    referralsSagas(),
    fbePixelSagas(),
    autoFillAddressesSagas(),
    shopAttributesSaga(),
    whatsappSaga(),
    googleOauthSagas(),
    policiesTosSaga(),
    pageHeaderSagas(),
    productList(),
    categoryList(),
    htmlBlockSaga(),
    legacySliderSagas(),
    storeTestimonialsSaga(),
    categoriesProductCountSagas(),
    categoriesProductsListSagas(),
    productsRatingSagas(),
    customPageUserSectionSagas(),
  ]);
}
