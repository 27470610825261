// import { getData, URLs } from '@common/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { getData, URLs } from '../../api';
import {
  CategoryPriceLimitsRequestPayloadType,
  fetchCategoryPriceLimitsFailure,
  fetchCategoryPriceLimitsRequest,
  fetchCategoryPriceLimitsSuccess,
  fetchShopAllPriceLimitsFailure,
  fetchShopAllPriceLimitsRequest,
  fetchShopAllPriceLimitsSuccess,
} from './slice';

function* fetchCategoryPriceLimitsSaga({
  payload: { categoryId },
}: PayloadAction<CategoryPriceLimitsRequestPayloadType>) {
  try {
    const { data } = yield call(() =>
      getData({ url: URLs.GET_CATEGORY_PRICE_LIMITS.replace('{id}', categoryId.toString()) }),
    );
    data.priceLimits = {
      minPrice: data?.price_limits?.min_price,
      maxPrice: data?.price_limits?.max_price,
    };
    yield put(fetchCategoryPriceLimitsSuccess(data));
  } catch (e) {
    yield put(fetchCategoryPriceLimitsFailure('Request failed with: ' + e));
  }
}

function* fetchShopAllPriceLimitsSaga() {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_SHOP_ALL_PRICE_LIMITS }));
    const result = {
      allPriceLimits: data?.price_limits,
    };
    yield put(fetchShopAllPriceLimitsSuccess(result));
  } catch (e) {
    yield put(fetchShopAllPriceLimitsFailure('Request failed with: ' + e));
  }
}

export default function* categoryPriceLimitsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchCategoryPriceLimitsRequest.type, fetchCategoryPriceLimitsSaga);
  yield takeEvery(fetchShopAllPriceLimitsRequest.type, fetchShopAllPriceLimitsSaga);
}
