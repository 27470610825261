import Startup from '@common/components/Startup';
import { createStore, setStore } from '@common/store';
import { initIDB } from '@common/store/cache';
import rootSaga from '@common/store/sagas';
import history from '@common/utils/history';
import Routes from '@routes';
import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

let preloadedState = {} as Record<string, unknown>;
declare global {
  interface Window {
    __INITIAL_STATE__?: Record<string, unknown>;
  }
}

if (typeof window !== 'undefined') {
  preloadedState = window.__INITIAL_STATE__ ?? {};
  delete window.__INITIAL_STATE__;
}

const store = createStore(preloadedState);
store.runSaga(rootSaga);
setStore(store);

(async () => {
  await initIDB();
})();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Startup>
        <Router history={history}>
          <Routes />
        </Router>
      </Startup>
    </Provider>
  );
};

export default App;
