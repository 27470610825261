import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CustomCssSliceType {
  customCss?: string;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const customCssSlice = createSlice({
  name: 'customCss',
  initialState: <CustomCssSliceType>{
    customCss: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchCustomCssRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.customCss = null;
      state.error = '';
    },
    fetchCustomCssSuccess(state, { payload }: PayloadAction<string>) {
      state.customCss = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchCustomCssFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const { fetchCustomCssSuccess, fetchCustomCssRequest, fetchCustomCssFailure } = customCssSlice.actions;

export default customCssSlice.reducer;
