export interface Theme {
  id: number;
  name: string;
  is_new: boolean;
  theme_hex_color: string;
  demo_store_url: string;
  is_premium: boolean;
}

export interface StoreInfo {
  tax_included_inventory: boolean;
  storename: string;
  accountId: string;
  logo?: string;
  social: {
    twitter?: string;
    facebook?: string;
    instagram?: string;
    youtube?: string;
    linkedin?: string;
    pinterest?: string;
    google?: string;
  };
  contactInfo: {
    address?: string;
    email?: string;
    number?: string;
  };
  hasSocialLinks: boolean;
  theme: Theme;
  paymentsAllowed: boolean;
  sliderEnabled: boolean;
  favicon?: string;
  storeType: string;
  gmasAccountId: number;
  username?: string;
  demoUser: boolean;
  isCodEnabled: boolean;
  isDomesticShippingEnabled: boolean;
  isInternationalShippingEnabled: boolean;
  underMaintenance?: boolean;
  isReferralEnabled?: boolean;
  isNewsletterSubscriptionEnabled?: boolean;
  isSEOEnabled?: boolean;
  isAddressAutoComplete?: boolean;
}

export const processResponse = (storeInfo: Record<string, unknown>): StoreInfo => {
  const social = storeInfo.social;
  const contactInfo = storeInfo.contact_info;
  // social = Object.keys(social).reduce(
  //   (acc, curr) => ({
  //     ...acc,
  //     [curr]: `https://${social[curr]}`,
  //   }),
  //   {},
  // );
  const hasSocialLinks = !!Object.values(social).filter((x) => x).length;
  return {
    storename: storeInfo.storename as string,
    accountId: storeInfo.account_id as string,
    logo: storeInfo.logo as string,
    social,
    contactInfo,
    hasSocialLinks: hasSocialLinks,
    theme: storeInfo.theme as Theme,
    paymentsAllowed: storeInfo.payments_allowed as boolean,
    sliderEnabled: storeInfo.slider_enabled as boolean,
    favicon: storeInfo.favicon as string,
    storeType: storeInfo.store_type as string,
    demoUser: storeInfo.demo_user as boolean,
    underMaintenance: storeInfo.under_maintenance as boolean,
    gmasAccountId: storeInfo.gmas_account_id as number,
    username: (storeInfo.username as string) || '',
    isCodEnabled: storeInfo.is_cod_enabled as boolean,
    isDomesticShippingEnabled: storeInfo.is_domestic_shipping_enabled as boolean,
    isInternationalShippingEnabled: storeInfo.is_international_shipping_enabled as boolean,
    isReferralEnabled: storeInfo.is_referral_enabled as boolean,
    isNewsletterSubscriptionEnabled: storeInfo.is_newsletter_subscription_enabled as boolean,
    isSEOEnabled: storeInfo.is_seo_enabled as boolean,
    isAddressAutoComplete: storeInfo.address_autocomplete as boolean,
    tax_included_inventory: storeInfo.tax_included_inventory as boolean,
  };
};

export default StoreInfo;

export enum STORE_TYPE {
  SHOPPING_STORE = 'shoppingstore',
  LANDING = 'landing',
  ENQUIRY = 'enquiry',
  DISABLED_CART = 'disabled_cart',
}
