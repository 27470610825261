import './index.css';

import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

export const useWindowSize = (): { width: number; height: number } => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export interface Breakpoint {
  width: number;
  chunkBy: number;
}

interface RenderSlideArgs {
  items: unknown[];
  chunkedItems: unknown[];
  current: number;
  chunkedItem: unknown;
  isFirstSlide: boolean;
  isLastSlide: boolean;
  chunkBy: number;
}

interface RenderControlsArgs {
  items: unknown[];
  chunkedItems: unknown[];
  current: number;
  isFirstSlide: boolean;
  isLastSlide: boolean;
  gotoPrevious: () => void;
  gotoNext: () => void;
  gotoSlide: (slideNumber: number) => void;
}

interface CarouselProps {
  items: unknown[];
  dummy?: React.ReactNode;
  breakpoints?: Breakpoint[];
  isVertical?: boolean;
  autoplayMilliSeconds?: number;
  renderSlide: (args: RenderSlideArgs) => React.ReactNode;
  renderControls: (args: RenderControlsArgs) => React.ReactNode;
}

const getCurrentBreakPoint = (breakpoints: Breakpoint[] = []): Breakpoint => {
  const { width } = useWindowSize();
  if (!breakpoints.length) {
    breakpoints = [{ width: 0, chunkBy: 1 }];
  }
  const filteredBreakpoints = breakpoints.filter((breakpoint) => (width || 0) >= breakpoint.width);
  return filteredBreakpoints[filteredBreakpoints.length - 1];
};

const chunkArray = (array: unknown[], size: number): unknown[][] => {
  const chunked_arr = [];
  let index = 0;
  while (index < array?.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
};

const Carousel: React.FC<CarouselProps> = ({
  items,
  breakpoints,
  renderSlide,
  renderControls,
  isVertical,
  autoplayMilliSeconds,
}) => {
  const [current, setCurrent] = useState(0);
  const [chunkBy, setChunkBy] = useState(1);
  const myRef = useRef(null);
  const currentBreakpoint = getCurrentBreakPoint(breakpoints);
  if (currentBreakpoint.chunkBy !== chunkBy) {
    setChunkBy(currentBreakpoint.chunkBy);
    setCurrent(0);
  }

  const chunkedItems = chunkArray(items, chunkBy);

  const gotoPrevious = () => {
    const length = myRef.current.children.length;
    let previous = current - 1;
    if (previous < 0) {
      previous = length - 1;
    }
    const { top, left } = !isVertical
      ? { top: 0, left: myRef.current.children[previous]?.offsetLeft }
      : { top: myRef.current.children[previous]?.offsetTop, left: myRef.current.children[previous]?.offsetLeft };
    myRef.current.scrollTo({ top, left, behavior: 'smooth' });
    setCurrent(previous);
  };

  const gotoNext = () => {
    const length = myRef.current.children.length;
    let next = current + 1;
    if (next >= length) {
      next = 0;
    }
    const { top, left } = !isVertical
      ? { top: 0, left: myRef.current.children[next]?.offsetLeft }
      : { top: myRef.current.children[next]?.offsetTop, left: myRef.current.children[next]?.offsetLeft };
    myRef.current.scrollTo({ top, left, behavior: 'smooth' });
    setCurrent(next);
  };

  const gotoSlide = (slideNumber) => {
    const length = myRef.current.children.length;
    if (slideNumber < 0 || slideNumber >= length) {
      return;
    }
    const left = myRef.current.children[slideNumber]?.offsetLeft;
    myRef.current.scrollTo({ top: 0, left, behavior: 'smooth' });
    setCurrent(slideNumber);
  };
  const isFirstSlide = current === 0;
  const isLastSlide = current === chunkedItems.length - 1;

  useEffect(() => {
    let timeout;
    if (autoplayMilliSeconds && chunkedItems.length >= 2) {
      timeout = setTimeout(gotoNext, autoplayMilliSeconds);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [current]);

  return (
    <div className={classnames('relative', { 'h-full': isVertical })}>
      <div
        ref={myRef}
        className={classnames('no-scrollbar', {
          'flex overflow-x-hidden': !isVertical,
          'flex flex-col h-full overflow-y-hidden': isVertical,
        })}
      >
        {chunkedItems.map((chunkedItem, index) => (
          <div key={index} className={classnames('flex-none', { 'w-full': !isVertical, 'h-full': isVertical })}>
            {renderSlide({ items, chunkedItems, current, chunkedItem, isFirstSlide, isLastSlide, chunkBy })}
          </div>
        ))}
      </div>
      {renderControls({ items, chunkedItems, current, gotoPrevious, gotoNext, isFirstSlide, isLastSlide, gotoSlide })}
    </div>
  );
};

export default Carousel;
