import { getQueryParams } from '@common/utils/constants';

interface UserSection {
  id: number;
  sectionId: number;
  order: number;
  sectionType: string;
  layoutId: number;
  layoutName: string;
  name: string;
  isDefault: string;
  isVisible: boolean;
  isMerchant?: boolean;
}

export const processResponse = (userSection: Record<string, unknown>): UserSection => {
  return {
    id: userSection.id,
    sectionId: userSection.section_id,
    order: userSection.order,
    sectionType: userSection.section_type,
    layoutId: userSection.layout_id,
    //TEMPORARY HACK FOR THE DEMO
    layoutName:
      userSection.name === 'Page Header'
        ? getQueryParams()['page-header'] || userSection.layout_name
        : userSection.layout_name,
    name: userSection.name,
    isDefault: userSection.is_default,
    isVisible: userSection.is_visible,
  };
};

export default UserSection;
