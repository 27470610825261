import { getLambdaLink, isMobile } from '@common/utils';

interface ImageAndTextItem {
  id: number;
  heading: string;
  paragraph: string;
}

interface ImageAndText {
  id: number;
  name: string;
  ctaLink: string;
  ctaText: string;
  showCta: boolean;
  texts: Array<ImageAndTextItem>;
  images: Array<string>;
}

// const aspectRatioMultiplier = 1;

export const imageAndTextDimensions = {
  ImageText1: {
    mobile: { width: 513, height: 291 },
    desktop: { width: 1440, height: 810 },
  },
  ImageText2: {
    mobile: { width: 561, height: 422 },
    desktop: { width: 1440, height: 1080 },
  },
  ImageText3: {
    mobile: { width: 561, height: 702 },
    desktop: { width: 1440, height: 1800 },
  },
  ImageText4: {
    mobile: { width: 561, height: 315 },
    desktop: { width: 1440, height: 810 },
  },
  ImageText5: {
    mobile: { width: 561, height: 315 },
    desktop: { width: 1440, height: 810 },
  },
  ImageText6: {
    mobile: { width: 561, height: 315 },
    desktop: { width: 720, height: 720 },
  },
  ImageText7: {
    mobile: { width: 561, height: 561 },
    desktop: { width: 800, height: 800 },
  },
  ImageText8: {
    mobile: { width: 621, height: 750 },
    desktop: { width: 1440, height: 500 },
  },
};

export const processResponse = (imageAndText: Record<string, unknown>, layoutName: string): ImageAndText => {
  const { desktop, mobile } = imageAndTextDimensions[layoutName] || {
    mobile: { width: 0, height: 0 },
    desktop: { width: 0, height: 0 },
  };
  return {
    id: imageAndText.id,
    name: imageAndText.name,
    ctaLink: imageAndText.cta_link,
    ctaText: imageAndText.cta_text,
    showCta: imageAndText.show_cta,
    texts: imageAndText.texts.map((text: Record<string, number | string>) => {
      return {
        id: text.id,
        heading: text.heading,
        paragraph: text.paragraph,
      };
    }),
    images: imageAndText.images?.map((imageObject) => {
      return decodeURIComponent(
        getLambdaLink({
          link: imageObject.image_url as string,
          ...(isMobile() ? mobile : desktop),
        }),
      );
    }),
    layout: imageAndText.layout,
  };
};
