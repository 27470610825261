import * as CategoriesProductCount from '@common/models/CategoriesProductCount';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CategoriesProductCountSliceType {
  results: CategoriesProductCount.CategoriesProductCount;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const categoriesProductCountSlice = createSlice({
  name: 'categoriesProductCount',
  initialState: <CategoriesProductCountSliceType>{
    results: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchCategoriesProductCountRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.results = null;
      state.error = '';
    },
    fetchCategoriesProductCountSuccess(state, { payload }: PayloadAction<CategoriesProductCountSliceType>) {
      state.isFetching = false;
      state.isFetched = true;
      state.results = payload.results;
    },
    fetchCategoriesProductCountFailure(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.isFetched = true;
      state.error = payload;
    },
  },
});

export const {
  fetchCategoriesProductCountRequest,
  fetchCategoriesProductCountSuccess,
  fetchCategoriesProductCountFailure,
} = categoriesProductCountSlice.actions;

export default categoriesProductCountSlice.reducer;
