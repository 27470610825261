import { getData, URLs } from '@common/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchUserAddressFailure, fetchUserAddressRequest, fetchUserAddressSuccess } from './slice';

/* eslint-disable-next-line */
function* fetchUserAddressSaga({ payload: addressId }: PayloadAction<number>) {
  if (!addressId) {
    return;
  }
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_USER_ADDRESS.replace('{id}', `${addressId}`) }));
    yield put(fetchUserAddressSuccess(data));
  } catch (e) {
    yield put(fetchUserAddressFailure('Request failed with: ' + e));
  }
}

export default function* userAddressSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchUserAddressRequest.type, fetchUserAddressSaga);
}
