interface CurrentProgram {
  createdDate: string;
  expiryDate: string;
  referrerDiscount: string;
  refereeDiscount: string;
  inviteLimit: number;
}

interface Discounts {
  couponCode: string;
  isActive: boolean;
  refereeName: string;
}

interface Referrals {
  referralCode: string;
  referredSignups: number;
  isActive: boolean;
  currentProgram: CurrentProgram;
  discounts: Discounts[];
  referrerName: string;
}

export const processResponse = (referrals: Record<string, never>): Referrals => {
  const discounts = (referrals.discounts as Record<string, never>[])?.map((discount) => ({
    couponCode: discount.coupon_code,
    isActive: discount.is_active,
    refereeName: discount.referee_name,
  }));
  return {
    referralCode: referrals.referral_code,
    referredSignups: referrals.referred_signups,
    isActive: referrals.is_active,
    currentProgram: {
      createdDate: (referrals.current_program as Record<string, never>)?.created_date,
      expiryDate: (referrals.current_program as Record<string, never>)?.expiry_date,
      referrerDiscount: (referrals.current_program as Record<string, never>)?.referrer_discount,
      refereeDiscount: (referrals.current_program as Record<string, never>)?.referee_discount,
      inviteLimit: (referrals.current_program as Record<string, never>)?.invite_limit,
    },
    discounts,
    referrerName: referrals.referrer_name,
  };
};

export default Referrals;
