import { getData, URLs } from '@common/api';
import * as Section from '@common/models/sections';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchCustomPageUserSectionRequest } from '../customPageUserSections/slice';
import { fetchCustomPageDetailFailure, fetchCustomPageDetailRequest, fetchCustomPageDetailSuccess } from './slice';

function* fetchCustomPageDetailSaga({ payload: customPageUrl }: PayloadAction<string>) {
  let redirectToTrailingSlash = customPageUrl;
  if (customPageUrl === '/faqs' || customPageUrl === '/aboutus') {
    redirectToTrailingSlash = customPageUrl.replace(customPageUrl, `${customPageUrl}/`);
  }

  try {
    const { data } = yield call(() =>
      getData({ url: URLs.GET_CUSTOM_PAGE_DETAIL_VIA_URL, params: { url: redirectToTrailingSlash } }),
    );
    data.sections = Section.processResponse(data.sections);
    yield put(fetchCustomPageDetailSuccess(data));
    if (data) {
      const customPageId = `custom-page/${data.id}`;
      yield put(fetchCustomPageUserSectionRequest(customPageId));
    }
  } catch (e) {
    yield put(fetchCustomPageDetailFailure('Request failed with: ' + e));
  }
}

export default function* customPageDetailSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchCustomPageDetailRequest.type, fetchCustomPageDetailSaga);
}
