import { ToastWithKeyType } from '@common/store/toasts/slice';
import { addToast, removeToast } from '@common/store/toasts/slice';
import Toast from '@lib/Toast';
import React from 'react';

export interface ToastsProps {
  toasts: ToastWithKeyType[];
  addToast: typeof addToast;
  removeToast: typeof removeToast;
}

const Toasts: React.FC<ToastsProps> = ({ toasts, removeToast }) => (
  <div>
    {toasts.map((toast) => (
      <Toast
        key={toast.key}
        content={toast.content}
        textColor={toast?.textColor}
        isIframe={toast?.isIframe}
        variant={toast.variant}
        onClose={() => removeToast(toast.key)}
      />
    ))}
  </div>
);

export default Toasts;
