import { getData, URLs } from '@common/api';
import * as Slider from '@common/models/Slider';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchSliderFailure, fetchSliderRequest, fetchSliderSuccess } from './slice';

function* fetchSliderSaga() {
  try {
    const data = yield call(() =>
      getData({
        url: URLs.GET_SLIDER,
      }),
    );

    const results = { ...data, results: data.results?.map((product) => Slider.processResponse(product)) };
    yield put(fetchSliderSuccess(results));
  } catch (e) {
    yield put(fetchSliderFailure('Request failed with: ' + e));
  }
}

export default function* sliderSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchSliderRequest.type, fetchSliderSaga);
}
