export interface StoreUpdateType {
  ctaText: string;
  subTitle: string;
  title: string;
  layout: {
    id: number;
    image: string;
    name: string;
  };
  id?: number;
  name?: string;
}

export interface FetchStoreUpdateType {
  data: StoreUpdateType;
}

export interface StoreUpdateResponseType {
  cta_text: string;
  sub_title: string;
  title: string;
  layout: {
    id: number;
    image: string;
    name: string;
  };
  section?: number;
  name?: string;
}

export interface StoreUpdatePostType {
  mutate: StoreUpdateResponseType | unknown;
}

export const processResponse = (data: Record<string, never>): StoreUpdateType => {
  const { layout, id, cta_text, sub_title, title, name } = data;
  return {
    ctaText: cta_text,
    subTitle: sub_title,
    title,
    layout,
    id,
    name,
  };
};

export const preparePayload = (data: Record<string, never>, mode: string): StoreUpdateResponseType => {
  const { layout, section, ctaText, subTitle, title } = data;
  return {
    cta_text: ctaText,
    sub_title: subTitle,
    title,
    layout,
    section,
    ...(mode === 'create' && { custom_page: data.customPages, store_page: data.storePage }),
  };
};
