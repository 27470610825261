import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CustomMetaTagsResponseType {
  count: number;
  next: string;
  previous: string;
  results: {
    name: string;
    content: string;
  }[];
}

export interface CustomMetaTagsSliceType {
  customMetaTags?: CustomMetaTagsResponseType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const customMetaTagsSlice = createSlice({
  name: 'customMetaTags',
  initialState: <CustomMetaTagsSliceType>{
    customMetaTags: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchCustomMetaTagsRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.customMetaTags = null;
      state.error = '';
    },
    fetchCustomMetaTagsSuccess(state, { payload }: PayloadAction<CustomMetaTagsResponseType>) {
      state.customMetaTags = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchCustomMetaTagsFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const {
  fetchCustomMetaTagsSuccess,
  fetchCustomMetaTagsRequest,
  fetchCustomMetaTagsFailure,
} = customMetaTagsSlice.actions;

export default customMetaTagsSlice.reducer;
