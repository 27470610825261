import { getData, URLs } from '@common/api';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchReportAbuseFailure, fetchReportAbuseRequest, fetchReportAbuseSuccess } from './slice';

function* fetchReportAbuseSaga() {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_REPORT_ABUSE_INFO }));
    yield put(fetchReportAbuseSuccess(data?.show_report_link));
  } catch (e) {
    yield put(fetchReportAbuseFailure('Request failed with: ' + e));
  }
}

export default function* reportAbuseSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchReportAbuseRequest.type, fetchReportAbuseSaga);
}
