import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WhatsappResponse {
  is_enabled: boolean;
}

export interface WhatsappSliceType {
  whatsapp?: WhatsappResponse;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const whatsappSlice = createSlice({
  name: 'whatsapp',
  initialState: <WhatsappSliceType>{
    whatsapp: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchIsWhatsappEnabledRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.whatsapp = null;
      state.error = '';
    },
    fetchIsWhatsappEnabledSuccess(state, { payload }: PayloadAction<WhatsappResponse>) {
      state.whatsapp = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchIsWhatsappEnabledFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const {
  fetchIsWhatsappEnabledSuccess,
  fetchIsWhatsappEnabledRequest,
  fetchIsWhatsappEnabledFailure,
} = whatsappSlice.actions;

export default whatsappSlice.reducer;
