import InviteBanner from '@common/images/InviteBanner.jpg';
import UserProfile from '@common/models/UserProfile';
import { RouteMap } from '@common/RouteMap';
import { RootState } from '@common/store';
import { fetchReferralProgramRequest, ReferralSliceType } from '@common/store/referral/slice';
import { smoothScroll } from '@common/utils';
import { formatDate } from '@common/utils/date';
import PageLayout from '@components/PageLayout';
import Link from '@lib/Link';
import Loading from '@lib/Loading';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

interface InviteProps {
  userProfile: UserProfile;
  referral: ReferralSliceType;
  fetchReferralProgramRequest: typeof fetchReferralProgramRequest;
}

const Invite: React.FC<InviteProps> = ({ userProfile, referral, fetchReferralProgramRequest }) => {
  useEffect(() => {
    fetchReferralProgramRequest();
    smoothScroll(0, 0);
  }, []);

  const isLoggedIn = userProfile?.profile?.email;
  const referralProgram = referral.referralProgram || {};
  return (
    <PageLayout>
      {referral.isReferralProgramFetching ? (
        <Loading />
      ) : referral.isReferralProgramFetched && referral.referralProgramError ? (
        <div className="flex h-400">
          <div className="m-auto text-center">
            <div className="mb-32 text-24 font-semibold text-referral-darkGray">
              Sorry, the referral program has ended.
            </div>
            <Link
              className="bg-theme rounded text-white py-10 px-40 text-center sm:w-5/12 w-auto sm:mx-0 mx-16"
              to={RouteMap.HOME}
            >
              Explore Products
            </Link>
          </div>
        </div>
      ) : (
        <div className="sm:w-6/12 w-full mx-auto mt-40 flex flex-col justify-center">
          <img src={InviteBanner} alt={'InviteBanner'} />
          <div className="sm:mx-auto mx-16">
            <div className="my-24 text-18 text-referral-gray">
              Invite your friends by giving them <strong>₹{referralProgram?.refereeDiscount}</strong> when they sign up
              using your referral link. You receive a discount coupon of{' '}
              <strong>₹{referralProgram?.referrerDiscount}</strong> when they complete a purchase.
            </div>
            {referralProgram?.expiryDate && (
              <div className="mb-24 text-12 text-referral-lightGray">
                *Referral program ends on {formatDate(referralProgram?.expiryDate)}
              </div>
            )}
          </div>
          <Link
            className="bg-theme rounded text-white py-10 px-40 mb-40 text-center sm:w-5/12 w-auto sm:mx-0 mx-16"
            to={isLoggedIn ? RouteMap.ACCOUNT_INVITE : RouteMap.ACCOUNT_LOGIN}
          >
            Get Referral Link
          </Link>
        </div>
      )}
    </PageLayout>
  );
};

export default connect(
  ({ userProfile, referral }: RootState) => ({
    userProfile,
    referral,
  }),
  { fetchReferralProgramRequest },
)(Invite);
