import * as Wishlist from '@common/models/Wishlist';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserWishlistResponseType {
  count: number;
  next: string | null;
  previous: string | null;
  results: Wishlist.default[];
}
export interface UserWishlistResponsePayloadType {
  response: UserWishlistResponseType;
  queryParams: UserWishlistQueryParams;
}
export interface wishListType {
  product?: string;
  status?: string;
}
export interface UserWishlistQueryParams {
  limit: number;
  offset: number;
  page: number;
}
export const getUserWishlistQueryParamsInitialState = (): UserWishlistQueryParams => ({
  limit: 10,
  offset: 0,
  page: 1,
});
export interface WishListSliceType {
  pagination: {
    currentPage: number;
    lastPage: number;
    multiplier: number;
  };
  currentQueryParams?: UserWishlistQueryParams;
  wishListProducts?: UserWishlistResponseType;
  isFetching: boolean;
  isFetched: boolean;
  productId: string;
  status: string;
  isProductInWishList: boolean;
  error: string;
}

const wishListSlice = createSlice({
  name: 'wishlist',
  initialState: <WishListSliceType>{
    wishListProducts: null,
    isFetching: false,
    isFetched: false,
    isProductInWishList: false,
    error: '',
  },
  reducers: {
    fetchWishListRequest(state, { payload: { queryParams } }: PayloadAction<{ queryParams: UserWishlistQueryParams }>) {
      state.wishListProducts = null;
      state.currentQueryParams = queryParams;
      state.isFetching = true;
      state.isFetched = false;
    },
    fetchWishListSuccess(
      state,
      { payload: { response: wishListProducts, queryParams } }: PayloadAction<UserWishlistResponsePayloadType>,
    ) {
      state.wishListProducts = wishListProducts;
      state.isFetching = false;
      state.isFetched = true;
      state.pagination = {
        currentPage: queryParams.page,
        lastPage: parseInt(`${(wishListProducts.count - 1) / queryParams.limit + 1}`),
        multiplier: queryParams.limit,
      };
    },
    fetchWishListFailure(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.isFetched = true;
      state.error = payload;
    },
    getProdWishlist(state, { payload: productSlug }: PayloadAction<string>) {
      state.productId = productSlug;
    },
    getProdWishlistSuccess(state, { payload }: PayloadAction<string>) {
      state.status = payload;
      state.isProductInWishList = true;
    },
    getProdWishlistFailure(state) {
      state.isProductInWishList = false;
    },
    addToWishlist(state, { payload: productSlug }: PayloadAction<string>) {
      state.productId = productSlug;
    },
    addToWishlistSuccess(state, { payload }: PayloadAction<string>) {
      state.status = payload;
      state.isProductInWishList = true;
    },
    addToWishlistFailure(state) {
      state.isProductInWishList = false;
    },
    removeFromWishlist(state, { payload: productSlug }: PayloadAction<string>) {
      state.productId = productSlug;
    },
    removeFromWishlistSuccess(state, { payload }: PayloadAction<string>) {
      state.status = payload;
      state.isProductInWishList = false;
    },
    removeFromWishlistFailure(state) {
      state.isProductInWishList = true;
    },
  },
});

export const WISHLIST_TOAST = 'WISHLIST_TOAST';

export const {
  fetchWishListSuccess,
  fetchWishListRequest,
  fetchWishListFailure,
  getProdWishlist,
  getProdWishlistSuccess,
  getProdWishlistFailure,
  addToWishlist,
  addToWishlistSuccess,
  addToWishlistFailure,
  removeFromWishlist,
  removeFromWishlistSuccess,
  removeFromWishlistFailure,
} = wishListSlice.actions;

export default wishListSlice.reducer;
