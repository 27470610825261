import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Category from '../../models/Category';

export interface CategorySliceType {
  category?: Category;
  categoryId?: number;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const categorySlice = createSlice({
  name: 'category',
  initialState: <CategorySliceType>{
    categoryId: null,
    category: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchCategoryRequest(state, { payload: categoryId }: PayloadAction<number>) {
      state.categoryId = categoryId;
      state.isFetching = true;
      state.isFetched = false;
      state.category = null;
      state.error = '';
    },
    fetchCategorySuccess(state, { payload }: PayloadAction<Category>) {
      state.category = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchCategoryFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const { fetchCategorySuccess, fetchCategoryRequest, fetchCategoryFailure } = categorySlice.actions;

export default categorySlice.reducer;
