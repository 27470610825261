import { getData, URLs } from '@common/api';
import * as Nudge from '@common/models/NudgeList';
import { getMerchantJWTToken } from '@common/utils/token';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchNudgesFailure, fetchNudgesRequest, fetchNudgesSuccess } from './slice';

function* fetchNudgesSaga() {
  try {
    const merchantToken = getMerchantJWTToken();
    const { data } = yield call(() =>
      getData({
        url: URLs.GET_NUDGES,
        ...(merchantToken && { headers: { Authorization: `Bearer ${merchantToken}` } }),
      }),
    );
    const results = {
      nudges: data?.nudges?.map(Nudge.processResponse),
      showNudges: data?.show_nudges,
      isUserOnPremiumPlan: data?.is_user_on_premium_plan,
    };
    results.nudges = results.nudges?.map(Nudge.mapResponse);
    yield put(fetchNudgesSuccess(results));
  } catch (e) {
    yield put(fetchNudgesFailure('Request failed with: ' + e));
  }
}

export default function* nudgesSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchNudgesRequest.type, fetchNudgesSaga);
}
