import { getData, URLs } from '@common/api';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchCustomCssFailure, fetchCustomCssRequest, fetchCustomCssSuccess } from './slice';

function* fetchCustomCssSaga() {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_CUSTOM_CSS }));
    yield put(fetchCustomCssSuccess(data?.custom_css));
  } catch (e) {
    yield put(fetchCustomCssFailure('Request failed with: ' + e));
  }
}

export default function* customCssSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchCustomCssRequest.type, fetchCustomCssSaga);
}
