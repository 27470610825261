// import { getData, URLs } from '@common/api';
// import * as CategoryAttribute from '@common/models/CategoryAttribute';
import * as ShopAttribute from '@common/models/ShopAttribute';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { getData, URLs } from '../../api';
import {
  CategoryAttributesRequestPayloadType,
  fetchCategoryAttributesFailure,
  fetchCategoryAttributesRequest,
  fetchCategoryAttributesSuccess,
} from './slice';

function* fetchCategoryAttributesSaga({
  payload: { queryParams },
}: PayloadAction<CategoryAttributesRequestPayloadType>) {
  try {
    const { data } = yield call(() => getData({ url: URLs.PRODUCT_FILTERS, params: queryParams }));
    data.results = ShopAttribute.processResponse(data?.results || []);
    yield put(fetchCategoryAttributesSuccess({ response: data, queryParams }));
  } catch (e) {
    yield put(fetchCategoryAttributesFailure('Request failed with: ' + e));
  }
}

export default function* categoryAttributesSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchCategoryAttributesRequest.type, fetchCategoryAttributesSaga);
}
