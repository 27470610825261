import { productRating } from '@common/models/ProductsRating';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface productsRatingSliceType {
  error?: string;
  id: number;
  results?: Array<productRating>;
}

const productsRatingSlice = createSlice({
  name: 'productsRating',
  initialState: {},
  reducers: {
    fetchProductsRatingRequest(state, { payload }) {
      const productsRatingObject = {
        isFetching: true,
        isFetched: false,
        results: [],
        error: '',
      };
      state[payload.id] = productsRatingObject;
    },
    fetchProductsRatingSuccess(state, { payload }: PayloadAction<productsRatingSliceType>) {
      const productsRatingObject = {
        isFetching: false,
        isFetched: true,
        results: payload.results,
        error: '',
      };
      state[payload.id] = productsRatingObject;
    },
    fetchProductsRatingFailure(state, { payload }: PayloadAction<productsRatingSliceType>) {
      const productsRatingObject = {
        isFetching: false,
        isFetched: true,
        results: [],
        error: payload.error,
      };
      state[payload.id] = productsRatingObject;
    },
  },
});

export const {
  fetchProductsRatingFailure,
  fetchProductsRatingRequest,
  fetchProductsRatingSuccess,
} = productsRatingSlice.actions;

export default productsRatingSlice.reducer;
