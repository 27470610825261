import { StoreTestimonialsSliceType } from '@common/store/storeTestimonials/slice';
import {
  MOBILE_TESTIMONIALS_IMAGE_HEIGHT,
  MOBILE_TESTIMONIALS_IMAGE_WIDTH,
  TESTIMONIALS_IMAGE_HEIGHT,
  TESTIMONIALS_IMAGE_WIDTH,
} from '@theme/utils/constants';

import { getLambdaLink, isMobile } from '../utils';
interface Testimonial {
  feedback: string;
  id: number;
  image: string;
  name: string;
  publish: boolean;
}

interface Testimonial {
  feedback: string;
  id: number;
  image: string | null;
  name: string;
  publish: boolean;
}

interface TestimonialPreviewResponseType {
  testimonials: [
    {
      feedback: string;
      user: string;
      publish: boolean;
      image: string;
    },
  ];
  layout: {
    id: number;
    image: string;
    name: string;
  };
  id?: number;
}

export const processResponse = (testimonial: Record<string, unknown>): Testimonial => {
  const { width, height } = isMobile()
    ? {
        width: MOBILE_TESTIMONIALS_IMAGE_WIDTH,
        height: MOBILE_TESTIMONIALS_IMAGE_HEIGHT,
      }
    : { width: TESTIMONIALS_IMAGE_WIDTH, height: TESTIMONIALS_IMAGE_HEIGHT };

  return {
    image: testimonial.image ? getLambdaLink({ link: testimonial.image as string, width, height }) : null,
    feedback: testimonial.feedback as string,
    id: testimonial.id as number,
    name: testimonial.name as string,
    publish: testimonial.publish as boolean,
  };
};

export const previewProcessResponse = (data: Record<string, never>): TestimonialPreviewResponseType => {
  const { id, layout, title } = data;
  const testimonials = (data.testimonials as Record<string, never>[])?.map((value) => ({
    id: value.id,
    feedback: value.feedback ? value.feedback : '',
    name: value.name,
    image: value.image,
    publish: value.publish,
    delete: value.delete,
  }));
  return {
    id,
    layout,
    title,
    testimonials,
  };
};

export const getFilteredTestimonials = (
  testimonials: StoreTestimonialsSliceType,
  id: number,
): StoreTestimonialsSliceType => {
  const filteredTestimonialData =
    testimonials[id]?.storeTestimonials?.testimonials?.filter(
      (testimonial) => testimonial?.publish && !testimonial?.delete,
    ) || [];

  return filteredTestimonialData;
};

export default Testimonial;
