import { getData, URLs } from '@common/api';
import * as Promotion from '@common/models/Promotion';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchPromotionsFailure, fetchPromotionsRequest, fetchPromotionsSuccess } from './slice';

function* fetchPromotionsSaga() {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_PROMOTIONS }));
    data.results = data.results?.map((result) => Promotion.processResponse(result));
    yield put(fetchPromotionsSuccess(data));
  } catch (e) {
    yield put(fetchPromotionsFailure('Request failed with: ' + e));
  }
}

export default function* promotionsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchPromotionsRequest.type, fetchPromotionsSaga);
}
