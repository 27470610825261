import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Product from '../../models/Product';

export interface SearchResponseType {
  count: number;
  next?: string;
  previous?: string;
  time_taken_in_ms?: string;
  results: Product[];
}

export interface SearchResponsePayloadType {
  response: SearchResponseType;
  queryParams: SearchQueryParams;
}

export interface SearchQueryParams {
  format: 'json';
  active: boolean;
  offset: number;
  limit: number;
  suggest: string;
  oos: boolean;
  bponly: boolean;
  is_featured?: boolean;
  cats?: string;
  ordering?:
    | 'created'
    | 'modified'
    | 'title'
    | 'sort_order'
    | 'price'
    | 'final_price'
    | 'discounted_price'
    | '-created'
    | '-modified'
    | '-title'
    | '-sort_order'
    | '-price'
    | '-final_price'
    | '-discounted_price';
}

export const getSearchQueryParamsInitialState = (search: string): SearchQueryParams => ({
  suggest: search,
  active: true,
  oos: undefined,
  bponly: true,
  format: 'json',
  // is_featured: true,
  cats: undefined,
  offset: 0,
  limit: 50,
  ordering: 'sort_order',
});

export interface SearchSliceType {
  pagination: {
    currentPage: number;
    lastPage: number;
    multiplier: number;
  };
  currentQueryParams?: SearchQueryParams;
  search?: SearchResponseType;
  fetchedProducts?: Product[];
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const searchInitialState = {
  pagination: {
    currentPage: 1,
    lastPage: 1,
    multiplier: 1,
  },
  search: null,
  fetchedProducts: [],
  isFetching: false,
  isFetched: false,
  error: '',
};

export interface FetchSearchRequestPayloadType {
  storeKey: string;
  queryParams: SearchQueryParams;
}

interface FetchSearchSuccessPayloadType extends FetchSearchRequestPayloadType {
  search: SearchResponseType;
}

interface FetchSearchFailurePayloadType {
  storeKey: string;
  error: string;
}

const searchSlice = createSlice({
  name: 'search',
  initialState: {} as Record<string, SearchSliceType>,
  reducers: {
    resetSearchStore(state, { payload: storeKey }: PayloadAction<string>) {
      state[storeKey] = searchInitialState;
    },
    fetchSearchRequest(state, { payload: { storeKey, queryParams } }: PayloadAction<FetchSearchRequestPayloadType>) {
      state[storeKey] = {
        ...state[storeKey],
        isFetching: true,
        isFetched: false,
        search: null,
        currentQueryParams: queryParams,
        error: '',
      };
    },
    fetchSearchSuccess(
      state,
      { payload: { storeKey, search, queryParams } }: PayloadAction<FetchSearchSuccessPayloadType>,
    ) {
      const currentPage = queryParams.offset / queryParams.limit + 1;
      state[storeKey].pagination = {
        currentPage,
        lastPage: parseInt(`${(search.count - 1) / queryParams.limit + 1}`),
        multiplier: queryParams.limit,
      };
      if (currentPage === 1) {
        state[storeKey].fetchedProducts?.splice(0, state[storeKey].fetchedProducts?.length);
        state[storeKey].fetchedProducts?.push(...search?.results);
      } else {
        state[storeKey].fetchedProducts?.push(...search?.results);
      }
      state[storeKey].search = search;
      state[storeKey].isFetching = false;
      state[storeKey].isFetched = true;
    },
    fetchSearchFailure(state, { payload: { storeKey, error } }: PayloadAction<FetchSearchFailurePayloadType>) {
      state[storeKey].error = error;
      state[storeKey].isFetching = false;
      state[storeKey].isFetched = true;
      state[storeKey].search = null;
    },
  },
});

export const { resetSearchStore, fetchSearchSuccess, fetchSearchRequest, fetchSearchFailure } = searchSlice.actions;

export default searchSlice.reducer;
