import StoreInfo from '@common/models/StoreInfo';
import { HandInfoIcon, LikeIcon, LocationOnGlobeIcon, LockV3Icon, ShipmentIcon } from '@instamojo/icons';
import React from 'react';

interface TrustBannerProps {
  storeInfo: StoreInfo;
}

const TrustBanner: React.FunctionComponent<TrustBannerProps> = ({ storeInfo }) => {
  return (
    <div className="w-full gray trustBanner">
      <div
        className="container text-center mx-auto pt-30 pb-22 md:pb-10 md:flex px-16 md:px-0"
        font-customisation="para-text"
      >
        <div className="md:w-1/2 w-2/3 mx-auto mb-24 md:mb-0 flex items-center md:block">
          <div className="inline-block mr-10">
            <LockV3Icon size="36" />
          </div>
          <br />
          <span className="text-14 text-label" color-customisation="page-body-text">
            Secure Payments
          </span>
        </div>
        {storeInfo?.isDomesticShippingEnabled && (
          <div className="md:w-1/2 w-2/3 mx-auto mb-24 md:mb-0 flex items-center md:block">
            <div className="inline-block mr-10">
              <ShipmentIcon size="36" />
            </div>
            <br />
            <span className="text-14 text-label" color-customisation="page-body-text">
              Shipping in India
            </span>
          </div>
        )}
        {storeInfo?.isInternationalShippingEnabled && (
          <div className="md:w-1/2 w-2/3 mx-auto mb-24 md:mb-0 flex items-center md:block">
            <div className="inline-block mr-10">
              <LocationOnGlobeIcon size="36" className="text-label" />
            </div>
            <br />
            <span className="text-14 text-label" color-customisation="page-body-text">
              International Shipping
            </span>
          </div>
        )}
        {storeInfo?.isCodEnabled && (
          <div className="md:w-1/2 w-2/3 mx-auto mb-24 md:mb-0 flex items-center md:block">
            <div className="inline-block mr-10">
              <HandInfoIcon size="36" className="text-label" />
            </div>
            <br />
            <span className="text-14 text-label" color-customisation="page-body-text">
              Cash on Delivery
            </span>
          </div>
        )}
        <div className="md:w-1/2 w-2/3 mx-auto mb-24 md:mb-0 flex items-center md:block">
          <div className="inline-block mr-10">
            <LikeIcon size="36" className="text-label" />
          </div>
          <br />
          <span className="text-14 text-label" color-customisation="page-body-text">
            Great Value &amp; Quality
          </span>
        </div>
      </div>
    </div>
  );
};

export default TrustBanner;
