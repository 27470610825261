import { store } from '@common/store';
import { addResellerToCart } from '@common/store/cart/slice';

import { isBrowser } from '.';

const saveResellerToCartFn = (): void => {
  if (!isBrowser()) {
    return;
  }

  const paths = [
    /^\/product\/(?<productId>[0-9]+)\/[\w-]+\/?$/,
    /^\/product\/(?<productId>[0-9]+)\/?$/,
    /^\/[\w-]+\/p(?<productId>[0-9]+)\/?$/,
  ];
  const queryParams = new URLSearchParams(window.location.search);

  const groupId = paths.reduce((acc, path) => {
    const groups = window.location.pathname.match(path)?.groups;

    return acc ?? groups?.productId;
  }, undefined);

  const reseller = queryParams.get('reseller');

  if (reseller && groupId) {
    const payload = {
      groupId: +groupId,
      reseller,
    };
    store.dispatch(addResellerToCart(payload));
  }
};

export const saveResellerToCart = saveResellerToCartFn;
