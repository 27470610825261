import { isBrowser } from '@common/utils/index';
import React from 'react';

export interface HeadingProps {
  id?: number | string;
  sectionType: string;
}

const Fallback: React.FC<HeadingProps> = ({ id, sectionType }) => {
  console.log('Section Type', id, sectionType);
  if (isBrowser() && !new URLSearchParams(location.search).get('no-ssr-fallback')) {
    const html = document.querySelector(`[id='${id}']`)?.innerHTML;
    return <div dangerouslySetInnerHTML={{ __html: html || '<div></div>' }} />;
  }
  return <div></div>;
};

export default Fallback;
