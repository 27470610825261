import { getData, URLs } from '@common/api';
import { processResponse } from '@common/models/Order';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { postData } from './../../api/index';
import {
  cancelOrderFailure,
  cancelOrderRequest,
  cancelOrderSuccess,
  fetchUserOrderFailure,
  fetchUserOrderRequest,
  fetchUserOrderSuccess,
  OTPArgs,
  resendOTPFailure,
  resendOTPRequest,
  resendOTPSuccess,
  sendOTPFailure,
  sendOTPRequest,
  sendOTPSuccess,
  UserOrderArgs,
} from './slice';

/* eslint-disable-next-line */
function* fetchUserOrderSaga({ payload: {url, params} }: PayloadAction<UserOrderArgs>) {
  try {
    let { data } = yield call(() => getData({ url, params }));
    data = processResponse(data);
    yield put(fetchUserOrderSuccess(data));
  } catch (e) {
    yield put(fetchUserOrderFailure('Request failed with: ' + e));
  }
}

function* cancelOrderSaga({ payload: { orderId, otpNumber } }: PayloadAction<OTPArgs>) {
  const requestData = {
    order_id: orderId,
    otp: otpNumber,
  };
  try {
    let { data } = yield call(() => postData({ url: URLs.CANCEL_ORDER, data: requestData }));
    data = processResponse(data);
    yield put(cancelOrderSuccess(data));
  } catch (e) {
    yield put(cancelOrderFailure(e.response.data));
  }
}

function* sendOTPSaga({ payload: orderId }: PayloadAction<string>) {
  const requestData = {
    order_id: orderId,
  };
  try {
    const { data } = yield call(() =>
      postData({ url: URLs.SEND_OTP_COD_ORDER_CANCELLATION.replace('{id}', `${orderId}`), data: requestData }),
    );
    yield put(sendOTPSuccess(data));
  } catch (e) {
    yield put(sendOTPFailure(e.response?.data));
  }
}

function* resendOTPSaga({ payload: orderId }: PayloadAction<string>) {
  const requestData = {
    order_id: orderId,
  };
  try {
    const { data } = yield call(() => postData({ url: URLs.RESEND_OTP_COD_ORDER_CANCELLATION, data: requestData }));
    // data = processResponse(data);
    yield put(resendOTPSuccess(data));
  } catch (e) {
    yield put(resendOTPFailure(e.response?.data));
  }
}

export default function* userOrderSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchUserOrderRequest.type, fetchUserOrderSaga);
  yield takeEvery(cancelOrderRequest.type, cancelOrderSaga);
  yield takeEvery(sendOTPRequest.type, sendOTPSaga);
  yield takeEvery(resendOTPRequest.type, resendOTPSaga);
}
