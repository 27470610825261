import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

import { context } from './constants';

export const initializeErrorTracking = (): void => {
  try {
    if (context.SENTRY_DSN && context.SENTRY_ENV) {
      Sentry.init({
        dsn: context.SENTRY_DSN,
        environment: context.SENTRY_ENV,
      });
    } else {
      console.log('Skipping Sentry initialization due to missing configuration');
    }
  } catch (e) {
    console.error('Initializing Error Logger Failed:', e);
  }
};

export const logError = (error: unknown): void => {
  try {
    Sentry?.captureException(error);
  } catch (e) {
    console.log('Logging Error Failed:', e);
  }
};

export const isFormError = (e: AxiosError): boolean =>
  e?.response?.status === 400 && e?.response?.headers?.['content-type'] === 'application/json';

export const isUnauthorizedError = (e: AxiosError): boolean => e?.response?.status === 401;

export const isForbiddenError = (e: AxiosError): boolean => e?.response?.status === 403;
