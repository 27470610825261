import '@common/styles/layouts.css';

import { RootState } from '@common/store';
import { fetchHtmlBlockRequest, HtmlBlockSliceType } from '@common/store/htmlBlock/slice';
import InnerHTML from 'dangerously-set-html-content';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ADD_NEW_SECTION_ID } from '../../common/utils/constants';

export interface HtmlBlockProps {
  id: string;
  layout: string;
  fetchHtmlBlockRequest: fetchHtmlBlockRequest.type;
  htmlBlock: HtmlBlockSliceType;
}

class HtmlBlock extends PureComponent<HtmlBlockProps> {
  constructor(props) {
    super(props);
    const { fetchHtmlBlockRequest, id, htmlBlock } = this.props;
    if (!htmlBlock[id]?.isFetched && !htmlBlock[id]?.isFetching && id !== ADD_NEW_SECTION_ID) {
      fetchHtmlBlockRequest(id);
    }
  }

  render(): React.ReactElement {
    const { htmlBlock, id } = this.props;
    const section = htmlBlock[id]?.htmlBlock;
    return section && section?.htmlBlock ? (
      <div className="container mx-auto layout-container py-40 html-block" id={id}>
        <InnerHTML html={section.htmlBlock} />
        {/* {section && layout ? <div className="" dangerouslySetInnerHTML={{ __html: section.htmlBlock }}></div> : null} */}
      </div>
    ) : null;
  }
}

export default connect(
  ({ htmlBlock }: RootState) => ({
    htmlBlock,
  }),
  {
    fetchHtmlBlockRequest,
  },
)(HtmlBlock);
