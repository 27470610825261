import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import StoreInfo from '../../models/StoreInfo';

export interface StoreInfoSliceType {
  storeInfo?: StoreInfo;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const storeInfoSlice = createSlice({
  name: 'storeInfo',
  initialState: <StoreInfoSliceType>{
    storeInfo: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchStoreInfoRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      // state.storeInfo = null;
      state.error = '';
    },
    fetchStoreInfoSuccess(state, { payload }: PayloadAction<StoreInfo>) {
      state.storeInfo = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchStoreInfoFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const { fetchStoreInfoSuccess, fetchStoreInfoRequest, fetchStoreInfoFailure } = storeInfoSlice.actions;

export default storeInfoSlice.reducer;
