import { getData, URLs } from '@common/api';
import * as MetaTags from '@common/models/MetaTags';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchMetaTagsFailure, fetchMetaTagsRequest, fetchMetaTagsSuccess } from './slice';

function* fetchMetaTagsSaga() {
  try {
    let data = yield call(() =>
      getData({
        url: URLs.GET_METATAGS,
      }),
    );
    data = MetaTags.processResponse(data?.data);
    yield put(fetchMetaTagsSuccess(data));
  } catch (e) {
    yield put(fetchMetaTagsFailure('Request failed with: ' + e));
  }
}

export default function* metaTagsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchMetaTagsRequest.type, fetchMetaTagsSaga);
}
