import '@indexcss';

import { loadableReady } from '@loadable/component';
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from '../serviceWorker';
import App from './App';

if (process.env.NODE_ENV === 'production') {
  serviceWorker.register();
  loadableReady(() => {
    ReactDOM.hydrate(<App />, document.getElementById('root'));
  });
} else {
  loadableReady(() => {
    ReactDOM.hydrate(<App />, document.getElementById('root'));
  });
}
// Learn more about service workers: https://bit.ly/CRA-PWA
