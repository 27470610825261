import { getData, URLs } from '@common/api';
import * as GoogleOAuth from '@common/models/GoogleOAuth';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchGoogleOauthDataFailure, fetchGoogleOauthDataRequest, fetchGoogleOauthDataSuccess } from './slice';

function* fetchGoogleOauthDataSaga() {
  try {
    let data = yield call(() =>
      getData({
        url: URLs.GET_GOOGLE_OAUTH_DATA,
      }),
    );
    data = GoogleOAuth.processResponse(data.data);
    yield put(fetchGoogleOauthDataSuccess(data));
  } catch (e) {
    yield put(fetchGoogleOauthDataFailure('Request failed with: ' + e));
  }
}

export default function* referralsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchGoogleOauthDataRequest.type, fetchGoogleOauthDataSaga);
}
