import useBottomFooter from '@common/hooks/useBottomFooter';
import useNudge from '@common/hooks/useNudge';
import { ReactComponent as WhatsApp } from '@common/icons/WhatsApp.svg';
import { isMobile } from '@common/utils';
import classnames from 'classnames';
import React from 'react';

import NudgeItem from './NudgeItem';

interface NudgeProps {
  slug?: string;
}

const Nudge: React.FC<NudgeProps> = ({ slug }) => {
  const { nudgeList, showNudges, nudge, handleModalOpen } = useNudge(slug);
  const { showFooterNudge, showSignUpAtInstamojo } = useBottomFooter();
  if (showNudges) {
    if (nudge && slug === 'enable-whatsapp-widget-desktop') {
      return (
        <div
          className={classnames('flex flex-col items-end fixed bottom-0 right-0 mr-20 z-30', {
            'mb-20': !showSignUpAtInstamojo,
            'mb-90': showSignUpAtInstamojo && !showFooterNudge && !isMobile(),
            'mb-80': showSignUpAtInstamojo && !showFooterNudge && isMobile(),
            'mb-130': showSignUpAtInstamojo && showFooterNudge && !isMobile(),
            'mb-140': showSignUpAtInstamojo && showFooterNudge && isMobile(),
          })}
        >
          <NudgeItem
            slug={nudge.slug}
            name={nudge.name}
            text={nudge.nudgeText}
            modalText={nudge?.modalText}
            ctaText={nudge.ctaText}
            ctaUrl={nudge.ctaUrl}
            modalCtaText={nudge?.modalCtaText}
            modalCtaUrl={nudge?.modalCtaUrl}
            isUserOnPremiumPlan={nudgeList.isUserOnPremiumPlan}
            handleModalOpen={handleModalOpen}
          />
          <WhatsApp className="mt-4" />
        </div>
      );
    }
    return nudge ? (
      <NudgeItem
        slug={nudge.slug}
        name={nudge.name}
        text={nudge.nudgeText}
        modalText={nudge?.modalText}
        ctaText={nudge.ctaText}
        ctaUrl={nudge.ctaUrl}
        modalCtaText={nudge?.modalCtaText}
        modalCtaUrl={nudge?.modalCtaUrl}
        isUserOnPremiumPlan={nudgeList.isUserOnPremiumPlan}
        handleModalOpen={handleModalOpen}
      />
    ) : null;
  }
  return null;
};

export default Nudge;
