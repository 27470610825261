import { SectionType } from '@common/models/sections';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FaqSliceType {
  sections?: Array<SectionType>;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const faqSlice = createSlice({
  name: 'faq',
  initialState: <FaqSliceType>{
    sections: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchFaqRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.sections = null;
      state.error = '';
    },
    fetchFaqSuccess(state, { payload }: PayloadAction<Array<SectionType>>) {
      state.sections = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchFaqFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const { fetchFaqSuccess, fetchFaqRequest, fetchFaqFailure } = faqSlice.actions;

export default faqSlice.reducer;
