import { getData, URLs } from '@common/api';
import * as Product from '@common/models/Product';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchSearchFailure, fetchSearchRequest, FetchSearchRequestPayloadType, fetchSearchSuccess } from './slice';

function* fetchSearchSaga({ payload: { queryParams, storeKey } }: PayloadAction<FetchSearchRequestPayloadType>) {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_SEARCH, params: queryParams }));
    data.results = data.results?.map((process) => Product.processResponse(process));
    yield put(fetchSearchSuccess({ search: data, queryParams, storeKey }));
  } catch (e) {
    yield put(fetchSearchFailure({ storeKey, error: 'Request failed with: ' + e }));
  }
}

export default function* searchSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchSearchRequest.type, fetchSearchSaga);
}
