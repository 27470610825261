import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ReportAbuseSliceType {
  showReportAbuseLink?: boolean;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const reportAbuseSlice = createSlice({
  name: 'reportAbuse',
  initialState: <ReportAbuseSliceType>{
    showReportAbuseLink: false,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchReportAbuseRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.showReportAbuseLink = false;
      state.error = '';
    },
    fetchReportAbuseSuccess(state, { payload }: PayloadAction<boolean>) {
      state.showReportAbuseLink = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchReportAbuseFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const { fetchReportAbuseSuccess, fetchReportAbuseRequest, fetchReportAbuseFailure } = reportAbuseSlice.actions;

export default reportAbuseSlice.reducer;
