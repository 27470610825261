interface HtmlBlock {
  id: number;
  layout: Record<string, unknown>;
  htmlBlock: HTMLElement;
}

export const processResponse = (htmlBlock: Record<string, unknown>): HtmlBlock => {
  return {
    id: htmlBlock.id,
    layout: htmlBlock.layout,
    htmlBlock: htmlBlock.html_block,
  };
};
