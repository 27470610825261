import { useWindowSize } from '@common/../lib-components/Carousel';
import { SliderSliceType } from '@common/store/slider/slice';
import { getLambdaLink, isMobile } from '@common/utils';
import {
  BANNER_IMAGE_HEIGHT,
  BANNER_IMAGE_WIDTH,
  MOBILE_BANNER_IMAGE_HEIGHT,
  MOBILE_BANNER_IMAGE_WIDTH,
} from '@theme/utils/constants';

interface Slider {
  slider: number;
  image: string;
  heading: string;
  subheading: string;
  link: string;
  type: string;
  label?: string;
}

export const processResponse = (slider: Record<string, unknown>): Slider => {
  const { width, height } = isMobile()
    ? { width: MOBILE_BANNER_IMAGE_WIDTH, height: MOBILE_BANNER_IMAGE_HEIGHT }
    : { width: BANNER_IMAGE_WIDTH, height: BANNER_IMAGE_HEIGHT };

  return {
    slider: slider.slider as number,
    image: slider.image ? getLambdaLink({ link: slider.image as string, width, height }) : null,
    heading: slider.is_custom_text_required ? (slider.heading as string) : '',
    subheading: slider.is_custom_text_required ? (slider.subheading as string) : '',
    link: slider.show_button ? (slider.link as string) : '',
    type: slider.type as string,
    label: slider.show_button ? ((slider.slider_button_label || 'Shop Now') as string) : '',
  };
};

export default Slider;

export const getFilteredSliderImages = ({
  slider,
  shouldImageBePresent,
}: {
  slider: SliderSliceType;
  shouldImageBePresent?: boolean;
}): Slider[] => {
  // console.log('SLIDER', slider, shouldImageBePresent);
  let filteredImages = slider.slider?.results?.filter((item) => item?.image || item?.heading || item?.subheading);
  const { width } = useWindowSize();
  if (width <= 768) {
    filteredImages = filteredImages?.filter((item) => item?.type !== 'web');
  } else {
    filteredImages = filteredImages?.filter((item) => item?.type !== 'mobile');
  }

  if (shouldImageBePresent) {
    filteredImages = filteredImages?.filter((item) => item?.image);
  }

  return filteredImages;
};
