import * as CategoriesProductsList from '@common/models/CategoriesProductsList';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CategoriesProductsListSliceType {
  result: Array<{ results: CategoriesProductsList.CategoriesProductsList[] }>;
  id: number;
  categoriesProductsList: CategoriesProductsList.CategoriesProductsList[];
}

const categoriesProductsListSlice = createSlice({
  name: 'categoriesProductsList',
  initialState: {},
  reducers: {
    fetchCategoriesProductsListRequest(state, { payload }) {
      const categoriesProductsListObject = {
        isFetching: true,
        isFetched: false,
        results: null,
        error: '',
      };
      state[payload.id] = categoriesProductsListObject;
    },
    fetchCategoriesProductsListSuccess(state, { payload }: PayloadAction<CategoriesProductsListSliceType>) {
      const categoriesProductsListObject = {
        isFetching: false,
        isFetched: true,
        results: payload.result.results,
      };
      state[payload.id] = categoriesProductsListObject;
    },
    fetchNextCategoriesProductsListRequest(state, { payload }: PayloadAction<CategoriesProductsListSliceType>) {
      const categoriesProductsListObject = {
        isNextFetching: true,
        isNextFetched: false,
        results: [...payload.categoriesProductsList[payload.id].results],
      };
      state[payload.id] = categoriesProductsListObject;
    },
    fetchNextCategoriesProductsListSuccess(state, { payload }: PayloadAction<CategoriesProductsListSliceType>) {
      const categoriesProductsListObject = {
        isNextFetching: false,
        isNextFetched: true,
        results: [...payload.categoriesProductsList[payload.id].results, ...payload.result.results],
      };
      state[payload.id] = categoriesProductsListObject;
    },
    fetchNextCategoriesProductsListFailure(state, { payload }: PayloadAction<CategoriesProductsListSliceType>) {
      const categoriesProductsListObject = {
        isNextFetching: false,
        isNextFetched: true,
        results: payload,
      };
      state[payload.id] = categoriesProductsListObject;
    },
    fetchCategoriesProductsListFailure(state, { payload }: PayloadAction<CategoriesProductsListSliceType>) {
      const categoriesProductsListObject = {
        isFetching: false,
        isFetched: true,
        results: payload,
      };
      state[payload.id] = categoriesProductsListObject;
    },
  },
});

export const {
  fetchCategoriesProductsListFailure,
  fetchCategoriesProductsListRequest,
  fetchCategoriesProductsListSuccess,
  fetchNextCategoriesProductsListRequest,
  fetchNextCategoriesProductsListSuccess,
  fetchNextCategoriesProductsListFailure,
} = categoriesProductsListSlice.actions;

export default categoriesProductsListSlice.reducer;
