import useBottomFooter from '@common/hooks/useBottomFooter';
import { ReactComponent as InstamojoFooterLogo } from '@common/icons/InstamojoFooterLogo.svg';
import BrandingImage from '@common/icons/security-seals.png';
import { isBrowser, isMobile } from '@common/utils';
import { getMerchantJWTToken } from '@common/utils/token';
import Image from '@lib/Image';
import classnames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { context } from '../../utils/constants';
import Nudge from '../Nudge';

export interface BrandingProps {
  className: string;
  showTrustBadge: boolean;
}

const Branding: React.FunctionComponent<BrandingProps> = ({ className, showTrustBadge = true }) => {
  const username = useSelector((state: unknown) => state?.storeInfo?.storeInfo?.username)
    ?.split('-')
    ?.join('_');
  const showReportAbuseLink = useSelector((state: unknown) => state?.reportAbuse?.showReportAbuseLink);
  const signUpAtInstamojoUrl = `${context.BASE_INSTAMOJO_URL}online-store/?utm_source=store-referral&utm_medium=merchant-store&utm_campaign=online-store-themes`;
  const { showSignUpAtInstamojo, showFooterNudge } = useBottomFooter();
  let reportAbuseUrl;

  if (isBrowser()) {
    reportAbuseUrl = `${context.BASE_INSTAMOJO_URL}report-abuse?seller=${username}&productType=2&productLink=${window.location.href}`;
  }
  return (
    <>
      {showSignUpAtInstamojo ? (
        <>
          <div
            className={classnames('container mx-auto', className, {
              'pb-80': showSignUpAtInstamojo && !showFooterNudge && !isMobile(),
              'pb-70': showSignUpAtInstamojo && !showFooterNudge && isMobile(),
              'pb-110': showSignUpAtInstamojo && showFooterNudge && !isMobile(),
              'pb-120': showSignUpAtInstamojo && showFooterNudge && isMobile(),
            })}
          >
            <div
              className={classnames('flex justify-center items-center', {
                'flex-row': !isMobile(),
                'flex-col-reverse': isMobile(),
              })}
            >
              {showReportAbuseLink ? (
                <a
                  className="text-theme underline text-12 mb-6"
                  href={reportAbuseUrl}
                  font-customisation="para-text"
                  color-customisation="page-body-text"
                >
                  Report this store
                </a>
              ) : null}
              {showTrustBadge ? (
                <Image src={BrandingImage} className="mt-40 mb-44 max-w-720 mx-auto" alt="Payment types" />
              ) : null}
            </div>
          </div>
          {showSignUpAtInstamojo ? (
            <div
              className={classnames(
                'fixed z-100 bottom-0 w-full text-center text-13 py-22 border-t border-branding-border',
                {
                  'bg-branding-bg': getMerchantJWTToken(),
                  'bg-branding-yellowbg': !getMerchantJWTToken(),
                },
              )}
              style={{ backdropFilter: 'blur(15px)' }}
            >
              <div className="container mx-auto">
                <div className="flex items-center justify-center">
                  <InstamojoFooterLogo className="block bg-white" />
                  <div className="mx-12 text-branding-text">Create your own online store for free.</div>
                  <a href={signUpAtInstamojoUrl} target="_blank" rel="noreferrer" className="text-branding-link block">
                    Sign Up Now
                  </a>
                </div>
                {showFooterNudge ? (
                  <div className="mt-8 w-max mx-auto">
                    <Nudge slug={`remove-instamojo-branding-${isMobile() ? 'mobile-web' : 'desktop'}`} />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default Branding;
