export default interface RichTextEditor {
  id: number;
  layout: Record<string, unknown>;
  content: HTMLElement;
  identifier: string;
}

export const processResponse = (richTextEditor: Record<string, never>): RichTextEditor => {
  return {
    id: richTextEditor.id,
    layout: richTextEditor.layout,
    content: richTextEditor.content,
    identifier: richTextEditor.identifier,
  };
};
