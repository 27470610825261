import Text from '@common/models/RichTextEditor';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RichTextEditorSliceType {
  sections?: Record<number, Text>;
}

export interface RichEditorTextResponseType {
  id: number;
  result: Text;
}

const richTextEditorSlice = createSlice({
  name: 'richTextEditor',
  initialState: <RichTextEditorSliceType>{
    sections: {},
  },
  reducers: {
    fetchRichTextEditorRequest(state, { payload }) {
      const textObject = {
        isFetching: true,
        isFetched: false,
        richTextEditor: null,
        error: '',
      };
      state[payload.id] = textObject;
    },
    fetchRichTextEditorSuccess(state, { payload }: PayloadAction<RichEditorTextResponseType>) {
      const textObject = {
        isFetching: false,
        isFetched: true,
        richTextEditor: payload.result,
        error: '',
      };
      state[payload.id] = textObject;
    },
    fetchRichTextEditorFailure() {
      // console.log(_payload);
    },
    editRichTextEditor(state, { payload }: unknown) {
      const textObject = {
        isFetching: false,
        isFetched: true,
        richTextEditor: payload.result,
        error: '',
      };
      state[payload.id] = textObject;
    },
  },
});

export const {
  fetchRichTextEditorSuccess,
  fetchRichTextEditorRequest,
  fetchRichTextEditorFailure,
  editRichTextEditor,
} = richTextEditorSlice.actions;

export default richTextEditorSlice.reducer;
