import { getData, URLs } from '@common/api';
import * as Section from '@common/models/sections';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchHomeFailure, fetchHomeRequest, fetchHomeSuccess } from './slice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* fetchHomeSaga() {
  try {
    const data = yield call(() =>
      getData({
        url: URLs.GET_HOME,
      }),
    );
    const sections = Section.processResponse(data?.data?.sections);
    yield put(fetchHomeSuccess(sections));
  } catch (e) {
    yield put(fetchHomeFailure('Request failed with: ' + e));
  }
}

export default function* homeSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchHomeRequest.type, fetchHomeSaga);
}
