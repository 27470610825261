import { addProductToCart, CartSliceType, subtractProductFromCart } from '@common/store/cart/slice';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { StoreInfoSliceType } from '@common/store/storeInfo/slice';
import { addToast } from '@common/store/toasts/slice';
import { fetchMoreUserSectionsRequest, UserSectionsSliceType } from '@common/store/userSections/slice';
import { getQueryParams, sectionTypeMap } from '@common/utils/constants';
import CategoryList from '@layouts/CategoryList';
import HtmlBlock from '@layouts/HtmlBlock';
import ImageAndText from '@layouts/ImageAndText';
import ProductList from '@layouts/ProductList';
import RichTextEditor from '@layouts/RichTextEditor';
import Slider from '@layouts/Slider';
import StoreUpdate from '@layouts/StoreUpdate';
import Testimonials from '@layouts/Testimonials';
import TextLayout from '@layouts/TextLayout';
import { LAYOUT_CONFIG } from '@theme/utils/constants';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../common/store';
import LoadMore from '../HomeLayout/LoadMore';
import ScrollRef from '../HomeLayout/ScrollRef';

interface UserSectionProps {
  userSections: UserSectionsSliceType;
  fetchMoreUserSectionsRequest: typeof fetchMoreUserSectionsRequest;
  addToast: typeof addToast;
  cart: CartSliceType;
  storeInfo: StoreInfoSliceType;
  addProductToCart: typeof addProductToCart;
  subtractProductFromCart: typeof subtractProductFromCart;
}

class UserSection extends PureComponent<UserSectionProps> {
  constructor(props: UserSectionProps) {
    super(props);
  }

  hasPrevSectionsLoaded() {
    const sections = this.props.userSections.userSections;

    return sections.every(({ sectionType, id }) => {
      const section = sectionTypeMap[sectionType];
      if (section === 'storeUpdate') return true;
      return this.props[section]?.[id]?.isFetched === true;
    });
  }

  render(): JSX.Element {
    const { userSections, addToast, cart, addProductToCart, subtractProductFromCart, storeInfo } = this.props;
    const queryParams = getQueryParams();

    return (
      <>
        {userSections.userSections
          .map((section, index) => {
            return (
              <>
                <div id={`${section.id}-scroll`} className="section-scroll"></div>
                {LAYOUT_CONFIG.homepageAnimationEnabled ? <ScrollRef sectionId={section.id} /> : null}
                {(() => {
                  switch (section.sectionType) {
                    case 'Hero Banner':
                      return (
                        <Slider
                          key={section.id}
                          id={section.id}
                          isFirstSection={index === 1}
                          previewQueryLayout={queryParams['HB']}
                        />
                      );

                    case 'Text':
                      return <TextLayout key={section.id} id={section.id} previewQueryLayout={queryParams['Text']} />;

                    case 'Image & Text':
                      return (
                        <ImageAndText key={section.id} id={section.id} previewQueryLayout={queryParams['ImageText']} />
                      );

                    case 'Rich Text Editor':
                      return (
                        <RichTextEditor
                          key={section.id}
                          name={`rte-${section.id}`}
                          id={section.id}
                          layoutName={queryParams['rte'] || section.layoutName}
                        />
                      );
                    case 'Product Showcase':
                      section = section as ProductsSection;
                      return (
                        <ProductList
                          id={section.id}
                          addToast={addToast}
                          cart={cart}
                          storeInfo={storeInfo}
                          addProductToCart={addProductToCart}
                          subtractProductFromCart={subtractProductFromCart}
                          key={section.id}
                          previewQueryLayout={queryParams['PS']}
                        />
                      );
                    case 'Category Showcase':
                      return <CategoryList key={section.id} id={section.id} previewQueryLayout={queryParams['CS']} />;

                    case 'HTML Block':
                      return (
                        <HtmlBlock
                          key={section.id}
                          id={section.id}
                          layoutName={queryParams['html-block'] || section.layoutName}
                        />
                      );

                    case 'Testimonial':
                      return (
                        <Testimonials
                          key={section.id}
                          id={section.id}
                          previewQueryLayout={queryParams['Testimonial']}
                        />
                      );

                    case 'Store Update':
                      return <StoreUpdate key={section.id} id={section.id} />;

                    default:
                      return null;
                  }
                })()}
              </>
            );
          })
          .filter((section) => {
            return section;
          })}
        <LoadMore
          hasPrevSectionsLoaded={() => this.hasPrevSectionsLoaded()}
          fetchMoreUserSections={this.props.fetchMoreUserSectionsRequest}
        />
        <div id="page-footer-scroll"></div>
      </>
    );
  }
}

export default connect(
  ({
    heroBanner,
    pageHeader,
    text,
    imageAndText,
    richTextEditor,
    productList,
    categoryList,
    htmlBlock,
    storeTestimonials,
  }: RootState) => ({
    categoryList,
    heroBanner,
    pageHeader,
    text,
    imageAndText,
    richTextEditor,
    productList,
    htmlBlock,
    storeTestimonials,
  }),
  {},
)(UserSection);
