import { SectionType } from '@common/models/sections';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PoliciesSliceType {
  policyType?: string;
  sections?: Array<SectionType>;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const policiesSlice = createSlice({
  name: 'policies',
  initialState: <PoliciesSliceType>{
    policyType: null,
    sections: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchPoliciesRequest(state, { payload: policyType }: PayloadAction<string>) {
      state.policyType = policyType;
      state.isFetching = true;
      state.isFetched = false;
      state.sections = null;
      state.error = '';
    },
    fetchPoliciesSuccess(state, { payload }: PayloadAction<Array<SectionType>>) {
      state.sections = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchPoliciesFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const { fetchPoliciesSuccess, fetchPoliciesRequest, fetchPoliciesFailure } = policiesSlice.actions;

export default policiesSlice.reducer;
