import { CartProductType } from '../store/cart/slice';
import * as CartProduct from './CartProduct';

export interface Cart {
  promoCode?: string;
  totalPrice: number;
  totalItems: number;
  items: CartProduct.default[];
  source: 'web';
}

export const processCartRequest = (cart: {
  totalPrice: number;
  totalItems: number;
  promoCode: string;
  items: Array<CartProductType>;
}): Cart => {
  const items = cart.items.map((item) => CartProduct.processProductRequest(item));

  return {
    promoCode: cart.promoCode,
    totalPrice: cart.totalPrice,
    totalItems: cart.totalItems,
    items,
    source: 'web',
  };
};

export interface CartResponse {
  id: number;
  cart_type: number;
  firstname: string;
  lastname: string;
  email: string;
  contact_number: string;
  cart_json: Record<string, unknown>;
  itemlist: [
    {
      title: string;
      product_type: string;
      pay_what_you_want: boolean;
      id: number;
      g: number;
      quantity: number;
      stock: number;
      moq: string;
      image: string;
      sku: string;
      maxoq: string;
      price: number;
      final_price: number;
      cod: boolean;
      bank: boolean;
      categories?: number[] | null;
      store_link: string;
      discounted_price: number;
      value_capping?: null;
      shipping_charge: number;
      cr_id: string;
      cr_code: string;
      prepaid: boolean;
      returns: boolean;
      replace: boolean;
    },
  ];
  promo: {
    entered_promo: string;
    promo_code: string;
    total_order: string;
    discount: string;
  };
  total_items: number;
  total_price: number;
  discounted_total_price: string;
  error_items?: null[] | null;
  total_order: number;
  final_discounted_price: number;
  ws_breakup: {
    storelevel: {
      leftover_total: number;
      discount_percent: number;
      leftover_discounted: number;
    };
    // itemlevel: Itemlevel;
    discounted_price: number;
    total_discount: number;
  };
  total_value: string;
  address: {
    id: number;
    firstname: string;
    lastname: string;
    address: string;
    city: string;
    state: {
      id: number;
      code: string;
      name: string;
    };
    country: {
      code: string;
      name: string;
    };
    code: string;
    contact: string;
  };
  created: string;
  modified: string;
}

export default Cart;
