import { getData, URLs } from '@common/api';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import * as MDPAppScript from '../../models/MDPAppScript';
import { fetchMDPAppScriptsFailure, fetchMDPAppScriptsRequest, fetchMDPAppScriptsSuccess } from './slice';

function* fetchMDPAppScriptsSaga() {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_MDP_APP_SCRIPTS }));

    data.scripts = data.scripts?.map((appScript) => MDPAppScript.processResponse(appScript));
    yield put(fetchMDPAppScriptsSuccess({ response: data }));
  } catch (e) {
    yield put(fetchMDPAppScriptsFailure('Request failed with: ' + e));
  }
}

export default function* mdpAppScriptsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchMDPAppScriptsRequest.type, fetchMDPAppScriptsSaga);
}
