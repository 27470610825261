import { getData, URLs } from '@common/api';
import * as UserSection from '@common/models/UserSection';
import { fetchSliderSaga } from '@common/store/heroBanner/sagas';
import { fetchHtmlBlockSaga } from '@common/store/htmlBlock/sagas';
import { fetchImageAndTextSaga } from '@common/store/imageAndText/sagas';
import { fetchPageHeaderSaga } from '@common/store/pageHeader/sagas';
import { fetchRichTextEditorSaga } from '@common/store/richTextEditor/sagas';
import { fetchTextSaga } from '@common/store/text/sagas';
import { all, call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects';

import { isBrowser } from '../../utils/index';
import {
  fetchCustomPageUserSectionFailure,
  fetchCustomPageUserSectionRequest,
  fetchCustomPageUserSectionSuccess,
  fetchMoreCustomPageUserSectionsRequest,
  fetchMoreCustomPageUserSectionsSuccess,
  updateCurrentPage,
  updateCustomPageCount,
} from './slice';

const urlParams = new URLSearchParams(isBrowser() && window.location.search);
const isMerchant = urlParams.get('merchant');

const NUMBER_OF_SECTIONS_PER_REQUEST = isMerchant ? 500 : 6;

export function* fetchCustomPageUserSectionSaga({ payload }: Record<string, string>): Generator<unknown> {
  try {
    const data = yield call(() =>
      getData({
        url: `${URLs.GET_USER_SECTIONS.replace(
          '{storePageName}',
          payload,
        )}?limit=${NUMBER_OF_SECTIONS_PER_REQUEST}&page=1&is_visible=true`,
      }),
    );
    const sections = data?.data?.results.map((section) => {
      return UserSection.processResponse(section);
    });
    const yields = sections
      .map((section) => {
        switch (section.sectionType) {
          case 'Page Header':
            return call(fetchPageHeaderSaga, { payload: section.id });
          case 'Rich Text Editor':
            return call(fetchRichTextEditorSaga, { payload: section.id });
          case 'Hero Banner':
            return call(fetchSliderSaga, { payload: section.id });
          case 'Image & Text':
            return call(fetchImageAndTextSaga, { payload: section.id });
          case 'Text':
            return call(fetchTextSaga, { payload: section.id });
          case 'HTML Block':
            return call(fetchHtmlBlockSaga, { payload: section.id });
        }
      })
      .filter((section) => section);
    yield all(yields);
    yield put(fetchCustomPageUserSectionSuccess({ sections, isMerchant }));
    yield put(updateCustomPageCount(data.data.count));
  } catch (e) {
    yield put(fetchCustomPageUserSectionFailure('Request failed with: ' + e));
  }
}

function* fetchMoreCustomPageUserSectionsSaga({ payload }) {
  try {
    const userSections = yield select((store) => {
      return store.customPageUserSections;
    });
    const { currentPage, count } = userSections;
    const { length } = userSections.userSections;
    if (length && length < count) {
      const data = yield call(() =>
        getData({
          url: `${URLs.GET_USER_SECTIONS.replace(
            '{storePageName}',
            payload,
          )}?limit=${NUMBER_OF_SECTIONS_PER_REQUEST}&page=${isMerchant ? 1 : currentPage + 1}&is_visible=true`,
        }),
      );
      const sections = data?.data?.results.map((section) => {
        return UserSection.processResponse(section);
      });
      yield put(fetchMoreCustomPageUserSectionsSuccess({ sections, isMerchant }));
      yield put(updateCurrentPage(currentPage + 1));
    }
  } catch (e) {
    yield put(fetchCustomPageUserSectionFailure('Request failed with: ' + e));
  }
}

export default function* customPageUserSectionSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchCustomPageUserSectionRequest.type, fetchCustomPageUserSectionSaga);
  yield takeEvery(fetchMoreCustomPageUserSectionsRequest.type, fetchMoreCustomPageUserSectionsSaga);
}
