import { SectionType } from '@common/models/sections';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ContactSliceType {
  sections?: Array<SectionType>;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const contactSlice = createSlice({
  name: 'contact',
  initialState: <ContactSliceType>{
    sections: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchContactRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.sections = null;
      state.error = '';
    },
    fetchContactSuccess(state, { payload }: PayloadAction<Array<SectionType>>) {
      state.sections = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchContactFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const { fetchContactSuccess, fetchContactRequest, fetchContactFailure } = contactSlice.actions;

export default contactSlice.reducer;
