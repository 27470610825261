import { deleteData, getData, patchData, postData, processUrl, URLs } from '@common/api';
import { CartResponse } from '@common/models/Cart';
import * as Order from '@common/models/Order';
import * as Product from '@common/models/Product';
import * as ProductBundle from '@common/models/ProductBundle';
import ShippingAddress from '@common/models/ShippingAddress';

interface postAddressApiInterface {
  address: ShippingAddress;
}

const postUserAddressApi = async ({ address }: postAddressApiInterface): Promise<void> => {
  const { data } = await postData({
    url: URLs.POST_USER_ADDRESS,
    data: address,
  });
  return data;
};

const getUserAddressListApi = async (): Promise<Array<ShippingAddress>> => {
  const { data } = await getData({
    url: URLs.GET_USER_ADDRESSES,
  });
  return data.results;
};

interface removeUserAddressApiInterface {
  id: number;
}

const removeUserAddressApi = async ({ id }: removeUserAddressApiInterface): Promise<void> => {
  const { data } = await deleteData({ url: URLs.DELETE_USER_ADDRESS.replace('{id}', `${id}`) });
  return data;
};

const getUserAddressByIdApi = async (addressId: string): Promise<ShippingAddress> => {
  const { data } = await getData({ url: URLs.GET_USER_ADDRESS.replace('{id}', `${addressId}`) });
  return data;
};

interface patchAddressApiInterface {
  address: ShippingAddress;
  addressId: string;
}

const patchUserAddressApi = async ({ addressId, address }: patchAddressApiInterface): Promise<void> => {
  const { data } = await patchData({
    url: URLs.PATCH_USER_ADDRESS.replace('{id}', addressId),
    data: address,
  });
  return data;
};

interface IGetOrderDetailApiArgs {
  hash?: string;
  email?: string;
}

const getPaymentStatus = async (orderid: string, email: string): Promise<Order.default> => {
  const { data } = await postData({
    url: URLs.POST_PAYMENT_STATUS,
    data: {
      orderid,
      email,
    },
  });
  return data;
};

const getOrderDetailApi = async (orderId: string, params: IGetOrderDetailApiArgs): Promise<Order.default> => {
  let { data } = await getData({
    url: URLs.GET_USER_ORDER_VIA_ORDER_ID.replace('{id}', `${orderId}`),
    params,
  });
  data = Order.processResponse(data);
  return data;
};

interface IValidateUserSessionResponse {
  msg: string;
  token: string;
  status: string;
}

const validateUserSessionApi = async (): Promise<IValidateUserSessionResponse> => {
  const { data } = await postData({
    url: processUrl(URLs.POST_USER_VALIDATE_SESSION),
  });
  return data;
};

const getCartDetailApi = async (hash: string): Promise<CartResponse> => {
  const publicCartURL = 'public_urls=true';
  const { data } = await getData({
    url: hash ? `${URLs.GET_CART}?cart_hash=${hash}&${publicCartURL}` : `${URLs.GET_CART}?${publicCartURL}`,
  });
  return data;
};

const getProductBundleApi = async (): Promise<ProductBundle.default> => {
  let { data } = await getData({
    url: `${URLs.GET_PRODUCT_BUNDLE_THANKYOU_PAGE}`,
  });

  data = ProductBundle.processResponse(data);
  if (data.products) {
    data.products = data.products?.map((product) => {
      return Product.processResponse(product);
    });
  }
  return data;
};

export {
  postUserAddressApi,
  getUserAddressListApi,
  removeUserAddressApi,
  getUserAddressByIdApi,
  patchUserAddressApi,
  getOrderDetailApi,
  validateUserSessionApi,
  getPaymentStatus,
  getCartDetailApi,
  getProductBundleApi,
};
