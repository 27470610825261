import { getData, URLs } from '@common/api';
import * as PageHeader from '@common/models/PageHeader';
import { Context as ContextInterface } from '@common/utils/constants';
import { call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects';

import { fetchPageHeaderFailure, fetchPageHeaderRequest, fetchPageHeaderSuccess } from './slice';

export function* fetchPageHeaderSaga({ payload }: Record<string, string>): Generator<unknown> {
  try {
    const context: ContextInterface = yield select((store) => {
      return store.context.context;
    });
    const merchantID = context.MERCHANT_ACCOUNT_ID;
    const data = yield call(() =>
      getData({
        url: URLs.GET_PAGE_HEADER.replace('{userSectionId}', payload).replace('{userId}', merchantID.toString()),
      }),
    );
    const result = PageHeader.processResponse(data.data);
    const { id, layout } = data?.data;
    yield put(fetchPageHeaderSuccess({ id: id, layout: layout, result }));
  } catch (e) {
    // console.log('ERROR', e);
    yield put(fetchPageHeaderFailure('Request failed with: ' + e));
  }
}

export default function* pageHeaderSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchPageHeaderRequest.type, fetchPageHeaderSaga);
}
