import Subdivision from '@common/models/geo/Subdivision';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SubdivisonQueryParams {
  country: string;
  page: number;
  limit: number;
}

export interface SubdivisionsResponseType {
  count: number;
  next: string;
  previous: string;
  results: Subdivision[];
}

export interface SubdivisionsSliceType {
  pagination: {
    currentPage: number;
    lastPage: number;
    multiplier: number;
  };
  subdivisions?: SubdivisionsResponseType;
  isFetching: boolean;
  isFetched: boolean;
  currentQueryParams?: SubdivisonQueryParams;
  error: string;
}

export const getSubdivisionsDefaultQueryParams = (): SubdivisonQueryParams => ({
  country: 'IN',
  page: 1,
  limit: 500,
});

const subdivisionInitialState = {
  pagination: {
    currentPage: 1,
    lastPage: 1,
    multiplier: 1,
  },
  subdivisions: null,
  isFetching: false,
  isFetched: false,
  currentQueryParams: null,
  error: '',
};

export interface FetchSubdivisionsRequestPayloadType {
  storeKey: string;
  queryParams: SubdivisonQueryParams;
}

interface FetchSubdivisionsSuccessPayloadType extends FetchSubdivisionsRequestPayloadType {
  subdivisions: SubdivisionsResponseType;
}

interface FetchSubdivisionsFailurePayloadType {
  storeKey: string;
  error: string;
}

const subdivisionsSlice = createSlice({
  name: 'subdivisions',
  initialState: {} as Record<string, SubdivisionsSliceType>,
  reducers: {
    resetSubdivisionsStore(state, { payload: storeKey }: PayloadAction<string>) {
      state[storeKey] = subdivisionInitialState;
    },
    fetchSubdivisionsRequest(
      state,
      { payload: { storeKey, queryParams } }: PayloadAction<FetchSubdivisionsRequestPayloadType>,
    ) {
      state[storeKey] = {
        ...subdivisionInitialState,
        isFetching: true,
        isFetched: false,
        subdivisions: null,
        currentQueryParams: queryParams,
        error: '',
      };
    },
    fetchSubdivisionsSuccess(
      state,
      { payload: { storeKey, subdivisions, queryParams } }: PayloadAction<FetchSubdivisionsSuccessPayloadType>,
    ) {
      state[storeKey].pagination = {
        currentPage: queryParams.page,
        lastPage: parseInt(`${(subdivisions.count - 1) / queryParams.limit + 1}`),
        multiplier: queryParams.limit,
      };
      state[storeKey].subdivisions = subdivisions;
      state[storeKey].isFetching = false;
      state[storeKey].isFetched = true;
    },
    fetchSubdivisionsFailure(
      state,
      { payload: { storeKey, error } }: PayloadAction<FetchSubdivisionsFailurePayloadType>,
    ) {
      state[storeKey].error = error;
      state[storeKey].isFetching = false;
      state[storeKey].isFetched = true;
      state[storeKey].subdivisions = null;
    },
  },
});

export const {
  resetSubdivisionsStore,
  fetchSubdivisionsSuccess,
  fetchSubdivisionsRequest,
  fetchSubdivisionsFailure,
} = subdivisionsSlice.actions;

export default subdivisionsSlice.reducer;
