import { saveFileProductOptions } from '../api';
import Product, { PRODUCT_OPTIONS_TYPE } from './Product';

export interface OptionValue {
  id: number;
  image: string | null;
  text: string;
  sortOrder: number;
}

export interface Option {
  formInputName: string;
  id: number;
  name: string;
  description: string;
  type: string;
  sortOrder: number;
  charges: number;
  chargeable: boolean;
  placeholder: string | null;
  required: boolean;
  values: OptionValue[];
}

export const processResponse = (option: Record<string, never>): Option => {
  const values = (option.values as Record<string, never>[])?.map((value) => ({
    id: value.id,
    image: value.image,
    text: value.text,
    sortOrder: value.sort_order,
  }));
  return {
    formInputName: `${option.id}-${option.name}`,
    id: option.id,
    name: option.name,
    description: option.description,
    type: option.type,
    sortOrder: option.sort_order,
    placeholder: option.placeholder,
    required: option.required,
    values,
    charges: +(option.charges || 0),
    chargeable: option.chargeable,
  };
};

export default Option;

export interface FormProductOptionValue {
  id: number;
  text: string;
}

export type FormProductOptionValueType = string | string[] | FormProductOptionValue | FormProductOptionValue[];
export interface FormProductOption {
  option: {
    id: number;
    chargeable: boolean;
    name: string;
    type: string;
    charges: number;
  };
  value: FormProductOptionValueType;
}

export interface GetFormProductOptionsReturnType {
  additionalCharges: number;
  formProductOptions: FormProductOption[];
}

export const getFormProductOptions = async (
  product: Product,
  values: Record<string, unknown>,
): Promise<GetFormProductOptionsReturnType> => {
  const formProductOptions = (
    await Promise.all(
      Object.keys(values)
        .filter((key) => !key.endsWith('^^Files'))
        .map(async (key) => {
          const productOption = product?.options?.find((option) => option.formInputName === key);
          let value = values[key] as FormProductOptionValueType;
          if (productOption.type === PRODUCT_OPTIONS_TYPE.CHECKBOX) {
            value = productOption.values
              .filter((productOptionValue) => Object.keys(values[key]).includes(productOptionValue.id.toString()))
              .map((productOptionValue) => ({
                id: productOptionValue.id,
                text: productOptionValue.text,
              }));
          }
          if (productOption.type === PRODUCT_OPTIONS_TYPE.RADIO) {
            value = productOption.values
              .filter((productOptionValue) => values[key] === productOptionValue.id.toString())
              .map((productOptionValue) => ({
                id: productOptionValue.id,
                text: productOptionValue.text,
              }))[0];
          }

          if (
            productOption.type === PRODUCT_OPTIONS_TYPE.FILE ||
            productOption.type === PRODUCT_OPTIONS_TYPE.MULTI_FILE
          ) {
            let files = values[`${key}^^Files`] ?? [];
            files = Array.from(files as FileList);
            if (!Array.isArray(files)) {
              return;
            }
            const links = (
              await Promise.all(
                files?.map((file) => {
                  const formData = new FormData();
                  formData.set('file', file);
                  return saveFileProductOptions(formData);
                }),
              )
            ).map((response) => response.data.file_url);
            value = links;
          }
          if (productOption.type === PRODUCT_OPTIONS_TYPE.FILE) {
            value = value[0];
          }

          return {
            option: {
              id: productOption.id,
              chargeable: productOption.chargeable,
              name: productOption.name,
              type: productOption.type,
              charges: productOption.charges,
            },
            value,
          };
        }),
    )
  ).filter((x) => x);
  return {
    formProductOptions: formProductOptions,
    additionalCharges: formProductOptions.reduce((acc, curr) => acc + curr.option.charges, 0),
  };
};
