import { getData, URLs } from '@common/api';
import * as Section from '@common/models/sections';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchFaqFailure, fetchFaqRequest, fetchFaqSuccess } from './slice';

function* fetchFaqSaga() {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_FAQ }));
    data.sections = Section.processResponse(data.sections);
    yield put(fetchFaqSuccess(data.sections));
  } catch (e) {
    yield put(fetchFaqFailure('Request failed with: ' + e));
  }
}

export default function* faqSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchFaqRequest.type, fetchFaqSaga);
}
