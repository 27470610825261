interface GoogleOAuthResults {
  id: number;
  user: string;
  metaName: string;
  metaContent: string;
}

interface GoogleOAuth {
  count: number;
  next: string;
  previous: string;
  results: GoogleOAuthResults[];
}

export const processResponse = (googleOauth: Record<string, never>): GoogleOAuth => {
  const results = (googleOauth.results as Record<string, never>[])?.map((results) => ({
    id: results.id,
    user: results.user,
    metaName: results.meta_name,
    metaContent: results.meta_content,
  }));
  return {
    count: googleOauth.count,
    next: googleOauth.next,
    previous: googleOauth.previous,
    results,
  };
};

export default GoogleOAuth;
