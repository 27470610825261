import React from 'react';

interface ToastComponentTypes {
  toastTitle: string;
  toastMessage?: string;
}

const ToastComponent: React.FC<ToastComponentTypes> = ({ toastTitle = '', toastMessage = '' }) => {
  return (
    <div className="flex gap-12">
      <div>
        <p className="text-layout-sectionTitle text-14 font-medium mb-4">{toastTitle}</p>
        <p className="text-txt-primary text-12">{toastMessage}</p>
      </div>
    </div>
  );
};

export const publishedSuccessfullyToast: React.FC = () => {
  const publishedTitle = `Published successfully!`;
  const publishedMessage = `You have successfully published all your changes. You are currently viewing the live version of your store.`;
  return <ToastComponent toastTitle={publishedTitle} toastMessage={publishedMessage} />;
};

export const sectionAddedSuccessfullyToast: React.FC<string> = (sectionName: string, storePageName: string) => {
  const sectionAddedTitle = `Section is added successfully!`;
  const sectionAddedMessage = `You have successfully added "${sectionName}" section to your ${storePageName} page.`;
  return <ToastComponent toastTitle={sectionAddedTitle} toastMessage={sectionAddedMessage} />;
};

export const sectionDuplicatedSuccessfullyToast: React.FC<string> = (sectionName: string, storePageName: string) => {
  const sectionDuplicatedTitle = `Section is duplicated successfully!`;
  const sectionDuplicatedMessage = `You have successfully duplicated the "${sectionName}" section on your ${storePageName} page.`;
  return <ToastComponent toastTitle={sectionDuplicatedTitle} toastMessage={sectionDuplicatedMessage} />;
};

export const storeHasResetSuccessfullyToast: React.FC = () => {
  const storeHasResetTitle = `Store has been reset!`;
  const storeHasResetMessage = `You have successfully reset your store. All content has been replaced with dummy store data.`;
  return <ToastComponent toastTitle={storeHasResetTitle} toastMessage={storeHasResetMessage} />;
};

export const sectionHiddenSuccessfullyToast: React.FC<string> = (sectionName: string, storePageName: string) => {
  const sectionHiddenTitle = `${sectionName} is now hidden!`;
  const sectionHiddenMessage = `You have successfully hidden the "${sectionName}" section on your ${storePageName} page.`;
  return <ToastComponent toastTitle={sectionHiddenTitle} toastMessage={sectionHiddenMessage} />;
};

export const sectionVisibleSuccessfullyToast: React.FC<string> = (sectionName: string, storePageName: string) => {
  const sectionVisibleTitle = `${sectionName} is now visible!`;
  const sectionVisibleMessage = `"${sectionName}" section is now successfully visible on your ${storePageName} page.`;
  return <ToastComponent toastTitle={sectionVisibleTitle} toastMessage={sectionVisibleMessage} />;
};

export const sectionReorderedSuccessfullyToast: React.FC<string> = (sectionName: string, storePageName: string) => {
  const sectionReorderedTitle = `${sectionName} has been reordered!`;
  const sectionReorderedMessage = `You have successfully reordered "${sectionName}" section on your ${
    sectionName !== 'Page Header' ? storePageName : ''
  } page.`;
  return <ToastComponent toastTitle={sectionReorderedTitle} toastMessage={sectionReorderedMessage} />;
};

export const sectionUpdatedSuccessfullyToast: React.FC<string> = (sectionName: string, storePageName?: string) => {
  const sectionUpdatedTitle = `${sectionName} updated!`;
  const sectionUpdatedMessage = `You have successfully updated your "${sectionName}" section on your ${
    sectionName !== 'Page Header' ? storePageName : ''
  } page.`;
  return <ToastComponent toastTitle={sectionUpdatedTitle} toastMessage={sectionUpdatedMessage} />;
};

export const sectionHasResetSuccessfullyToast: React.FC<string> = (sectionName: string) => {
  const sectionHasResetTitle = `${sectionName} has been reset!`;
  const sectionHasResetMessage = `You have successfully reset your "${sectionName}". All content has been replaced with dummy store data.`;
  return <ToastComponent toastTitle={sectionHasResetTitle} toastMessage={sectionHasResetMessage} />;
};

export const rteSuccessfullyToast: React.FC = () => {
  const rteUpdatedSuccessfullyTitle = `RTE has been updated successfully!`;
  const rteUpdatedSuccessfullyMessage = `You have successfully updated your RTE.`;
  return <ToastComponent toastTitle={rteUpdatedSuccessfullyTitle} toastMessage={rteUpdatedSuccessfullyMessage} />;
};

export const deletedMediaSuccessfullyToast: React.FC = () => {
  const mediaDeletedSuccessfullyTitle = `Deleted successfully!`;
  const mediaDeletedSuccessfullyMessage = `You have successfully deleted this media in the manager.`;
  return <ToastComponent toastTitle={mediaDeletedSuccessfullyTitle} toastMessage={mediaDeletedSuccessfullyMessage} />;
};

export const renamedMediaSuccessfullyToast: React.FC = () => {
  const mediaRenamedSuccessfullyTitle = `Renamed successfully!`;
  const mediaRenamedSuccessfullyMessage = `You have successfully renamed this media in the manager.`;
  return <ToastComponent toastTitle={mediaRenamedSuccessfullyTitle} toastMessage={mediaRenamedSuccessfullyMessage} />;
};

export const addedMediaSuccessfullyToast: React.FC = () => {
  const mediaAddedSuccessfullyTitle = `Added successfully!`;
  const mediaAddedSuccessfullyMessage = `You have successfully added this media in the manager.`;
  return <ToastComponent toastTitle={mediaAddedSuccessfullyTitle} toastMessage={mediaAddedSuccessfullyMessage} />;
};

export const mediaManagerFailureToast: React.FC = () => {
  const mediaManagerFailureTitle = `Something went wrong!`;
  const mediaManagerFailureMessage = `Please retry as something has went wrong while completing this request.`;
  return <ToastComponent toastTitle={mediaManagerFailureTitle} toastMessage={mediaManagerFailureMessage} />;
};

export const somethingWentWrongFailureMessageToast: React.FC = () => {
  const somethingWentWrongTitle = `Something went wrong!`;
  const somethingWentWrongMessage = `Something went wrong :(. Please contact support@instamojo.com .`;
  return <ToastComponent toastTitle={somethingWentWrongTitle} toastMessage={somethingWentWrongMessage} />;
};

export const formErrorMessageToast: React.FC = (error) => {
  const formErrorMessageTitle = `Something went wrong!`;
  const formErrorMessageMessage = error ? error : `Please fix the form errors.`;
  return <ToastComponent toastTitle={formErrorMessageTitle} toastMessage={formErrorMessageMessage} />;
};

export const errorFromAPIToast: React.FC<string> = (message: string) => {
  const errorFromAPIMessageTitle = `Something went wrong!`;
  const errorFromAPIMessageMessage = message;
  return <ToastComponent toastTitle={errorFromAPIMessageTitle} toastMessage={errorFromAPIMessageMessage} />;
};

export const customColorAddedSuccessfullyToast: React.FC = () => {
  const customColorAddedSuccessfullyTitle = `Colour applied successfully!`;
  const customColorAddedSuccessfullyMessage = `You have successfully applied a new colour to your store. It is now live to all your customers.`;
  return (
    <ToastComponent toastTitle={customColorAddedSuccessfullyTitle} toastMessage={customColorAddedSuccessfullyMessage} />
  );
};

export const customFontAddedSuccessfullyToast: React.FC = () => {
  const customFontAddedSuccessfullyTitle = `Font applied successfully!`;
  const customFontAddedSuccessfullyMessage = `You have successfully applied new font style to your store. It is now live to all your customers.`;
  return (
    <ToastComponent toastTitle={customFontAddedSuccessfullyTitle} toastMessage={customFontAddedSuccessfullyMessage} />
  );
};

export const themeAppliedSuccessfullyToast: React.FC = () => {
  const themeAppliedSuccessfullyTitle = `Theme applied successfully!`;
  const themeAppliedSuccessfullyMessage = `You have successfully applied a new theme on your store. It is now live to all your customers.`;
  return <ToastComponent toastTitle={themeAppliedSuccessfullyTitle} toastMessage={themeAppliedSuccessfullyMessage} />;
};

export const customPageSuccessfullyToast: React.FC = (isUpdated: false) => {
  const customPageAddedSuccessfullyTitle = `Page ${isUpdated ? 'updated' : 'created'} successfully!`;
  const customPAgeAddedSuccessfullyMessage = `You have successfully ${
    isUpdated ? 'updated' : 'created new'
  } page to your store. It is now live to all your customers.`;
  return (
    <ToastComponent toastTitle={customPageAddedSuccessfullyTitle} toastMessage={customPAgeAddedSuccessfullyMessage} />
  );
};

export const customCSSAddedSuccessfullyToast: React.FC = () => {
  const customCSSAddedSuccessfullyTitle = 'Custom CSS Applied Successfully!';
  const customCSSAddedSuccessfullyMessage =
    'You have successfully applied custom CSS to your store. The changes are now live for all your customers.';
  return (
    <ToastComponent toastTitle={customCSSAddedSuccessfullyTitle} toastMessage={customCSSAddedSuccessfullyMessage} />
  );
};

export const faviconAddedSuccessfullyToast: React.FC = () => {
  const faviconAddedSuccessfullyTitle = 'Favicon uploaded successfully!';
  const faviconAddedSuccessfullyMessage = `Your store's favicon has been uploaded and is now live for all your customers.`;
  return <ToastComponent toastTitle={faviconAddedSuccessfullyTitle} toastMessage={faviconAddedSuccessfullyMessage} />;
};

export const faviconDeletedSuccessfullyToast: React.FC = () => {
  const faviconDeletedSuccessfullyTitle = 'Favicon deleted successfully!';
  const faviconDeletedSuccessfullyMessage = `Your store's favicon has been removed and will no longer appear for your customers.`;
  return (
    <ToastComponent toastTitle={faviconDeletedSuccessfullyTitle} toastMessage={faviconDeletedSuccessfullyMessage} />
  );
};
