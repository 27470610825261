import { SectionEnum } from './SectionEnum';

export interface Category {
  type: SectionEnum.CATEGORY;
  name: string;
  heading: string;
}

export const categoryProcessResponse = (category: Record<string, never>): Category => {
  return {
    type: category.type,
    name: category.name,
    heading: category.heading,
  };
};
