import { getFilteredSliderImages } from '@common/models/Slider';
import { SliderSliceType } from '@common/store/slider/slice';
import React from 'react';
import { Helmet } from 'react-helmet';

export interface sliderMainProps {
  className: string;
  id: string;
  slider: SliderSliceType;
}

const SliderMain: React.FC<sliderMainProps> = ({ slider, children, ...props }) => {
  const filteredImages = getFilteredSliderImages({ slider, shouldImageBePresent: true });

  return filteredImages?.length ? (
    <>
      <Helmet>
        <link rel="preload" as="image" href={filteredImages[0].image} />
      </Helmet>
      <div {...props}>{children}</div>
    </>
  ) : null;
};

export default SliderMain;
