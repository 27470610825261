import { getData, URLs } from '@common/api';
import * as Category from '@common/models/Category';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import {
  fetchCategoriesFailure,
  fetchCategoriesRequest,
  FetchCategoriesRequestPayloadType,
  fetchCategoriesSuccess,
} from './slice';

function* fetchCategoriesSaga({
  payload: { queryParams, useCache, fetchAfterServe },
}: PayloadAction<FetchCategoriesRequestPayloadType>) {
  try {
    const { data } = yield call(() =>
      getData({ url: URLs.GET_CATEGORIES, params: queryParams, useCache, fetchAfterServe }),
    );
    data.results = data.results
      ?.slice()
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .reverse()
      .map((category) => Category.processResponse(category));
    data.results = [Category.getShopAllCategory(data.results), ...data.results];
    data.featuredCategories = Category.getFeaturedCategories(data.results[0]);
    yield put(fetchCategoriesSuccess(data));
  } catch (e) {
    yield put(fetchCategoriesFailure('Request failed with: ' + e));
  }
}

export default function* categoriesSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchCategoriesRequest.type, fetchCategoriesSaga);
}
