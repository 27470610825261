import { RootState } from '@common/store';
import { fetchTextRequest, TextSliceType } from '@common/store/text/slice';
import loadable from '@loadable/component';
import { DEFAULT_TEXT_LAYOUT_SELECTION } from '@theme/utils/constants';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ADD_NEW_SECTION_ID } from '../../common/utils/constants';
import Fallback from '../common/Fallback';

export interface TextLayoutProps {
  layout: string;
  layoutName: string;
}

export interface TextLayoutProps {
  id: string;
  layout: string;
  fetchTextRequest: fetchTextRequest.type;
  text: TextSliceType;
  previewQueryLayout: string;
}

const TextLayout1 = loadable(() => import(/* webpackChunkName: "TextLayout1" */ './TextLayout1'));
const TextLayout2 = loadable(() => import(/* webpackChunkName: "TextLayout2" */ './TextLayout2'));
const TextLayout3 = loadable(() => import(/* webpackChunkName: "TextLayout3" */ './TextLayout3'));
const TextLayout4 = loadable(() => import(/* webpackChunkName: "TextLayout4" */ './TextLayout4'));
const TextLayout5 = loadable(() => import(/* webpackChunkName: "TextLayout5" */ './TextLayout5'));

class TextLayout extends PureComponent<TextLayoutProps> {
  constructor(props) {
    super(props);
    const { fetchTextRequest, id, text } = this.props;
    if (!text[id]?.isFetched && !text[id]?.isFetching && id !== ADD_NEW_SECTION_ID) {
      fetchTextRequest(id);
    }
  }
  render(): React.ReactElement {
    const { text, id, previewQueryLayout } = this.props;
    const layoutName = previewQueryLayout || text[id]?.layout?.name || DEFAULT_TEXT_LAYOUT_SELECTION;
    return (
      <div className="text-layout-bg">
        <div className="text-container layout-container container mx-auto py-40" id={id}>
          {text[id] && layoutName ? (
            <>
              {layoutName === 'Text1' ? (
                <TextLayout1 fallback={<TextLayoutFallback id={id} />} text={text[id].text} />
              ) : null}
              {layoutName === 'Text2' ? (
                <TextLayout2 fallback={<TextLayoutFallback id={id} />} text={text[id].text} />
              ) : null}
              {layoutName === 'Text3' ? (
                <TextLayout3 fallback={<TextLayoutFallback id={id} />} text={text[id].text} />
              ) : null}
              {layoutName === 'Text4' ? (
                <TextLayout4 fallback={<TextLayoutFallback id={id} />} text={text[id].text} />
              ) : null}
              {layoutName === 'Text5' ? (
                <TextLayout5 fallback={<TextLayoutFallback id={id} />} text={text[id].text} />
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

const TextLayoutFallback = ({ id }) => {
  return <Fallback id={id} sectionType="Text" />;
};

export default connect(
  ({ text }: RootState) => ({
    text,
  }),
  {
    fetchTextRequest,
  },
)(TextLayout);
