import { getData, URLs } from '@common/api';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import * as Features from '../../models/Features';
import { fetchFeaturesFailure, fetchFeaturesRequest, fetchFeaturesSuccess } from './slice';

function* fetchFeaturesSaga() {
  try {
    let data = yield call(() =>
      getData({
        url: URLs.GET_FEATURES,
      }),
    );
    data = Features.processResponse(data.data);
    yield put(fetchFeaturesSuccess(data));
  } catch (e) {
    yield put(fetchFeaturesFailure('Request failed with: ' + e));
  }
}

export default function* featuresSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchFeaturesRequest.type, fetchFeaturesSaga);
}
