export const ERROR_TEXT = {
  PRODUCTS: 'Failed to load Products',
  PRODUCT: 'Failed to load Product Details',
  CATEGORIES: 'Failed to load Categories',
  CATEGORY: 'Failed to fetch Category Details',
  SEARCH_RESULTS: 'Failed to fetch Search Results',
  TESTIMONIALS: 'Failed to fetch Testimonials',
  ABOUT: 'Failed to fetch About Content',
  CONTACT: 'Failed to fetch Contact Details',
  FAQ: 'Failed to fetch FAQs',
  HOME: 'Failed to fetch Sections',
  ADDRESS: 'Failed to fetch Address Details',
  ADDRESSES: 'Failed to fetch list of your Addresses',
  ORDER: 'Failed to fetch Order Details',
  ORDER_EMAIL: 'Email does not match the email in purchased order',
  ORDERS: 'Failed to load your Orders',
  POLICY: 'Failed to load Policy',
  PROFILE: 'Failed to load your Profile Information',
  SOCIAL_PROFILES: 'Failed to load your Social Login Information',
  WISHLIST: 'Failed to fetch wishlist',
};
