import { getData, URLs } from '@common/api';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchCustomMetaTagsFailure, fetchCustomMetaTagsRequest, fetchCustomMetaTagsSuccess } from './slice';

function* fetchCustomMetaTagsSaga({ payload }) {
  try {
    const data = yield call(() =>
      getData({
        url: URLs.GET_CUSTOM_METATAGS,
        params: payload,
      }),
    );
    yield put(fetchCustomMetaTagsSuccess(data?.data));
  } catch (e) {
    yield put(fetchCustomMetaTagsFailure('Request failed with: ' + e));
  }
}

export default function* customMetaTagsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchCustomMetaTagsRequest.type, fetchCustomMetaTagsSaga);
}
