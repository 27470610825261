import Country from '@common/models/geo/Country';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CountriesSliceType {
  countries?: Array<Country>;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const countriesSlice = createSlice({
  name: 'countries',
  initialState: <CountriesSliceType>{
    countries: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchCountriesRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.countries = null;
      state.error = '';
    },
    fetchCountriesSuccess(state, { payload }: PayloadAction<Array<Country>>) {
      state.countries = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchCountriesFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const { fetchCountriesSuccess, fetchCountriesRequest, fetchCountriesFailure } = countriesSlice.actions;

export default countriesSlice.reducer;
