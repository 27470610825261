import { virtualEventVenue } from '@common/utils/constants';

import DEFAULT_PRODUCT_IMAGE from '../images/DefaultProductImage.png';
import { computeProductHash, shouldUseCustomNavigation } from '../utils';
import { isExpired } from '../utils/date';
import { calculateSavings, NUM_VARIANTS, PRODUCT_REFERRED_FROM, PRODUCT_TYPE, SavingsType } from './Product';

interface Variant {
  hash: string;
  id: number;
  sku: string;
  title: string;
  categories: {
    name: string;
    id: number;
  }[];
  price: number;
  finalPrice: number;
  hasDiscount: boolean;
  discountedPrice?: number;
  stock: number;
  inStock: boolean;
  type: string;
  groupId: number;
  images: Array<string>;
  shortDescription: string;
  isFeatured: boolean;
  slug: string;
  event: {
    startDate: string;
    endDate: string;
    venue: string;
  };
  isFree: boolean;
  maxQuantity: number;
  minQuantity: number;
  unlimitedQuantity: boolean;
  optionNames: Array<string>;
  optionValues: Array<string>;
  storeLink: string;
  referredStoreLinks: Record<PRODUCT_REFERRED_FROM, string>;
  sortOrder: number;
  payWhatYouWant: boolean;
  shipInDays: number;
  shippingCharges: number;
  averageRating: number;
  ratingScore: number;
  ratingVotes: number;
  shortLink: string;
  digitalFile: string | null;
  pageTitle: string;
  description: string;
  shipping: boolean;
  created: string;
  modified: string;
  savings: SavingsType;
  isVariant: boolean;
  isEvent: boolean;
  isExpired: boolean;
  bundleDiscountAmount?: number;
  bundleId?: number;
  productVideoEnabled?: boolean;
  productVideoUrl?: string;
}

export const processResponse = (variant: Record<string, never>, parentOptionNames: string[]): Variant => {
  const optionNames = [...parentOptionNames];

  let optionValues = [];
  for (let i = 1; i <= NUM_VARIANTS; i++) {
    optionValues.push(variant[`opt${i}_value`]);
  }
  optionValues = optionValues.filter((value) => value);

  const groupId = +variant.group_id;
  const storeLink = shouldUseCustomNavigation()
    ? `/${variant.slug}/p${groupId}`
    : `/product/${groupId}/${variant.slug}`;
  const referredStoreLinks = Object.keys(PRODUCT_REFERRED_FROM).reduce((acc, curr) => {
    return {
      ...acc,
      [PRODUCT_REFERRED_FROM[curr]]: `${storeLink}?referred_from=${PRODUCT_REFERRED_FROM[curr]}`,
    };
  }, {}) as Record<PRODUCT_REFERRED_FROM, string>;

  const price = variant.price != null ? +variant.price : null;
  const payWhatYouWant = variant.pwyw;
  let discountedPrice = variant.discounted_price != null ? +variant.discounted_price : null;
  discountedPrice = payWhatYouWant || (discountedPrice != null && discountedPrice >= price) ? null : discountedPrice;

  const bundleDiscountAmount = variant.bundle_discount_amount != null ? +variant.bundle_discount_amount : null;

  const hasDiscount = discountedPrice != null || bundleDiscountAmount != null;

  const finalPrice = bundleDiscountAmount ? price - bundleDiscountAmount : discountedPrice ?? price;
  const savings = calculateSavings(price, discountedPrice);

  let images = variant.images as string[];
  if (!images?.length) {
    images = [DEFAULT_PRODUCT_IMAGE];
  }
  const isEvent = variant.type === PRODUCT_TYPE.EVENT;

  const processedVariant = {
    isExpired: isExpired(isEvent && (variant.event as Record<string, string>)?.end_date),
    id: variant.id,
    hash: (variant.id as number)?.toString(),
    sku: variant.sku,
    title: variant.title,
    categories: variant.categories || [],
    storeLink,
    referredStoreLinks,
    isVariant: true,
    price,
    finalPrice,
    discountedPrice,
    hasDiscount,
    savings,
    stock: variant.stock,
    inStock: variant.in_stock ?? variant.stock !== 0,
    type: variant.type,
    groupId,
    isEvent,
    images,
    shortDescription: variant.short_description,
    isFeatured: variant.is_featured,
    slug: variant.slug,
    event: {
      startDate: (variant.event as Record<string, string>)?.start_date,
      endDate: (variant.event as Record<string, string>)?.end_date,
      venue: (variant.event as Record<string, string>)?.venue ?? virtualEventVenue(),
    },
    isFree: variant.is_free,
    maxQuantity: +(variant.max_qty || Infinity),
    minQuantity: +(variant.min_qty || 1),
    unlimitedQuantity: variant.unlimited_qty,
    optionNames,
    optionValues,
    sortOrder: variant.sort_order,
    payWhatYouWant,
    shipInDays: +variant.ship_in_days,
    shippingCharges: +variant.shipping_charges,
    averageRating: variant.average_rating,
    ratingScore: variant.rating_score,
    ratingVotes: variant.rating_votes,
    shortLink: variant.short_link,
    digitalFile: variant.digital_file,
    pageTitle: variant.page_title,
    description: variant.description,
    shipping: variant.shipping,
    created: variant.created,
    modified: variant.modified,
    bundleDiscountAmount,
    bundleId: variant.bundle_id || null,
    productVideoEnabled: variant.product_video_enabled,
    productVideoUrl: variant.product_video_url,
  };
  processedVariant.hash = computeProductHash(processedVariant);
  return processedVariant;
};

export default Variant;
