import Product from '@common/models/Product';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FetchProductSuccessResponseType {
  count: number;
  next: string | null;
  previous: string | null;
  results: Product[];
}

export interface FetchProductsRequestPayloadType {
  useCache?: boolean;
  fetchAfterServe?: boolean;
  sectionId: string;
  previewQueryLayout: string;
  userId?: number;
}

export interface FetchProductsSuccessPayloadType {
  sectionId: string;
  products: FetchProductSuccessResponseType;
  title: string;
  layout: string;
  showcaseType: string;
  productDetails: Product;
}

export interface FetchProductsFailurePayloadType {
  sectionId: string;
  error: string;
}

export interface ProductsSliceType {
  list?: FetchProductSuccessResponseType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const productsSlice = createSlice({
  name: 'productsList',
  initialState: <Record<string, ProductsSliceType>>{},
  reducers: {
    fetchProductListRequest(
      state,
      { payload: { sectionId, previewQueryLayout } }: PayloadAction<FetchProductsRequestPayloadType>,
    ) {
      state[sectionId] = {
        ...state[sectionId],
        isFetching: true,
        isFetched: false,
        id: sectionId,
        previewQueryLayout: previewQueryLayout,
        error: '',
      };
    },
    fetchProductListSuccess(
      state,
      {
        payload: { sectionId, products, title, layout, showcaseType, productDetails },
      }: PayloadAction<FetchProductsSuccessPayloadType>,
    ) {
      const stateObject = {
        isFetching: false,
        isFetched: true,
        id: sectionId,
        list: products,
        productDetails,
        title: title,
        error: '',
        layout: layout,
        showcaseType,
      };
      state[sectionId] = stateObject;
    },
    fetchProductListFailure(state, { payload: { sectionId, error } }: PayloadAction<FetchProductsFailurePayloadType>) {
      const stateObject = {
        isFetching: false,
        isFetched: true,
        id: sectionId,
        error: error,
        list: null,
      };
      state[sectionId] = stateObject;
    },
    editProductShowCaseList(state, { payload }: unknown) {
      const stateObject = {
        isFetching: false,
        isFetched: true,
        id: payload.id,
        list: { results: payload.list },
        title: payload.title,
        layout: payload.layout,
        error: '',
        showcaseType: payload.showcaseType,
        productDetails: payload?.productDetails,
      };
      state[payload.id] = stateObject;
    },
  },
});

export const {
  fetchProductListSuccess,
  fetchProductListRequest,
  fetchProductListFailure,
  editProductShowCaseList,
} = productsSlice.actions;

export default productsSlice.reducer;
