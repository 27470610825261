import { getData, URLs } from '@common/api';
import * as Order from '@common/models/Order';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchUserOrdersFailure, fetchUserOrdersRequest, fetchUserOrdersSuccess, UserOrdersQueryParams } from './slice';

function* fetchUserOrdersSaga({ payload: { queryParams } }: PayloadAction<{ queryParams: UserOrdersQueryParams }>) {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_USER_ORDERS, params: queryParams }));
    data.results = data.results?.map((order) => Order.processResponse(order));
    yield put(fetchUserOrdersSuccess({ response: data, queryParams }));
  } catch (e) {
    yield put(fetchUserOrdersFailure('Request failed with: ' + e));
  }
}

export default function* userOrdersSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchUserOrdersRequest.type, fetchUserOrdersSaga);
}
