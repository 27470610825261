import { getData, URLs } from '@common/api';
import { processResponse } from '@common/models/FilterBar';
import { RootState, store } from '@common/store';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchUserProfileSuccess, resetUserProfile } from '../userProfile/slice';
import { fetchNavigationDetailsFailure, fetchNavigationDetailsRequest, fetchNavigationDetailsSuccess } from './slice';

function* fetchNavigationDetails() {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_NAVBAR }));
    const processedResponse = processResponse(data);
    const state: RootState = store.getState();
    const { customPages } = state.features?.features || {};
    yield put(fetchNavigationDetailsSuccess({ processedResponse, customPages }));
  } catch (e) {
    yield put(fetchNavigationDetailsFailure('Request failed with: ' + e));
  }
}

export default function* navigationDetailsSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchNavigationDetailsRequest.type, fetchNavigationDetails);
  yield takeEvery(fetchUserProfileSuccess.type, fetchNavigationDetails);
  yield takeEvery(resetUserProfile.type, fetchNavigationDetails);
}
