import { SectionType } from '@common/models/sections';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CustomPageDetailResponseType {
  id: number;
  name: string;
  url: string;
  title: string;
  sections: Array<SectionType>;
  themed: boolean;
  protected: boolean;
}

export interface CustomPageDetailSliceType {
  customPageUrl: string;
  details?: CustomPageDetailResponseType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const customPageDetailSlice = createSlice({
  name: 'customPageDetail',
  initialState: <CustomPageDetailSliceType>{
    customPageUrl: null,
    details: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchCustomPageDetailRequest(state, { payload: customPageUrl }: PayloadAction<string>) {
      state.customPageUrl = customPageUrl;
      state.isFetching = true;
      state.isFetched = false;
      state.details = null;
      state.error = '';
    },
    fetchCustomPageDetailSuccess(state, { payload }: PayloadAction<CustomPageDetailResponseType>) {
      state.details = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchCustomPageDetailFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const {
  fetchCustomPageDetailSuccess,
  fetchCustomPageDetailRequest,
  fetchCustomPageDetailFailure,
} = customPageDetailSlice.actions;

export default customPageDetailSlice.reducer;
