import UserAddress from '@common/models/UserAddress';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserAddressesResponseType {
  count: number;
  next: string | null;
  previous: string | null;
  results: UserAddress[];
}

export interface UserAddressesSliceType {
  addresses?: UserAddressesResponseType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const userAddressesSlice = createSlice({
  name: 'userAddresses',
  initialState: <UserAddressesSliceType>{
    addresses: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchUserAddressesRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.addresses = null;
      state.error = '';
    },
    fetchUserAddressesSuccess(state, { payload }: PayloadAction<UserAddressesResponseType>) {
      state.addresses = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchUserAddressesFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const {
  fetchUserAddressesSuccess,
  fetchUserAddressesRequest,
  fetchUserAddressesFailure,
} = userAddressesSlice.actions;

export default userAddressesSlice.reducer;
