import Category from '@common/models/Category';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FetchCategoriesRequestPayloadType {
  queryParams: CategoryQueryParamsType;
  useCache: boolean;
  fetchAfterServe: boolean;
}

interface FetchCategorySuccessPayloadType {
  count: number;
  next: string | null;
  previous: string | null;
  results: Category[];
  featuredCategories: Category[];
}

export interface CategoryQueryParamsType {
  parent_category__isnull: boolean;
  parent_category?: number;
  is_featured?: boolean;
  created__gte?: string;
  created__lte?: string;
  modified__gte?: string;
  modified__lte?: string;
  ordering:
    | 'created'
    | 'modified'
    | 'name'
    | 'order'
    | 'label'
    | '-created'
    | '-modified'
    | '-name'
    | '-order'
    | '-label';
  limit: number;
  offset: number;
}

export const getCategoryQueryParamsInitialState = (): CategoryQueryParamsType => ({
  parent_category__isnull: true,
  limit: 1000,
  offset: 0,
  ordering: 'order',
});

export interface CategoriesSliceType {
  categories?: FetchCategorySuccessPayloadType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: <CategoriesSliceType>{
    categories: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    /* eslint-disable-next-line */
    fetchCategoriesRequest(state, { payload }: PayloadAction<FetchCategoriesRequestPayloadType>) {
      state.isFetching = true;
      state.isFetched = false;
      state.categories = null;
      state.error = '';
    },
    fetchCategoriesSuccess(state, { payload }: PayloadAction<FetchCategorySuccessPayloadType>) {
      state.categories = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchCategoriesFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const { fetchCategoriesSuccess, fetchCategoriesRequest, fetchCategoriesFailure } = categoriesSlice.actions;

export default categoriesSlice.reducer;
