interface MDPAppScript {
  pageType: number;
  script: string;
  section: number;
}

export default MDPAppScript;

export const processResponse = (mdpAppScript: Record<string, never>): MDPAppScript => {
  return {
    pageType: mdpAppScript.page_type,
    script: mdpAppScript.script,
    section: mdpAppScript.section,
  };
};

export enum MDPPageType {
  PRODUCT_PAGE = 1,
  CATEGORY_PAGE = 2,
  CUSTOM_PAGE = 3,
  STORE_PAGE = 4,
  STORE_WIDE = 5,
}
