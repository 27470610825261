import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as CategoryAttribute from '../../models/CategoryAttribute';

export interface CategoryAttributesResponseType {
  count: number;
  next: string | null;
  previous: string | null;
  results: CategoryAttribute.default[];
}

export interface CategoryAttributesResponsePayloadType {
  response: CategoryAttributesResponseType;
  queryParams: CategoryAttributesQueryParams;
}

export interface CategoryAttributesQueryParams {
  limit: number;
  page: number;
  category?: number;
}

export interface CategoryAttributesRequestPayloadType {
  queryParams: CategoryAttributesQueryParams;
}

export const getCategoryAttributesQueryParamsInitialState = (): CategoryAttributesQueryParams => ({
  limit: 500,
  page: 1,
});

export interface CategoryAttributesSliceType {
  pagination: {
    currentPage: number;
    lastPage: number;
    multiplier: number;
  };
  currentQueryParams?: CategoryAttributesQueryParams;
  categoryAttributes?: CategoryAttributesResponseType;
  categoryId: number;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const categoryAttributesSlice = createSlice({
  name: 'categoryAttributes',
  initialState: <CategoryAttributesSliceType>{
    pagination: {
      currentPage: 1,
      lastPage: 1,
      multiplier: 1,
    },
    categoryAttributes: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchCategoryAttributesRequest(
      state,
      { payload: { queryParams } }: PayloadAction<CategoryAttributesRequestPayloadType>,
    ) {
      state.categoryId = queryParams.category;
      state.isFetching = true;
      state.isFetched = false;
      state.categoryAttributes = null;
      state.currentQueryParams = queryParams;
      state.error = '';
    },
    fetchCategoryAttributesSuccess(
      state,
      { payload: { response: categoryAttributes, queryParams } }: PayloadAction<CategoryAttributesResponsePayloadType>,
    ) {
      state.categoryAttributes = categoryAttributes;
      state.isFetching = false;
      state.isFetched = true;
      state.pagination = {
        currentPage: queryParams.page,
        lastPage: parseInt(`${(categoryAttributes.count - 1) / queryParams.limit + 1}`),
        multiplier: queryParams.limit,
      };
    },
    fetchCategoryAttributesFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
      state.categoryAttributes = null;
    },
  },
});

export const {
  fetchCategoryAttributesSuccess,
  fetchCategoryAttributesRequest,
  fetchCategoryAttributesFailure,
} = categoryAttributesSlice.actions;

export default categoryAttributesSlice.reducer;
