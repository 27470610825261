export interface FBEPixel {
  isAccountLinked: boolean;
  pixelId: string;
}

export const processResponse = (fbePixel: Record<string, never>): FBEPixel => {
  return {
    isAccountLinked: fbePixel.is_account_linked,
    pixelId: fbePixel.pixel_id ?? '',
  };
};

export default FBEPixel;
