import { getLambdaLink, isMobile } from '@common/utils';

interface StoreTestimonials {
  id: number;
  title: string;
  name: string;
  layout: Record<string, unknown>;
  testimonials: TestimonialData[];
}

interface TestimonialData {
  id: string;
  name: string;
  image: string | null;
  feedback: string;
  publish: boolean;
}
export const testimonialUserImageDimensions = {
  Testimonial1: {
    mobile: { width: 100, height: 100 },
    desktop: { width: 100, height: 100 },
  },
  Testimonial2: {
    mobile: { width: 48, height: 48 },
    desktop: { width: 48, height: 48 },
  },
  Testimonial3: {
    mobile: { width: 92, height: 92 },
    desktop: { width: 100, height: 100 },
  },
  Testimonial4: {
    mobile: { width: 70, height: 70 },
    desktop: { width: 70, height: 70 },
  },
  Testimonial5: {
    mobile: { width: 50, height: 50 },
    desktop: { width: 82, height: 82 },
  },
  Testimonial6: {
    mobile: { width: 44, height: 44 },
    desktop: { width: 94, height: 94 },
  },
  Testimonial7: {
    mobile: { width: 122, height: 122 },
    desktop: { width: 122, height: 122 },
  },
  Testimonial8: {
    mobile: { width: 100, height: 100 },
    desktop: { width: 100, height: 100 },
  },
  Testimonial9: {
    mobile: { width: 80, height: 80 },
    desktop: { width: 222, height: 302 },
  },
  Testimonial10: {
    mobile: { width: 100, height: 100 },
    desktop: { width: 100, height: 100 },
  },
  Testimonial11: {
    mobile: { width: 90, height: 90 },
    desktop: { width: 90, height: 90 },
  },
  Testimonial12: {
    mobile: { width: 24, height: 24 },
    desktop: { width: 24, height: 24 },
  },
  Testimonial13: {
    mobile: { width: 72, height: 72 },
    desktop: { width: 112, height: 112 },
  },
  Testimonial14: {
    mobile: { width: 160, height: 200 },
    desktop: { width: 160, height: 200 },
  },
  Testimonial15: {
    mobile: { width: 122, height: 122 },
    desktop: { width: 122, height: 122 },
  },
  Testimonial16: {
    mobile: { width: 64, height: 64 },
    desktop: { width: 64, height: 64 },
  },
  Testimonial17: {
    mobile: { width: 40, height: 40 },
    desktop: { width: 48, height: 48 },
  },
  Testimonial18: {
    mobile: { width: 24, height: 24 },
    desktop: { width: 40, height: 40 },
  },
};

export const processResponse = (storeTestimonials: Record<string, unknown>, layoutName: string): StoreTestimonials => {
  const { desktop, mobile } = testimonialUserImageDimensions[layoutName] || {
    mobile: { width: 0, height: 0 },
    desktop: { width: 0, height: 0 },
  };
  const testimonials = (storeTestimonials.testimonials as Record<string, never>[])?.map((testimonial) => ({
    id: testimonial.id,
    name: testimonial.name,
    image: getLambdaLink({ link: testimonial.image, ...(isMobile() ? mobile : desktop) }),
    feedback: testimonial.feedback,
    publish: testimonial.publish,
  }));
  return {
    id: storeTestimonials.id,
    title: storeTestimonials.title,
    name: storeTestimonials.name,
    layout: storeTestimonials.layout,
    testimonials,
  };
};
