import useSubscribe from '@common/hooks/useSubscribe';
import { getQueryParamsFromURL } from '@common/utils/params';
import PageLayout from '@components/PageLayout';
import Loading from '@lib/Loading';
import React, { useEffect } from 'react';

const Unsubscribe: React.FC = () => {
  const { unsubscribe } = useSubscribe();
  useEffect(() => {
    const queryParam = getQueryParamsFromURL();
    unsubscribe(queryParam?.email);
  }, []);
  return (
    <PageLayout>
      <Loading />
    </PageLayout>
  );
};

export default Unsubscribe;
