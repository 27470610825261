import Nudge from '@common/components/Nudge';
import { ReactComponent as FacebookFooter } from '@common/icons/FacebookFooter.svg';
import { ReactComponent as InstagramFooter } from '@common/icons/InstagramFooter.svg';
import { ReactComponent as LinkedinFooter } from '@common/icons/LinkedinFooter.svg';
import { ReactComponent as Location } from '@common/icons/Location.svg';
import { ReactComponent as Mail } from '@common/icons/Mail.svg';
import { ReactComponent as Phone } from '@common/icons/Phone.svg';
import { ReactComponent as PinterestFooter } from '@common/icons/PinterestFooter.svg';
import { ReactComponent as TwitterFooter } from '@common/icons/TwitterFooter.svg';
import { ReactComponent as YoutubeFooter } from '@common/icons/YoutubeFooter.svg';
import StoreInfo from '@common/models/StoreInfo';
import { RouteMap } from '@common/RouteMap';
import { FeaturesSliceType } from '@common/store/features/slice';
import { FilterBarSliceType } from '@common/store/filterBar/slice';
import { PoliciesSliceTosType } from '@common/store/policiesTos/slice';
import { getActiveNavbar } from '@common/utils';
import Link from '@lib/Link';
import React from 'react';

import { context } from '../../utils/constants';
import { getMerchantJWTToken } from '../../utils/token';

interface FooterProps {
  storeInfo: StoreInfo;
  features: FeaturesSliceType;
  filterBar: FilterBarSliceType;
  policies: PoliciesSliceTosType;
}

const Footer: React.FunctionComponent<FooterProps> = ({ storeInfo, features, filterBar, policies: { policyTos } }) => {
  const copyrightWrapperClass = !storeInfo?.contactInfo ? 'mt-30' : '';
  const arePoliciesPresent = Object.keys(policyTos).length > 0;

  return (
    <div className="w-full bg-theme footer-parent" color-customisation="footer-bg" id="page-footer">
      <div className="container mx-auto pt-30 pb-22 md:pb-0 md:flex justify-between">
        <div className="md:w-1/2 mb-16 md:mb-0">
          <div
            className="text-footer-heading text-16 mb-16 font-normal"
            font-customisation="section-heading"
            color-customisation="footer-heading"
          >
            Quick Links
          </div>
          <div className="flex flex-col md:flex-row flex-wrap">
            {features.features?.contactUsPage && getActiveNavbar('Contact Us', filterBar) ? (
              <Link
                to={RouteMap.CONTACT}
                className="mb-8 flex md:w-1/4 text-footer-link text-opacity-70 text-14 font-normal"
                font-customisation="para-text"
                color-customisation="footer-links"
                id="footer-contact-us"
              >
                Contact Us
              </Link>
            ) : null}
            {arePoliciesPresent && !policyTos?.cancelAndRefund && (
              <Link
                to="/policy/cancellation"
                className="mb-8 flex md:w-1/3 text-footer-link text-opacity-70 text-14 font-normal mr-8"
                font-customisation="para-text"
                color-customisation="footer-links"
                id="footer-cancellation-refund"
              >
                Cancellation &amp; Refund
              </Link>
            )}
            {arePoliciesPresent && !policyTos?.termsAndConditions && (
              <Link
                to="/policy/tnc"
                className="mb-8 flex md:w-1/3 text-footer-link text-opacity-70 text-14 font-normal"
                font-customisation="para-text"
                color-customisation="footer-links"
                id="footer-terms-conditions"
              >
                Terms &amp; Conditions
              </Link>
            )}
            {features?.features?.customPages && getActiveNavbar('About Us', filterBar) ? (
              <Link
                to={RouteMap.ABOUT}
                className="mb-8 flex md:w-1/4 text-footer-link text-opacity-70 text-14 font-normal"
                font-customisation="para-text"
                color-customisation="footer-links"
                id="footer-about-us"
              >
                About Us
              </Link>
            ) : null}
            {arePoliciesPresent && !policyTos?.shippingAndDelivery && (
              <Link
                to="/policy/shipping"
                className="mb-8 flex md:w-1/3 text-footer-link text-opacity-70 text-14 font-normal mr-8"
                font-customisation="para-text"
                color-customisation="footer-links"
                id="footer-shipping-delivery"
              >
                Shipping &amp; Delivery
              </Link>
            )}
            {arePoliciesPresent && !policyTos?.privacy && (
              <Link
                to="/policy/privacy"
                className="mb-8 flex md:w-1/3 text-footer-link text-opacity-70 text-14 font-normal"
                font-customisation="para-text"
                color-customisation="footer-links"
                id="footer-privacy"
              >
                Privacy
              </Link>
            )}
          </div>
        </div>
        <div className="md:w-2/12">&nbsp;</div>
        {storeInfo?.hasSocialLinks || storeInfo?.contactInfo ? (
          <div className="md:w-1/2">
            <div
              className="text-footer-heading text-16 mb-24"
              font-customisation="section-heading"
              color-customisation="footer-heading"
            >
              Connect with us
            </div>
            {storeInfo?.hasSocialLinks ? (
              <div className="flex items-center md:-mr-30">
                {storeInfo?.social?.pinterest ? (
                  <a href={storeInfo?.social?.pinterest} title="Pinterest" target="_blank" rel="noreferrer noopener">
                    <PinterestFooter id="footer-pinterest-link" className="w-24 mr-30" />
                  </a>
                ) : null}
                {storeInfo?.social?.linkedin ? (
                  <a href={storeInfo?.social?.linkedin} title="LinkedIn" target="_blank" rel="noreferrer noopener">
                    <LinkedinFooter id="footer-linkedin-link" className="w-24 mr-30" />
                  </a>
                ) : null}
                {storeInfo?.social?.youtube ? (
                  <a href={storeInfo?.social?.youtube} title="YouTube" target="_blank" rel="noreferrer noopener">
                    <YoutubeFooter id="footer-youtube-link" className="w-24 mr-30" />
                  </a>
                ) : null}
                {storeInfo?.social?.twitter ? (
                  <a href={storeInfo?.social?.twitter} title="Twitter" target="_blank" rel="noreferrer noopener">
                    <TwitterFooter id="footer-twitter-link" className="w-24 mr-30" />
                  </a>
                ) : null}
                {storeInfo?.social?.instagram ? (
                  <a href={storeInfo?.social?.instagram} title="Instagram" target="_blank" rel="noreferrer noopener">
                    <InstagramFooter id="footer-instagram-link" className="w-24 mr-30" />
                  </a>
                ) : null}
                {storeInfo?.social?.facebook ? (
                  <a href={storeInfo?.social?.facebook} title="Facebook" target="_blank" rel="noreferrer noopener">
                    <FacebookFooter id="footer-facebook-link" className="w-24 mr-30" />
                  </a>
                ) : null}
              </div>
            ) : null}
            <div className="flex items-center justify-between md:-mr-30 text-14">
              <div className="text-left">
                <div className="mt-24">
                  {storeInfo?.contactInfo?.email ? (
                    <div className="flex flex-wrap sm:flex-row mb-8">
                      <Mail id="footer-contact-mail-icon" className="mr-10" />
                      <div
                        className="pt-2 text-footer-link text-opacity-70"
                        font-customisation="para-text"
                        color-customisation="footer-links"
                        id="footer-contact-mail"
                      >
                        {storeInfo?.contactInfo?.email}
                      </div>
                    </div>
                  ) : null}
                  {storeInfo?.contactInfo?.number ? (
                    <div className="flex flex-wrap sm:flex-row mb-8">
                      <Phone id="footer-contact-number-icon" />
                      <div
                        className="ml-10 pt-2 text-footer-link text-opacity-70"
                        font-customisation="para-text"
                        color-customisation="footer-links"
                        id="footer-contact-number"
                      >
                        {storeInfo?.contactInfo?.number}
                      </div>
                    </div>
                  ) : null}
                  {storeInfo?.contactInfo?.address ? (
                    <div className="flex items-start">
                      <div className="flex items-end">
                        <Location id="footer-contact-address-icon" className="mr-0 lg:-mx-0" />
                      </div>
                      <div className="flex flex-wrap">
                        <div
                          className="ml-10 pt-4 text-footer-link text-opacity-70"
                          font-customisation="para-text"
                          color-customisation="footer-links"
                          id="footer-contact-address"
                        >
                          {storeInfo?.contactInfo?.address}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="mt-8">
                    <Nudge slug="upgrade-to-premium-themes-desktop" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : storeInfo?.hasSocialLinks === false && getMerchantJWTToken() ? (
          <div>
            <div className="mt-8">
              <Nudge slug="upgrade-to-premium-themes-desktop" />
            </div>
          </div>
        ) : null}
      </div>
      <div className="flex flex-wrap md:flex-no-wrap pb-10 container mx-auto text-center items-center md:text-left md:justify-between">
        <div
          className={`text-14 w-full md:w-auto text-footer-link ${copyrightWrapperClass}`}
          font-customisation="para-text"
          color-customisation="footer-heading"
        >
          © {context.MERCHANT_HOSTNAME} {new Date().getFullYear()}. All rights reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
