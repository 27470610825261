import { getData, URLs } from '@common/api';
import * as ProductsRating from '@common/models/ProductsRating';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchProductsRatingFailure, fetchProductsRatingRequest, fetchProductsRatingSuccess } from './slice';

function* fetchProductsRatingSaga({ payload: { id, productIds } }) {
  try {
    const data = yield call(() => getData({ url: URLs.GET_PRODUCTS_RATING.replace('{productIds}', productIds) }));
    const results = ProductsRating.processResponse(data.data.results);
    yield put(fetchProductsRatingSuccess({ id, results, error: '' }));
  } catch (e) {
    yield put(fetchProductsRatingFailure({ id, error: 'Request failed with: ' + e }));
  }
}

export default function* productsRatingSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchProductsRatingRequest.type, fetchProductsRatingSaga);
}
