import capitalize from 'lodash/capitalize';

interface SocialProfile {
  id: number;
  providerJson?: Record<string, unknown>;
  provider: string;
  prettyProvider: string;
  socialId?: string;
}

export const processResponse = (socialProfile: Record<string, never>): SocialProfile => {
  const provider = socialProfile.provider;
  return {
    id: socialProfile.id,
    providerJson: socialProfile.provider_json,
    provider,
    prettyProvider: capitalize(provider as string),
    socialId: socialProfile.social_id,
  };
};

export default SocialProfile;
