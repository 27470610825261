import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FBEPixel } from './../../models/FBEPixel';

export interface FBEPixelSliceType {
  pixel?: FBEPixel;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const fbePixel = createSlice({
  name: 'fbePixel',
  initialState: <FBEPixelSliceType>{
    pixelId: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchFBEPixelRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.pixel = null;
      state.error = '';
    },
    fetchFBEPixelSuccess(state, { payload }: PayloadAction<FBEPixel>) {
      state.pixel = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchFBEPixelFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const { fetchFBEPixelSuccess, fetchFBEPixelRequest, fetchFBEPixelFailure } = fbePixel.actions;

export default fbePixel.reducer;
