import { getData, URLs } from '@common/api';
import * as Category from '@common/models/Category';
import { Context as ContextInterface } from '@common/utils/constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects';

import {
  FetchCategoriesRequestPayloadType,
  fetchCategoryListFailure,
  fetchCategoryListRequest,
  fetchCategoryListSuccess,
} from './slice';

export function* fetchCategoryListSaga({
  payload: { userId, sectionId, useCache, fetchAfterServe },
}: PayloadAction<FetchCategoriesRequestPayloadType>): Generator<unknown> {
  try {
    const context: ContextInterface = yield select((store) => {
      return store.context.context;
    });
    const merchantID = userId || context.MERCHANT_ACCOUNT_ID;

    const [{ data }, sectionData] = yield all([
      call(() =>
        getData({
          url: URLs.GET_CATEGORIESLIST.replace('{id}', sectionId).replace('{userId}', merchantID.toString()),
          useCache,
          fetchAfterServe,
        }),
      ),
      call(() =>
        getData({
          url: URLs.GET_CATEGORIESLIST_SECTION.replace('{id}', sectionId).replace('{userId}', merchantID.toString()),
          useCache,
          fetchAfterServe,
        }),
      ),
    ]);

    data.results = data.results?.map((category) => Category.processResponse(category));
    yield put(
      fetchCategoryListSuccess({
        sectionId,
        categories: data,
        title: sectionData?.data?.title,
        layout: sectionData?.data?.layout,
        showcaseType: sectionData?.data?.showcase_type,
      }),
    );
  } catch (e) {
    yield put(fetchCategoryListFailure({ sectionId, error: 'Request failed with: ' + e }));
  }
}

export default function* CategoryListSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchCategoryListRequest.type, fetchCategoryListSaga);
}
