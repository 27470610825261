import Slider from '@common/models/Slider';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SliderSliceType {
  slider?: SliderResponseType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

export interface SliderResponseType {
  count: number;
  next: string | null;
  previous: string | null;
  results: Slider[];
}

const sliderSlice = createSlice({
  name: 'legacySlider',
  initialState: <SliderSliceType>{
    slider: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchLegacySliderRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.slider = null;
      state.error = '';
    },
    fetchLegacySliderSuccess(state, { payload }: PayloadAction<SliderResponseType>) {
      state.slider = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchLegacySliderFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const { fetchLegacySliderSuccess, fetchLegacySliderRequest, fetchLegacySliderFailure } = sliderSlice.actions;

export default sliderSlice.reducer;
