import { createSlice } from '@reduxjs/toolkit';

export interface contextType {
  context: unknown;
  error: string;
}

const context = createSlice({
  name: 'context',
  initialState: <contextType>{
    context: null,
    error: '',
  },
  reducers: {
    setContextInStore(state, { payload }) {
      state.context = payload;
      state.error = '';
    },
  },
});

export const { setContextInStore } = context.actions;

export default context.reducer;
