interface merchantJounneySteps {
  step: string;
  points: number;
  completed: boolean;
  completed_at: null;
}
interface OnboardStatus {
  [key: string]: merchantJounneySteps;
}

const onboardStatusSlug = {
  store_onboarding_completed: 'storeOnboardingCompleted',
  add_business_logo: 'addBusinessLogo',
  add_product: 'addProduct',
  add_public_contact_info: 'addPublicContactInfo',
  add_social_media_links: 'addSocialMediaLinks',
  get_android_app: 'getAndroidApp',
};

export const processResponse = (onboardStatus: Array<merchantJounneySteps>): OnboardStatus => {
  const processedResponse = {};

  onboardStatus.forEach((res) => {
    const { step, ...otherDetails } = res;
    processedResponse[onboardStatusSlug[step]] = otherDetails;
  });
  return processedResponse;
};

export default OnboardStatus;
