import { FilterLinkResponseType } from '@common/models/FilterBar';
import { CUSTOM_LINK_LABELS } from '@common/utils/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FilterBarSliceType {
  links?: Array<FilterLinkResponseType>;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const filterBarSlice = createSlice({
  name: 'filterBar',
  initialState: <FilterBarSliceType>{
    links: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchNavigationDetailsRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.links = null;
      state.error = '';
    },
    fetchNavigationDetailsSuccess(state, { payload: { customPages, processedResponse } }) {
      if (!customPages) {
        state.links = processedResponse.filter((link) => !CUSTOM_LINK_LABELS.includes(link.label)) || [];
      } else {
        state.links = processedResponse;
      }
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchNavigationDetailsFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const {
  fetchNavigationDetailsSuccess,
  fetchNavigationDetailsRequest,
  fetchNavigationDetailsFailure,
} = filterBarSlice.actions;

export default filterBarSlice.reducer;
