import { PRODUCT_REFERRED_FROM } from '@common/models/Product';
import queryString from 'query-string';

import CartProduct from '../models/CartProduct';
import { RootState, store } from '../store';
import { trackEvent } from '../utils/analytics';
import { getMerchantJWTToken } from '../utils/token';
import history, { historyTimeline } from './history';

export const trackDetailsEntry = (
  products: Array<CartProduct>,
  eventName: string,
  additionalProperties: Record<string, unknown> = {},
): void => {
  const state: RootState = store.getState();
  const totalAmount = products.reduce((sum, product) => sum + product.quantity * product.final_price, 0);
  const totalProducts = products.reduce((sum, product) => sum + product.quantity, 0);

  trackEvent(eventName, {
    order_id: '',
    cart_amount: totalAmount,
    num_products: products.length,
    num_items: totalProducts,
    buyer_id: state.userProfile.profile?.id || '',
    parent_ref_store_flag: 1,
    ga_client_id: '',
    self_flag: !!getMerchantJWTToken(),
    seller_username: state.storeInfo.storeInfo?.username,
    seller_account_id: state.storeInfo.storeInfo?.accountId,
    is_mojocommerce_cart: true,
    login_flag: !!state.userProfile.profile?.id,
    ...additionalProperties,
  });
};

export const trackAddAddress = (source: string): void => {
  const state: RootState = store.getState();
  trackEvent('Added Buyer Address', {
    buyer_id: state.userProfile.profile?.id || '',
    login_flag: !!state.userProfile.profile?.id,
    source,
  });
};

export const viewOfferPage = <T extends { toString: () => string }>(prodData: T): void => {
  const state: RootState = store.getState();
  let { referred_from } = queryString.parse(history.location.search);

  const currentIndex = historyTimeline.length - 1;
  const previousIndex = historyTimeline.length - 2;

  if (!referred_from && currentIndex >= 0) {
    const location = historyTimeline[currentIndex];
    if (location?.pathname === '/') {
      referred_from = PRODUCT_REFERRED_FROM.STORE_HOME;
    } else if (location?.pathname?.startsWith('/category/') && !/^\/[\w-]+\/c\d+/.test(location?.pathname)) {
      referred_from = PRODUCT_REFERRED_FROM.CATEGORY;
    }
  }
  if (!referred_from && previousIndex >= 0) {
    const location = historyTimeline[previousIndex];
    if (location?.pathname === '/') {
      referred_from = PRODUCT_REFERRED_FROM.STORE_HOME;
    } else if (location?.pathname?.startsWith('/category/') && !/^\/[\w-]+\/c\d+/.test(location?.pathname)) {
      referred_from = PRODUCT_REFERRED_FROM.CATEGORY;
    }
  }

  if (!referred_from) {
    referred_from = PRODUCT_REFERRED_FROM.STORE_HOME;
  }

  trackEvent('Viewed Offer Page', {
    link_source: 'commerce',
    store_account_id: state.storeInfo.storeInfo?.accountId,
    seller_account_id: state.storeInfo.storeInfo?.accountId,
    seller_username: state.storeInfo.storeInfo?.username,
    self_flag: !!getMerchantJWTToken(),
    referred_from,
    ...prodData,
  });
};
