import Text from '@common/models/Text';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TextSliceType {
  sections?: Record<number, Text>;
}

export interface TextResponseType {
  id: number;
  result: Text;
}

const textSlice = createSlice({
  name: 'text',
  initialState: <TextSliceType>{
    sections: {},
  },
  reducers: {
    fetchTextRequest(state, { payload }) {
      const textObject = {
        isFetching: true,
        isFetched: false,
        text: null,
        error: '',
        layout: null,
      };
      state[payload.id] = textObject;
    },
    fetchTextSuccess(state, { payload }: PayloadAction<TextResponseType>) {
      const textObject = {
        isFetching: false,
        isFetched: true,
        text: payload.result,
        layout: payload.layout,
        error: '',
      };
      state[payload.id] = textObject;
    },
    fetchTextFailure() {
      // console.log(_payload);
    },
    editText(state, { payload }: unknown) {
      const textObject = {
        isFetching: false,
        isFetched: true,
        text: payload.data,
        layout: payload.layout,
        error: '',
      };
      state[payload.id] = textObject;
    },
  },
});

export const { fetchTextSuccess, fetchTextRequest, fetchTextFailure, editText } = textSlice.actions;

export default textSlice.reducer;
