import React from 'react';

const ErrorFallback: React.FC = () => {
  return (
    <div className="container mx-auto flex align-center justify-center items-center h-screen">
      <div className="mb-200">
        <h1 className="text-32 font-light mb-16">Oops!</h1>
        <h3 className="text-24 font-medium mb-16">Something went wrong.</h3>
        <button
          className="bg-theme py-12 px-20 text-16 text-white rounded"
          onClick={() => {
            window.location.reload();
          }}
        >
          Try Again
        </button>
      </div>
    </div>
  );
};

export default ErrorFallback;
