import { Banner, bannerProcessResponse } from './Banner';
import { Category, categoryProcessResponse } from './Category';
import { Products, productsProcessResponse } from './Products';
import { SectionEnum } from './SectionEnum';
import { Testimonial, testimonialProcessResponse } from './Testimonial';
import { Text, textProcessResponse } from './Text';

export { SectionEnum };

export type { Banner, Testimonial, Products, Category, Text };
export type SectionType = Banner | Category | Products | Testimonial | Text | Record<string, unknown>;

export const processResponse = (sections: Array<Record<string, never>>): Array<SectionType> => {
  return sections.map((section) => {
    switch (section.type) {
      case SectionEnum.BANNER:
        return bannerProcessResponse(section);
      case SectionEnum.CATEGORY:
        return categoryProcessResponse(section);
      case SectionEnum.PRODUCTS:
        return productsProcessResponse(section);
      case SectionEnum.TESTIMONIAL:
        return testimonialProcessResponse(section);
      case SectionEnum.TEXT:
        return textProcessResponse(section);
      default:
        return section;
    }
  });
};
