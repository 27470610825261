interface Subdivison {
  id: number;
  slug: string;
  name: string;
  country: string;
}

export const processResponse = (subdivision: Record<string, never>): Subdivison => {
  return {
    id: subdivision.id,
    slug: subdivision.iso3166_2,
    name: subdivision.name,
    country: subdivision.country,
  };
};

export default Subdivison;
