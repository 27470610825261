import { getData, URLs } from '@common/api';
import * as Policy from '@common/models/sections/Policy';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchTosFailure, fetchTosRequest, fetchTosSuccess } from './slice';

function* fetchTosSaga() {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_TOS }));
    data.processData = Policy.policyProcessResponse(data);
    yield put(fetchTosSuccess(data.processData));
  } catch (e) {
    yield put(fetchTosFailure('Request failed with: ' + e));
  }
}

export default function* policiesTosSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchTosRequest.type, fetchTosSaga);
}
