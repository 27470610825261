import { getData, URLs } from '@common/api';
import * as Section from '@common/models/sections';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchPoliciesFailure, fetchPoliciesRequest, fetchPoliciesSuccess } from './slice';

function* fetchPoliciesSaga({ payload: policyType }: PayloadAction<string>) {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_POLICIES.replace('{policyType}', policyType) }));
    data.sections = Section.processResponse(data.sections);
    yield put(fetchPoliciesSuccess(data.sections));
  } catch (e) {
    yield put(fetchPoliciesFailure('Request failed with: ' + e));
  }
}

export default function* policiesSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchPoliciesRequest.type, fetchPoliciesSaga);
}
