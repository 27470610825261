import StoreTestimonials from '@common/models/StoreTestimonials';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface StoreTestimonialsSliceType {
  sections?: Record<number, StoreTestimonials>;
}

export interface StoreTestimonialsResponseType {
  id: number;
  result: StoreTestimonials;
}

const storeTestimonialsSlice = createSlice({
  name: 'storeTestimonials',
  initialState: <StoreTestimonialsSliceType>{
    sections: {},
  },
  reducers: {
    fetchStoreTestimonialsRequest(state, { payload }) {
      const storeTestimonialsObject = {
        isFetching: true,
        isFetched: false,
        storeTestimonials: null,
        error: '',
      };
      state[payload.id] = storeTestimonialsObject;
    },
    fetchStoreTestimonialsSuccess(state, { payload }: PayloadAction<StoreTestimonialsResponseType>) {
      const storeTestimonialsObject = {
        isFetching: false,
        isFetched: true,
        storeTestimonials: payload.result,
        error: '',
      };
      state[payload.id] = storeTestimonialsObject;
    },
    fetchStoreTestimonialsFailure() {
      // console.log(_payload);
    },
    editStoreTestimonials(state, { payload }: unknown) {
      const storeTestimonialsObject = {
        isFetching: false,
        isFetched: true,
        storeTestimonials: payload.result,
        error: '',
      };
      state[payload.id] = storeTestimonialsObject;
    },
  },
});

export const {
  fetchStoreTestimonialsSuccess,
  fetchStoreTestimonialsRequest,
  fetchStoreTestimonialsFailure,
  editStoreTestimonials,
} = storeTestimonialsSlice.actions;

export default storeTestimonialsSlice.reducer;
