interface UserProfile {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  contact: string;
  birthday: string;
  referralCode: string;
}

export const processResponse = (userProfile: Record<string, never>): UserProfile => {
  return {
    id: userProfile.id,
    firstname: userProfile.firstname,
    lastname: userProfile.lastname,
    email: userProfile.email,
    contact: userProfile.contact,
    birthday: userProfile.birthday && (userProfile.birthday as string).split('-').reverse().join('-'),
    referralCode: userProfile.referral_code,
  };
};

export const processPayload = (userProfile: UserProfile): Record<string, unknown> => {
  return {
    firstname: userProfile.firstname,
    lastname: userProfile.lastname,
    email: userProfile.email,
    contact: userProfile.contact,
    birthday: userProfile.birthday && (userProfile.birthday as string).split('-').reverse().join('-'),
  };
};

export default UserProfile;
