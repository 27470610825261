import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import GoogleOAuth from '../../models/GoogleOAuth';

export interface GoogleOAuthSliceType {
  googleOauth?: GoogleOAuth;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const googleOauthSlice = createSlice({
  name: 'googleOauth',
  initialState: <GoogleOAuthSliceType>{
    googleOauth: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchGoogleOauthDataRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.googleOauth = null;
      state.error = '';
    },
    fetchGoogleOauthDataSuccess(state, { payload }: PayloadAction<GoogleOAuth>) {
      state.googleOauth = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchGoogleOauthDataFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const {
  fetchGoogleOauthDataSuccess,
  fetchGoogleOauthDataRequest,
  fetchGoogleOauthDataFailure,
} = googleOauthSlice.actions;

export default googleOauthSlice.reducer;
