import '@common/styles/layouts.css';

import StoreInfo from '@common/models/StoreInfo';
import { RootState } from '@common/store';
import {
  addProductToCart,
  CartSliceType,
  removeProductFromCart,
  setProductQuantityInCart,
  subtractProductFromCart,
} from '@common/store/cart/slice';
import { CategoriesSliceType } from '@common/store/categories/slice';
import { FeaturesSliceType } from '@common/store/features/slice';
import { fetchPageHeaderRequest, PageHeaderSliceType } from '@common/store/pageHeader/slice';
import { fetchProductsRequest } from '@common/store/products/slice';
import { fetchSearchRequest, SearchSliceType } from '@common/store/search/slice';
import { UserProfileSliceType } from '@common/store/userProfile/slice';
import { UserSectionsSliceType } from '@common/store/userSections/slice';
import loadable from '@loadable/component';
import { DEFAULT_NAVBAR_LAYOUT_SELECTION } from '@theme/utils/constants';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ADD_NEW_SECTION_ID } from '../../common/utils/constants';
import Fallback from '../common/Fallback';

export interface NavbarLayoutProps {
  userProfile: UserProfileSliceType;
  cart: CartSliceType;
  storeInfo: StoreInfo;
  features: FeaturesSliceType;
  addProductToCart: typeof addProductToCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  setProductQuantityInCart: typeof setProductQuantityInCart;
  removeProductFromCart: typeof removeProductFromCart;
  fetchSearchRequest: typeof fetchSearchRequest;
  fetchProductsRequest: typeof fetchProductsRequest;
  search: Record<string, SearchSliceType>;
  categories: CategoriesSliceType;
  fetchPageHeaderRequest: fetchPageHeaderRequest.type;
  pageHeader: PageHeaderSliceType;
  id: string;
  layoutName: string;
  userSections: UserSectionsSliceType;
}

interface NavBarState {
  ViewCart: boolean;
}

const PageHeader1 = loadable(() => import(/* webpackChunkName: "PH1" */ './PageHeader1'));
const PageHeader2 = loadable(() => import(/* webpackChunkName: "PH2" */ './PageHeader2'));
const PageHeader3 = loadable(() => import(/* webpackChunkName: "PH3" */ './PageHeader3'));
const PageHeader4 = loadable(() => import(/* webpackChunkName: "PH4" */ './PageHeader4'));
const PageHeader5 = loadable(() => import(/* webpackChunkName: "PH5" */ './PageHeader5'));
const PageHeader6 = loadable(() => import(/* webpackChunkName: "PH6" */ './PageHeader6'));
const PageHeader7 = loadable(() => import(/* webpackChunkName: "PH7" */ './PageHeader7'));
const PageHeader8 = loadable(() => import(/* webpackChunkName: "PH8" */ './PageHeader8'));
const PageHeader9 = loadable(() => import(/* webpackChunkName: "PH9" */ './PageHeader9'));

class Navbar extends PureComponent<NavbarLayoutProps, NavBarState> {
  constructor(props) {
    super(props);
    const { fetchPageHeaderRequest, id, pageHeader } = this.props;
    if (!pageHeader[id]?.isFetched && !pageHeader[id]?.isFetching && id !== ADD_NEW_SECTION_ID) {
      fetchPageHeaderRequest(id);
    }
    this.state = { ViewCart: this.props?.ViewCart || false };
  }
  render(): React.ReactElement {
    const { pageHeader, id, storeInfo, userSections } = this.props;
    const layoutName = pageHeader[id]?.layout?.name || DEFAULT_NAVBAR_LAYOUT_SELECTION;
    const commonProps = {
      ...this.props,
      storeInfo: storeInfo.storeInfo,
      storeType: storeInfo.storeInfo?.storeType,
      isPaymentsDisabled: !storeInfo.storeInfo?.paymentsAllowed || !storeInfo.storeInfo?.isCodEnabled,
      isStoreInfoFetching: storeInfo.isFetching,
      ViewCart: this.props?.ViewCart,
      id: id,
      setViewCart: this.props?.setViewCart
        ? this.props?.setViewCart
        : () => {
            this.setState({ ViewCart: false });
          },
    };
    return (
      <div id={`ph-${id}`}>
        {pageHeader[id] && layoutName ? (
          <>
            {layoutName === 'PH1' ? (
              <PageHeader1
                fallback={<Fallback id={`ph-${id}`} sectionType={'PageHeader'} />}
                {...commonProps}
                pageHeader={pageHeader[id]?.pageHeader}
              />
            ) : null}
            {layoutName === 'PH2' ? (
              <PageHeader2
                fallback={<Fallback id={`ph-${id}`} sectionType={'PageHeader'} />}
                {...commonProps}
                pageHeader={pageHeader[id]?.pageHeader}
              />
            ) : null}
            {layoutName === 'PH3' ? (
              <PageHeader3
                fallback={<Fallback id={`ph-${id}`} sectionType={'PageHeader'} />}
                {...commonProps}
                pageHeader={pageHeader[id]?.pageHeader}
              />
            ) : null}
            {layoutName === 'PH4' ? (
              <PageHeader4
                fallback={<Fallback id={`ph-${id}`} sectionType={'PageHeader'} />}
                {...commonProps}
                pageHeader={pageHeader[id]?.pageHeader}
              />
            ) : null}
            {layoutName === 'PH5' ? (
              <PageHeader5
                fallback={<Fallback id={`ph-${id}`} sectionType={'PageHeader'} />}
                {...commonProps}
                pageHeader={pageHeader[id]?.pageHeader}
              />
            ) : null}
            {layoutName === 'PH6' ? (
              <PageHeader6
                fallback={<Fallback id={`ph-${id}`} sectionType={'PageHeader'} />}
                {...commonProps}
                pageHeader={pageHeader[id]?.pageHeader}
              />
            ) : null}
            {layoutName === 'PH7' ? (
              <PageHeader7
                fallback={<Fallback id={`ph-${id}`} sectionType={'PageHeader'} />}
                {...commonProps}
                pageHeader={pageHeader[id]?.pageHeader}
              />
            ) : null}
            {layoutName === 'PH8' ? (
              <PageHeader8
                fallback={<Fallback id={`ph-${id}`} sectionType={'PageHeader'} />}
                {...commonProps}
                pageHeader={pageHeader[id]?.pageHeader}
              />
            ) : null}
            {layoutName === 'PH9' ? (
              <PageHeader9
                fallback={<Fallback id={`ph-${id}`} sectionType={'PageHeader'} />}
                {...commonProps}
                pageHeader={pageHeader[id]?.pageHeader}
                isHBFirstElement={
                  userSections?.userSections[1]?.sectionType === 'Hero Banner' &&
                  userSections?.userSections[1]?.layoutName !== 'HB10' &&
                  userSections?.userSections[1]?.layoutName !== 'HB11'
                }
              />
            ) : null}
          </>
        ) : null}
      </div>
    );
  }
}

export default connect(
  ({ pageHeader, cart, products, features, storeInfo, search, userProfile, categories, userSections }: RootState) => ({
    pageHeader,
    cart,
    products,
    features,
    storeInfo,
    search,
    userProfile,
    categories,
    userSections,
  }),
  {
    fetchPageHeaderRequest,
    addProductToCart,
    removeProductFromCart,
    subtractProductFromCart,
    fetchSearchRequest,
    setProductQuantityInCart,
    fetchProductsRequest,
  },
)(Navbar);
