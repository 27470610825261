import { ChangeEvent, FocusEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { ToastVariant } from '../../lib-components/Toast';
import { store } from '../store';
import { addToast } from '../store/toasts/slice';

interface FormArguments {
  isModal?: boolean;
  initialValues?: Record<string, unknown>;
  validate?: (values: Record<string, unknown>) => Record<string, unknown>;
  asyncValidate?: (values: Record<string, unknown>) => Promise<Record<string, unknown>>;
  asyncBlurFields?: string[];
}
export interface FormReturnType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Record<string, any>;
  setValues: (value: Record<string, unknown>, ignoreHandleChange?: boolean) => void;
  handleChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleBlur: (
    e: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement> | FocusEvent<HTMLSelectElement>,
  ) => void;
  handleSubmit: (onSubmit: (values: Record<string, unknown>) => void) => (e: FormEvent<HTMLFormElement>) => void;
  initialize: (initialValues: Record<string, unknown>) => void;
  setDynamicErrors: (errors: Record<string, unknown>) => void;
  errors: Record<string, unknown>;
  asyncErrors: Record<string, unknown>;
  submitting: boolean;
  valid: boolean;
  submissions: number;
  dirty: boolean;
  pristine: boolean;
  touched: Record<string, boolean>;
  clearForm: () => void;
  hasHandleChangeExecuted: boolean;
}

export const isEmpty = (obj: Record<string, unknown>): boolean =>
  Object.keys(obj).filter((key) => obj[key]).length === 0;

const useForm = ({
  initialValues = {},
  validate,
  asyncValidate,
  asyncBlurFields = [],
  isModal,
}: FormArguments): FormReturnType => {
  const initial = useRef(initialValues);
  const [values, setValues] = useState(initialValues);
  const [submissions, setSubmissions] = useState(0);
  const [touched, setTouched] = useState({});
  const [asyncValidatingField, setAsyncValidatingField] = useState(null);
  const [asyncErrors, setAsyncErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [hasHandleChangeExecuted, setHasHandleChangeExecuted] = useState(false);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>,
  ) => {
    e.persist?.();
    const { type, name } = e.target;

    const getValue = () => {
      if (type === 'checkbox') {
        e = e as ChangeEvent<HTMLInputElement>;
        return e.target.checked;
      } else if (type === 'select-multiple') {
        e = e as ChangeEvent<HTMLSelectElement>;
        return Array.from(e.target.selectedOptions).map((o) => o.value);
      }
      return e.target.value;
    };

    const value = getValue();
    if (type === 'checkbox' && e.currentTarget.dataset?.type === 'multiple') {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: {
          ...((prevValues[name] as Record<string, boolean>) ?? {}),
          [e.target.value]: value,
        },
      }));
    } else {
      setValues((prevValues) => ({ ...prevValues, [name]: value }));
    }
    if (type === 'file') {
      /* eslint-disable-next-line */
      setValues((prevValues) => ({ ...prevValues, [`${name}^^Files`]: (e.target as any).files }));
    }
    setAsyncErrors((asyncErrors) => {
      const errors = { ...asyncErrors };
      delete errors[name];
      return errors;
    });
    if (!hasHandleChangeExecuted) {
      setHasHandleChangeExecuted(true);
    }
  };

  const setDynamicErrors = (errors: Record<string, unknown>) => {
    const modifiedErrors = Object.keys(errors).reduce((acc, key) => {
      let error = Array.isArray(errors[key]) ? errors[key][0] : errors[key];
      error = typeof error === 'object' ? JSON.stringify(error) : error;
      return { ...acc, [key]: error };
    }, {});
    const { non_field_errors, non_fields_errors, __all__, ...modifiedFormFieldErrorsOnly } = modifiedErrors as Record<
      string,
      string
    >;
    const otherErrors = non_field_errors || non_fields_errors || __all__;
    if (otherErrors) {
      if (isModal) {
        toast.error(otherErrors.toString());
      } else {
        store.dispatch(
          addToast({
            content: otherErrors,
            variant: ToastVariant.error,
            dismissAfterMillis: 10000,
          }),
        );
      }
    }
    setAsyncErrors(modifiedFormFieldErrorsOnly);
  };

  const handleBlur = (
    e: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement> | FocusEvent<HTMLSelectElement>,
  ) => {
    const { name } = e.target;
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));

    if (asyncValidate) {
      const isAsyncField = !!asyncBlurFields.find((field) => field === name);
      if (isAsyncField) {
        setAsyncValidatingField(name);
      }
    }
  };

  const initialize = useCallback((initialValues) => {
    if (!initialValues) {
      setValues(initial.current);
    } else {
      initial.current = initialValues;
      setValues(initialValues);
    }
  }, []);

  useEffect(() => {
    if (asyncValidatingField) {
      asyncValidate(values).then((errors) => {
        if (!isEmpty(errors)) {
          setAsyncErrors(errors);
        }
        setAsyncValidatingField(null);
      });
    }
  }, [asyncValidatingField, values, asyncValidate]);

  const asyncValidateFields = () => {
    return asyncValidate(values).then((errors) => {
      if (!isEmpty(errors)) {
        setAsyncErrors(errors);
        return false;
      }
      setAsyncValidatingField(null);
      return true;
    });
  };

  const errors = validate ? validate(values) : {};
  const valid = isEmpty(errors) && isEmpty(asyncErrors);

  const handleSubmit = (onSubmit) => (e) => {
    setSubmissions((submissions) => submissions + 1);
    setSubmitting(true);
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    if (asyncValidate) {
      e.persist?.();
      asyncValidateFields().then((asyncValid) => {
        if (asyncValid && valid) {
          Promise.resolve(onSubmit(values, e)).finally(() => setSubmitting(false));
        } else {
          setSubmitting(false);
        }
      });
    } else if (valid) {
      Promise.resolve(onSubmit(values, e)).finally(() => setSubmitting(false));
    } else {
      setSubmitting(false);
    }
  };

  const dirty = !!Object.keys(initial.current).find((key) => initial.current[key] !== values[key]);
  const pristine = !dirty;

  const clearForm = () => {
    setValues({});
    setTouched({});
    setAsyncErrors({});
    setSubmissions(0);
  };

  return {
    values,
    setValues: (value: Record<string, string>, ignoreHandleChange?: boolean) => {
      if (!ignoreHandleChange) {
        setHasHandleChangeExecuted(true);
      }
      setValues(value);
    },
    handleChange,
    handleBlur,
    handleSubmit,
    initialize,
    setDynamicErrors,
    submissions,
    errors,
    asyncErrors,
    submitting,
    valid,
    dirty,
    pristine,
    touched,
    clearForm,
    hasHandleChangeExecuted,
  };
};

export default useForm;
