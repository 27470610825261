import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PoliciesSliceTosType {
  isFetched: boolean;
  error: string;
  policyTos: PolicyTos;
}

export interface PolicyTos {
  cancelAndRefund: boolean;
  termsAndConditions: boolean;
  shippingAndDelivery: boolean;
  privacy: boolean;
}

const policiesSliceTos = createSlice({
  name: 'policiesTos',
  initialState: <PoliciesSliceTosType>{
    isFetched: false,
    error: '',
    policyTos: {},
  },
  reducers: {
    fetchTosRequest(state) {
      state.isFetched = false;
    },
    fetchTosSuccess(state, { payload }: PayloadAction<PolicyTos>) {
      state.policyTos = payload;
      state.isFetched = true;
    },
    fetchTosFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetched = true;
    },
  },
});

export const { fetchTosRequest, fetchTosSuccess, fetchTosFailure } = policiesSliceTos.actions;

export default policiesSliceTos.reducer;
