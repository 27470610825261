import classnames from 'classnames';
import React from 'react';

interface ImageProps {
  src: string;
  alt: string;
  isEager?: boolean;
  id?: string;
  className?: string;
  noDefaultFit?: boolean;
  noDefaultDimensions?: boolean;
  width?: string;
  height?: string;
  style?: Record<string, string>;
  onClick?: () => void;
}

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  isEager,
  noDefaultDimensions,
  noDefaultFit,
  className = '',
  id = '',
  style = {},
  onClick,
}) => {
  return (
    <img
      id={id}
      className={classnames(
        'text-13 overflow-hidden',
        {
          'object-contain': !noDefaultFit,
        },
        {
          'h-full w-full': !noDefaultDimensions,
        },
        className,
      )}
      loading={isEager ? 'eager' : 'lazy'}
      src={`${src}`}
      alt={alt}
      style={style}
      onClick={onClick}
    />
  );
};

export default Image;
