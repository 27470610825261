import React, { useEffect, useRef } from 'react';

export interface LoadMoreProps {
  fetchMoreUserSections?: () => void;
  hasPrevSectionsLoaded: () => boolean;
}

const LoadMore: React.FC<LoadMoreProps> = ({ fetchMoreUserSections, hasPrevSectionsLoaded }) => {
  const ref = useRef();
  const firstCallImminent = useRef(true);
  useEffect(() => {
    const options = {
      threshold: 1.0,
      rootMargin: '400px',
    };

    const observer = new IntersectionObserver(() => {
      console.log('REACHED END OF SECTIONS');
      if (!firstCallImminent.current) {
        if (hasPrevSectionsLoaded()) {
          fetchMoreUserSections();
        }
      } else {
        firstCallImminent.current = false;
      }
    }, options);

    const target = document.querySelector('#user-sections-end');
    observer.observe(target);

    return () => {
      observer.disconnect();
      firstCallImminent.current = true;
    };
  }, []);
  return <div ref={ref} className="h-4" id="user-sections-end" />;
};

export default LoadMore;
