export const PRODUCT_CARD_WIDTH = 300;
export const PRODUCT_CARD_HEIGHT = 300;

export const CATEGORY_IMAGE_WIDTH = 450;
export const CATEGORY_IMAGE_HEIGHT = 370;

export const BANNER_IMAGE_WIDTH = 1440;
export const BANNER_IMAGE_HEIGHT = 480;

export const MOBILE_CATEGORY_IMAGE_WIDTH = 280;
export const MOBILE_CATEGORY_IMAGE_HEIGHT = 230;

export const MOBILE_BANNER_IMAGE_WIDTH = 390;
export const MOBILE_BANNER_IMAGE_HEIGHT = 375;

export const TESTIMONIALS_IMAGE_WIDTH = 64;
export const TESTIMONIALS_IMAGE_HEIGHT = 64;

export const MOBILE_TESTIMONIALS_IMAGE_WIDTH = 64;
export const MOBILE_TESTIMONIALS_IMAGE_HEIGHT = 64;

export const DEFAULT_LAYOUT_SELECTION = 3;
export const DEFAULT_NAVBAR_LAYOUT_SELECTION = 'PH5';
export const DEFAULT_SLIDER_LAYOUT_SELECTION = 'HB5';
export const DEFAULT_PRODUCTLIST_LAYOUT_SELECTION = 'PS9';
export const DEFAULT_CATEGORYLIST_LAYOUT_SELECTION = 'CS12';
export const DEFAULT_TESTIMONIALS_LAYOUT_SELECTION = 'Testimonial16';
export const DEFAULT_TEXT_LAYOUT_SELECTION = 'Text1';

export const SHOW_ALL_PRODUCTS_IN_HOME = true;
export const SECTION_HEADING_CLASSNAME = 'font-semibold';
export const SLIDER_TOP_PADDING = 'pt-0';
export const LAYOUT_CONFIG = {
  productList: {
    ShowTaxTag: false,
  },
  slider: {
    showCtaBtn: false,
    aspectRatio: 1680 / 560,
  },
  HB5: {
    desktop: {
      width: 1920,
      height: 640,
    },
    mobile: {
      width: 600,
      height: 200,
    },
  },
};
export const COMMON_TOAST_MESSAGE = {};
