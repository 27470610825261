import Product from '@common/models/Product';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FetchProductSuccessResponseType {
  count: number;
  next: string | null;
  previous: string | null;
  results: Product[];
}

export interface FetchProductsRequestPayloadType {
  storeKey: string;
  queryParams: ProductQueryParamsType;
  useCache?: boolean;
  fetchAfterServe?: boolean;
  resetAllProducts?: boolean;
}

export interface FetchProductsSuccessPayloadType {
  storeKey: string;
  products: FetchProductSuccessResponseType;
  queryParams: ProductQueryParamsType;
}

export interface FetchProductsFailurePayloadType {
  storeKey: string;
  error: string;
}

export type PRODUCTS_SORT_ORDERING_TYPE =
  | 'created'
  | 'modified'
  | 'title'
  | 'sort_order'
  | 'price'
  | 'final_price'
  | 'discounted_price'
  | '-created'
  | '-modified'
  | '-title'
  | '-sort_order'
  | '-price'
  | '-final_price'
  | '-discounted_price';

export interface ProductQueryParamsType {
  facets?: string;
  base_product__isnull: boolean;
  base_product?: number;
  price__gte?: number;
  price__lte?: number;
  discounted_price__gte?: number;
  discounted_price__lte?: number;
  final_price__gte?: number;
  final_price__lte?: number;
  is_featured?: boolean;
  type?: 'physical-product' | 'digital-product' | 'event-tickets' | 'other';
  stock__gte?: number;
  stock__lte?: number;
  created__gte?: string;
  created__lte?: string;
  modified__gte?: string;
  modified__lte?: string;
  category?: string;
  ordering: PRODUCTS_SORT_ORDERING_TYPE;
  // account: string;
  limit: number;
  page: number;
  product_filter?: string;
}

export interface ProductsSliceType {
  pagination: {
    currentPage: number;
    lastPage: number;
    multiplier: number;
  };
  currentQueryParams?: ProductQueryParamsType;
  list?: FetchProductSuccessResponseType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
  fetchedProducts: Product[];
}

export const getProductQueryParamsInitialState = (limit?: number): ProductQueryParamsType => ({
  base_product__isnull: true,
  limit: limit ?? 24,
  page: 1,
  ordering: 'sort_order',
});

const productsInitialState: ProductsSliceType = {
  pagination: {
    currentPage: 1,
    lastPage: 1,
    multiplier: 1,
  },
  currentQueryParams: null,
  isFetching: false,
  isFetched: false,
  list: null,
  error: '',
  fetchedProducts: [],
};

const productsSlice = createSlice({
  name: 'products',
  initialState: <Record<string, ProductsSliceType>>{},
  reducers: {
    resetProductsStore(state, { payload: storeKey }: PayloadAction<string>) {
      state[storeKey] = productsInitialState;
    },
    fetchProductsRequest(
      state,
      { payload: { storeKey, queryParams } }: PayloadAction<FetchProductsRequestPayloadType>,
    ) {
      state[storeKey] = {
        // ...productsInitialState,
        ...state[storeKey],
        isFetching: true,
        isFetched: false,
        // list: null,
        currentQueryParams: queryParams,
        error: '',
      };
    },
    fetchProductsSuccess(
      state,
      { payload: { storeKey, products, queryParams } }: PayloadAction<FetchProductsSuccessPayloadType>,
    ) {
      state[storeKey].pagination = {
        currentPage: queryParams.page,
        lastPage: parseInt(`${(products.count - 1) / queryParams.limit + 1}`),
        multiplier: queryParams.limit,
      };

      if (queryParams.page === 1) {
        state[storeKey].fetchedProducts?.splice(0, state[storeKey].fetchedProducts?.length);
        state[storeKey].fetchedProducts?.push(...products?.results);
      } else {
        state[storeKey].fetchedProducts?.push(...products?.results);
      }
      state[storeKey].list = products;
      state[storeKey].isFetching = false;
      state[storeKey].isFetched = true;
    },
    fetchProductsFailure(state, { payload: { storeKey, error } }: PayloadAction<FetchProductsFailurePayloadType>) {
      state[storeKey].error = error;
      state[storeKey].isFetching = false;
      state[storeKey].isFetched = true;
      state[storeKey].list = null;
    },
  },
});

export const {
  fetchProductsSuccess,
  fetchProductsRequest,
  fetchProductsFailure,
  resetProductsStore,
} = productsSlice.actions;

export default productsSlice.reducer;
