import { SectionEnum } from './SectionEnum';

export interface Banner {
  type: SectionEnum.BANNER;
  sliderEnabled: boolean;
  bannerEnabled: boolean;
  bannerText: string;
  layout: string;
}

export const bannerProcessResponse = (banner: Record<string, never>): Banner => {
  return {
    type: banner.type,
    sliderEnabled: banner.slider_enabled,
    bannerEnabled: banner.banner_enabled,
    bannerText: banner.banner_text,
    layout: banner.layout,
  };
};
