import { getData, URLs } from '@common/api';
import * as UserProfile from '@common/models/UserProfile';
import { isForbiddenError, isUnauthorizedError } from '@common/utils/error';
import { removeBuyerJWTToken } from '@common/utils/token';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { trackEvent } from '../../utils/analytics';
import { COOKIE_KEYS } from '../../utils/constants';
import history from '../../utils/history';
import { getCookie } from '../../utils/token';
import { fetchUserProfileFailure, fetchUserProfileRequest, fetchUserProfileSuccess } from './slice';

function* fetchUserProfileSaga({ payload: isAfterLogin }: PayloadAction<boolean>) {
  try {
    let { data } = yield call(() => getData({ url: URLs.GET_USER_DETAILS }));
    data = UserProfile.processResponse(data);
    yield put(fetchUserProfileSuccess(data));
    if (isAfterLogin) {
      trackEvent('Logged In Buyer on Store', {
        method: getCookie(COOKIE_KEYS.LOGIN_TYPE),
        buyer_id: data.id,
        source: history.location.pathname?.startsWith('/checkout/v2') ? 'checkout' : 'store',
      });
    }
  } catch (e) {
    if (isForbiddenError(e) || isUnauthorizedError(e)) {
      removeBuyerJWTToken();
      history.push('/account/login');
    }
    yield put(fetchUserProfileFailure('Request failed with: ' + e));
  }
}

export default function* userProfileSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchUserProfileRequest.type, fetchUserProfileSaga);
}
