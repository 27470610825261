import { saveFileProductOptions } from '../api';
import Product, { PRODUCT_CUSTOM_FIELD_TYPE } from './Product';

export interface OptionValue {
  id: number;
  image: string | null;
  text: string;
  sortOrder: number;
}

export interface Option {
  formInputName: string;
  id: number;
  name: string;
  type: number;
  charges: number;
  chargeable: boolean;
  required: boolean;
  values: OptionValue[];
}

export const processResponse = (option: Record<string, never>): Option => {
  const values = (option.options as Record<string, never>[])?.map((value) => ({
    id: value.id,
    image: value.image,
    text: value.text,
    sortOrder: value.sort_order,
  }));
  return {
    formInputName: `${option.id}-${option.title}`,
    id: option.id,
    name: option.title,
    type: option.field_type,
    required: option.is_mandatory,
    values,
    charges: +(option.charges || 0),
    chargeable: option.is_chargeable,
  };
};

export default Option;

export interface FormProductOptionValue {
  id: number;
  text: string;
}

export type FormProductOptionValueType = string[] | FormProductOptionValue | FormProductOptionValue[];
export interface FormCustomField {
  id: number;
  chargeable: boolean;
  name: string;
  type: number;
  charges: number;
  values: FormProductOptionValueType;
}

export interface FormCustomFieldReturnType {
  id: number;
  is_chargeable: boolean;
  title: string;
  field_type: number;
  charges: number;
  is_mandatory: boolean;
  values: FormProductOptionValueType;
}

export interface GetFormCustomFieldReturnType {
  additionalCharges: number;
  formCustomFields: FormCustomFieldReturnType[];
}

export const getFormCustomField = async (
  product: Product,
  options: Record<string, unknown>,
): Promise<GetFormCustomFieldReturnType> => {
  const formCustomFields = (
    await Promise.all(
      Object.keys(options)
        .filter((key) => !key.endsWith('^^Files'))
        .map(async (key) => {
          const productOption = product.customFields.find((option) => option.formInputName === key);
          let values = options[key] as FormProductOptionValueType;
          if (productOption.type === PRODUCT_CUSTOM_FIELD_TYPE.CHECKBOX) {
            values = productOption.values
              .filter(
                (productOptionValue) =>
                  Object.keys(options[key]).includes(productOptionValue.id.toString()) &&
                  options[key][productOptionValue.id.toString()],
              )
              .map((productOptionValue) => productOptionValue.text);
          }
          if (productOption.type === PRODUCT_CUSTOM_FIELD_TYPE.RADIO) {
            values = productOption.values
              .filter((productOptionValue) => options[key] === productOptionValue.id.toString())
              .map((productOptionValue) => productOptionValue.text);
          }

          if (
            productOption.type === PRODUCT_CUSTOM_FIELD_TYPE.FILE ||
            productOption.type === PRODUCT_CUSTOM_FIELD_TYPE.MULTI_FILE
          ) {
            let files = options[`${key}^^Files`] ?? [];
            files = Array.from(files as FileList);
            if (!Array.isArray(files)) {
              return;
            }
            const links = (
              await Promise.all(
                files?.map((file) => {
                  const formData = new FormData();
                  formData.set('file', file);
                  formData.set('upload_type', 'customfield');
                  formData.set('object_id', productOption?.id.toString());
                  return saveFileProductOptions(formData);
                }),
              )
            ).map((response) => ({ ...response.data }));
            values = links;
          }
          if (productOption.type === PRODUCT_CUSTOM_FIELD_TYPE.FILE) {
            values = values;
          }
          if (
            productOption.type === PRODUCT_CUSTOM_FIELD_TYPE.TEXT ||
            productOption.type === PRODUCT_CUSTOM_FIELD_TYPE.DATE ||
            productOption.type === PRODUCT_CUSTOM_FIELD_TYPE.DATETIME
          ) {
            values = [values];
          }
          if (Array.isArray(values) && values.length) {
            return {
              id: productOption.id,
              is_chargeable: productOption.chargeable,
              title: productOption.name,
              field_type: productOption.type,
              charges: productOption.charges,
              is_mandatory: productOption.required,
              values:
                productOption.type === PRODUCT_CUSTOM_FIELD_TYPE.FILE ||
                productOption.type === PRODUCT_CUSTOM_FIELD_TYPE.MULTI_FILE
                  ? values.map((item) => item.file_url)
                  : values,
              ...((productOption.type === PRODUCT_CUSTOM_FIELD_TYPE.FILE ||
                productOption.type === PRODUCT_CUSTOM_FIELD_TYPE.MULTI_FILE) && {
                public_urls: values
                  .map((val) => ({ [val.file_url]: val.file_public_url }))
                  .reduce((acc, next) => ({ ...acc, ...next })),
              }),
            };
          }
        }),
    )
  ).filter((x) => x);
  return {
    formCustomFields: formCustomFields,
    additionalCharges: formCustomFields.reduce((acc, curr) => acc + curr.charges, 0),
  };
};
