import Product from './Product';

export default interface ProductBundle {
  bundleDiscountedPrice: number;
  bundlePriceWithoutDiscount: number;
  bundleId: number;
  discountAmount: number;
  products: Array<Product>;
  title: string;
  description: string;
  location?: string;
  discountType: number;
}

export const processResponse = (productBundle: Record<string, never>): ProductBundle => {
  return {
    bundleDiscountedPrice: productBundle.bundle_discounted_price,
    bundlePriceWithoutDiscount: productBundle.bundle_price_without_discount,
    products: productBundle.products,
    bundleId: productBundle.bundle_id || null,
    discountAmount: productBundle.discount_amount,
    title: productBundle.title,
    description: productBundle.description,
    location: null,
    discountType: productBundle.discount_type,
  };
};
