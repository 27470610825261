import UserSection from '@common/models/UserSection';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  userSections: [],
  isFetching: false,
  isFetched: false,
  error: '',
  count: 0,
  currentPage: 1,
  customPageId: '',
  isLoading: false,
};

const customPageUserSectionsSlice = createSlice({
  name: 'customPageUserSections',
  initialState,
  reducers: {
    fetchCustomPageUserSectionRequest(state, { payload: customPageId }) {
      state.isFetching = true;
      state.isFetched = false;
      state.customPageId = customPageId;
      state.error = '';
      state.userSections = [];
      state.isLoading = true;
    },
    fetchMoreCustomPageUserSectionsRequest(state) {
      state.isFetching = true;
      state.error = '';
    },
    fetchCustomPageUserSectionSuccess(state, { payload }: PayloadAction<Array<UserSection>>) {
      const { sections, isMerchant } = payload;
      state.userSections = isMerchant ? sections : [...sections];
      state.isFetching = false;
      state.isFetched = true;
      state.isLoading = false;
    },
    fetchMoreCustomPageUserSectionsSuccess(state, { payload }: PayloadAction<Array<UserSection>>) {
      const { sections, isMerchant } = payload;
      state.userSections = isMerchant ? sections : [...state.userSections, ...sections];
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchCustomPageUserSectionFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
      state.isLoading = false;
    },
    addCustomPageUserSections(state, { payload }) {
      state.userSections = [...state.userSections, payload];
    },
    updateCustomPageCount(state, { payload }) {
      state.count = payload;
    },
    updateCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
    deleteCustomPageUserSections(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.error = '';
    },
  },
});

export const {
  fetchCustomPageUserSectionFailure,
  fetchMoreCustomPageUserSectionsSuccess,
  fetchMoreCustomPageUserSectionsRequest,
  fetchCustomPageUserSectionRequest,
  fetchCustomPageUserSectionSuccess,
  addCustomPageUserSections,
  updateCustomPageCount,
  updateCurrentPage,
  deleteCustomPageUserSections,
} = customPageUserSectionsSlice.actions;

export default customPageUserSectionsSlice.reducer;
