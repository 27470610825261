import './Nudge.css';

import { ReactComponent as Diamond } from '@common/icons/Diamond.svg';
import { RewardIcon } from '@instamojo/icons';
import cn from 'classnames';
import React from 'react';

interface NudgeItemProps {
  name: string;
  slug: string;
  text?: string;
  ctaText?: string;
  ctaUrl?: string;
  modalText?: string;
  isUserOnPremiumPlan: boolean;
  modalCtaText?: string;
  modalCtaUrl?: string;
  handleModalOpen: () => void;
}

const NudgeItem: React.FC<NudgeItemProps> = ({ text, ctaText, isUserOnPremiumPlan, handleModalOpen }) => {
  const Icon = isUserOnPremiumPlan ? RewardIcon : Diamond;
  return (
    <div>
      <div className="w-full bg-nudge-lightYellow border border-nudge-yellow rounded text-nudge-text">
        <div
          className={cn('py-8 px-16 mx-auto w-full flex max-w-940 items-center h-40', {
            'justify-center': !ctaText,
            'justify-between': !!ctaText,
          })}
        >
          <p
            onClick={handleModalOpen}
            className={cn('flex items-center text-12 md:text-14', { 'cursor-pointer underline': !ctaText })}
          >
            <Icon className="mr-4 min-w-20 h-20" size={20} />
            {text}
          </p>
          {ctaText && (
            <button onClick={handleModalOpen} className="underline text-14 focus:outline-none">
              {ctaText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NudgeItem;
