import { CartProductType } from '../store/cart/slice';
import { FormCustomField } from './CustomField';
import { FormProductOption } from './Option';

interface CartProduct {
  title: string;
  product_type: string;
  pay_what_you_want: boolean;
  id: number;
  g: number;
  quantity: number;
  stock: number;
  moq: number;
  image: string;
  sku: string;
  maxoq: number;
  price: number;
  final_price: number;
  discounted_price: number;
  cod: true;
  bank: true;
  reseller?: string;
  categories: number[];
  store_link: string;
  option1?: string;
  option2?: string;
  option3?: string;
  option1_value?: string;
  option2_value?: string;
  option3_value?: string;
  options?: FormProductOption[];
  bundle_discounted_price?: number;
  bundle_discount_amount?: number;
  bundle_id?: number;
  hash?: string;
  custom_fields?: FormCustomField[];
}

export const processProductRequest = (product: CartProductType): CartProduct => {
  const options = {} as Record<string, string>;
  product.optionNames.map((optionName, index) => {
    options[`option${index + 1}`] = optionName;
  });
  product.optionValues.map((optionValue, index) => {
    options[`option${index + 1}_value`] = optionValue;
  });
  return {
    ...options,
    title: product.title,
    product_type: product.type,
    pay_what_you_want: product.payWhatYouWant,
    id: product.id,
    g: product.groupId,
    quantity: product.cartQuantity,
    stock: product.stock,
    moq: product.minQuantity,
    image: product.images[0],
    sku: product.sku,
    maxoq: product.maxQuantity,
    price: product.price,
    final_price: product.finalPrice,
    discounted_price: product.discountedPrice,
    cod: true,
    bank: true,
    categories: [],
    store_link: product.storeLink,
    options: product.formProductOptions || [],
    custom_fields: product.formCustomFields || [],
    reseller: product.reseller,
    bundle_discount_amount: product.bundleDiscountAmount,
    bundle_discounted_price: product.bundleDiscountAmount,
    bundle_id: product.bundleId,
    hash: product.hash,
  };
};

export default CartProduct;
