import { getData, URLs } from '@common/api';
import * as CategoriesProductCount from '@common/models/CategoriesProductCount';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import {
  fetchCategoriesProductCountFailure,
  fetchCategoriesProductCountRequest,
  fetchCategoriesProductCountSuccess,
} from './slice';

function* fetchCategoriesProductCountSaga() {
  try {
    let data = yield call(() => getData({ url: URLs.GET_CATEGORIES_PRODUCT_COUNT }));
    data = CategoriesProductCount.processResponse(data.data);
    yield put(fetchCategoriesProductCountSuccess(data));
  } catch (e) {
    yield put(fetchCategoriesProductCountFailure('Request failed with: ' + e));
  }
}

export default function* categoriesProductCountSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchCategoriesProductCountRequest.type, fetchCategoriesProductCountSaga);
}
