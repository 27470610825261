interface PageScript {
  id: number;
  pageType: number;
  pageTypeVerbose: string;
  product: number;
  category: number;
  custompage: number;
  others: string;
  script: string;
  section: number;
  created: string;
  modified: string;
  pageName: string;
}

export default PageScript;

export interface StorePages {
  home: string;
  shop: string;
  faq: string;
  aboutus: string;
  contactus: string;
  privacyPolicy: string;
  tnc: string;
  cnrpolicy: string;
  sndpolicy: string;
  disclaimerPolicy: string;
  compareProducts: string;
  checkout: string;
  trackOrder: string;
  paymentCallback: string;
  product: string;
  category: string;
}

export const processResponse = (pageScript: Record<string, never>): PageScript => {
  return {
    id: pageScript.id,
    pageType: pageScript.page_type,
    pageTypeVerbose: pageScript.page_type_verbose,
    product: pageScript.product,
    category: pageScript.category,
    custompage: pageScript.custompage,
    others: pageScript.others,
    script: pageScript.script,
    section: pageScript.section,
    created: pageScript.created,
    modified: pageScript.modified,
    pageName: pageScript.page_name,
  };
};

export const processStorePagesResponse = (storePages: Record<string, never>): StorePages => {
  return {
    home: storePages.home,
    shop: storePages.shop,
    faq: storePages.faq,
    aboutus: storePages.aboutus,
    contactus: storePages.contactus,
    privacyPolicy: storePages.privacy_policy,
    tnc: storePages.tnc,
    cnrpolicy: storePages.cnrpolicy,
    sndpolicy: storePages.sndpolicy,
    disclaimerPolicy: storePages.disclaimer_policy,
    compareProducts: storePages.compare_products,
    checkout: storePages.checkout,
    trackOrder: storePages.track_order,
    paymentCallback: storePages.payment_callback,
    product: storePages.product,
    category: storePages.category,
  };
};
