import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as PageScript from '../../models/PageScript';

export interface PageScriptsResponseType {
  count: number;
  next: string | null;
  previous: string | null;
  results: PageScript.default[];
}

export interface PageScriptsResponsePayloadType {
  response: PageScriptsResponseType;
  queryParams: PageScriptsQueryParams;
}
export interface PageScriptsRequestPayloadType {
  queryParams: PageScriptsQueryParams;
}

export interface PageScriptsQueryParams {
  limit: number;
  page: number;
}

export const getPageScriptsQueryParamsInitialState = (): PageScriptsQueryParams => ({
  limit: 500,
  page: 1,
});

export interface PageScriptsSliceType {
  pagination: {
    currentPage: number;
    lastPage: number;
    multiplier: number;
  };
  currentQueryParams?: PageScriptsQueryParams;
  pageScripts?: PageScriptsResponseType;
  storePages?: PageScript.StorePages;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const pageScriptsSlice = createSlice({
  name: 'pageScripts',
  initialState: <PageScriptsSliceType>{
    pagination: {
      currentPage: 1,
      lastPage: 1,
      multiplier: 1,
    },
    pageScripts: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchPageScriptsRequest(state, { payload: { queryParams } }: PayloadAction<PageScriptsRequestPayloadType>) {
      state.isFetching = true;
      state.isFetched = false;
      state.pageScripts = null;
      state.currentQueryParams = queryParams;
      state.error = '';
    },
    fetchPageScriptsSuccess(
      state,
      { payload: { response: pageScripts, queryParams } }: PayloadAction<PageScriptsResponsePayloadType>,
    ) {
      state.pageScripts = pageScripts;
      state.isFetching = false;
      state.isFetched = true;
      state.pagination = {
        currentPage: queryParams.page,
        lastPage: parseInt(`${(pageScripts.count - 1) / queryParams.limit + 1}`),
        multiplier: queryParams.limit,
      };
    },
    fetchPageScriptsFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
      state.pageScripts = null;
    },
    fetchPageScriptsStorePagesSuccess(state, { payload }: PayloadAction<PageScript.StorePages>) {
      state.storePages = payload;
    },
  },
});

export const {
  fetchPageScriptsSuccess,
  fetchPageScriptsRequest,
  fetchPageScriptsFailure,
  fetchPageScriptsStorePagesSuccess,
} = pageScriptsSlice.actions;

export default pageScriptsSlice.reducer;
