import { getData, URLs } from '@common/api';
import * as ReferralProgram from '@common/models/ReferralProgram';
import * as Referrals from '@common/models/Referrals';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import {
  fetchReferralCodeDetailsFailure,
  fetchReferralCodeDetailsRequest,
  fetchReferralCodeDetailsSuccess,
  fetchReferralProgramFailure,
  fetchReferralProgramRequest,
  fetchReferralProgramSuccess,
  fetchReferralsFailure,
  fetchReferralsRequest,
  fetchReferralsSuccess,
} from './slice';

function* fetchReferralsSaga() {
  try {
    let { data } = yield call(() => getData({ url: URLs.GET_REFERRALS }));
    data = Referrals.processResponse(data);
    yield put(fetchReferralsSuccess(data));
  } catch (e) {
    yield put(fetchReferralsFailure('Request failed with: ' + e));
  }
}

function* fetchReferralProgramSaga() {
  try {
    let { data } = yield call(() => getData({ url: URLs.GET_REFERRAL_PROGRAM }));
    data = ReferralProgram.processResponse(data);
    yield put(fetchReferralProgramSuccess(data));
  } catch (e) {
    yield put(fetchReferralProgramFailure('Request failed with: ' + e));
  }
}

function* fetchReferralCodeDetailsSaga({ payload: referralCode }: PayloadAction<string>) {
  try {
    let { data } = yield call(() =>
      getData({ url: URLs.GET_REFERRAL_CODE_DETAILS.replace('{referralCode}', referralCode) }),
    );
    data = Referrals.processResponse(data);
    yield put(fetchReferralCodeDetailsSuccess(data));
  } catch (e) {
    yield put(fetchReferralCodeDetailsFailure('Request failed with: ' + e));
  }
}

export default function* referralsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchReferralsRequest.type, fetchReferralsSaga);
  yield takeEvery(fetchReferralProgramRequest.type, fetchReferralProgramSaga);
  yield takeEvery(fetchReferralCodeDetailsRequest.type, fetchReferralCodeDetailsSaga);
}
