interface Features {
  id: number;
  user: number;
  themes: number;
  customPages: number;
  customStoreLogo: boolean;
  referralDiscounts: boolean;
  customMetaTags: boolean;
  whiteLabel: boolean;
  wishList: boolean;
  productRating: boolean;
  wholesalePricing: boolean;
  storeTestimonial: boolean;
  relatedProducts: boolean;
  recentlyViewed: boolean;
  productSearch: boolean;
  premiumThemes: boolean;
  seo: boolean;
  editCss: boolean;
  productReview: boolean;
  pageSpecificScripts: boolean;
  basicSeo: boolean;
  contactUsPage: boolean;
  categories: boolean;
  discountLink: boolean;
  buyerLogin: boolean;
  customFields: boolean;
  productFilters: boolean;
  productVideo: boolean;
  themeCustomisation: boolean;
  promoCodeMarketing: boolean;
}

export const processResponse = (features: Record<string, never>): Features => {
  // const buyerLogin = false;
  const buyerLogin = features.buyer_login;
  return {
    id: features.id,
    user: features.user,
    themes: features.themes,
    customPages: features.custom_pages,
    customStoreLogo: features.custom_store_logo,
    referralDiscounts: features.referral_discounts,
    customMetaTags: features.custom_meta_tags,
    whiteLabel: features.white_label,
    wishList: features.wish_list && buyerLogin,
    productRating: features.product_rating,
    wholesalePricing: features.wholesale_pricing,
    storeTestimonial: features.store_testimonial,
    relatedProducts: features.related_products,
    recentlyViewed: features.recently_viewed,
    productSearch: features.product_search,
    premiumThemes: features.premium_themes,
    seo: features.seo,
    editCss: features.edit_css,
    productReview: features.product_review,
    pageSpecificScripts: features.page_specific_scripts,
    basicSeo: features.basic_seo,
    contactUsPage: features.contact_us_page,
    categories: features.categories,
    discountLink: features.discount_link,
    buyerLogin,
    customFields: features.custom_fields,
    productFilters: features.product_filters,
    productVideo: features.product_video,
    themeCustomisation: features.theme_customisation,
    promoCodeMarketing: features.promo_code_marketing,
  };
};

export default Features;
