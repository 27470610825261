// import { getData, URLs } from '@common/api';
import * as ShopAttribute from '@common/models/ShopAttribute';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { getData, URLs } from '../../api';
import {
  fetchShopAttributesFailure,
  fetchShopAttributesRequest,
  fetchShopAttributesSuccess,
  ShopAttributesRequestPayloadType,
} from './slice';

function* fetchShopAttributesSaga({ payload: { queryParams } }: PayloadAction<ShopAttributesRequestPayloadType>) {
  try {
    const { data } = yield call(() => getData({ url: URLs.PRODUCT_FILTERS, params: queryParams }));
    data.results = ShopAttribute.processResponse(data?.results || []);
    yield put(fetchShopAttributesSuccess({ response: data, queryParams }));
  } catch (e) {
    yield put(fetchShopAttributesFailure('Request failed with: ' + e));
  }
}

export default function* shopAttributesSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchShopAttributesRequest.type, fetchShopAttributesSaga);
}
