// import { URLs } from '@common/api';
import { getData, URLs } from '@common/api';
import * as RichTextEditor from '@common/models/RichTextEditor';
import { Context as ContextInterface } from '@common/utils/constants';
import { call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects';

import { fetchRichTextEditorFailure, fetchRichTextEditorRequest, fetchRichTextEditorSuccess } from './slice';

export function* fetchRichTextEditorSaga({ payload }: Record<string, string>): Generator<unknown> {
  try {
    const context: ContextInterface = yield select((store) => {
      return store.context.context;
    });
    const merchantID = context.MERCHANT_ACCOUNT_ID;
    const data = yield call(() =>
      getData({ url: URLs.GET_RICH_TEXT_EDITOR.replace('{id}', payload).replace('{userId}', merchantID.toString()) }),
    );
    const result = RichTextEditor.processResponse(data.data);
    const { id } = data?.data;
    yield put(fetchRichTextEditorSuccess({ id: id, result }));
  } catch (e) {
    yield put(fetchRichTextEditorFailure('Request failed with Error: ' + e));
  }
}

export default function* richTextEditorSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchRichTextEditorRequest.type, fetchRichTextEditorSaga);
}
