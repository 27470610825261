import MetaTags from '@common/models/MetaTags';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MetaTagsSliceType {
  metaTags?: MetaTags;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const metaTagsSlice = createSlice({
  name: 'metaTags',
  initialState: <MetaTagsSliceType>{
    metaTags: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchMetaTagsRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.metaTags = null;
      state.error = '';
    },
    fetchMetaTagsSuccess(state, { payload }: PayloadAction<MetaTags>) {
      state.metaTags = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchMetaTagsFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const { fetchMetaTagsSuccess, fetchMetaTagsRequest, fetchMetaTagsFailure } = metaTagsSlice.actions;

export default metaTagsSlice.reducer;
