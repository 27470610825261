import OnboardStatus from '@common/models/OnboardStatus';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OnboardStatusSliceType {
  onboardStatus?: OnboardStatus;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const onboardStatusSlice = createSlice({
  name: 'onboardStatus',
  initialState: <OnboardStatusSliceType>{
    onboardStatus: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchOnboardStatusRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.onboardStatus = null;
      state.error = '';
    },
    fetchOnboardStatusSuccess(state, { payload }: PayloadAction<OnboardStatus>) {
      state.onboardStatus = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchOnboardStatusFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = false;
    },
  },
});

export const {
  fetchOnboardStatusRequest,
  fetchOnboardStatusSuccess,
  fetchOnboardStatusFailure,
} = onboardStatusSlice.actions;

export default onboardStatusSlice.reducer;
