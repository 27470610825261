import { getData, URLs } from '@common/api';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchUserAddressesFailure, fetchUserAddressesRequest, fetchUserAddressesSuccess } from './slice';

function* fetchUserAddressesSaga() {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_USER_ADDRESSES }));
    yield put(fetchUserAddressesSuccess(data));
  } catch (e) {
    yield put(fetchUserAddressesFailure('Request failed with: ' + e));
  }
}

export default function* userAddressesSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchUserAddressesRequest.type, fetchUserAddressesSaga);
}
