import { getData, URLs } from '@common/api';
import * as CategoriesProductsList from '@common/models/CategoriesProductsList';
import { RootState, store } from '@common/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import {
  CategoriesProductsListQueryParamsType,
  fetchCategoriesProductsListFailure,
  fetchCategoriesProductsListRequest,
  fetchCategoriesProductsListSuccess,
  fetchNextCategoriesProductsListFailure,
  fetchNextCategoriesProductsListRequest,
  fetchNextCategoriesProductsListSuccess,
} from './slice';

function* fetchCategoriesProductsListSaga({
  payload: { id, categoriesIds },
}: PayloadAction<CategoriesProductsListQueryParamsType>) {
  try {
    const data = yield call(() =>
      getData({ url: URLs.GET_CATEGORIES_PRODUCTS_LIST.replace('{categoriesIds}', categoriesIds) }),
    );
    const result = CategoriesProductsList.processResponse(data.data);
    yield put(fetchCategoriesProductsListSuccess({ result, id }));
  } catch (e) {
    yield put(fetchCategoriesProductsListFailure('Request Failed with: ' + e));
  }
}

function* fetchNextCategoriesProductsListSaga({
  payload: { id, categoriesIds },
}: PayloadAction<CategoriesProductsListQueryParamsType>) {
  try {
    const data = yield call(() =>
      getData({ url: URLs.GET_CATEGORIES_PRODUCTS_LIST.replace('{categoriesIds}', categoriesIds) }),
    );
    const state: RootState = store.getState();
    const categoriesProductsList = state.categoriesProductsList;
    const result = CategoriesProductsList.processResponse(data.data);
    yield put(fetchNextCategoriesProductsListSuccess({ result, id, categoriesProductsList }));
  } catch (e) {
    yield put(fetchNextCategoriesProductsListFailure('Request Failed with: ' + e));
  }
}

export default function* categoriesProductsListSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchCategoriesProductsListRequest.type, fetchCategoriesProductsListSaga);
  yield takeEvery(fetchNextCategoriesProductsListRequest.type, fetchNextCategoriesProductsListSaga);
}
