import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import UserProfile from '../../models/UserProfile';

export interface UserProfileSliceType {
  profile?: UserProfile;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: <UserProfileSliceType>{
    profile: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    resetUserProfile(state) {
      state.profile = null;
      state.isFetched = false;
      state.isFetching = false;
      state.error = '';
    },
    // eslint-disable-next-line
    fetchUserProfileRequest(state, { payload: isAfterLogin }: PayloadAction<boolean>) {
      state.isFetching = true;
      state.isFetched = false;
      state.profile = null;
      state.error = '';
    },
    fetchUserProfileSuccess(state, { payload }: PayloadAction<UserProfile>) {
      state.profile = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchUserProfileFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const {
  resetUserProfile,
  fetchUserProfileSuccess,
  fetchUserProfileRequest,
  fetchUserProfileFailure,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
