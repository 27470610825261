import Order from '@common/models/Order';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserOrderSliceType {
  userOrder?: Order;
  url?: string;
  queryParams?: Record<string, string>;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
  cancelOrder: Record<string, string>;
  orderId?: string;
  otpNumber?: string;
  getOtpResponse?: string;
  optErrorResponse?: OTPError;
  sendOTPError?: string;
  getResendOtpResponse?: GetResendOtpResponse;
  getResendOtpErrorResponse?: GetResendOtpErrorResponse;
  orderStatus?: string;
}

export interface GetResendOtpResponse {
  is_otp_sent?: boolean;
  no_of_resend_attempts_left?: number;
  no_of_verification_attempts_left?: number;
}

export interface GetResendOtpErrorResponse {
  non_field_errors?: string;
  is_otp_sent?: boolean;
  error?: string;
}

export interface UserOrderArgs {
  url: string;
  params?: {
    hash?: string;
    email?: string;
  };
}

export interface OTPError {
  error: {
    otp: Array<string>;
  };
  is_otp_verified: boolean;
  no_of_resend_attempts_left: number;
  no_of_verification_attempts_left: number;
}

export interface OTPArgs {
  orderId: string;
  otpNumber: string;
}

const userOrderSlice = createSlice({
  name: 'userOrder',
  initialState: <UserOrderSliceType>{
    url: null,
    queryParams: null,
    userOrder: null,
    isFetching: false,
    isFetched: false,
    error: '',
    cancelOrder: null,
    orderId: '',
    otpNumber: '',
    getOtpResponse: null,
    optErrorResponse: null,
    sendOTPError: null,
    orderStatus: null,
    getResendOtpResponse: {
      is_otp_sent: false,
      no_of_resend_attempts_left: 2,
      no_of_verification_attempts_left: 2,
    },
  },
  reducers: {
    resetUserOrder(state) {
      state.url = null;
      state.queryParams = null;
      state.userOrder = null;
      state.isFetching = false;
      state.isFetched = false;
      state.error = '';
      state.getOtpResponse = null;
    },
    fetchUserOrderRequest(state, { payload: { url, params } }: PayloadAction<UserOrderArgs>) {
      state.url = url;
      state.queryParams = params;
      state.isFetching = true;
      state.isFetched = false;
      state.userOrder = null;
      state.error = '';
    },
    fetchUserOrderSuccess(state, { payload }: PayloadAction<Order>) {
      state.userOrder = payload;
      state.getOtpResponse = null;
      state.isFetching = false;
      state.isFetched = true;
      state.sendOTPError = null;
      state.orderStatus = null;
      state.getResendOtpResponse = null;
      state.optErrorResponse = null;
    },
    fetchUserOrderFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
      state.sendOTPError = null;
      state.orderStatus = null;
      state.getResendOtpResponse = null;
      state.optErrorResponse = null;
    },
    cancelOrderRequest(state, { payload: { orderId, otpNumber } }: PayloadAction<OTPArgs>) {
      state.isFetching = true;
      state.isFetched = false;
      state.orderId = orderId;
      state.otpNumber = otpNumber;
      state.orderStatus = null;
      state.getResendOtpResponse = null;
      state.error = '';
    },
    cancelOrderSuccess(state, { payload }: PayloadAction<Order>) {
      state.orderStatus = payload.orderStatus;
      state.getOtpResponse = null;
      state.userOrder = payload;
      state.getResendOtpResponse = null;
      state.isFetching = false;
      state.isFetched = true;
    },
    cancelOrderFailure(state, { payload }: PayloadAction<OTPError>) {
      state.optErrorResponse = payload;
      state.orderStatus = null;
      state.getResendOtpResponse = null;
      state.isFetching = false;
      state.isFetched = true;
    },
    sendOTPRequest(state, { payload: orderId }: PayloadAction<string>) {
      state.orderId = orderId;
      state.orderStatus = null;
      state.getResendOtpResponse = null;
      state.isFetching = true;
      state.isFetched = false;
      state.error = '';
    },
    sendOTPSuccess(state, { payload }: PayloadAction<string>) {
      state.getOtpResponse = payload;
      state.orderStatus = null;
      state.getResendOtpResponse = null;
      state.isFetching = false;
      state.isFetched = true;
    },
    sendOTPFailure(state, { payload }: PayloadAction<GetResendOtpErrorResponse>) {
      state.sendOTPError = payload.non_field_errors;
      state.getOtpResponse = null;
      state.orderStatus = null;
      state.getResendOtpResponse = null;
      state.isFetching = false;
      state.isFetched = true;
    },
    resendOTPRequest(state, { payload: orderId }: PayloadAction<string>) {
      state.orderId = orderId;
      state.isFetching = true;
      state.isFetched = false;
    },
    resendOTPSuccess(state, { payload }: PayloadAction<GetResendOtpResponse>) {
      state.getResendOtpResponse = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    resendOTPFailure(state, { payload }: PayloadAction<GetResendOtpErrorResponse>) {
      state.sendOTPError = payload?.error;
      state.getResendOtpResponse = null;
      state.getOtpResponse = null;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const {
  fetchUserOrderSuccess,
  resetUserOrder,
  fetchUserOrderRequest,
  fetchUserOrderFailure,
  cancelOrderRequest,
  cancelOrderSuccess,
  cancelOrderFailure,
  sendOTPRequest,
  sendOTPSuccess,
  sendOTPFailure,
  resendOTPRequest,
  resendOTPSuccess,
  resendOTPFailure,
} = userOrderSlice.actions;

export default userOrderSlice.reducer;
