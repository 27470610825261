import { RootState } from '@common/store';
import { FeaturesSliceType } from '@common/store/features/slice';
import { isMobile } from '@common/utils';
import { useSelector } from 'react-redux';

import useNudge, { UseNudgeReturnType } from './useNudge';

interface ReturnType {
  showSignUpAtInstamojo: boolean;
  showFooterNudge: boolean;
  isStickyFooterShown: boolean;
  footerNudge: UseNudgeReturnType;
}

export default (): ReturnType => {
  const features: FeaturesSliceType = useSelector((state: RootState) => state.features);
  const footerNudge = useNudge(`remove-instamojo-branding-${isMobile() ? 'mobile-web' : 'desktop'}`);

  const showSignUpAtInstamojo = !features?.features?.whiteLabel;
  const showFooterNudge = Boolean(footerNudge.nudge);
  const isStickyFooterShown = showSignUpAtInstamojo || showFooterNudge;
  return { showSignUpAtInstamojo, showFooterNudge, isStickyFooterShown, footerNudge };
};
