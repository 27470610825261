import { getBuyerJWTToken } from '@common/utils/token';
import * as Sentry from '@sentry/react';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import ErrorFallback from '../ErrorFallback';

interface CustomRouteProps extends RouteProps {
  isProtected?: boolean;
  isDisabled?: boolean;
}

const CustomRoute: React.FC<CustomRouteProps> = ({
  component: Component,
  isProtected = false,
  children,
  isDisabled = false,
  ...rest
}) => {
  let component = null;
  if (children) {
    component = (
      <Route {...rest}>
        {(isProtected && getBuyerJWTToken()) || !isProtected ? (
          <Sentry.ErrorBoundary fallback={ErrorFallback}>{children}</Sentry.ErrorBoundary>
        ) : (
          <Redirect to="/" />
        )}
      </Route>
    );
  } else {
    component = (
      <Route
        {...rest}
        render={(props) =>
          (isProtected && getBuyerJWTToken()) || !isProtected ? (
            <Sentry.ErrorBoundary fallback={ErrorFallback}>
              <Component {...props} />
            </Sentry.ErrorBoundary>
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
  }
  if (!isDisabled) {
    return component;
  } else {
    return <Redirect to="/" />;
  }
};

export default CustomRoute;
