import HtmlBlock from '@common/models/HtmlBlock';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface HtmlBlockSliceType {
  sections?: Record<number, HtmlBlock>;
}

export interface HtmlBlockResponseType {
  id: number;
  result: HtmlBlock;
}

const htmlBlockSlice = createSlice({
  name: 'htmlBlock',
  initialState: <HtmlBlockSliceType>{
    sections: {},
  },
  reducers: {
    fetchHtmlBlockRequest(state, { payload }) {
      const htmlObject = {
        isFetching: true,
        isFetched: false,
        htmlBlock: null,
        error: '',
      };
      state[payload.id] = htmlObject;
    },
    fetchHtmlBlockSuccess(state, { payload }: PayloadAction<HtmlBlockResponseType>) {
      const htmlObject = {
        isFetching: false,
        isFetched: true,
        htmlBlock: payload.result,
        error: '',
      };
      state[payload.id] = htmlObject;
    },
    fetchHtmlBlockFailure() {
      // console.log(_payload);
    },
    editHtmlBlock(state, { payload }: unknown) {
      const htmlObject = {
        isFetching: false,
        isFetched: true,
        htmlBlock: payload.result,
        error: '',
      };
      state[payload.id] = htmlObject;
    },
  },
});

export const {
  fetchHtmlBlockSuccess,
  fetchHtmlBlockRequest,
  fetchHtmlBlockFailure,
  editHtmlBlock,
} = htmlBlockSlice.actions;

export default htmlBlockSlice.reducer;
