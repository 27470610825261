import { getData, URLs } from '@common/api';
import * as Category from '@common/models/Category';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchCategoryFailure, fetchCategoryRequest, fetchCategorySuccess } from './slice';

/* eslint-disable-next-line */
function* fetchCategorySaga({ payload: categoryId }: PayloadAction<number>) {
  try {
    let { data } = yield call(() => getData({ url: URLs.GET_CATEGORY.replace('{id}', categoryId.toString()) }));
    data = Category.processResponse(data);
    yield put(fetchCategorySuccess(data));
  } catch (e) {
    yield put(fetchCategoryFailure('Request failed with: ' + e));
  }
}

export default function* categorySagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchCategoryRequest.type, fetchCategorySaga);
}
