import { getData, URLs } from '@common/api';
import * as Testimonial from '@common/models/Testimonial';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchTestimonialsFailure, fetchTestimonialsRequest, fetchTestimonialsSuccess } from './slice';

function* fetchTestimonialsSaga() {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_TESTIMONIALS }));
    data.results = data.results
      ?.filter((testimonial) => testimonial.publish)
      .map((result) => Testimonial.processResponse(result));

    yield put(fetchTestimonialsSuccess(data));
  } catch (e) {
    yield put(fetchTestimonialsFailure('Request failed with: ' + e));
  }
}

export default function* testimonialsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchTestimonialsRequest.type, fetchTestimonialsSaga);
}
