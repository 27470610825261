import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as Features from '../../models/Features';

export interface FeaturesSliceType {
  features?: Features.default;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const featuresSlice = createSlice({
  name: 'features',
  initialState: <FeaturesSliceType>{
    features: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchFeaturesRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.features = null;
      state.error = '';
    },
    fetchFeaturesSuccess(state, { payload }: PayloadAction<Features.default>) {
      state.features = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchFeaturesFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const { fetchFeaturesSuccess, fetchFeaturesRequest, fetchFeaturesFailure } = featuresSlice.actions;

export default featuresSlice.reducer;
