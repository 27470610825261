import ThemeCustomisation from '@common/models/ThemeCustomisation';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ThemeCustomisationSliceType {
  themeCustomisation?: ThemeCustomisation;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}
const themeCustomisationSlice = createSlice({
  name: 'themeCustomisation',
  initialState: <ThemeCustomisationSliceType>{
    themeCustomisation: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchThemeCustomisationRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.themeCustomisation = null;
      state.error = '';
    },
    fetchThemeCustomisationSuccess(state, { payload }: PayloadAction<ThemeCustomisation>) {
      state.themeCustomisation = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchThemeCustomisationFailure(state, { payload }: PayloadAction<string>) {
      // console.log(payload);
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const {
  fetchThemeCustomisationRequest,
  fetchThemeCustomisationSuccess,
  fetchThemeCustomisationFailure,
} = themeCustomisationSlice.actions;

export default themeCustomisationSlice.reducer;
