import Slider from '@common/models/Slider';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SliderType {
  slider?: SliderResponseType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

export interface SliderSliceType {
  sliders?: Record<number, SliderResponseType>;
}

export interface SliderResponseType {
  id: number;
  count: number;
  next: string | null;
  previous: string | null;
  results: Slider[];
  layout: string;
}

const sliderSlice = createSlice({
  name: 'slider',
  initialState: <SliderSliceType>{
    sliders: {},
  },
  reducers: {
    fetchSliderRequest(state, { payload }) {
      const sliderObject = {
        isFetching: true,
        isFetched: false,
        slider: null,
        error: '',
        layout: null,
      };
      state[payload.id] = sliderObject;
    },
    fetchSliderSuccess(state, { payload }: PayloadAction<SliderResponseType>) {
      const sliderObject = {
        isFetching: false,
        isFetched: true,
        slider: payload.results,
        layout: payload.layout,
        error: '',
      };
      state[payload.id] = sliderObject;
    },
    fetchSliderFailure() {
      //   state.error = payload;
      //   state.isFetching = false;
      //   state.isFetched = true;
    },
    editHeroBanner(state, { payload }: unknown) {
      const sliderObject = {
        isFetching: false,
        isFetched: true,
        slider: payload.data,
        layout: payload.layout,
        error: '',
      };
      state[payload.id] = sliderObject;
    },
  },
});

export const { fetchSliderSuccess, fetchSliderRequest, fetchSliderFailure, editHeroBanner } = sliderSlice.actions;

export default sliderSlice.reducer;
