import NetworkCacheRow from '@common/models/NetworkCacheRow';

export const DB_NAME = 'cacheStore';

export enum API_OBJECT_STORE {
  PRODUCTS = 'products',
}

let db: IDBDatabase | undefined;

export const initIDB = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, 1);

    request.onupgradeneeded = () => {
      const db = request.result;

      Object.values(API_OBJECT_STORE).map((objectStore) => {
        db.createObjectStore(objectStore, {
          keyPath: 'hash',
        });
      });
    };

    request.onerror = (e) => {
      console.log('Failed to open Database: ' + e);
      reject('Failed to open Database: ' + e);
    };

    request.onsuccess = () => {
      db = request.result;
      console.log('Indexed DB successfully created & upgraded');
      resolve();
    };
  });
};

export const insertToCache = (
  objectStoreName: API_OBJECT_STORE,
  row: NetworkCacheRow,
  overwrite = true,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (!db) {
      resolve();
      return;
    }
    const tx = db.transaction(objectStoreName, 'readwrite');
    const store = tx.objectStore(objectStoreName);

    if (overwrite) {
      store.put(row);
    } else {
      store.add(row);
    }
    tx.oncomplete = () => {
      console.log('Successfully Inserted data.');
      resolve();
    };

    tx.onerror = (e) => {
      console.error('Failed to insert data.' + e);
      reject('Failed to insert data.');
    };
  });
};

export const retrieveFromCache = (objectStoreName: API_OBJECT_STORE, hash: string): Promise<NetworkCacheRow> => {
  return new Promise((resolve, reject) => {
    if (!db) {
      reject('DB not initialized yet.');
      return;
    }
    const tx = db.transaction(objectStoreName, 'readonly');
    const store = tx.objectStore(objectStoreName);

    const request = store.get(hash);

    tx.oncomplete = () => {
      resolve(request.result);
    };

    tx.onerror = () => {
      reject('Failed to fetch data.');
    };
  });
};
