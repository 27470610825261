import UserAddress from '@common/models/UserAddress';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserAddressSliceType {
  userAddress?: UserAddress;
  userAddressId?: number;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const userAddressSlice = createSlice({
  name: 'userAddress',
  initialState: <UserAddressSliceType>{
    userAddressId: null,
    userAddress: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    resetUserAddress(state) {
      state.userAddressId = null;
      state.userAddress = null;
      state.isFetching = false;
      state.isFetched = false;
      state.error = '';
    },
    fetchUserAddressRequest(state, { payload: userAddressId }: PayloadAction<number>) {
      state.userAddressId = userAddressId;
      state.isFetching = true;
      state.isFetched = false;
      state.userAddress = null;
      state.error = '';
    },
    fetchUserAddressSuccess(state, { payload }: PayloadAction<UserAddress>) {
      state.userAddress = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchUserAddressFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
  },
});

export const {
  fetchUserAddressSuccess,
  fetchUserAddressRequest,
  fetchUserAddressFailure,
  resetUserAddress,
} = userAddressSlice.actions;

export default userAddressSlice.reducer;
