import { ToastVariant } from '../../lib-components/Toast';
import { postData, URLs } from '../api';
import { store } from '../store';
import { fetchPromotionsRequest } from '../store/promotions/slice';
import { addToast } from '../store/toasts/slice';
import { fetchUserAddressesRequest } from '../store/userAddresses/slice';
import { fetchUserProfileRequest, resetUserProfile } from '../store/userProfile/slice';
import { computeRedirectUrl } from './computation';
import { COOKIE_KEYS } from './constants';
import { isFormError } from './error';
import history from './history';
import { getCookie, getLocalStorageItem, removeBuyerJWTToken, setBuyerJWTToken, setCookie } from './token';

const afterLogin = (isSocial?: boolean) => {
  store.dispatch(
    addToast({
      content: `Successfully Logged In`,
      variant: ToastVariant.success,
      dismissAfterMillis: 5000,
    }),
  );
  const searchParams = new URLSearchParams(window.location.search);
  if (!/checkout/i.test(window.location.href)) {
    if (isSocial) {
      const afterLoginRedirectUrl = getLocalStorageItem(COOKIE_KEYS.AFTER_LOGIN_REDIRECT) || '';
      history.push(afterLoginRedirectUrl || '/');
    } else {
      const redirectUrl = searchParams.get('redirect_url');
      history.push(redirectUrl || '/');
    }
  } else {
    if (isSocial) {
      history.push(window.location.pathname);
    }
  }

  store.dispatch(fetchUserProfileRequest(true));
  store.dispatch(fetchUserAddressesRequest());
  store.dispatch(fetchPromotionsRequest());
};

const processUrl = (url: string) => {
  if (process.env.NODE_ENV === 'production') {
    return `${window.location.origin}${url}`;
  }
  return url;
};

export enum SOCIAL_NETWORK {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
}

export const handleSocialRedirectResponse = (accessToken: string, socialNetwork: SOCIAL_NETWORK): void => {
  const redirectUri = getCookie('redirect_uri');
  if (accessToken && redirectUri) {
    window.location.href = `${redirectUri}?social_access_token=${accessToken}&provider=${socialNetwork}`;
  } else {
    window.location.href = redirectUri || '/';
  }
};

export const handleSocialLoginResponse = async (accessToken: string, socialNetwork: SOCIAL_NETWORK): Promise<void> => {
  try {
    const formData = new FormData();
    formData.append('access_token', accessToken);
    const { data } = await postData({
      url: processUrl(socialNetwork === SOCIAL_NETWORK.GOOGLE ? URLs.POST_GOOGLE_LOGIN : URLs.POST_FACEBOOK_LOGIN),
      data: formData,
      headers: { 'content-type': 'multipart/form-data' },
    });
    setBuyerJWTToken(data.token);
    setCookie(COOKIE_KEYS.LOGIN_TYPE, socialNetwork === SOCIAL_NETWORK.GOOGLE ? 'google_login' : 'fb_login');
    afterLogin(true);
  } catch (e) {
    console.log('Request failed with error: ', e);
    store.dispatch(
      addToast({
        content: `Some error occured while trying to log you in`,
        variant: ToastVariant.error,
        dismissAfterMillis: 5000,
      }),
    );
  }
};

export const handleLoginResponse = async (
  values: Record<string, unknown>,
  setDynamicErrors: (errors: Record<string, unknown>) => void,
  afterLoginCallback: () => void,
): Promise<void> => {
  try {
    const formData = new FormData();
    formData.append('email', values.email as string);
    formData.append('password', values.password as string);
    const { data } = await postData({
      url: processUrl(URLs.POST_LOGIN),
      data: formData,
      headers: { 'content-type': 'multipart/form-data' },
    });
    setBuyerJWTToken(data.token);
    setCookie(COOKIE_KEYS.LOGIN_TYPE, 'custom');
    if (afterLoginCallback) {
      afterLoginCallback?.();
    } else {
      afterLogin();
    }
  } catch (e) {
    if (isFormError(e)) {
      setDynamicErrors(e?.response?.data || {});
    }
    console.log('Request failed with error: ', e);
  }
};

export const handleRegisterResponse = async (
  values: Record<string, unknown>,
  setDynamicErrors: (errors: Record<string, unknown>) => void,
  afterLoginCallback: () => void,
): Promise<void> => {
  try {
    if (!values['referrer_code']) {
      delete values['referrer_code'];
    }
    const { data } = await postData({ url: processUrl(URLs.POST_REGISTER), data: values });
    setBuyerJWTToken(data.token);
    setCookie(COOKIE_KEYS.LOGIN_TYPE, 'custom');
    if (afterLoginCallback) {
      afterLoginCallback?.();
    } else {
      afterLogin();
    }
  } catch (e) {
    console.log({ e });
    if (isFormError(e)) {
      setDynamicErrors(e?.response?.data || {});
    }
    console.log('Request failed with error: ', e);
  }
};

export const handleSignOut = async (): Promise<void> => {
  const href = window.location.href;
  try {
    await postData({ url: processUrl(URLs.POST_LOG_OUT) });
    store.dispatch(
      addToast({
        content: `Successfully Logged Out`,
        variant: ToastVariant.success,
        dismissAfterMillis: 5000,
      }),
    );
    store.dispatch(resetUserProfile());
  } catch (e) {
    console.log('Request failed with error: ', e);
  }
  removeBuyerJWTToken();
  window.location.href = href;
};

export const handleResetPasswordResponse = async (
  values: Record<string, unknown>,
  setDynamicErrors?: (errors: Record<string, unknown>) => void,
  afterSuccess?: (message: string) => void,
): Promise<void> => {
  try {
    const formData = new FormData();
    formData.append('email', values.email as string);
    const { data } = await postData({
      url: URLs.POST_RESET_PASSWORD,
      data: formData,
      headers: { 'content-type': 'multipart/form-data' },
    });
    afterSuccess?.(data.msg || `Check you mail for further instructions`);
    // history.push('/');
  } catch (e) {
    if (isFormError(e)) {
      setDynamicErrors?.(e?.response?.data || {});
    }
    console.log('Request failed with error: ', e);
  }
};

export const handleChangePassword = async (
  values: Record<string, unknown>,
  setDynamicErrors: (errors: Record<string, unknown>) => void,
  afterSuccessAction: () => void,
): Promise<void> => {
  try {
    removeBuyerJWTToken();
    await postData({ url: processUrl(URLs.POST_CHANGE_PASSWORD), data: values });
    store.dispatch(
      addToast({
        content: `Successfully Changed the password`,
        variant: ToastVariant.success,
        dismissAfterMillis: 2000,
      }),
    );
    // store.dispatch(resetUserProfile());
    if (afterSuccessAction) {
      afterSuccessAction();
    } else {
      const redirectUrl = computeRedirectUrl();
      window.location.href = `/account/login?redirect_url=${redirectUrl}`;
    }
  } catch (e) {
    console.log({ e });
    if (isFormError(e)) {
      setDynamicErrors(e?.response?.data || {});
    }
    console.log('Request failed with error: ', e);
  }
  // window.location.reload();
};
