import { getData, URLs } from '@common/api';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchIsWhatsappEnabledFailure, fetchIsWhatsappEnabledRequest, fetchIsWhatsappEnabledSuccess } from './slice';

function* fetchIsWhatsappEnabledSaga() {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_IS_WHATSAPP_ENABLED }));
    yield put(fetchIsWhatsappEnabledSuccess(data));
  } catch (e) {
    yield put(fetchIsWhatsappEnabledFailure('Request failed with: ' + e));
  }
}

export default function* isWhatsappEnabledSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchIsWhatsappEnabledRequest.type, fetchIsWhatsappEnabledSaga);
}
