import '@common/styles/layouts.css';

import { RootState } from '@common/store';
import { fetchStoreTestimonialsRequest, StoreTestimonialsSliceType } from '@common/store/storeTestimonials/slice';
import loadable from '@loadable/component';
import { DEFAULT_TESTIMONIALS_LAYOUT_SELECTION } from '@theme/utils/constants';
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ADD_NEW_SECTION_ID } from '../../common/utils/constants';

export interface StoreTestimonialsProps {
  id: string;
  storeTestimonials: StoreTestimonialsSliceType;
  fetchStoreTestimonialsRequest: fetchStoreTestimonialsRequest.type;
  previewQueryLayout: string;
}

const Testimonial1 = loadable(() => import(/* webpackChunkName: "Testimonial1" */ './Testimonial1')); //Accent
const Testimonial2 = loadable(() => import(/* webpackChunkName: "Testimonial2" */ './Testimonial2')); //Bright
const Testimonial3 = loadable(() => import(/* webpackChunkName: "Testimonial3" */ './Testimonial3')); //Cipher
const Testimonial4 = loadable(() => import(/* webpackChunkName: "Testimonial4" */ './Testimonial4')); //Comfort
const Testimonial5 = loadable(() => import(/* webpackChunkName: "Testimonial5" */ './Testimonial5')); //Compass
const Testimonial6 = loadable(() => import(/* webpackChunkName: "Testimonial6" */ './Testimonial6')); //coral
const Testimonial7 = loadable(() => import(/* webpackChunkName: "Testimonial7" */ './Testimonial7')); //Desire
const Testimonial8 = loadable(() => import(/* webpackChunkName: "Testimonial8" */ './Testimonial8')); //Essense
const Testimonial9 = loadable(() => import(/* webpackChunkName: "Testimonial9" */ './Testimonial9')); //Esteem
const Testimonial10 = loadable(() => import(/* webpackChunkName: "Testimonial10" */ './Testimonial10')); //Flow
const Testimonial11 = loadable(() => import(/* webpackChunkName: "Testimonial11" */ './Testimonial11')); //Grace
const Testimonial12 = loadable(() => import(/* webpackChunkName: "Testimonial12" */ './Testimonial12')); //Gulab
const Testimonial13 = loadable(() => import(/* webpackChunkName: "Testimonial13" */ './Testimonial13')); //Ikkat
const Testimonial14 = loadable(() => import(/* webpackChunkName: "Testimonial14" */ './Testimonial14')); //Melody
const Testimonial15 = loadable(() => import(/* webpackChunkName: "Testimonial15" */ './Testimonial15')); //Phulkari
const Testimonial16 = loadable(() => import(/* webpackChunkName: "Testimonial16" */ './Testimonial16')); //Rugged
const Testimonial17 = loadable(() => import(/* webpackChunkName: "Testimonial17" */ './Testimonial17')); //Florencia
const Testimonial18 = loadable(() => import(/* webpackChunkName: "Testimonial18" */ './Testimonial18')); //Himalaya

class Testimonials extends PureComponent<StoreTestimonialsProps> {
  constructor(props) {
    super(props);
    const { fetchStoreTestimonialsRequest, id, storeTestimonials } = this.props;
    if (!storeTestimonials[id]?.isFetched && !storeTestimonials[id]?.isFetching && id !== ADD_NEW_SECTION_ID) {
      fetchStoreTestimonialsRequest(id);
    }
  }
  render(): React.ReactElement {
    const { storeTestimonials, id, previewQueryLayout } = this.props;
    const testimonialData = storeTestimonials[id]?.storeTestimonials;
    const layoutName = previewQueryLayout || testimonialData?.layout?.name || DEFAULT_TESTIMONIALS_LAYOUT_SELECTION;
    return (
      <>
        {testimonialData?.testimonials?.length ? (
          <div
            className={classnames('', {
              'container layout-container mx-auto testimonials-layout py-40': layoutName !== 'Testimonial18',
            })}
            id={id}
          >
            {layoutName === 'Testimonial1' ? <Testimonial1 {...this.props} fallback={<TestimonialFallback />} /> : null}
            {layoutName === 'Testimonial2' ? <Testimonial2 {...this.props} fallback={<TestimonialFallback />} /> : null}
            {layoutName === 'Testimonial3' ? <Testimonial3 {...this.props} fallback={<TestimonialFallback />} /> : null}
            {layoutName === 'Testimonial4' ? <Testimonial4 {...this.props} fallback={<TestimonialFallback />} /> : null}
            {layoutName === 'Testimonial5' ? <Testimonial5 {...this.props} fallback={<TestimonialFallback />} /> : null}
            {layoutName === 'Testimonial6' ? <Testimonial6 {...this.props} fallback={<TestimonialFallback />} /> : null}
            {layoutName === 'Testimonial7' ? <Testimonial7 {...this.props} fallback={<TestimonialFallback />} /> : null}
            {layoutName === 'Testimonial8' ? <Testimonial8 {...this.props} fallback={<TestimonialFallback />} /> : null}
            {layoutName === 'Testimonial9' ? <Testimonial9 {...this.props} fallback={<TestimonialFallback />} /> : null}
            {layoutName === 'Testimonial10' ? (
              <Testimonial10 {...this.props} fallback={<TestimonialFallback />} />
            ) : null}
            {layoutName === 'Testimonial11' ? (
              <Testimonial11 {...this.props} fallback={<TestimonialFallback />} />
            ) : null}
            {layoutName === 'Testimonial12' ? (
              <Testimonial12 {...this.props} fallback={<TestimonialFallback />} />
            ) : null}
            {layoutName === 'Testimonial13' ? (
              <Testimonial13 {...this.props} fallback={<TestimonialFallback />} />
            ) : null}
            {layoutName === 'Testimonial14' ? (
              <Testimonial14 {...this.props} fallback={<TestimonialFallback />} />
            ) : null}
            {layoutName === 'Testimonial15' ? (
              <Testimonial15 {...this.props} fallback={<TestimonialFallback />} />
            ) : null}
            {layoutName === 'Testimonial16' ? (
              <Testimonial16 {...this.props} fallback={<TestimonialFallback />} />
            ) : null}
            {layoutName === 'Testimonial17' ? (
              <Testimonial17 {...this.props} fallback={<TestimonialFallback />} />
            ) : null}
            {layoutName === 'Testimonial18' ? (
              <Testimonial18 {...this.props} fallback={<TestimonialFallback />} />
            ) : null}
          </div>
        ) : null}
      </>
    );
  }
}

export default connect(({ storeTestimonials }: RootState) => ({ storeTestimonials }), {
  fetchStoreTestimonialsRequest,
})(Testimonials);

const TestimonialFallback = () => {
  return <div></div>;
};
