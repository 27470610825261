export const defaultLayouts = {
  Accent: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH6',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB5',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS4',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS3',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial10',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Anchor: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH5',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB5',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS6',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS5',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text2',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial17',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Bright: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH8',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB5',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS2',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS6',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial14',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Cipher: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH5',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB5',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS9',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS12',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial16',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Comfort: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH1',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB8',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS1',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS1',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial4',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Florencia: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH1',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB9',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS14',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS17',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial17',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Oceanna: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH1',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB8',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS1',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS1',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial4',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Oceanna: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH1',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB8',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS1',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS1',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial4',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Compass: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH3',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB7',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS10',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS10',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial13',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Coral: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH4',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB3',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS2',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS16',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial5',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Desire: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH1',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB8',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS1',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS1',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial4',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Essense: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH7',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB5',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS6',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS13',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial1',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Esteem: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH7',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB4',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS4',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS5',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial8',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Flow: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH8',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB1',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS5',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS7',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial7',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Grace: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH4',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB2',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS4',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS15',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial10',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Gulab: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH2',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB4',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS1',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS14',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial6',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Ikkat: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH6',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB5',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS1',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS4',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial9',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Melody: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH4',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB2',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS4',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS15',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial10',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Phulkari: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH1',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB8',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS1',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS2',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial3',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Rugged: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH7',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB3',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS1',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS11',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial12',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Runway: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH7',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB6',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS7',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS8',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial11',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Sakra: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH4',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB2',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS3',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS15',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial2',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Simplar: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH1',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB1',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS1',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS1',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial1',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
  Spring: {
    DEFAULT_NAVBAR_LAYOUT_SELECTION: 'PH8',
    DEFAULT_SLIDER_LAYOUT_SELECTION: 'HB5',
    DEFAULT_PRODUCTLIST_LAYOUT_SELECTION: 'PS8',
    DEFAULT_CATEGORYLIST_LAYOUT_SELECTION: 'CS9',
    DEFAULT_IMAGETEXT_LAYOUT_SELECTION: 'ImageText2',
    DEFAULT_TEXT_LAYOUT_SELECTION: 'Text1',
    DEFAULT_TESTIMONIAL_LAYOUT_SELECTION: 'Testimonial15',
    DEFAULT_STORE_UPDATE_LAYOUT_SELECTION: 'SU1',
  },
};
