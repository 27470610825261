import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CategoryPriceLimitsResponseType {
  priceLimits: {
    minPrice: number;
    maxPrice: number;
  };
}

export interface CategoryPriceLimitsRequestPayloadType {
  categoryId: number;
}

export interface CategoryPriceLimitsSliceType {
  categoryId: number;
  priceLimits: {
    minPrice?: number;
    maxPrice?: number;
  };
  isFetching: boolean;
  isFetched: boolean;
  error: string;
  allPriceLimits: {
    [key: string]: {
      min_price?: number;
      max_price?: number;
    };
  };
}

export interface ShopAllPriceLimitsSliceType {
  allPriceLimits: {
    [key: string]: {
      min_price?: number;
      max_price?: number;
    };
  };
}

const categoryPriceLimitsSlice = createSlice({
  name: 'categoryPriceLimits',
  initialState: <CategoryPriceLimitsSliceType>{
    categoryId: null,
    priceLimits: {},
    isFetched: false,
    isFetching: false,
    error: null,
    allPriceLimits: {},
  },
  reducers: {
    fetchCategoryPriceLimitsRequest(
      state,
      { payload: { categoryId } }: PayloadAction<CategoryPriceLimitsRequestPayloadType>,
    ) {
      state.categoryId = categoryId;
      state.isFetching = true;
      state.isFetched = false;
      state.error = '';
    },
    fetchCategoryPriceLimitsSuccess(state, { payload }: PayloadAction<CategoryPriceLimitsResponseType>) {
      state.priceLimits = payload.priceLimits;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchCategoryPriceLimitsFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
      state.priceLimits = {};
    },
    fetchShopAllPriceLimitsRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.error = '';
    },
    fetchShopAllPriceLimitsSuccess(state, { payload }: PayloadAction<ShopAllPriceLimitsSliceType>) {
      state.allPriceLimits = payload.allPriceLimits;
      const { min_price, max_price } = payload.allPriceLimits?.all_categories;
      const priceLimits = {
        minPrice: min_price,
        maxPrice: max_price,
      };
      state.priceLimits = priceLimits;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchShopAllPriceLimitsFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
      state.allPriceLimits = {};
    },
    setShopAllPriceLimits(state, { payload }: PayloadAction<CategoryPriceLimitsResponseType>) {
      state.priceLimits = payload.priceLimits;
    },

    setPriceLimitsByCategoryId(state, { payload }: PayloadAction<{ ids: string }>) {
      const categoryId = payload?.ids.split(',')[0];
      if (!categoryId) {
        state.priceLimits = {
          minPrice: state.allPriceLimits.all_categories?.min_price,
          maxPrice: state.allPriceLimits.all_categories?.max_price,
        };
        return;
      }
      const { priceLimits: currentPriceLimits, allPriceLimits } = state;
      const categoryPriceLimits = allPriceLimits[categoryId];
      if (categoryPriceLimits) {
        const data = {
          priceLimits: {
            minPrice: currentPriceLimits?.minPrice,
            maxPrice: currentPriceLimits?.maxPrice,
          },
        };

        if (categoryPriceLimits?.min_price < currentPriceLimits?.minPrice) {
          data.priceLimits.minPrice = +categoryPriceLimits?.min_price;
        }

        if (categoryPriceLimits?.max_price > currentPriceLimits?.maxPrice) {
          data.priceLimits.maxPrice = +categoryPriceLimits?.max_price;
        }

        if (currentPriceLimits?.minPrice == 0) {
          data.priceLimits.minPrice = +categoryPriceLimits?.min_price;
          data.priceLimits.maxPrice = +categoryPriceLimits?.max_price;
        }
        state.priceLimits = data.priceLimits;
      }
    },
  },
});

export const {
  fetchCategoryPriceLimitsSuccess,
  fetchCategoryPriceLimitsRequest,
  fetchCategoryPriceLimitsFailure,
  fetchShopAllPriceLimitsSuccess,
  fetchShopAllPriceLimitsRequest,
  fetchShopAllPriceLimitsFailure,
  setShopAllPriceLimits,
  setPriceLimitsByCategoryId,
} = categoryPriceLimitsSlice.actions;

export default categoryPriceLimitsSlice.reducer;
