import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GlobalSliceType {
  isEditMode: boolean;
  filterBarTopPosition: number;
}

const globalSlice = createSlice({
  name: 'global',
  initialState: <GlobalSliceType>{
    isEditMode: false,
    filterBarTopPosition: 0,
  },
  reducers: {
    toggleEditMode(state, { payload: override }: PayloadAction<boolean | undefined>) {
      if (typeof override === 'boolean') {
        state.isEditMode = override;
      } else {
        state.isEditMode = !state.isEditMode;
      }
    },
    updateFilterBarTopPosition(state, { payload }) {
      state.filterBarTopPosition = payload;
    },
  },
});

export const { toggleEditMode, updateFilterBarTopPosition } = globalSlice.actions;

export default globalSlice.reducer;
