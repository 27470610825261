interface TextItem {
  id: number;
  heading: string;
  paragraph: string;
}

interface Text {
  id: number;
  name: string;
  ctaLink: string;
  ctaText: string;
  showCta: boolean;
  texts: Array<TextItem>;
}

export const processResponse = (text: Record<string, unknown>): Text => {
  return {
    id: text.id,
    name: text.name,
    ctaLink: text.cta_link,
    ctaText: text.cta_text,
    showCta: text.show_cta,
    layout: text.layout,
    texts: text?.texts?.map((text: Record<string, number | string>) => {
      return {
        id: text.id,
        heading: text.heading,
        paragraph: text.paragraph,
      };
    }),
  };
};

export default Text;
