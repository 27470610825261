import ImageAndText from '@common/models/ImageAndText';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ImageAndTextSliceType {
  sections?: Record<number, ImageAndText>;
}

export interface ImageAndTextTextResponseType {
  id: number;
  result: ImageAndText;
}

const imageAndTextSlice = createSlice({
  name: 'imageAndText',
  initialState: <ImageAndTextSliceType>{
    sections: {},
  },
  reducers: {
    fetchImageAndTextRequest(state, { payload }) {
      const imageAndTextObject = {
        isFetching: true,
        isFetched: false,
        imageAndText: null,
        error: '',
        layout: null,
      };
      state[payload.id] = imageAndTextObject;
    },
    fetchImageAndTextSuccess(state, { payload }: PayloadAction<ImageAndTextTextResponseType>) {
      const imageAndTextObject = {
        isFetching: false,
        isFetched: true,
        imageAndText: payload.result,
        error: '',
        layout: payload.layout,
      };
      state[payload.id] = imageAndTextObject;
    },
    fetchImageAndTextFailure() {
      //   state.error = payload;
      //   state.isFetching = false;
      //   state.isFetched = true;
    },
    editImageAndText(state, { payload }: unknown) {
      const imageAndTextObject = {
        isFetching: false,
        isFetched: true,
        imageAndText: payload.result,
        error: '',
        layout: payload.layout,
      };
      state[payload.id] = imageAndTextObject;
    },
  },
});

export const {
  fetchImageAndTextSuccess,
  fetchImageAndTextRequest,
  fetchImageAndTextFailure,
  editImageAndText,
} = imageAndTextSlice.actions;

export default imageAndTextSlice.reducer;
