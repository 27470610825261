import * as Order from '@common/models/Order';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserOrdersResponseType {
  count: number;
  next: string | null;
  previous: string | null;
  results: Order.default[];
}

export interface UserOrdersResponsePayloadType {
  response: UserOrdersResponseType;
  queryParams: UserOrdersQueryParams;
}

export interface UserOrdersQueryParams {
  limit: number;
  page: number;
  ordering?: 'created' | '-created';
}

export const getUserOrdersQueryParamsInitialState = (): UserOrdersQueryParams => ({
  limit: 24,
  page: 1,
  ordering: '-created',
});

export interface UserOrdersSliceType {
  pagination: {
    currentPage: number;
    lastPage: number;
    multiplier: number;
  };
  currentQueryParams?: UserOrdersQueryParams;
  orders?: UserOrdersResponseType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const userOrdersSlice = createSlice({
  name: 'userOrders',
  initialState: <UserOrdersSliceType>{
    pagination: {
      currentPage: 1,
      lastPage: 1,
      multiplier: 1,
    },
    orders: null,
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchUserOrdersRequest(state, { payload: { queryParams } }: PayloadAction<{ queryParams: UserOrdersQueryParams }>) {
      state.isFetching = true;
      state.isFetched = false;
      state.orders = null;
      state.currentQueryParams = queryParams;
      state.error = '';
    },
    fetchUserOrdersSuccess(
      state,
      { payload: { response: orders, queryParams } }: PayloadAction<UserOrdersResponsePayloadType>,
    ) {
      state.orders = orders;
      state.isFetching = false;
      state.isFetched = true;
      state.pagination = {
        currentPage: queryParams.page,
        lastPage: parseInt(`${(orders.count - 1) / queryParams.limit + 1}`),
        multiplier: queryParams.limit,
      };
    },
    fetchUserOrdersFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
      state.orders = null;
    },
  },
});

export const { fetchUserOrdersSuccess, fetchUserOrdersRequest, fetchUserOrdersFailure } = userOrdersSlice.actions;

export default userOrdersSlice.reducer;
