import NudgeList from '@common/models/NudgeList';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NudgesSliceType {
  results: NudgeList;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const nudgesSlice = createSlice({
  name: 'nudges',
  initialState: <NudgesSliceType>{
    results: {
      nudges: [],
      showNudges: false,
      isUserOnPremiumPlan: false,
    },
    isFetching: false,
    isFetched: false,
    error: '',
  },
  reducers: {
    fetchNudgesRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.results = null;
      state.error = '';
    },
    fetchNudgesSuccess(state, { payload }: PayloadAction<NudgeList>) {
      state.results = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchNudgesFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = false;
    },
  },
});

export const { fetchNudgesRequest, fetchNudgesSuccess, fetchNudgesFailure } = nudgesSlice.actions;

export default nudgesSlice.reducer;
