import { ToastVariant } from '@common/../lib-components/Toast';
import { getData, postData, URLs } from '@common/api';
import * as StoreUpdate from '@common/models/StoreUpdate';
import { RootState } from '@common/store';
import { addToast } from '@common/store/toasts/slice';
import { ADD_NEW_SECTION_ID } from '@common/utils/constants';
import { isFormError } from '@common/utils/error';
import { getLocalStorageItem, setLocalStorageItem } from '@common/utils/token';
import { validateEmail, validateRequired } from '@common/utils/validations';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useForm from './useForm';

const SUBSCRIBE_BUTTON_STATES = {
  INITIAL: 'INITIAL',
  FORM: 'FORM',
  FINAL: 'FINAL',
  HIDE: 'HIDE',
};

const PRIVACY_POLICY_WARNING = 'Please accept the privacy policy';
const HIDE_LOCAL_STORAGE_KEY = 'subscribe-hide';
const validate = (values: Record<string, string>) => {
  const errors = {
    email: [validateRequired(values.email), validateEmail(values.email)].filter((x) => x)?.[0],
  };
  return errors;
};

// const initialValues = {
//   ctaText: 'Subscribe',
//   subTitle: 'Receive important store related news, exclusive discounts, new product launches and more...',
//   title: 'Stay up to date',
//   layout: {},
//   id: -1,
//   name: 'Store Update',
// };

// eslint-disable-next-line
export default (userSectionId: string): any => {
  const userProfile = useSelector((state: RootState) => state?.userProfile);
  const storeInfo = useSelector((state: RootState) => state.storeInfo);
  const [currentState, setCurrentState] = useState(SUBSCRIBE_BUTTON_STATES.INITIAL);
  const [storeUpdate, setStoreUpdate] = useState({});
  const form = useForm({
    validate,
    initialValues: {},
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const fetchStoreUpdate = async (id: string) => {
    try {
      const { data } = await getData({ url: URLs.GET_STORE_UPDATE.replace('{id}', id) });
      setStoreUpdate(StoreUpdate.processResponse(data));
    } catch (e) {
      console.log('StoreUpdate Error', e?.response?.data);
    }
  };
  const firstStepHandler = () => setCurrentState(SUBSCRIBE_BUTTON_STATES.FORM);
  const secondStepHandler = async (values: Record<string, string>) => {
    if (!values.accept) {
      dispatch(
        addToast({
          content: 'Please accept the privacy policy',
          variant: ToastVariant.error,
          dismissAfterMillis: 5000,
        }),
      );
      return;
    }
    const payload = { email: values.email };
    try {
      await postData({ url: URLs.POST_SUBSCRIBE, data: payload });
      setCurrentState(SUBSCRIBE_BUTTON_STATES.FINAL);
      setLocalStorageItem(HIDE_LOCAL_STORAGE_KEY, 'true');
    } catch (e) {
      if (isFormError(e)) {
        form.setDynamicErrors(e?.response?.data || {});
      } else {
        dispatch(
          addToast({
            content: 'Some error occurred',
            variant: ToastVariant.error,
            dismissAfterMillis: 5000,
          }),
        );
      }
    }
  };
  const doItLater = () => {
    setCurrentState(SUBSCRIBE_BUTTON_STATES.INITIAL);
  };
  const unsubscribe = async (email: string) => {
    if (!email) {
      history.push('/');
      return;
    }
    try {
      await postData({ url: URLs.POST_UNSUBSCRIBE, data: { email: email } });
      dispatch(
        addToast({
          content: 'Unsubscribed Successfully',
          variant: ToastVariant.success,
          dismissAfterMillis: 5000,
        }),
      );
    } catch (e) {
      if (e?.response?.status === 404) {
        dispatch(
          addToast({
            content: 'Email has already been unsubscribed',
            variant: ToastVariant.success,
            dismissAfterMillis: 5000,
          }),
        );
      } else {
        dispatch(
          addToast({
            content: 'Some error occurred',
            variant: ToastVariant.error,
            dismissAfterMillis: 5000,
          }),
        );
      }
    }
    history.push('/');
  };

  useEffect(() => {
    const hideLocalStorageValue = getLocalStorageItem(HIDE_LOCAL_STORAGE_KEY);
    if (hideLocalStorageValue) {
      setCurrentState(SUBSCRIBE_BUTTON_STATES.HIDE);
    }
  }, []);

  useEffect(() => {
    if (userProfile?.profile?.email) {
      form.setValues({ email: userProfile?.profile?.email });
    }
  }, [userProfile?.profile?.email]);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data.type === 'PREVIEW_STORE_UPDATES') {
        setStoreUpdate(e.data.previewData);
      }
    });
    userSectionId !== ADD_NEW_SECTION_ID && fetchStoreUpdate(userSectionId);
  }, []);

  return {
    currentState,
    setCurrentState,
    firstStepHandler,
    secondStepHandler,
    SUBSCRIBE_BUTTON_STATES,
    form,
    storeInfo,
    doItLater,
    PRIVACY_URL: '/policy/privacy',
    PRIVACY_POLICY_WARNING,
    unsubscribe,
    storeUpdate,
    setStoreUpdate,
  };
};
