import { SectionEnum } from './SectionEnum';

export interface Text {
  type: SectionEnum.TEXT;
  name: string;
  content: string;
  additionalContent: string;
}

export const textProcessResponse = (text: Record<string, never>): Text => {
  return {
    type: text.type,
    name: text.name,
    content: text.content,
    additionalContent: text.additional_content,
  };
};
