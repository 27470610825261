import { ToastVariant } from '@lib/Toast';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ToastType {
  content: string | React.ReactNode;
  variant: ToastVariant;
  dismissAfterMillis?: number;
  productImage?: string;
  type?: string;
  options?: Array<OptionType>;
  isIframe?: boolean;
  textColor?: string;
  onClose?: () => void;
}
interface OptionType {
  name: string;
}
export interface ToastWithKeyType extends ToastType {
  key: number;
}

const toastsSlice = createSlice({
  name: 'toasts',
  initialState: [] as ToastWithKeyType[],
  reducers: {
    addToast: {
      reducer(state, { payload: toast }: PayloadAction<ToastWithKeyType>) {
        state.push(toast);
      },
      prepare(toast: ToastType) {
        const toastWithKey = {
          key: Math.trunc(Math.random() * Math.pow(10, 9)),
          ...toast,
        };
        return { payload: toastWithKey };
      },
    },
    removeToast(state, { payload: key }: PayloadAction<number>) {
      const index = state.findIndex((toast) => toast.key === key);
      if (index >= 0) {
        state.splice(index, 1);
      }
    },
    removeAllToasts() {
      return [];
    },
  },
});

export const { addToast, removeToast, removeAllToasts } = toastsSlice.actions;

export default toastsSlice.reducer;
