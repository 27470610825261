interface ReferralProgram {
  createdDate: string;
  expiryDate: string;
  referrerDiscount: string;
  refereeDiscount: string;
  inviteLimit: number;
  totalSales: number;
  totalDiscount: number;
}

export const processResponse = (referralProgram: Record<string, never>): ReferralProgram => {
  return {
    createdDate: referralProgram?.created_date,
    expiryDate: referralProgram?.expiry_date,
    referrerDiscount: referralProgram?.referrer_discount,
    refereeDiscount: referralProgram?.referee_discount,
    inviteLimit: referralProgram?.invite_limit,
    totalSales: referralProgram?.total_sales,
    totalDiscount: referralProgram?.total_discount,
  };
};

export default ReferralProgram;
