import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FetchCategoriesSuccessResponseType {
  count: number;
  next: string | null;
  previous: string | null;
  results: [];
}

export interface FetchCategoriesRequestPayloadType {
  useCache?: boolean;
  fetchAfterServe?: boolean;
  sectionId: string;
  userId?: number;
}

export interface FetchCategoriesSuccessPayloadType {
  sectionId: string;
  categories: FetchCategoriesSuccessResponseType;
  title: string;
  showcaseType: number;
  layout: {
    name: string;
    id: number;
    image?: string;
  };
}

export interface FetchCategoriesFailurePayloadType {
  sectionId: string;
  error: string;
}

export interface CategoriesSliceType {
  list?: FetchCategoriesSuccessResponseType;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
}

const categoriesSlice = createSlice({
  name: 'categoryList',
  initialState: <Record<string, CategoriesSliceType>>{},
  reducers: {
    fetchCategoryListRequest(state, { payload: { sectionId } }: PayloadAction<FetchCategoriesRequestPayloadType>) {
      state[sectionId] = {
        ...state[sectionId],
        isFetching: true,
        isFetched: false,
        id: sectionId,
        layout: null,
        error: '',
      };
    },
    fetchCategoryListSuccess(
      state,
      {
        payload: { sectionId, categories, title, layout, showcaseType },
      }: PayloadAction<FetchCategoriesSuccessPayloadType>,
    ) {
      const stateObject = {
        isFetching: false,
        isFetched: true,
        id: sectionId,
        list: categories,
        title: title,
        error: '',
        layout: layout,
        showcaseType: showcaseType,
      };
      state[sectionId] = stateObject;
    },
    fetchCategoryListFailure(
      state,
      { payload: { sectionId, error } }: PayloadAction<FetchCategoriesFailurePayloadType>,
    ) {
      const stateObject = {
        isFetching: false,
        isFetched: true,
        id: sectionId,
        error: error,
        list: null,
      };
      state[sectionId] = stateObject;
    },
    editCategoryList(state, { payload }: unknown) {
      const stateObject = {
        isFetching: false,
        isFetched: true,
        id: payload.id,
        list: { results: payload.categories },
        title: payload.title,
        layout: payload.layout,
        showcaseType: payload.showcaseType,
        error: '',
      };
      state[payload.id] = stateObject;
    },
  },
});

export const {
  fetchCategoryListSuccess,
  fetchCategoryListRequest,
  fetchCategoryListFailure,
  editCategoryList,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
