import { createBrowserHistory, createMemoryHistory, History, LocationDescriptor, parsePath } from 'history';
import queryString from 'query-string';

import { isBrowser } from '.';

export function inIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const historyTimeline = [];

const createCustomBrowserHistory = (): History => {
  const customBrowserHistory = createBrowserHistory();
  historyTimeline.push(window.location);

  const originalBrowserHistoryPush = customBrowserHistory.push;
  const queryParamsToPreserve = ['theme'];

  customBrowserHistory.push = (path: LocationDescriptor | string, state?: History.LocationState) => {
    if (path == null) {
      return;
    }
    const nextLocation = typeof path === 'string' ? { ...parsePath(path as string), state } : path;

    const currentQuery = queryString.parse(customBrowserHistory.location.search);
    const preservedQuery = Object.keys(currentQuery).reduce((preservedQuery, key) => {
      if (queryParamsToPreserve.includes(key)) {
        return {
          ...preservedQuery,
          [key]: currentQuery[key],
        };
      }
      return preservedQuery;
    }, {});

    const nextQuery = {
      ...preservedQuery,
      ...queryString.parse(nextLocation.search),
    };

    nextLocation.search = queryString.stringify(nextQuery);
    // the following is to support the update of url when we click a link
    // in inside the iframe. Iframe is used for showing a mobile view for Admin
    if (inIframe()) {
      window.top.postMessage({ location: nextLocation }, '*');
    }
    historyTimeline.push(nextLocation);
    originalBrowserHistoryPush(nextLocation);
  };

  return customBrowserHistory;
};

const history = !isBrowser() ? createMemoryHistory() : createCustomBrowserHistory();

export default history;
