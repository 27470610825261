interface ValueType {
  id: string;
  name: string;
  productCount: number;
}

interface ShopAttribute {
  id: number;
  idString: string;
  name: string;
  sortOrder: number;
  filterable: boolean;
  filterType: string;
  values: ValueType[];
}

// interface ShopAttribute {
//   keys: KeyType[];
// }

export default ShopAttribute;

export const processResponse = (shopAttributes: Record<string, never>[]): ShopAttribute[] => {
  const keys = shopAttributes?.map((key) => {
    const values = (key.filter_values as Record<string, never>[])?.map((value) => {
      return {
        id: value.value,
        name: value.value,
        productCount: value.product_count,
      };
    });
    return {
      id: key.id,
      idString: (key?.id as number)?.toString(),
      name: key.name,
      sortOrder: key.sort_order,
      filterable: true,
      filterType: key.filter_type,
      values,
    };
  });

  return keys;
};
