import { processCartRequest } from '../models/Cart';
import { getFormCustomField } from '../models/CustomField';
import Product, { ProductOverridePropsType } from '../models/Product';
import { RootState, store } from '../store';
import { CartProductType, computeResellerForProducts } from '../store/cart/slice';
import { saveToLocalStorage } from '.';
import { trackEvent } from './analytics';
import { CART_LOCAL_STORAGE_KEY } from './constants';
import { getMerchantJWTToken, setLocalStorageItem } from './token';

interface PerformCheckoutArgs {
  promoCode?: string;
  totalPrice: number;
  totalItems: number;
  items?: Array<CartProductType>;
  item?: CartProductType;
  values?: Record<string, never>;
  baseItem?: Product;
  finalPrice?: number;
  isOptionsFormValid?: () => boolean;
  isPayWhatYouWantFormValid?: () => boolean;
}

export const getInputElement = (name: string, value: string): HTMLElement => {
  const inputElement = document.createElement('input');
  inputElement.type = 'text';
  inputElement.name = name;
  inputElement.value = value;
  return inputElement;
};

export const performCheckout = async ({
  totalItems,
  promoCode,
  totalPrice,
  items,
  item,
  baseItem,
  values,
  finalPrice,
  isOptionsFormValid,
  isPayWhatYouWantFormValid,
}: // getFormProductOptions,
PerformCheckoutArgs): Promise<void> => {
  store.dispatch(computeResellerForProducts());
  const state: RootState = store.getState();
  saveToLocalStorage(CART_LOCAL_STORAGE_KEY, (state.cart as unknown) as Record<string, unknown>);
  const gmasAccountId = state.storeInfo.storeInfo?.gmasAccountId ?? state.features.features?.user;

  let processedCart;
  if (items) {
    processedCart = processCartRequest({
      promoCode,
      totalItems,
      totalPrice,
      items,
    });
    setLocalStorageItem(`${gmasAccountId}.product_type`, items[0]?.type);
  } else if (item) {
    try {
      if (baseItem.hasOptions && !isOptionsFormValid()) {
        return;
      }
      if (baseItem.payWhatYouWant && !isPayWhatYouWantFormValid()) {
        return;
      }
      const otherProps = { finalPrice } as ProductOverridePropsType;
      if (baseItem.hasOptions) {
        const { additionalCharges, formCustomFields } = await getFormCustomField(baseItem, values);
        otherProps.formCustomFields = formCustomFields;
        otherProps.finalPrice = otherProps.finalPrice + additionalCharges;
      }
      processedCart = processCartRequest({
        promoCode,
        totalItems,
        totalPrice,
        items: [{ ...item, ...otherProps }],
      });
      setLocalStorageItem(`${gmasAccountId}.product_type`, item?.type);
    } catch (e) {
      return;
    }
  }

  trackEvent(
    'Checked Out Cart',
    {
      order_id: '',
      cart_amount: processedCart.totalPrice,
      num_products: processedCart.items?.length,
      num_items: processedCart.totalItems,
      ga_client_id: '',
      self_flag: !!getMerchantJWTToken(),
      seller_username: state.storeInfo.storeInfo?.username,
      seller_account_id: state.storeInfo.storeInfo?.accountId,
      is_mojocommerce_cart: true,
    },
    {
      send_immediately: true,
    },
    () => {
      window.location.href = '/checkout/v2';
    },
  );

  // const formElement = document.createElement('form');
  // formElement.hidden = true;
  // formElement.method = 'POST';
  // formElement.action = `${getStoreUrl()}${getCookie('new-checkout') ? 'cart/checkout/' : 'checkout/'}`;

  // if (processedCart.promoCode) {
  //   formElement.appendChild(getInputElement('promo_code', processedCart.promoCode));
  // }
  // processedCart.items.forEach((item) => {
  //   formElement.appendChild(getInputElement('items', JSON.stringify(item)));
  // });
  // formElement.appendChild(getInputElement('total_price', processedCart.totalPrice.toString()));
  // formElement.appendChild(getInputElement('total_items', processedCart.totalItems.toString()));
  // formElement.appendChild(getInputElement('source', 'web'));

  // document.body.appendChild(formElement);
  // formElement.submit();
};
